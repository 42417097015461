import React, { useEffect, useState } from "react"
import { Button, Card, CardBody } from "reactstrap"
import { clearCookiesExcept, getCookieName } from "../../../helpers/functions"
import { get, post } from "../../../helpers/api_helper"
import toastr from "toastr"
import Swal from "sweetalert2"

const InstagramLogin = () => {
  const [data, setData] = useState({})

  useEffect(() => {
    getConnection()
  }, [])

  const generateLoginUrl = () => {
    const accessToken = getCookieName()
    clearCookiesExcept([accessToken, `_${accessToken}`])
    get(`auth/instagram`)
      .then(res => {
        window.location.href = res.data
      })
      .catch(err => console.log(err))
  }

  const getConnection = async () => {
    try {
      const { data } = await get("instagram/connection")

      setData(data)
    } catch (error) {
      console.log(error?.response?.data?.message || error.message)
    }
  }

  const handleLogOut = () => {
    return Swal.fire({
      title: "Are you sure you want to log out?",
      // text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Logout",
    }).then(result => {
      if (result.isConfirmed) {
        post(`instagram/logout`)
          .then(res => {
            toastr.success("Instgram logged out")
            getConnection()
          })
          .catch(err => {
            toastr.error(err?.response?.data?.message)
          })
      }
    })
  }

  return (
    <Card>
      <CardBody>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center gap-3 p-2">
            <img src="/sm-insta.svg" width="40" alt="logo" />
            <h6 className="m-0">Instagram</h6>
          </div>
          <div className="d-flex align-items-center gap-3 p-2">
            {data?.instagramId?.length > 0 ? (
              <>
                <Button className={`bg-soft-success`} color={"success"}>
                  Connected
                </Button>
                <Button className="bg-soft-danger" color="danger" onClick={handleLogOut}>
                  Logout
                </Button>
              </>
            ) : (
              <>
                <Button className="bg-soft-primary" color="primary" onClick={generateLoginUrl}>
                  Login
                </Button>
              </>
            )}
          </div>
        </div>
        {data?.username && data?.instagramId?.length > 0 && (
          <div className="m-2">
            <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex gap-3 align-items-end">
                <div className="d-flex gap-1 font-size-14 align-items-end justify-content-center">
                  <img className="rounded-circle " src={data?.profile} alt="profile" width={20} />
                  <div className="fw-bold font-size-14">{data?.username}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  )
}

export default InstagramLogin
