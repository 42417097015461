/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, CardHeader, Col, Label, Row } from "reactstrap"
import SelectErrorMessage from "../../Common/SelectErrorMessage"
// import TablePagination from "../../Common/TablePagination"
import { Tooltip } from "@mui/material"
import BulkUploadModal from "../BulkUploadModal"
import Select from "react-select"
import { post, get } from "../../../helpers/api_helper"
import * as mainConfig from "../../../pages/Whatsapp/config"
import toastr from "toastr"
import "./style.scss"
import _ from "lodash"
import { PhoneFrame } from ".."
import { API_URL } from "../../../config"

const index = ({ setRefresh }) => {
  const [masterObj, setMasterObj] = useState({})
  const [selected, setSelected] = useState({})
  const [error, setError] = useState({})
  const [options, setOptions] = useState()
  const [previewData, setPreviewData] = useState({})
  const scheduleMinTime = moment().add(5, "minutes")

  useEffect(() => {
    fetchOptions(mainConfig.OPTIONS_ROUTE.template, "template")
    fetchOptions(mainConfig.OPTIONS_ROUTE.contactCategory(2), "category")
  }, [])

  const fetchOptions = async (OPTIONS_ROUTE, NAME) => {
    try {
      let res = await get(OPTIONS_ROUTE)
      if (res.data) {
        setOptions(prev => ({ ...prev, [NAME]: res.data }))
      }
    } catch (error) {
      console.error(`Error ${NAME} options fetching`)
    }
  }

  const formRef = useRef()

  const [file, setFile] = useState(null)
  const [show, setShow] = useState(false)
  const toggle = () => {
    setShow(!show)
  }

  const handleChange = (e, set_name = null) => {
    let name = set_name ? set_name : e?.target.name
    let value = set_name
      ? Array.isArray(e)
        ? e.map(doc => doc.value)
        : e?.value
      : e.target?.value ?? ""

    if (set_name) {
      setSelected(prev => {
        let obj = { ...prev }
        obj[set_name] = e
        return obj
      })
    }

    setMasterObj(prev => {
      let obj = { ...prev }
      obj[name] = value

      if (name === "template") {
        let whitelistedMediaType = ["image", "video", "document"]
        obj.variableBodyText =
          e?.body?.replacementDetails?.filter(doc => doc?.custom)?.length > 0
            ? e?.body?.replacementDetails?.filter(doc => doc?.custom)
            : []
        obj.variableHeaderText =
          e?.header?.replacementDetails?.filter(doc => doc?.custom)?.length > 0
            ? e?.header?.replacementDetails?.filter(doc => doc?.custom)
            : []

        obj.variableButtonText = e?.buttons?.filter(
          btn => btn.mode === "dynamic" && btn.replacementDetails.custom
        )

        obj.headerMediaType = e?.headerMediaType ?? "none"
        if (e?.uploaded) {
          obj.headerUrl = `${API_URL}${e?.uploaded}`
        }

        setPreviewData({
          file: whitelistedMediaType.includes(obj.headerMediaType) ? true : false,
          headerMediaType: obj.headerMediaType,
          header: e?.header,
          body: e?.body,
          footer: e?.footer,
          buttons: e?.buttons,
        })
      }

      inputErrorCheck(obj)

      return obj
    })
  }

  const reset = () => {
    setMasterObj({})
    setSelected({})
    setPreviewData({})
    setError({})
    formRef?.current?.reset()
  }

  const inputErrorCheck = (obj = masterObj) => {
    let flag = false
    // let CONTACT_LIMIT = 3

    if (!obj.schedule) {
      setError(prev => ({ ...prev, schedule: true }))
      flag = true
    }

    if (!obj.template) {
      setError(prev => ({ ...prev, template: true }))
      flag = true
    }

    if (!obj.category || obj?.category?.length === 0) {
      setError(prev => ({ ...prev, category: true }))
      flag = true
    }

    if (obj?.schedule === 2 && obj?.scheduleDate && obj?.scheduleTime) {
      let minTimeEntered = moment(`${obj?.scheduleDate} ${obj?.scheduleTime}`, "YYYY-MM-DD HH:mm")
      if (!minTimeEntered.isAfter(scheduleMinTime)) {
        flag = true
        setError(prev => ({ ...prev, scheduleTime: true }))
      } else {
        flag = false
        setError(prev => ({ ...prev, scheduleTime: false }))
      }
    }
    return flag
  }

  const handleSubmit = () => {
    let flag = inputErrorCheck()
    if (flag) {
      return
    }

    post(mainConfig.ROUTE.broadcast({ post: true }, _, {}), masterObj)
      .then(res => {
        toastr.success(res.message)
        reset()
        setRefresh(true)
      })
      .catch(err => {
        toastr.error(err.message)
      })
  }

  const handleExampleContent = (name, e, idx) => {
    let value = e?.target.value
    setMasterObj(prev => {
      let obj = { ...prev }
      obj[name][idx].value = value
      return obj
    })
  }

  return (
    <>
      <div className="template-creation-model">
        <BulkUploadModal
          setFile={setFile}
          show={show}
          toggle={toggle}
          urlPath={"whatsapp/contact/bulk-upload/"}
          selectOptions={"options/contactCategory"}
        />

        <Card className="create-broadcast">
          <CardHeader>
            <h6 className="bold" style={{ textWrap: "nowrap" }}>
              Add Broadcast
            </h6>

            <div className="extra-options">
              <div className="input-output">
                <Tooltip title="Import (.xls, .xlsx)" arrow placement="bottom">
                  <Button onClick={toggle}>
                    <i className="uil-import" />
                    <span>{file ? "Imported" : "Import Contact"}</span>
                  </Button>
                </Tooltip>
              </div>

              <Tooltip title="Download sample file">
                <a
                  href={`./sample/bulk_upload_broadcast_templates.xlsx`}
                  target="_blank"
                  rel="noreferrer"
                  download
                  style={{ fontSize: "1rem", width: "fit-content" }}
                  className="d-flex align-items-center btn btn-success download-btn"
                >
                  <i style={{ fontSize: "13px" }} className="fa fa-solid fa-download" />
                  <span style={{ marginLeft: "5px", fontSize: "13px" }}>Download Sample Sheet</span>
                </a>
              </Tooltip>
            </div>
          </CardHeader>
          <CardBody>
            <AvForm
              ref={formRef}
              className="needs-validation"
              onValidSubmit={(e, v) => {
                handleSubmit()
              }}
              onInvalidSubmit={(e, v) => {
                inputErrorCheck()
              }}
            >
              <Row>
                <Col md={12} lg={4}>
                  <div className="mb-3">
                    <Label>
                      Broadcast Name<span className="text-danger"> *</span>
                    </Label>
                    <AvField
                      name="broadcastName"
                      type="text"
                      placeholder="Broadcast name"
                      value={masterObj?.broadcastName ?? ""}
                      onChange={e => handleChange(e)}
                      errorMessage="Enter a broadcast name"
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                </Col>

                <Col md={12} lg={4} className="mb-3">
                  <div className="mb-3">
                    <Label>
                      Select Template<span className="text-danger"> *</span>
                    </Label>
                    <Select
                      name="template"
                      className="mb-3"
                      placeholder="Select a template"
                      options={options?.template ?? ""}
                      isClearable
                      value={selected?.template ?? ""}
                      onChange={e => handleChange(e, "template")}
                    />
                    <SelectErrorMessage
                      message="Please select a template"
                      show={!masterObj?.template && error?.template}
                    />
                  </div>
                </Col>

                <Col md={12} lg={4} className="mb-3">
                  <div className="mb-3">
                    <Label>
                      Select Category<span className="text-danger"> *</span>
                    </Label>
                    <Select
                      name="category"
                      className="mb-3"
                      placeholder="Select a category"
                      options={options?.category ?? ""}
                      isClearable
                      isMulti
                      value={selected?.category ?? ""}
                      closeMenuOnSelect={false}
                      onChange={e => handleChange(e, "category")}
                    />
                    <SelectErrorMessage
                      message="Please select a category"
                      show={
                        (!masterObj?.category || masterObj?.category?.length === 0) &&
                        error?.category
                      }
                    />
                  </div>
                </Col>
              </Row>

              <Col md={12} lg={4} className="mb-3">
                <div className="mb-3">
                  <Label>
                    When do you want to send it?<span className="text-danger"> *</span>
                  </Label>
                  <Select
                    name="schedule"
                    placeholder="Select Schedule"
                    options={[
                      {
                        label: "Send Now",
                        value: 1,
                      },
                      {
                        label: "Schedule for a specific time",
                        value: 2,
                      },
                    ]}
                    isClearable
                    value={selected?.schedule ?? ""}
                    onChange={e => handleChange(e, "schedule")}
                    className="mb-3"
                  />
                  <SelectErrorMessage
                    message="Please select a schedule"
                    show={!masterObj?.schedule && error.schedule}
                  />
                </div>
              </Col>

              {masterObj?.schedule === 2 && (
                <Row>
                  <Col md={6} lg={4} className="mb-3">
                    <div className="mb-3">
                      <Label>
                        Date <span className="text-danger"> *</span>
                      </Label>
                      <AvField
                        type="date"
                        name="scheduleDate"
                        placeholder="Schedule Date"
                        value={masterObj?.scheduleDate ?? ""}
                        min={moment().format("YYYY-MM-DD")}
                        onChange={e => handleChange(e)}
                        errorMessage="Please select a date"
                        required={masterObj?.schedule === 2}
                      />
                    </div>
                  </Col>

                  <Col md={6} lg={4} className="mb-3">
                    <div className="mb-3">
                      <Label>
                        Time <span className="text-danger"> *</span>
                      </Label>
                      <AvField
                        type="time"
                        name="scheduleTime"
                        placeholder="Schedule Date"
                        value={masterObj?.scheduleTime ?? ""}
                        onChange={e => handleChange(e)}
                        errorMessage="Please select a time"
                        required={masterObj?.schedule === 2}
                      />
                      <SelectErrorMessage
                        message="Selected time must be at least 5 minutes later than the current time"
                        show={error.scheduleTime}
                      />
                    </div>
                  </Col>
                </Row>
              )}

              {masterObj?.variableHeaderText?.length > 0 ||
              masterObj?.variableBodyText?.length > 0 ||
              masterObj?.variableButtonText?.length > 0 ||
              previewData.file ? (
                <Col md={12} className="mt-3">
                  <div className="mb-3">
                    <Label className="bold">Variable content</Label>
                    <p>Just enter variable value here (it does need to be exact).</p>

                    {previewData.file ? (
                      <Col md={12}>
                        <Label className="bold">Provide the dynamic media</Label>

                        <AvField
                          name="headerUrl"
                          type="url"
                          placeholder={`Enter url of ${masterObj?.headerMediaType} here`}
                          value={masterObj?.headerUrl ?? ""}
                          onChange={e => handleChange(e)}
                          errorMessage="Enter url here"
                          validate={{
                            required: { value: true },
                            maxLength: { value: 2000 },
                          }}
                        />
                      </Col>
                    ) : null}

                    {masterObj?.variableHeaderText?.length > 0 ? (
                      <Col md={12}>
                        <Label className="bold">Variable content of Header</Label>
                        {masterObj?.variableHeaderText?.map((dataField, key) => (
                          <Col md={12} className={"mb-4"} key={key}>
                            <AvField
                              name={`variableHeader-${key}`}
                              type="text"
                              placeholder={`Enter ${masterObj?.variableHeaderText[key]?.placeholder} value here`}
                              value={masterObj?.variableHeaderText[key]?.value ?? ""}
                              onChange={e => handleExampleContent("variableHeaderText", e, key)}
                              errorMessage="Enter variable value here"
                              validate={{
                                required: { value: true },
                                maxLength: { value: 200 },
                              }}
                            />
                          </Col>
                        ))}
                      </Col>
                    ) : null}

                    {masterObj?.variableBodyText?.length > 0 ? (
                      <Col md={12}>
                        <Label className="bold">Variable content of body</Label>
                        {masterObj?.variableBodyText?.map((dataField, key) => (
                          <Col md={12} className={"mb-4"} key={key}>
                            <AvField
                              name={`variableBody-${key}`}
                              type="text"
                              placeholder={`Enter ${masterObj?.variableBodyText[key]?.placeholder} value here`}
                              value={masterObj?.variableBodyText[key]?.value ?? ""}
                              onChange={e => handleExampleContent("variableBodyText", e, key)}
                              errorMessage="Enter variable value here"
                              validate={{
                                required: { value: true },
                                maxLength: { value: 200 },
                              }}
                            />
                          </Col>
                        ))}
                      </Col>
                    ) : null}

                    {masterObj?.variableButtonText?.length > 0 ? (
                      <Col md={12}>
                        <Label className="bold">Variable content of button</Label>
                        {masterObj?.variableButtonText?.map((dataField, key) => (
                          <Col md={12} className={"mb-4"} key={key}>
                            <AvField
                              name={`variableButton-${key}`}
                              type="text"
                              placeholder={`Enter url path here`}
                              value={masterObj?.variableButtonText[key]?.value ?? ""}
                              onChange={e => handleExampleContent("variableButtonText", e, key)}
                              errorMessage="Enter variable value here"
                              validate={{
                                required: { value: true },
                                maxLength: { value: 200 },
                              }}
                            />
                          </Col>
                        ))}
                      </Col>
                    ) : null}
                  </div>
                </Col>
              ) : null}

              <Row>
                <Col md="12">
                  <div
                    className="mb-3"
                    style={{
                      paddingTop: "20px",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Button color="primary" aria-label="sendNow" type="submit">
                      {masterObj?.schedule === 2 ? "Add Broadcast" : "Send Broadcast"}
                    </Button>

                    <Button onClick={reset} color="danger" type="button">
                      Discard
                    </Button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>

        <PhoneFrame
          masterObj={masterObj}
          previewData={previewData}
          file={previewData?.file}
          header={previewData?.header}
          body={previewData?.body}
          footer={previewData?.footer}
          buttons={previewData?.buttons}
        />
      </div>
    </>
  )
}
export default index
