import React, { useEffect, useState } from "react";
import { Modal, Card, CardBody, Button } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import {
  dateConverter,
  numberToString,
  timeConverter,
} from "../../../helpers/functions";
import { get } from "../../../helpers/api_helper";
import { API_URL } from "../../../config";

const BulkUploadDetails = ({ isOpen, onToggle, id }) => {
  const statusValue = {
    0: { text: "Success", color: "success" },
    1: { text: "Failed", color: "danger" },
  };
  
  const [tableData, setTableData] = useState([]);
  const [masterData, setMasterData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isOpen && id) handleData();
  }, [isOpen && id]); // eslint-disable-line

  const handleData = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const response = await get(`bulk-upload/details?id=${id}`);
  
      if (!response.success) {
        throw new Error(response.message || 'Failed to fetch data');
      }
  
      const data = response.data;
  
      // Format master data
      const formattedMasterData = {
        ...data,
        date: dateConverter(data.date),
        time: timeConverter(data.time),
        staff: data.addedBy ? `${data.addedBy.firstName} ${data.addedBy.lastName}`.trim() : '--',
        total: numberToString(data.count?.total),
        failed: numberToString(data.count?.failed),
        success: numberToString(data.count?.success),
        fileName: data.fileName?.startsWith('/') ? data.fileName : `/${data.fileName}`
      };
  
      setMasterData(formattedMasterData);
  
      // Format table data using original request data
      const formattedTableData = data.tableData.map((item, index) => ({
        id: index + 1,
        name: item.name || "----",
        mobileNo: item.mobileNo || "----",
        email: item.email || "----",
        leadSource: item.leadSource || "----",
        level: item.level || "----",
        priority: item.priority || "----",
        address: item.address || "----",
        comments: item.comments || "----",
        campaign: item.campaign || "----",
        status: (
          <Button size="sm" disabled color={statusValue[item.status]?.color || 'secondary'}>
            {statusValue[item.status]?.text || 'Unknown'}
          </Button>
        ),
        remarks: item.remarks || "----"
      }));
  
      setTableData(formattedTableData);
    } catch (error) {
      console.error('Error fetching details:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Name", field: "name" },
      { label: "Mobile", field: "mobileNo" },
      { label: "Email", field: "email" },
      { label: "Lead Source", field: "leadSource" },
      { label: "Level", field: "level" },
      { label: "Priority", field: "priority" },
      { label: "Address", field: "address" },
      { label: "Comments", field: "comments" },
      { label: "Campaign", field: "campaign" },
      { label: "Status", field: "status" },
      { label: "Remarks", field: "remarks" },
    ],
    rows: tableData,
  };

  return (
    <Modal isOpen={isOpen} toggle={onToggle} size="xl" centered={true}>
      <div className="modal-header">
        <div className="modal-title">
          <h5 className="modal-title mb-0">View Details</h5>
        </div>
        <button
          type="button"
          onClick={onToggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        <div className="row mb-4">
          <div className="col-md-12">
            <div className="d-flex flex-wrap gap-5">
              <div><strong>Date:</strong> {masterData.date}</div>
              <div><strong>Time:</strong> {masterData.time}</div>
              <div><strong>Total:</strong> {masterData.total}</div>
              <div><strong>Success:</strong> {masterData.success}</div>
              <div><strong>Failed:</strong> {masterData.failed}</div>
              <div><strong>Added By:</strong> {masterData.staff}</div>
              <div>
                <strong>File:</strong>{" "}
                <a href={`${API_URL}${masterData?.fileName}`} download>
                  <i className="fa fa-file-excel excel-icon"></i>
                </a>
              </div>
              <div><strong>Remarks:</strong> {masterData.remarks || '--'}</div>
            </div>
          </div>
        </div>

        <Card>
          <CardBody>
            <MDBDataTable
              id="leadsBulkDetailsTable"
              responsive
              bordered
              data={data}
              info={false}
              paging={false}
              sortable={false}
              searching={false}
              disableRetreatAfterSorting={true}
            />
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

export default BulkUploadDetails;