import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Card, CardBody, Button, Label, CardHeader } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import Breadcrumbs from "../../../../components/Common/Breadcrumb.js";
import moment from "moment";
import toastr from "toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { get, post, put } from "../../../../helpers/api_helper.js";
import Swal from "sweetalert2";

const ResponseMain = () => {
  const API_URL = process.env.REACT_APP_APIURL + 'response-type'

  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedResponse, setSelectedResponse] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const columns = [
    {
      label: "#",
      field: "index",
      sort: "asc",
      width: 50,
    },
    {
      label: "Date",
      field: "date",
      sort: "asc",
      width: 100,
    },
    {
      label: "Name",
      field: "name",
      sort: "asc",
      width: 150,
    },
    {
      label: "Actions",
      field: "actions",
      sort: false,
      width: 100,
    },
  ];

  const fetchResponses = async () => {
    try {
      setLoading(true);
      const response = await get(`${API_URL}/main`);
      setResponses(response.responseMain || []);
    } catch (error) {
      console.error('Error fetching responses:', error);
      toastr.error(error.message || "Error fetching responses");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchResponses();
  }, []); // eslint-disable-line

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    
    if (touched[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: validateField(name, value)
      }));
    }
  };

  const validateField = (name, value) => {
    switch (name) {
      case 'name':
        if (!value) {
          return 'Name is required';
        } else if (value.length < 2) {
          return 'Name must be at least 2 characters long';
        } else if (value.length > 50) {
          return 'Name cannot exceed 50 characters';
        } else if (/^\d+$/.test(value)) {
          return 'Name cannot contain only numbers';
        } else if (!/^[a-zA-Z0-9\s-]*$/.test(value)) {
          return 'Name can only contain letters, numbers, spaces, and hyphens';
        }
        return '';
        
      default:
        return '';
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setTouched(prev => ({
      ...prev,
      [name]: true
    }));
    setErrors(prev => ({
      ...prev,
      [name]: validateField(name, value)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const newErrors = {};
    Object.keys(formData).forEach(key => {
      const error = validateField(key, formData[key]);
      if (error) {
        newErrors[key] = error;
      }
    });

    setTouched({
      name: true
    });
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        setSubmitting(true);

        const payload = {
          name: formData.name,
          ip: '::1', // Add default values for additional fields
          status: 0,
          date: moment().format("YYYY-MM-DD"),
          time: moment().format("HH:mm:ss")
        };

        if (isEditing) {
          await put(`${API_URL}/main/${selectedResponse._id}`, payload);
          toastr.success("Response updated successfully");
        } else {
          await post(`${API_URL}/main`, payload);
          toastr.success("Response created successfully");
        }
        resetForm();
        fetchResponses();
      } catch (error) {
        toastr.error(error.message || "Response already exists");
      } finally {
        setSubmitting(false);
      }
    }
  };

  const handleEdit = (response) => {
    setFormData({
      name: response.name,
    });
    setSelectedResponse(response);
    setIsEditing(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure you want to delete this response?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        put(`${API_URL}/main/${id}`, { status: 1 })
          .then(res => {
            fetchResponses()
            toastr.success(res.message || "Response deleted successfully");
          })
          .catch(err => {
            toastr.error(err.response?.data?.message || "Error deleting response");
          })
      }
    })
  }

  const resetForm = () => {
    setFormData({
      name: "",
    });
    setIsEditing(false);
    setSelectedResponse(null);
    setTouched({});
    setErrors({});
    setSubmitting(false);
  };

  const tableData = useMemo(() => {
    const rows = responses
      .filter(response => response.status === 0)
      .map((response, index) => ({
        index: index + 1,
        date: moment(response.createdAt).format("DD-MM-YYYY"),
        name: response.name,
        actions: (
          <div className="d-flex justify-content-center align-items-center gap-3">
            <Button
              color="link"
              className="p-0 me-2"
              onClick={() => handleEdit(response)}
            >
              <FontAwesomeIcon icon={faPenToSquare} color='#3D3D3D' />
            </Button>
            <Button
              color="link"
              className="p-0"
              onClick={() => handleDelete(response._id)}
            >
              <FontAwesomeIcon icon={faTrash} color='#3D3D3D' />
            </Button>
          </div>
        ),
      }));

    return {
      columns,
      rows
    };
  }, [responses]);

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Home" breadcrumbItem="Response" />
        
        {/* Add/Edit Form */}
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h4 className="card-title mb-3 fw-bold">
                  {isEditing ? "Edit Response" : "Add Response"}
                </h4>
              </CardHeader>
              <CardBody>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="4">
                      <div className="mb-3">
                        <Label>
                          Response Name<span className="text-danger"> *</span>
                        </Label>
                        <input
                          name="name"
                          className={`form-control ${errors.name && touched.name ? 'is-invalid' : ''}`}
                          type="text"
                          value={formData.name}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          placeholder="Enter Response Name"
                        />
                        {errors.name && touched.name && (
                          <div className="invalid-feedback">
                            {errors.name}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mb-3" style={{ paddingTop: "28px" }}>
                        <Button color="primary" type="submit" className="me-2" disabled={submitting}>
                          {submitting ? "Processing..." : (isEditing ? "Update" : "Submit")}
                        </Button>
                        <Button color="danger" onClick={resetForm}>
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Response List */}
        <Row>
          <Col className="col-12">
            <Card>
              <CardHeader>
                <h4 className="card-title mb-3 fw-bold">Response List</h4>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div className="text-center">Loading...</div>
                ) : (
                  <MDBDataTable
                    responsive
                    bordered
                    data={tableData}
                    searching={true}
                    paging={true}
                    info={true}
                    noRecordsFoundLabel="No responses found"
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ResponseMain;