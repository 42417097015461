/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useEffect, useRef, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import srvlogo from "../../../assets/fotos/srv11.png"
import { WhatsappChat, WhatsappLeadManage } from "../../../components/Whatsapp"
import "./style.scss"
import { get } from "../../../helpers/api_helper"
import * as mainConfig from "../config"
import { getTimeAgoOrDate } from "../../../helpers/functions"
import { API_URL } from "../../../config"
import { useDispatch, useSelector } from "react-redux"
import { refreshPageAction } from "../../../store/actions"
import { debounce } from "lodash"
import { useNavigate } from "react-router-dom"
import { Spinner, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"

const index = () => {
  const navigate = useNavigate()
  const user = JSON.parse(localStorage.getItem("authUser")) || null
  const [show, setShow] = useState({ mobileBack: true, lead: false })
  const [selectedUser, setSelectedUser] = useState({})
  const refresh = useSelector(state => state.refreshReducer.refresh)
  const dispatch = useDispatch()
  const [filterQuery, setFilterQuery] = React.useState({})
  const [loading, setLoading] = useState(true)

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleDrop = () => setDropdownOpen(prevState => !prevState)

  const [inboxUserList, setInboxUserList] = useState([])

  let limit = 20
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  let [count, setCount] = useState(0)

  const fetchInboxUserList = async (
    currentPage = page,
    currentFilterQuery = filterQuery,
    scrolled = false
  ) => {
    try {
      const res = await get(
        mainConfig.ROUTE.inboxUserList({ limit, query: currentFilterQuery, page: currentPage })
      )
      setLoading(false)
      if (res) {
        if (currentFilterQuery?.search) {
          setInboxUserList(res?.data)
        } else {
          if (scrolled) {
            setInboxUserList(prev => {
              return [...prev, ...res?.data]
            })
          } else {
            setInboxUserList(res?.data)
          }
        }
        setCount(Number(res?.count))
        const totalPage = Math.ceil(Number(res?.count) / limit)
        setTotalPage(totalPage)
      }

      let localUserId = window.localStorage.getItem("userId")
      if (localUserId) {
        window.localStorage.removeItem("userId")
        handleOpenChat(localUserId)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchUserInfo = async userId => {
    try {
      const res = await get(`${API_URL}whatsapp/inbox/user-list/${userId}`)
      if (res) {
        setSelectedUser(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpenChat = userId => {
    fetchUserInfo(userId)
    // window.localStorage.setItem("userId", userId)
    setShow(prev => ({ ...prev, mobileBack: false }))
  }

  const closeLead = () => {
    setShow(prev => ({ ...prev, lead: false }))
  }

  const toggle = e => {
    let label = e?.currentTarget?.getAttribute("aria-label")

    let flag = false

    if (label === "mobileBack") {
      flag = true
      setSelectedUser(null)
    }

    if (label === "lead") {
      flag = true
    }

    if (flag) {
      setShow(prev => ({ ...prev, [label]: !prev[label] }))
    }
  }

  const handleWindowResize = () => {
    let width = window.innerWidth

    if (width < 1000) {
      document.body.className = "right-bar-enabled"
    }
  }

  React.useEffect(() => {
    handleWindowResize()
    window.addEventListener("resize", handleWindowResize)
    return () => window.removeEventListener("resize", handleWindowResize)
  }, [])

  const debouncedFetchData = useCallback(debounce(fetchInboxUserList, 300), [])

  useEffect(() => {
    dispatch(refreshPageAction(false))
    setPage(1)
    debouncedFetchData(page, filterQuery)
    return debouncedFetchData.cancel
  }, [refresh, filterQuery])

  const observerLastDiv = useRef()

  const lastItemRef = useCallback(
    node => {
      if (!node) return
      if (observerLastDiv.current) observerLastDiv.current.disconnect()
      observerLastDiv.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          setPage(prev => {
            //page down
            if (prev <= totalPage) {
              prev += 1
            }
            fetchInboxUserList(prev, filterQuery, true)

            return prev
          })
        }
      })

      observerLastDiv.current.observe(node)
    },
    [totalPage]
  )

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Home" breadcrumbItem="Inbox" />
        <div
          className={`whatsapp-split ${show.lead ? "isLead" : ""} ${
            selectedUser?._id ? "" : "noChatId"
          }`}
        >
          {loading ? (
            <div
              className={`inbox-loader ${loading ? "active" : ""}`}
              ref={limit === inboxUserList.length ? lastItemRef : null}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              <h5>Wait a moment...</h5>
            </div>
          ) : count > 0 ? (
            <div className={`chat-box-container ${show.mobileBack ? "isMobile" : ""}`}>
              <div className="chat-box-user-list">
                <div className="d-flex justify-content-center align-items-center gap-1">
                  <div className="chat-box-search w-100">
                    <input
                      type="text"
                      placeholder="Search Here"
                      required
                      value={filterQuery?.search ?? ""}
                      onChange={e => setFilterQuery(prev => ({ ...prev, search: e.target?.value }))}
                    />
                    <button className="search-btn">
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                  <Dropdown isOpen={dropdownOpen} toggle={toggleDrop}>
                    <DropdownToggle caret color="primary">
                      <i class="fa fa-filter" aria-hidden="true"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem header>Filter</DropdownItem>
                      <DropdownItem
                        active={filterQuery.unread === true ? true : false}
                        onClick={() => setFilterQuery(prev => ({ ...prev, unread: true }))}
                      >
                        Unread
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem
                        onClick={() => setFilterQuery(prev => ({ ...prev, unread: false }))}
                      >
                        Clear
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>

                <div className="chat-user-list">
                  {inboxUserList?.length > 0 ? (
                    inboxUserList.map((user, index) => (
                      <div
                        key={index}
                        onClick={() => handleOpenChat(user?.contact)}
                        className={`chat-user ${
                          inboxUserList?.length !== index + 1 ? "bottom_border" : ""
                        } ${
                          selectedUser?._id?.toString() === user?.contact?.toString()
                            ? "active"
                            : ""
                        }`}
                      >
                        <div className="avatar">
                          <div className="avatar_img">
                            {user.image ? (
                              <img src={srvlogo} alt="#" />
                            ) : (
                              <div className="img-letter">{user?.name?.charAt(0) ?? "Un"}</div>
                            )}
                          </div>

                          <img
                            className={"platform-icon"}
                            src="/icons/whatsapp.png"
                            alt="platform icon"
                          />
                        </div>

                        <div className="userMeta">
                          <p>{user.name ?? user?.mobile ?? user?.wa_id ?? "Unknown"}</p>
                          <span className="activeTime">
                            {user?.timestamp ? getTimeAgoOrDate(user?.timestamp) : "No Messages"}
                          </span>
                        </div>

                        {user?.received_count > 0 ? (
                          <span className="new-message-count">{user?.received_count}</span>
                        ) : null}
                      </div>
                    ))
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        padding: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      No results
                    </div>
                  )}

                  {!filterQuery?.search &&
                  count > limit &&
                  count != inboxUserList?.length &&
                  inboxUserList?.length > 0 ? (
                    <div
                      className={`inbox-loader ${loading ? "active" : ""}`}
                      ref={limit === inboxUserList?.length ? lastItemRef : null}
                    >
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                      <h5>Wait a moment...</h5>
                    </div>
                  ) : null}
                </div>
              </div>

              {selectedUser?._id ? (
                <WhatsappChat selectedUser={selectedUser} refresh={refresh} toggle={toggle} />
              ) : (
                <div className="no-conversation">
                  <h1>No conversation Opened</h1>
                </div>
              )}
            </div>
          ) : (
            <div className="inbox-welcome-screen">
              <h1>Welcome , {user.firstName ?? "User"}</h1>
              <p>Ready? Set. Chat! Let's jump right into things.</p>

              <ul>
                <li>
                  <p>Send a message to a contact number</p>
                  <button onClick={() => navigate("/contacts")}>Start Chat</button>
                </li>
                <li>
                  <p>Send a broadcast message</p>
                  <button onClick={() => navigate("/broadcast")}>Broadcast</button>
                </li>
              </ul>
            </div>
          )}

          {selectedUser?._id && <WhatsappLeadManage selectedUser={selectedUser} toggle={toggle} />}
        </div>
      </div>
    </div>
  )
}

export default index
