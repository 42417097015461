import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import ReactApexChart from "react-apexcharts"
import accessToken from "../../../helpers/jwt-token-access/accessToken"
import axios from "axios"
import moment from "moment"
import MiniWidget from "./mini-widget"
import Select from "react-select"
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/"

function MediaPlatformFacebook() {
  const [reportData, setReportData] = useState([])
  const [selectedPage, setSelectedPage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [since, setSince] = useState(moment().subtract(7, "days").unix())
  const [until, setUntil] = useState(moment().unix())
  const [dateFilter, setDateFilter] = useState("last7days")
  const [series, setSeries] = useState([
    {
      name: "Sentiments",
      data: [40, 30, 30], // Default static values
    },
  ])

  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      height: 600,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "right",
        },
        distributed: true,
        barHeight: '40%',
        columnWidth: '90%',
      },
    },
    xaxis: {
      categories: ["Positive", "Negative", "Neutral"],
    },
    colors: ["#34c38f", "#f46a6a", "#FFCD69"],
    legend: {
      show: true,
      position: "top",
    },
    dataLabels: {
      enabled: false,
    },
  })

  console.log("SEries", series)
  // useEffect(() => {
  //   fetchReport();
  // }, []);
  // Fetch Report Data
  useEffect(() => {
    const fetchReport = async () => {
      try {
        const response = await axios.get(
          `${API_URL}report/v2/facebook/report?since=${since}&until=${until}`
        )
        if (response.data && response.data.results.length > 0) {
          setReportData(response.data.results)
          setSelectedPage(response.data.results[0])
        }
      } catch (error) {
        console.error("Error fetching report data:", error)
      }
    }

    fetchReport()
  }, [since, until])

  // Handle Date Filter Change
  const handleDateFilterChange = event => {
    const value = event.target.value
    setDateFilter(value)

    if (value === "last7days") {
      setSince(moment().subtract(7, "days").unix())
      setUntil(moment().unix())
    } else if (value === "last30days") {
      setSince(moment().subtract(30, "days").unix())
      setUntil(moment().unix())
    }
  }

  // Handle Custom Date Change
  const handleCustomDateChange = event => {
    const { name, value } = event.target
    const unixTime = moment(value).unix()
    if (name === "since") setSince(unixTime)
    if (name === "until") setUntil(unixTime)
  }
  const handlePageChange = selectedOption => {
    const page = reportData.find(p => p.pageId === selectedOption.value)
    setSelectedPage(page)
  }
  const formatDate = (date, fullMonth = false) => {
    const options = fullMonth
      ? {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }
      : {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }

    return new Date(date).toLocaleString("en-US", options)
  }

  const [startDate, setStartDate] = useState(() => new Date().setDate(new Date().getDate() - 30))
  const [endDate, setEndDate] = useState(new Date())
  const [showFullMonth, setShowFullMonth] = useState(false)

  const toggleShowFullMonth = () => {
    setShowFullMonth(!showFullMonth)
  }

  const fromDate = moment(startDate).format("YYYY-MM-DD")
  const toDate = moment(endDate).format("YYYY-MM-DD")

  const [sentimentCount, setSentimentCount] = useState()
  const [sentimentCountPercentage, setSentimentCountPercentage] = useState({
    series: [],
    labels: [],
  })
  const [metrics, setMetrics] = useState()

  const series1 = sentimentCount || ""

  const option1 = {
    chart: {
      type: "bar",
      height: 600,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
      categories: ["KIAL"],
    },
    colors: ["#34c38f", "#f46a6a", "#FFCD69"],
  }

  const series2 = sentimentCountPercentage?.series

  const options2 = {
    labels: sentimentCountPercentage?.labels,
    colors: ["#34c38f", "#f46a6a", "#FFCD69"],

    legend: {
      show: !0,
      position: "right",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: !1,
      fontSize: "14px",
      offsetX: 0,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: !1,
          },
        },
      },
    ],
  }

  const series11 = [
    {
      data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54],
    },
  ]

  const options1 = {
    fill: {
      colors: ["#5b73e8"],
    },
    chart: {
      width: 70,
      sparkline: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    tooltip: {
      fixed: {
        enabled: !1,
      },
      x: {
        show: !1,
      },
      y: {
        title: {
          formatter: function (seriesName) {
            return ""
          },
        },
      },
      marker: {
        show: !1,
      },
    },
  }

  const series22 = [70]

  const options22 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series3 = [55]

  const options3 = {
    fill: {
      colors: ["#5b73e8"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series4 = [
    {
      data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54],
    },
  ]

  const options4 = {
    fill: {
      colors: ["#f1b44c"],
    },
    chart: {
      width: 70,
      sparkline: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    tooltip: {
      fixed: {
        enabled: !1,
      },
      x: {
        show: !1,
      },
      y: {
        title: {
          formatter: function (seriesName) {
            return ""
          },
        },
      },
      marker: {
        show: !1,
      },
    },
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fromDate = moment().subtract(7, "days").format("YYYY-MM-DD")
        const toDate = moment().format("YYYY-MM-DD")
        const response = await axios.get(
          `${API_URL}report/v2/facebook/sentiment/report?fromDate=${fromDate}&toDate=${toDate}`
        )

        if (response.data.success) {
          const { percentages,counts } = response.data.data
          setSeries([
            {
              name: "Sentiments",
              data: [
                parseFloat
                (counts.positive),
                parseFloat(counts.negative),
                parseFloat(counts.neutral),
              ],
            },
          ])
        }
      } catch (error) {
        console.error("Error fetching sentiment data", error)
      }
    }

    fetchData()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div>
            <p className="titletit">Facebook Dashboard</p>
          </div>
          <div>
            <Row>
              <Col md={3}>
                <FormGroup>
                  <Label for="sinceDate">Since</Label>
                  <Input
                    type="date"
                    name="since"
                    onChange={handleCustomDateChange}
                    defaultValue={moment.unix(since).format("YYYY-MM-DD")}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="untilDate">Until</Label>
                  <Input
                    type="date"
                    name="until"
                    onChange={handleCustomDateChange}
                    defaultValue={moment.unix(until).format("YYYY-MM-DD")}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="pageSelect">Select Page</Label>
                  <Select
                    id="pageSelect"
                    options={reportData.map(page => ({
                      value: page.pageId,
                      label: page.pageName,
                    }))}
                    onChange={handlePageChange}
                  />
                </FormGroup>
                
              </Col>
            </Row>

            {selectedPage && (
              <Row>
                <MiniWidget
                  reports={[
                    {
                      id: 1,
                      icon: "mdi mdi-arrow-up-bold",
                      title: "Impressions",
                      value: selectedPage.totalImpressions,
                      color: "success",
                      desc: "since last week",
                    },
                    {
                      id: 2,
                      icon: "mdi mdi-arrow-down-bold",
                      title: "Organic Impressions",
                      value: selectedPage.totalOrganicImpressions,
                      color: "danger",
                      desc: "since last week",
                    },
                    {
                      id: 3,
                      icon: "mdi mdi-arrow-down-bold",
                      title: "Paid Impressions",
                      value: selectedPage.totalPaidImpressions,
                      color: "danger",
                      desc: "since last week",
                    },
                    {
                      id: 4,
                      icon: "mdi mdi-arrow-up-bold",
                      title: "Unique Impressions",
                      value: selectedPage.totalUniqueImpressions,
                      color: "success",
                      desc: "since last week",
                    },
                    {
                      id: 5,
                      icon: "mdi mdi-arrow-up-bold",
                      title: "Engagements",
                      value: selectedPage.totalEngagements,
                      color: "success",
                      desc: "since last week",
                    },
                  ]}
                />
              </Row>
            )}
          </div>

          <Row style={{ rowGap: "20px" }}>
            {/* bar 1*/}
            <Col md="6">
              <Card>
                <CardBody>
                  <div className="float-end">
                    {/* <UncontrolledDropdown>
              <DropdownToggle tag="a" className="text-reset" caret href="#">
                <span className="fw-semibold">Sort By:</span>{" "}
                <span className="text-muted">
                  Yearly<i className="mdi mdi-chevron-down ms-1"></i>
                </span>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem href="#">Monthly</DropdownItem>
                <DropdownItem href="#">Yearly</DropdownItem>
                <DropdownItem href="#">Weekly</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
                  </div>
                  <h5>Sentiment Breakup</h5>
                  <ReactApexChart
                    type="bar"
                    height="200"
                    className="apex-charts"
                    options={options}
                    series={series}
                  />
                </CardBody>
              </Card>
            </Col>

            {/* <Col md="6">
              <Card>
                <CardBody>
                  <div className="float-end">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        tag="a"
                        className="text-reset"
                        id="dropdownMenuButton5"
                        caret
                        href="#"
                      >
                        <span className="fw-semibold">Sort By:</span>{" "}
                        <span className="text-muted">
                          Yearly<i className="mdi mdi-chevron-down ms-1"></i>
                        </span>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem href="#">Monthly</DropdownItem>
                        <DropdownItem href="#">Yearly</DropdownItem>
                        <DropdownItem href="#">Weekly</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  <h5>Sentiment Percentage</h5>
                  <ReactApexChart
                    type="pie"
                    height="200"
                    className="apex-charts"
                    options={options1}
                    series={series1}
                  />
                </CardBody>
              </Card>
            </Col> */}
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default MediaPlatformFacebook
