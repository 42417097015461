import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import CountUp from "react-countup";
import { Bar, Doughnut } from "react-chartjs-2";
import { get } from "../../../helpers/api_helper.js";
import Breadcrumbs from "../../../components/Common/Breadcrumb.js";
import {
    Chart,
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
  } from 'chart.js';

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement, 
  Title,
  Tooltip,
  Legend
);

const LeadDashboard = () => {
  const [dashboardData, setDashboardData] = useState({
    counts: { today: 0, week: 0, month: 0, total: 0 },
    filledDailyLeads:[],
    leadStatus: {
      callLater: 0,
      wrongNumber: 0,
      messageSent: 0,
      notResponding: 0,
      interested: 0,
      notInterested: 0,
    }
  });

  const fetchDashboardStats = useCallback(async () => {
    try {
      const response = await get("/leads/stats");
      if (response.success) {
        setDashboardData(prevData => ({
          ...prevData,
          ...response.data
        }));
      }
    } catch (error) {
      console.error("Error fetching dashboard stats:", error);
    }
  }, []);

  useEffect(() => {
    fetchDashboardStats();
  }, [fetchDashboardStats]);

  const StatCard = React.memo(({ title, value, icon }) => (
    <Card>
      <CardBody>
        <Row className="align-items-center">
          <Col xs={8}>
            <h2 className="mt-2 mb-2">
              <CountUp end={value} separator="," duration={2} />
            </h2>
            <p className="mb-0 text-muted">{title}</p>
          </Col>
          <Col xs={4} className="text-end">
            <i className={`${icon} fa-3x`}></i>
          </Col>
        </Row>
      </CardBody>
    </Card>
  ));

  const chartData = useMemo(() => ({
    labels: dashboardData.filledDailyLeads.map(lead => lead.date),
    datasets: [{
      label: 'Daily Leads',
      data: dashboardData.filledDailyLeads.map(lead => lead.count),
      backgroundColor: 'rgba(255, 99, 132, 0.6)',
      borderColor: 'rgba(255, 99, 132, 1)',
      borderWidth: 1,
      borderRadius: 0,
    }]
  }), [dashboardData.filledDailyLeads]);

  const chartOptions = useMemo(() => ({
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: true,
          color: 'rgba(0, 0, 0, 0.1)'
        },
        ticks: {
          maxRotation: 45,
          minRotation: 45,
          autoSkip: false
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          display: true,
          drawBorder: true,
          color: 'rgba(0, 0, 0, 0.1)'
        },
        ticks: {
          maxTicksLimit: 6,
          callback: function(value) {
            return value.toFixed(0);
          }
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            return tooltipItems[0].label;
          },
          label: (context) => {
            return `Leads: ${context.raw}`;
          }
        }
      }
    }
  }), []);

  const statusColors = useMemo(() => ({
    callLater: '#FFFF00',
    wrongNumber: '#0000FF',
    messageSent: '#00CED1',
    notResponding: '#FF1493',
    interested: '#00FF00',
    notInterested: '#FF0000',
  }), []);

  const statusDisplayNames = useMemo(() => ({
    callLater: 'Call Later',
    wrongNumber: 'Wrong Number',
    messageSent: 'Message Sent',
    notResponding: 'Not Responding',
    interested: 'Interested',
    notInterested: 'Not Interested',
  }), []);

  const LeadStatusCard = () => {
    const donutData = useMemo(() => ({
      labels: Object.entries(dashboardData.leadStatus).map(
        ([status]) => statusDisplayNames[status]
      ),
      datasets: [
        {
          data: Object.values(dashboardData.leadStatus),
          backgroundColor: Object.values(statusColors),
          borderWidth: 0,
        },
      ],
    }), [dashboardData.leadStatus, statusDisplayNames, statusColors]);
  
    const donutOptions = useMemo(() => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              const label = context.label || '';
              const value = context.raw || 0;
              const total = context.dataset.data.reduce((acc, curr) => acc + curr, 0);
              const percentage = ((value / total) * 100).toFixed(1);
              return `${label}: ${value} (${percentage}%)`;
            },
          },
        },
      },
      cutout: '60%',
    }), []);
  
    return (
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">Lead Status</h4>
          <div className="lead-status-container">
            <div className="mt-4" style={{ height: '240px' }}>
              <Doughnut data={donutData} options={donutOptions} />
            </div>
            {Object.entries(dashboardData.leadStatus).map(([status, count]) => (
              <div key={status} className="d-flex justify-content-between align-items-center mb-2">
                <div className="d-flex align-items-center">
                  <div 
                    className="status-indicator"
                    style={{
                      width: '12px',
                      height: '12px',
                      borderRadius: '50%',
                      backgroundColor: statusColors[status],
                      marginRight: '10px'
                    }}
                  />
                  <span className="status-text">{statusDisplayNames[status]}</span>
                </div>
                <span 
                  className="status-count"
                  style={{
                    backgroundColor: '#f8f9fa',
                    padding: '2px 10px',
                    borderRadius: '4px',
                    fontSize: '0.9rem',
                    fontWeight: 'bold'
                  }}
                >
                  {count}
                </span>
              </div>
            ))}
          </div>
        </CardBody>
      </Card>
    );
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Home" breadcrumbItem="Lead Dashboard" />
        
        {/* Stats Cards */}
        <Row>
          <Col xl={3} md={6}>
            <StatCard
              title="TODAY"
              value={dashboardData.counts.today}
              icon="fas fa-user-plus"
            />
          </Col>
          <Col xl={3} md={6}>
            <StatCard
              title="THIS WEEK"
              value={dashboardData.counts.week}
              icon="fas fa-users"
            />
          </Col>
          <Col xl={3} md={6}>
            <StatCard
              title="THIS MONTH"
              value={dashboardData.counts.month}
              icon="fas fa-chart-line"
            />
          </Col>
          <Col xl={3} md={6}>
            <StatCard
              title="TOTAL"
              value={dashboardData.counts.total}
              icon="fas fa-database"
            />
          </Col>
        </Row>

        {/* Charts Row */}
        <Row className="mt-4">
          <Col xl={8}>
            <Card>
              <CardBody>
                <h4 className="card-title mb-4">Last 30 Days Added Leads</h4>
                <div style={{ height: "360px" }}>
                  <Bar data={chartData} options={chartOptions} />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={4}>
            <LeadStatusCard />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default React.memo(LeadDashboard);