import React, { useEffect, useRef, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb.js"
import Select from "react-select"
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap"
import { MDBDataTable } from "mdbreact"
// import OverlayTrigger from "react-bootstrap/OverlayTrigger"
// import Tooltip from "react-bootstrap/Tooltip"
import "./Feedbackquestions.scss"
import { post, put, del, get } from "../../../helpers/api_helper.js"
import toastr from "toastr"
import Swal from "sweetalert2"
import { reactSelectCustomStyle } from "../../../helpers/functions.js"
import _ from "lodash"

const reasonForOptions = [
  {
    label: "1 Star",
    value: 1,
  },
  {
    label: "2 Star",
    value: 2,
  },
  {
    label: "3 Star",
    value: 3,
  },
  {
    label: "4 Star",
    value: 4,
  },
  {
    label: "5 Star",
    value: 5,
  },
]

const emojiOptions = [
  {
    label: (
      <div>
        <span style={{ fontSize: "18px" }}>&#128546;</span>
        (Needs Improvement)
      </div>
    ),
    value: 1,
  },
  {
    label: (
      <div>
        <span style={{ fontSize: "18px" }}>&#128533;</span>
        (Average)
      </div>
    ),
    value: 2,
  },
  {
    label: (
      <div>
        <span style={{ fontSize: "18px" }}>&#128528;</span>
        (Good)
      </div>
    ),
    value: 3,
  },
  {
    label: (
      <div>
        <span style={{ fontSize: "18px" }}>&#128578;</span>
        (Best)
      </div>
    ),
    value: 4,
  },
  {
    label: (
      <div>
        <span style={{ fontSize: "18px" }}>&#128512;</span>
        Excellent
      </div>
    ),
    value: 5,
  },
]

const questionOptions = [
  {
    label: "Yes/No",
    value: 0,
  },
  {
    label: "Rating",
    value: 1,
  },
  {
    label: "Emoji Rating",
    value: 2,
  },
  {
    label: "Text",
    value: 3,
  },
]

const questionOptionsMap = {
  0: { label: "Yes/No", value: 0 },
  1: { label: "Rating", value: 1 },
  2: { label: "Emoji Rating", value: 2 },
  3: { label: "Text", value: 3 },
}

function Feedbackform() {
  const initialMasterObject = {
    name: {},
    required: { name: false, mobile: false, email: false, isDefault: false },
    questions: [{ type: "", reason: false }],
    languages: [
      {
        _id: "67809d7948d98bccfc1825ba",
        label: "English",
        key: "English",
        value: null,
      },
    ],
    defaultLanguage: {
      _id: "67809d7948d98bccfc1825ba",
      label: "English",
      key: "English",
      value: null,
    },
    // isDefault: false,
  }

  const formRef = useRef()
  const [popupView, setPopupView] = useState(false)
  const handleCloseModal = () => setPopupView(false)

  const [tableData, setTableData] = useState([])

  const [languages, setLanguages] = useState([])

  const [selectedFields, setSelectedFields] = useState({
    branch: "",
    questions: [""],
    language: [""],
  })

  const [masterObject, setMasterObject] = useState(initialMasterObject)

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const toggle = (start = true) => {
    var body = document.body

    if (start) {
      body.classList.remove("vertical-collpsed")
      body.classList.remove("sidebar-enable")
      body.setAttribute("data-sidebar-size", "sm")
    } else {
      body.classList.add("vertical-collpsed")
      body.classList.add("sidebar-enable")
      body.setAttribute("data-sidebar-size", "lg")
    }
  }

  const handleChangeDefaultLan = (id, language) => {
    put(`feedback/form/lang`, { id, language })
      .then(res => {
        toastr.success(res.message)
        fetchTableData()
      })
      .catch(err => toastr.error(err.response.data.message))
  }

  // useEffect(() => {
  //   toggle()

  //   return () => {
  //     toggle(false)
  //   }
  //   // eslint-disable-next-line
  // }, [])

  const fetchTableData = () => {
    get(`feedback/form`)
      .then(res => {
        const data = res.data
        setTableData(
          data.map((item, index) => ({
            ...item,
            index: index + 1,
            form: item?.name?.english,
            name: item.required.name ? (
              <div style={{ textAlign: "center" }}>
                <i style={{ color: "green", textAlign: "center" }} class="fas fa-check"></i>
              </div>
            ) : (
              ""
            ),
            mobile: item.required.mobile ? (
              <div style={{ textAlign: "center" }}>
                <i style={{ color: "green", textAlign: "center" }} class="fas fa-check"></i>
              </div>
            ) : (
              ""
            ),
            email: item.required.email ? (
              <div style={{ textAlign: "center" }}>
                <i style={{ color: "green", textAlign: "center" }} class="fas fa-check"></i>
              </div>
            ) : (
              ""
            ),
            default: item.required.isDefault ? (
              <div style={{ textAlign: "center" }}>
                <i style={{ color: "green" }} className="fas fa-check"></i>
              </div>
            ) : (
              ""
            ),
            actions: (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="uil-edit-alt"
                  style={{
                    fontSize: "1.2em",
                    cursor: "pointer",
                    marginLeft: "0.5rem",
                    marginRight: "0.5rem",
                  }}
                  onClick={() => {
                    toTop()
                    fetchUpdateData(item._id)
                  }}
                ></i>
                <i
                  className="uil-trash-alt"
                  style={{ fontSize: "1.2em", cursor: "pointer" }}
                  onClick={() => {
                    handleDelete(item._id)
                  }}
                ></i>
              </div>
            ),
            defaultLanguage: (
              <Select
                options={item?.languages}
                menuPosition="fixed"
                styles={reactSelectCustomStyle}
                classNamePrefix="select2-selection"
                value={
                  item?.defaultLanguage
                    ? {
                        label: item?.defaultLanguage?.label?.split(" ")[0],
                        value: item?.defaultLanguage?.value,
                      }
                    : null
                }
                isClearable={false}
                onChange={e => {
                  if (e.value === item?.defaultLanguage?.value) return
                  handleChangeDefaultLan(item._id, e)
                }}
              />
            ),
          }))
        )
      })
      .catch(err => console.error(err))
  }

  useEffect(() => {
    fetchLanguageOptions()
    fetchTableData()
    //eslint-disable-next-line
  }, [])

  const handleCheckBoxClick = name => {
    setMasterObject({
      ...masterObject,
      required: {
        ...masterObject.required,
        [name]: !masterObject.required[name],
      },
    })
  }

  const reset = () => {
    setMasterObject(initialMasterObject)
    setSelectedFields({
      // branch: branchOptions[0],
      questions: [""],
    })
    formRef.current.reset()
  }

  // const handleSubmit = () => {
  //   const isValid = masterObject.questions.every(item => {
  //     return [0, 1, 2, 3, "0", "1", "2"].includes(item.type)
  //   })
  //   if (!isValid) {
  //     return toastr.error("Please select type for all questions")
  //   }

  //   masterObject.questions.map(item => {
  //     delete item.reasonUptoSelected
  //     delete item.reasonForSelected
  //     return item
  //   })

  //   if (masterObject._id) {
  //     put(`feedback/form`, { ...masterObject })
  //       .then(res => {
  //         reset()
  //         fetchTableData()
  //         toastr.success(res.message)
  //       })
  //       .catch(err => toastr.error(err.response.data.message))
  //   } else {
  //     post(`feedback/form`, { ...masterObject })
  //       .then(res => {
  //         reset()
  //         fetchTableData()
  //         toastr.success(res.message)
  //       })
  //       .catch(err => toastr.error(err.response.data.message))
  //   }
  // }

  const handleSubmit = async () => {
    const isValid = masterObject.questions.every(item => {
      return [0, 1, 2, 3, "0", "1", "2"].includes(item.type)
    })
    if (!isValid) {
      return toastr.error("Please select type for all questions")
    }
    
    // Clean up questions data
    const submissionData = {
      ...masterObject,
      questions: masterObject.questions.map(item => {
        const cleanItem = { ...item }
        delete cleanItem.reasonUptoSelected
        delete cleanItem.reasonForSelected
        return cleanItem
      }),
    }

    try {
      const response = await (submissionData._id
        ? put(`feedback/form`, submissionData)
        : post(`feedback/form`, submissionData))

      reset()
      fetchTableData()
      toastr.success(response.message || "Form saved successfully")
    } catch (err) {
      // Check for conflict using both possible response structures
      const isDefaultConflict =
        err?.response?.status === 409 ||
        err?.code === 409 ||
        err?.message?.includes("default form already exists") ||
        err?.response?.data?.message?.includes("default form already exists")

      if (isDefaultConflict) {
        const result = await Swal.fire({
          title: "Default Form Exists",
          text: "Another form is already set as default. Do you want to make this form the default instead?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, make this default",
          cancelButtonText: "No, keep as non-default",
          reverseButtons: true,
          allowOutsideClick: false,
        })

        if (result.isConfirmed) {
          // User chose to override existing default
          try {
            const response = await (submissionData._id
              ? put(`feedback/form`, { ...submissionData, overrideDefault: true })
              : post(`feedback/form`, { ...submissionData, overrideDefault: true }))

            reset()
            fetchTableData()
            toastr.success(response.message || "Form saved as default successfully")
          } catch (overrideErr) {
            console.error("Override error:", overrideErr)
            toastr.error(
              overrideErr?.message ||
                overrideErr?.response?.data?.message ||
                "Failed to update form"
            )
          }
        } else {
          // User chose to keep form as non-default
          try {
            const updatedData = {
              ...submissionData,
              required: {
                ...submissionData.required,
                isDefault: false,
              },
            }
            const response = await (submissionData._id
              ? put(`feedback/form`, updatedData)
              : post(`feedback/form`, updatedData))

            reset()
            fetchTableData()
            toastr.success(response.message || "Form saved successfully")
          } catch (nonDefaultErr) {
            console.error("Non-default error:", nonDefaultErr)
            toastr.error(
              nonDefaultErr?.message ||
                nonDefaultErr?.response?.data?.message ||
                "Failed to create form"
            )
          }
        }
      } else {
        // Handle other types of errors
        console.error("General error:", err)
        const errorMessage = err?.message || err?.response?.data?.message || "An error occurred"
        toastr.error(errorMessage)
      }
    }
  }

  const fetchLanguageOptions = () => {
    get(`options/fb-languages`).then(res => {
      setLanguages(res.data)
    })
  }

  const fetchUpdateData = id => {
    get(`feedback/form/${id}`).then(res => {
      const data = res.data

      data.questions.map(item => {
        if (item.type === 0) {
          item.reasonForSelected =
            item.reasonFor === 1 ? { label: "Yes", value: 1 } : { label: "No", value: 2 }
        } else if (item.type === 1) {
          item.reasonUptoSelected = reasonForOptions.find(
            option => option.value === item.reasonUpto
          )
        } else if (item.type === 2) {
          item.reasonForSelected = emojiOptions.find(option => option.value === item.reasonFor)
        }
        return item
      })

      setMasterObject(data)
      setSelectedFields({ questions: data.questions.map(item => questionOptionsMap[item.type]) })
    })
  }

  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`feedback/form/${id}`)
          .then(res => {
            fetchTableData()
            reset()
            toastr.success(res.message)
          })
          .catch(err => {
            toastr.error(err.response.data.message)
          })
      }
    })
  }

  const handleAddMore = () => {
    const data = { ...masterObject }
    data.questions.push({ type: "", reason: false })
    setMasterObject(data)
  }

  const handleRemove = index => {
    const data = { ...masterObject }
    const selected = { ...selectedFields }
    data.questions.splice(index, 1)
    setMasterObject(data)

    selected.questions.splice(index, 1)
    setSelectedFields(selected)
  }

  const handleChangeName = (e, inner) => {
    const { name, value } = e.target
    const data = { ...masterObject }
    if (inner) {
      if (!data[inner]) data[inner] = {}
      data[inner][name] = value
    } else data[name] = value
    setMasterObject(data)
  }

  const handleChangeQuestions = (e, index) => {
    let { value, name } = e.target
    name = name.split("-")[0]
    const data = { ...masterObject }
    data.questions[index][name] = value
    setMasterObject(data)
  }

  const handleCheckBox = (e, index) => {
    const data = { ...masterObject }
    data.questions[index].reason = !e.target.checked
    setMasterObject(data)
  }

  const handleSelectChange = (e, name, index = undefined) => {
    const newObj = { ...selectedFields }
    if (index !== undefined) {
      newObj.questions[index] = e
    } else {
      newObj[name] = e
    }
    setSelectedFields(newObj)

    const data = { ...masterObject }
    data.questions[index][name] = e.value
    setMasterObject(data)
  }

  const handleSelectReasonChange = (e, name, index) => {
    const master = { ...masterObject }
    master.questions[index][name] = e.value
    master.questions[index][`${name}Selected`] = e
    if (name === "reason") {
      master.questions[index]["reasonForSelected"] = null
      master.questions[index]["reasonUptoSelected"] = null
    }
    setMasterObject(master)
  }

  console.log("masterObject :>> ", masterObject)
  // console.log("selectedFields :>> ", selectedFields)

  const data = {
    columns: [
      {
        label: "#",
        field: "index",
        width: 150,
      },
      {
        label: "Form",
        field: "form",
        width: 150,
      },
      {
        label: "Unique ID",
        field: "uniqueId",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        width: 150,
      },
      {
        label: "Mobile",
        field: "mobile",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        width: 150,
      },
      {
        label: "Default Lang",
        field: "defaultLanguage",
        width: 150,
      },
      {
        label: "Default",
        field: "default",
        width: 100,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "desc",
        width: 400,
      },
    ],
    rows: tableData,
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Feedback Questions" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm onValidSubmit={handleSubmit} ref={formRef}>
                    <Row>
                      <Col md="5">
                        <div className="mb-3">
                          <Label>Language</Label>
                          <Select
                            options={languages}
                            classNamePrefix="select2-selection"
                            value={masterObject?.languages || null}
                            isClearable={false}
                            isMulti
                            onChange={e => {
                              if (!e.map(item => item.label).includes("English")) {
                                return
                              }
                              setMasterObject(pre => ({ ...pre, languages: e }))
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="form-check pt" style={{ marginTop: "32px" }}>
                          <input
                            type="checkbox"
                            className="form-check-input mycheckbox"
                            id="defaultForm"
                            checked={masterObject?.required?.isDefault || false}
                            onClick={() => handleCheckBoxClick("isDefault")}
                          />
                          <label className="form-check-label" htmlFor="defaultForm">
                            Default Form
                          </label>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      {masterObject.languages.map(item => (
                        <Col md="3">
                          <div>
                            <Label>Feedback Name {item.key}</Label>
                            <AvField
                              key={`name-${JSON.stringify(masterObject.languages)}`}
                              name={item.key.toLocaleLowerCase()}
                              className="form-control"
                              type="text"
                              value={masterObject?.name[item?.key?.toLocaleLowerCase() || ""] || ""}
                              placeholder="Feedback Form Name"
                              validate={{
                                required: { value: true, errorMessage: "Name is required" },
                              }}
                              onChange={e => handleChangeName(e, "name")}
                            />
                          </div>
                        </Col>
                      ))}
                    </Row>

                    <Row className="ps-2 my-3">
                      <Col md="1">
                        <div className="form-check pt" style={{ display: "flex", gap: "3px" }}>
                          <input
                            type="checkbox"
                            className="form-check-input mycheckbox"
                            id="myCheckbox1"
                            checked={masterObject?.required?.name || false}
                            onClick={() => handleCheckBoxClick("name")}
                          />
                          <label className="myname" htmlFor="myCheckbox1">
                            Name
                          </label>
                        </div>
                      </Col>
                      <Col md="1">
                        <div className="form-check pt" style={{ display: "flex", gap: "3px" }}>
                          <input
                            type="checkbox"
                            className="form-check-input mycheckbox"
                            id="myCheckbox2"
                            checked={masterObject?.required?.mobile || false}
                            onClick={() => handleCheckBoxClick("mobile")}
                          />
                          <label className="myname" htmlFor="myCheckbox2">
                            Mobile
                          </label>
                        </div>
                      </Col>
                      <Col md="1">
                        <div className="form-check pt" style={{ display: "flex", gap: "3px" }}>
                          <input
                            type="checkbox"
                            className="form-check-input mycheckbox"
                            id="myCheckbox3"
                            checked={masterObject?.required?.email || false}
                            onClick={() => handleCheckBoxClick("email")}
                          />
                          <label className="myname" htmlFor="myCheckbox3">
                            Email
                          </label>
                        </div>
                      </Col>
                    </Row>

                    {masterObject.questions.map((item, index) => (
                      <>
                        <Card className="p-3">
                          <Row className="d-flex align-items-center">
                            <Col md="3">
                              <div className="mb-3">
                                <Label>Type</Label>
                                <Select
                                  options={questionOptions}
                                  classNamePrefix="select2-selection"
                                  onChange={e => handleSelectChange(e, "type", index)}
                                  value={selectedFields.questions[index] || null}
                                />
                              </div>
                            </Col>

                            {item.type !== 3 && (
                              <Col md="1">
                                <div
                                  className="form-check mt-3 pt-1"
                                  style={{ display: "flex", gap: "3px" }}
                                >
                                  <input
                                    key={`name-${JSON.stringify(masterObject.languages)}`}
                                    type="checkbox"
                                    className="form-check-input mycheckbox"
                                    id={`reason-${index}`}
                                    checked={item.reason || false}
                                    onClick={e => handleCheckBox(e, index)}
                                    disabled={!selectedFields.questions[index]}
                                  />
                                  <label className="myname" htmlFor={`reason-${index}`}>
                                    Reason
                                  </label>
                                </div>
                              </Col>
                            )}

                            {item.reason && item.type !== 3 && (
                              <>
                                {item.type === 0 ? (
                                  <Col md="2">
                                    <div className="mb-3">
                                      <Label>Reason for</Label>
                                      <Select
                                        options={[
                                          {
                                            label: "Yes",
                                            value: 1,
                                          },
                                          {
                                            label: "No",
                                            value: 2,
                                          },
                                        ]}
                                        classNamePrefix="select2-selection"
                                        onChange={e =>
                                          handleSelectReasonChange(e, "reasonFor", index)
                                        }
                                        value={item?.reasonForSelected || null}
                                      />
                                    </div>
                                  </Col>
                                ) : item.type === 1 ? (
                                  <Col md="2">
                                    <div className="mb-3">
                                      <Label>Reason upto</Label>
                                      <Select
                                        options={reasonForOptions}
                                        classNamePrefix="select2-selection"
                                        onChange={e =>
                                          handleSelectReasonChange(e, "reasonUpto", index)
                                        }
                                        value={item.reasonUptoSelected || null}
                                      />
                                    </div>
                                  </Col>
                                ) : (
                                  <Col md="2">
                                    <div className="mb-3">
                                      <Label>Reason upto</Label>
                                      <Select
                                        options={emojiOptions}
                                        classNamePrefix="select2-selection"
                                        onChange={e =>
                                          handleSelectReasonChange(e, "reasonFor", index)
                                        }
                                        value={item.reasonForSelected || null}
                                      />
                                    </div>
                                  </Col>
                                )}
                              </>
                            )}
                          </Row>
                          <Row>
                            {masterObject.languages.map(el => (
                              <Col md="3">
                                <div className="mb-3">
                                  <Label>Question {el.key}</Label>
                                  <AvField
                                    key={`${index}-name-${JSON.stringify(masterObject.languages)}`}
                                    name={`${el.key.toLocaleLowerCase()}-question-${index}`}
                                    className="form-control"
                                    rows={1}
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage: "This field is required.",
                                      },
                                    }}
                                    onChange={e => handleChangeQuestions(e, index)}
                                    value={item[el.key.toLocaleLowerCase() || ""] || ""}
                                    type="textarea"
                                    placeholder=""
                                    errorMessage=""
                                  />
                                </div>
                              </Col>
                            ))}
                          </Row>
                          {masterObject.questions.length > 1 && (
                            <div>
                              <span
                                className="feedback-delete"
                                style={{
                                  fontSize: "1.125rem",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleRemove(index)
                                }}
                              >
                                <i className="trash-btn mdi mdi-delete"></i>
                              </span>
                            </div>
                          )}
                        </Card>
                      </>
                    ))}

                    <Row>
                      <Col md={12}>
                        <div className="d-flex justify-content-between align-items-center">
                          <button
                            type="button"
                            onClick={handleAddMore}
                            className="waves-effect btn btn-outline-light d-flex"
                            style={{ gap: "5px" }}
                          >
                            Add more <i className="mdi mdi-plus-circle-outline"></i>
                          </button>
                        </div>
                      </Col>

                      <Col>
                        <div className="mb-3" style={{ paddingTop: "20px" }}>
                          <Button
                            color={masterObject._id ? "warning" : "primary"}
                            style={{ marginRight: "10px" }}
                            type="submit"
                          >
                            {masterObject._id ? "Update" : "Submit"}
                          </Button>

                          <Button color="danger" onClick={reset}>
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="FeedbackformId"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
        className="lead-modal"
      >
        <div className="modal-header">
          <div className="modal-title">
            <h6 style={{ marginBottom: "0px" }} className="modal-title ">
              <h6>Feedback Details</h6>
            </h6>
          </div>
          <button
            style={{ marginBottom: "2px" }}
            type="button"
            onClick={handleCloseModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body">
          <div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{ width: "120px", textAlign: "left" }}>Type</th>
                  <th style={{ width: "320px", textAlign: "left" }}>Question</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ textAlign: "left" }}>Rating</td>
                  <td style={{ textAlign: "left" }}>How do you rate the overall experience?</td>
                </tr>

                <tr>
                  <td style={{ textAlign: "left" }}>Emoji Rating</td>
                  <td style={{ textAlign: "left" }}>How likely do you rate our staff behaviour?</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>Emoji Rating</td>
                  <td style={{ textAlign: "left" }}>
                    How do you feel about the cleanliness of the branch/ATM?
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>Yes/No</td>
                  <td style={{ textAlign: "left" }}>
                    Would you recommend Bank of Maharashtra to your friends or relatives?
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Feedbackform
