import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import { connect, useDispatch, useSelector } from "react-redux"

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import "toastr/build/toastr.min.css"
import toastr from "toastr"

// Import scss
import "./assets/scss/theme.scss"

// Import Fack Backend
import fakeBackend from "./helpers/AuthType/fakeBackend"
import { accessToken, refreshPageAction } from "./store/actions"
import Cookies from "js-cookie"
import io from "socket.io-client"

// Firebase
import { initializeApp } from "firebase/app"
import { getMessaging, getToken } from "firebase/messaging"
import { getCookieName, setBrowserToken } from "./helpers/functions"
import { API_URL, FAVICON } from "./config"
import { getMenu } from "./store/menu/actions"

import { disconnectSocket, initializeSocket, getSocket } from "./socket"

// Activating fake backend
fakeBackend()

toastr.options = {
  closeButton: true,
}

const App = props => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const isTvScreen = location.pathname.startsWith("/tv-screen")

  const token = Cookies.get("_token")

  const updateFavicon = iconURL => {
    const link = document.querySelector("link[rel~='icon']")
    if (link) {
      link.href = iconURL
    }
  }

  useEffect(() => {
    if (!isTvScreen) {
      initializeSocket()
      return () => {
        disconnectSocket()
      }
    }
  }, [])

  useEffect(() => {
    let socket = getSocket()
    if (socket) {
      socket.on("whatsapp", notification => {
        let path_name = window?.location.pathname.split("/")[1]
        if (path_name !== "inbox" && path_name !== "broadcast-templates") {
          if (notification?.title) {
            showNotification(notification)
          }
        }
        dispatch(refreshPageAction(true))
      })
      return () => socket.disconnect()
    }
  }, [])

  const showNotification = data => {
    if (Notification.permission === "granted") {
      let notification = new Notification(data?.title ?? "Notification", {
        body: data?.text ?? "You have a notification message",
        // icon: "",
      })

      notification.onclick = () => {
        if (data?.userId) {
          window.localStorage.setItem("userId", notification.userId)
        }

        let whatsappModule = {
          icon: "whatsapp-brands-solid-1737106813092-470221716.svg",
          moduleName: "Whatsapp",
          order: 8,
          redirect_url: "/broadcast-analytics",
          shortCode: "WA",
          _id: "67873b0dc5d1c4d99e7a4132",
        }

        sessionStorage.setItem("module", whatsappModule.moduleName)
        sessionStorage.setItem("module_code", whatsappModule.shortCode)
        sessionStorage.setItem("module_id", whatsappModule._id)
        sessionStorage.setItem("module_url", whatsappModule.redirect_url)
        if (Cookies.get(`_${getCookieName()}`) && localStorage.getItem("authUser")) {
          const user = JSON.parse(localStorage.getItem("authUser"))._id
          dispatch(getMenu({ user, module: whatsappModule._id }))
        }

        if (data?.type === "template") {
          navigate(`/broadcast-templates`)
        }

        if (data?.type === "message") {
          navigate(`/inbox`)
        }
        window.focus()
      }
    }
  }

  useEffect(() => {
    // Set a default favicon when the app loads
    updateFavicon(FAVICON)
  }, [])

  useEffect(() => {
    if (Cookies.get(`_${getCookieName()}`)) dispatch(accessToken(navigate, location.pathname))
    // eslint-disable-next-line
  }, [])

  // Firebase credentials are safe to be exposed to public
  const firebaseConfig = {
    apiKey: "AIzaSyDW_IfBXE9wp8EvPfmIpoRNFuUWFIRsqII",
    
    authDomain: "srvsocial-61f7b.firebaseapp.com",
    projectId: "srvsocial-61f7b",
    storageBucket: "srvsocial-61f7b.firebasestorage.app",
    messagingSenderId: "1074875636611",
    appId: "1:1074875636611:web:a16a1c0a707c145e5634b6",
    measurementId: "G-V6RY5K2Y8V",
  }

  const requestPermission = () => {
    if ("Notification" in window) {
      Notification.requestPermission().then(async permission => {
        if (permission === "granted") {
          const app = initializeApp(firebaseConfig)
          const messaging = getMessaging(app)

          getToken(messaging, { vapidKey: process.env.REACT_APP_NOTI_VAPIDKEY })
            .then(token => {
              setBrowserToken(token)
            })
            .catch(error => {
              console.error(error)
            })
        }
      })
    }
  }

  useEffect(() => {
    if (!isTvScreen) {
      requestPermission()
    }
  }, [])

  function getLayout() {
    let layoutCls = VerticalLayout

    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()

  return (
    <React.Fragment>
      <Routes>
        {authRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            isAuthProtected={false}
          />
        ))}

        {userRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware path={route.path}>
                <Layout>{route.component}</Layout>
              </Authmiddleware>
            }
            key={idx}
            isAuthProtected={true}
            exact
          />
        ))}
      </Routes>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
