/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { Tooltip } from "@mui/material"
import Select from "react-select"
import SelectErrorMessage from "../../Common/SelectErrorMessage"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { get, post } from "../../../helpers/api_helper"
import { isArray } from "lodash"
import { API_URL } from "../../../config"
import toastr from "toastr"

const BulkUploadModal = ({ sample = false, show, toggle, setFile, urlPath, selectOptions }) => {
  const [bulkCategory, setBulkCategory] = useState("")
  let [selectedFile, setSelectedFile] = useState("")

  const [options, setOptions] = useState()

  useEffect(() => {
    if (isArray(selectOptions)) {
      setOptions(selectOptions)
    } else {
      getOptions(selectOptions)
    }
  }, [])

  const getOptions = async OPTION_PATH => {
    try {
      const res = await get(OPTION_PATH)
      if (res.data) {
        setOptions(res.data)
      }
    } catch (error) {
      console.error(`Error fetching options:`, error)
    }
  }

  const [error, setError] = useState({})
  const bulkFormRef = useRef()

  const bulkReset = () => {
    bulkFormRef?.current?.reset()
    setBulkCategory("")
    setFile(null)
  }

  useEffect(() => {
    if (!show) bulkReset()
  }, [show])

  const handleFileImport = e => {
    if (!e.target.files[0]) return
    setSelectedFile(e.target.files[0])
    setFile(true)
  }

  const checkErrorInput = () => {
    let flag = false

    if (!bulkCategory?.value) {
      flag = true
      setError(prev => ({ ...prev, bulkCategory: true }))
    }
    return flag
  }
  const handleBulkUpload = async () => {
    if (!bulkCategory?.value) {
      toastr.info("Please select a category")
      return
    }

    try {
      let formData = new FormData()
      formData.append("file", selectedFile)
      let res = await post(`${API_URL}${urlPath}${bulkCategory.value ?? ""}`, formData)
      toastr.success(res.message ?? "Uploaded successfully")

      toggle()
    } catch (err) {
      console.error("Error in bulk upload", err)
      toastr.error(err.message ?? "Error in bulk upload")
    }
  }
  return (
    <Modal isOpen={show} toggle={toggle}>
      <ModalHeader toggle={toggle}>Bulk Upload</ModalHeader>
      <AvForm
        ref={bulkFormRef}
        onValidSubmit={(e, v) => {
          handleBulkUpload()
        }}
        onInvalidSubmit={() => {
          checkErrorInput()
        }}
      >
        <ModalBody>
          <FormGroup>
            <div className="mb-3">
              <Label for="source">Select Category</Label>
              <Select
                placeholder="Select a Category"
                value={bulkCategory}
                onChange={e => setBulkCategory(e)}
                options={options}
              />
            </div>
            <SelectErrorMessage
              message={"Please select a category"}
              show={!bulkCategory?.value && error?.bulkCategory}
            />
          </FormGroup>
          <FormGroup className="mt-3">
            <Label>Upload File</Label>
            <Tooltip title="(.xls, .xlsx)" arrow placement="bottom">
              <div>
                <AvField
                  name="file"
                  type="file"
                  onChange={handleFileImport}
                  accept=".xls, .xlsx"
                  style={{ lineHeight: "1.8" }}
                  validate={{ required: { value: true } }}
                  errorMessage="Please select a file to import"
                />
              </div>
            </Tooltip>
          </FormGroup>
        </ModalBody>
        <ModalFooter className={`${sample ? "d-flex justify-content-between" : ""}`}>
          {sample ? (
            <Tooltip title="Download sample file">
              <a
                href={`./sample/bulk_upload_contacts.xlsx`}
                target="_blank"
                rel="noreferrer"
                download
                style={{ fontSize: "1rem", width: "fit-content" }}
                className="d-flex align-items-center btn btn-success download-btn"
              >
                <i style={{ fontSize: "13px" }} className="fa fa-solid fa-download" />
                <span style={{ marginLeft: "5px", fontSize: "13px" }}>Download Sample Sheet</span>
              </a>
            </Tooltip>
          ) : null}
          <div>
            <Button type="submit" color="primary" className="mx-2">
              Submit
            </Button>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </AvForm>
    </Modal>
  )
}

BulkUploadModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setFile: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  urlPath: PropTypes.string.isRequired,
  selectOptions: PropTypes.any.isRequired,
}

export default BulkUploadModal
