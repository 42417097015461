import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import Select from 'react-select';
import leadService from '../../../services/leadService';
import toastr from 'toastr';

const LeadModal = ({ isOpen, toggle, reviewData }) => {
  const initialFormState = {
    name: '',
    mobileNo: '',
    email: '',
    leadSource: null,
    level: null,
    priority: null,
    department: null,
    designation: null,
    leadType: null,
    campaign: null,
    followUpDate: '',
    followUpTime: '',
    address: '',
    comments: '',
    reviewId: null
  };

  const [formData, setFormData] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [leadTypes, setLeadTypes] = useState([]);
  const [sources, setSources] = useState([]);
  const [campaigns, setCampaigns] = useState([]);

  // Options for select fields
  const levelOptions = [
    { value: 'P', label: 'P' },
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' }
  ];

  const priorityOptions = [
    { value: 'High', label: 'High' },
    { value: 'Medium', label: 'Medium' },
    { value: 'Low', label: 'Low' }
  ];
  
  const populateReviewData = async (reviewData) => {
    try {
      // If sources are empty, fetch them first
      if (sources.length === 0) {
        await fetchDropdownData();
        // Add a small delay to ensure state is updated
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      // Find matching source
      const matchingSource = sources.find(source => {
        const sourceLabel = (source.label || '').toLowerCase();
        const sourceName = (source.name || '').toLowerCase();
        const reviewSourceLower = (reviewData.source || '').toLowerCase();
        
        return sourceLabel === reviewSourceLower || 
               sourceName === reviewSourceLower || 
               sourceLabel.includes(reviewSourceLower) ||
               reviewSourceLower.includes(sourceLabel);
      });

      setFormData({
        ...initialFormState,
        name: reviewData.reviewerName || '',
        comments: reviewData.content || '',
        leadSource: matchingSource || null,
        reviewId: reviewData._id
      });

    } catch (error) {
      console.error('Error in populateReviewData:', error);
    }
  };

  useEffect(() => {
    let mounted = true;

    const init = async () => {
      if (!isOpen) {
        resetForm();
      } else {
        await fetchDropdownData();
        if (mounted && reviewData) {
          await populateReviewData(reviewData);
        }
      }
    };

    init();

    return () => {
      mounted = false;
    };
  }, [isOpen, reviewData]); //eslint-disable-line

  const resetForm = () => {
    setFormData(initialFormState);
    setFormErrors({});
  };

  const handleModalClose = () => {
    resetForm();
    toggle();
  };

  const fetchDropdownData = async () => {
    setIsLoading(true);
    try {
      const [deptResponse, desigResponse, typesResponse, sourceResponse, campaignResponse] = await Promise.all([
        leadService.getDepartments(),
        leadService.getDesignations(),
        leadService.getLeadTypes(),
        leadService.getSource(),
        leadService.getCampaign()
      ]);

      const formatOptions = (data) => {
        if (!Array.isArray(data)) return [];
        return data.map(item => ({
          value: item._id,
          label: item.name,
          ...item
        }));
      };

      setDepartments(formatOptions(deptResponse.data || deptResponse));
      setDesignations(formatOptions(desigResponse.data || desigResponse));
      setLeadTypes(formatOptions(typesResponse.data || typesResponse));
      setCampaigns(formatOptions(campaignResponse.data || campaignResponse));
      const formattedSources = formatOptions(sourceResponse.data || sourceResponse);
      setSources(formattedSources);
    } catch (error) {
      console.error('Error fetching dropdown data:', error);
      toastr.error('Failed to load form data');
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (name, value) => {
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user starts typing
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleSelectChange = (option, { name }) => {
    handleInputChange(name, option);
    handleBlur(name, option);
  };

  const handleBlur = (name, value) => {
    let error = '';
    
    // For Select components, value will be an object with a value property
    const actualValue = value?.value || value;
    
    switch (name) {
      // case 'mobileNo':
      //   if (!actualValue) {
      //     error = 'Mobile number is required';
      //   } else if (!/^\d{10}$/.test(actualValue)) {
      //     error = 'Mobile number must be 10 digits';
      //   }
      //   break;
        
      case 'name':
        if (!actualValue || actualValue.trim() === '') {
          error = 'Name is required';
        }
        break;
        
      case 'leadSource':
        if (!actualValue) {
          error = 'Lead Source is required';
        }
        break;
        
      case 'level':
        if (!actualValue) {
          error = 'Level is required';
        }
        break;
        
      case 'department':
        if (!actualValue) {
          error = 'Department is required';
        }
        break;
        
      case 'address':
        if (!actualValue || actualValue.trim() === '') {
          error = 'Address is required';
        }
        break;
  
      case 'leadType':
        if (!actualValue) {
          error = 'Lead Type is required';
        }
        break;
        
      default:
        break;
    }
  
    setFormErrors(prev => ({
      ...prev,
      [name]: error
    }));
  
    return error;
  };

  const validateForm = () => {
    const errors = {};
    
    // if (!formData.mobileNo) {
    //   errors.mobileNo = 'Mobile number is required';
    // } else if (!/^\d{10}$/.test(formData.mobileNo)) {
    //   errors.mobileNo = 'Mobile number must be 10 digits';
    // }
    
    if (!formData.name || formData.name.trim() === '') {
      errors.name = 'Name is required';
    }
  
    if (!formData.leadSource) {
      errors.leadSource = 'Lead source is required';
    }
  
    if (!formData.level) {
      errors.level = 'Level is required';
    }
  
    if (!formData.department) {
      errors.department = 'Department is required';
    }
    
    if (!formData.leadType) {
      errors.leadType = 'Lead type is required';
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(isLoading) return;

    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length > 0) return;

    try {
      setIsLoading(true);
      
      // Transform form data for API
      const apiFormData = {
        ...formData,
        leadSource: formData.leadSource?.value,
        department: formData.department?.value,
        designation: formData.designation?.value,
        leadType: formData.leadType?.value,
        level: formData.level?.value,
        priority: formData.priority?.value,
        campaign: formData.campaign?.value,
        reviewId: formData.reviewId,
      };
      
      await leadService.createLead(apiFormData);
      toastr.success("Lead Created Successfully");
      toggle();
    } catch (error) {
      console.error("Error submitting lead:", error);
      toastr.error("Error saving lead");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={handleModalClose} size="xl">
      <ModalHeader toggle={handleModalClose}>Create Lead</ModalHeader>
      <Form onSubmit={handleSubmit}>
        <ModalBody>
        <Row>
            <Col md={3}>
            <FormGroup>
                <Label for="mobileNo">
                Mobile No.
                {/* <span className="text-danger"> *</span> */}
                </Label>
                <Input
                type="text"
                name="mobileNo"
                id="mobileNo"
                value={formData.mobileNo}
                onChange={(e) => handleInputChange('mobileNo', e.target.value)}
                // onBlur={(e) => handleBlur('mobileNo', e.target.value)}
                invalid={!!formErrors.mobileNo}
                placeholder='Enter Mobile No'
                />
                {/* {formErrors.mobileNo && (
                <div className="invalid-feedback">
                    {formErrors.mobileNo}
                </div>
                )} */}
            </FormGroup>
            </Col>

            <Col md={3}>
            <FormGroup>
                <Label for="name">
                Name<span className="text-danger"> *</span>
                </Label>
                <Input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={(e) => handleInputChange('name', e.target.value)}
                onBlur={(e) => handleBlur('name', e.target.value)}
                invalid={!!formErrors.name}
                placeholder='Enter Name'
                />
                {formErrors.name && (
                <div className="invalid-feedback">
                    {formErrors.name}
                </div>
                )}
            </FormGroup>
            </Col>

            <Col md={3}>
            <FormGroup>
                <Label for="email">
                Email
                </Label>
                <Input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={(e) => handleInputChange('email', e.target.value)}
                invalid={!!formErrors.email}
                placeholder='Enter Email ID'
                />
            </FormGroup>
            </Col>

            <Col md={3}>
            <FormGroup>
                <Label for="leadSource">
                Lead Source<span className="text-danger"> *</span>
                </Label>
                <Select
                name="leadSource"
                value={formData.leadSource}
                options={sources}
                onChange={(option) => handleSelectChange(option, { name: 'leadSource' })}
                onMenuClose={() => handleBlur('leadSource', formData.leadSource)}
                className={formErrors.leadSource ? 'is-invalid' : ''}
                isClearable
                />
                {formErrors.leadSource && (
                <div className="invalid-feedback">
                    {formErrors.leadSource}
                </div>
                )}
            </FormGroup>
            </Col>

            <Col md={3}>
            <FormGroup>
                <Label for="level">
                Level
                </Label>
                <Select
                name="level"
                value={formData.level}
                options={levelOptions}
                onChange={(option) => handleSelectChange(option, { name: 'level' })}
                onMenuClose={() => handleBlur('level', formData.level)}
                className={formErrors.level ? 'is-invalid' : ''}
                isClearable
                />
                {formErrors.level && (
                <div className="invalid-feedback">
                    {formErrors.level}
                </div>
                )}
            </FormGroup>
            </Col>

            <Col md={3}>
            <FormGroup>
                <Label for="priority">
                Priority
                </Label>
                <Select
                name="priority"
                value={formData.priority}
                options={priorityOptions}
                onChange={(option) => handleSelectChange(option, { name: 'priority' })}
                className={formErrors.priority ? 'is-invalid' : ''}
                isClearable
                />
            </FormGroup>
            </Col>        

            <Col md={3}>
            <FormGroup>
                <Label for="department">
                Department<span className="text-danger"> *</span>
                </Label>
                <Select
                name="department"
                value={formData.department}
                options={departments}
                onChange={(option) => handleSelectChange(option, { name: 'department' })}
                onMenuClose={() => handleBlur('department', formData.department)}
                className={formErrors.department ? 'is-invalid' : ''}
                isLoading={isLoading}
                isClearable
                />
                {formErrors.department && (
                <div className="invalid-feedback">
                    {formErrors.department}
                </div>
                )}
            </FormGroup>
            </Col>

            {/* <Col md={3}>
            <FormGroup>
                <Label for="designation">
                Designation
                </Label>
                <Select
                name="designation"
                value={formData.designation}
                options={designations}
                onChange={(option) => handleSelectChange(option, { name: 'designation' })}
                className={formErrors.designation ? 'is-invalid' : ''}
                isLoading={isLoading}
                />
                {formErrors.designation && (
                <div className="invalid-feedback">
                    {formErrors.designation}
                </div>
                )}
            </FormGroup>
            </Col> */}

            <Col md={3}>
            <FormGroup>
                <Label for="leadType">
                Lead Type<span className="text-danger"> *</span>
                </Label>
                <Select
                name="leadType"
                value={formData.leadType}
                options={leadTypes}
                onChange={(option) => handleSelectChange(option, { name: 'leadType' })}
                onMenuClose={() => handleBlur('leadType', formData.leadType)}
                className={formErrors.leadType ? 'is-invalid' : ''}
                isLoading={isLoading}
                isClearable
                />
                {formErrors.leadType && (
                <div className="invalid-feedback">
                    {formErrors.leadType}
                </div>
                )}
            </FormGroup>
            </Col>

            <Col md={3}>
            <FormGroup>
                <Label for="campaign">
                Campaign
                </Label>
                <Select
                name="campaign"
                value={formData.campaign}
                options={campaigns}
                onChange={(option) => handleSelectChange(option, { name: 'campaign' })}
                className={formErrors.campaign ? 'is-invalid' : ''}
                isClearable
                />
                {formErrors.campaign && (
                <div className="invalid-feedback">
                    {formErrors.campaign}
                </div>
                )}
            </FormGroup>
            </Col>

            <Col md={3}>
            <FormGroup>
                <Label for="followUpDate">
                Follow Up Date
                </Label>
                <Input
                type="date"
                name="followUpDate"
                id="followUpDate"
                value={formData.followUpDate}
                onChange={(e) => handleInputChange('followUpDate', e.target.value)}
                invalid={!!formErrors.followUpDate}
                />
            </FormGroup>
            </Col>

            <Col md={3}>
            <FormGroup>
                <Label for="followUpTime">
                Follow Up Time
                </Label>
                <Input
                type="time"
                name="followUpTime"
                id="followUpTime"
                value={formData.followUpTime}
                onChange={(e) => handleInputChange('followUpTime', e.target.value)}
                invalid={!!formErrors.followUpTime}
                disabled={!formData.followUpDate} // Disable if no date is selected
                />
                {formData.followUpTime && !formData.followUpDate && (
                  <div className="text-danger small mt-1">
                    Please select a follow-up date first
                  </div>
                )}
            </FormGroup>
            </Col>

            <Col md={6}>
            <FormGroup>
                <Label for="address">
                Address
                </Label>
                <Input
                type="textarea"
                name="address"
                id="address"
                value={formData.address}
                onChange={(e) => handleInputChange('address', e.target.value)}
                onBlur={(e) => handleBlur('address', e.target.value)}
                invalid={!!formErrors.address}
                style={{ minHeight: '120px' }}
                className={formErrors.address ? 'is-invalid' : ''}
                placeholder="Enter Address"
                />
                {formErrors.address && (
                <div className="invalid-feedback">
                    {formErrors.address}
                </div>
                )}
            </FormGroup>
            </Col>

            <Col md={6}>
            <FormGroup>
                <Label for="comments">
                Comments
                </Label>
                <Input
                type="textarea"
                name="comments"
                id="comments"
                value={formData.comments}
                onChange={(e) => handleInputChange('comments', e.target.value)}
                invalid={!!formErrors.comments}
                style={{ minHeight: '120px' }}
                placeholder="Enter Comments"
                />
            </FormGroup>
            </Col>
        </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" disabled={isLoading}>
            {isLoading ? 'Creating...' : 'Create Lead'}
          </Button>
          <Button color="danger" onClick={resetForm} type="button">
            Reset
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default LeadModal;