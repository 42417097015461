import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { dateConverter, setTitle } from "../../../helpers/functions"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import { isArray } from "lodash"
import { del, get, post, put } from "../../../helpers/api_helper"

import toastr from "toastr"

import Swal from "sweetalert2"

import { MDBDataTable } from "mdbreact"

const initialSelected = { type: { label: "Name", value: "name" } }

const initialMaster = { type: "name" }

const AlertKeyWords = () => {
  const [selected, setSelected] = useState(initialSelected)

  const [master, setMaster] = useState(initialMaster)

  const [reload, setReload] = useState(false)

  const [rows, setRows] = useState([])

  const formRef = useRef(null)

  useEffect(() => {
    fetchKeywords()
  }, [reload])

  const handleSelect = (e, name) => {
    const value = e ? (isArray(e) ? e.map(item => item.value) : e.value) : null
    setSelected(pre => ({ ...pre, [name]: e }))
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  const handleChange = e => {
    let { name, value } = e.target
    const data = { ...master }
    data[name] = value
    if (name === "name") delete data.keyword
    else delete data.name
    setMaster(data)
  }

  const handleUpdate = item => {
    const data = { id: item._id, type: item.type, priority: item.priority, alert: item.alert }

    if (item.type === "name") data.name = item.name.join(", ")
    else data.keyword = item.keyword.join(", ")

    setMaster(data)

    setSelected({
      type: { label: item.type, value: item.type },
      priority: { label: item.priority, value: item.priority },
      alert: { label: item.alert, value: item.alert },
    })
  }

  const handleSubmit = () => {
    const data = { ...master }
    if (data.type === "name") data.name = data.name.split(",").map(item => item.trim())
    if (data.type === "keyword") data.keyword = data.keyword.split(",").map(item => item.trim())

    if (data.id) {
      put("alert-keyword", data).then(response => {
        setReload(pre => !pre)
        toastr.success(response.message)
        reset()
      })
    } else {
      post("alert-keyword", data).then(response => {
        setReload(pre => !pre)
        toastr.success(response.message)
        reset()
      })
    }
  }

  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`alert-keyword/${id}`)
          .then(res => {
            toastr.success(res.message)
            setReload(pre => !pre)
          })
          .catch(err => {
            toastr.success(err.response.data.message)
          })
      }
    })
  }

  const fetchKeywords = () => {
    get("alert-keyword").then(response => {
      const data = response.data.map((item, idx) => {
        item.id = idx + 1

        item.date = dateConverter(item.date)

        item.nameOrKeyword = item.name.length > 0 ? item.name.join(", ") : item.keyword.join(", ")

        item.action = (
          <div>
            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.1em",
                marginRight: "0.5em",
              }}
              onClick={() => {
                handleUpdate(item)
                toTop()
              }}
            ></i>

            <i
              className="far fa-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer" }}
              onClick={() => {
                handleDelete(item._id)
              }}
            ></i>
          </div>
        )

        return item
      })

      setRows(data)
    })
  }

  const reset = () => {
    formRef.current.reset()
    setSelected(initialSelected)
    setMaster(initialMaster)
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },

      {
        label: "Type",
        field: "type",
        sort: "asc",
        width: 150,
      },
      {
        label: "Priority",
        field: "priority",
        sort: "asc",
        width: 150,
      },
      {
        label: "Alert",
        field: "alert",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name/Keyword",
        field: "nameOrKeyword",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem={setTitle("Alert Keywords")} />

          <Row>
            <Card>
              <CardBody>
                <AvForm
                  ref={formRef}
                  className="needs-validation"
                  onValidSubmit={(e, v) => {
                    handleSubmit()
                  }}
                >
                  <Row>
                    <Col md={3}>
                      <div className="mb-3">
                        <Label>Type</Label>
                        <Select
                          name="type"
                          //   value={selectedBranch}
                          value={selected?.type}
                          onChange={e => handleSelect(e, "type")}
                          options={[
                            { label: "Name", value: "name" },
                            { label: "Keyword", value: "keyword" },
                          ]}
                          classNamePrefix="select2-selection"
                        />
                        <p className="text-danger" style={{ fontSize: "11px" }}></p>
                      </div>
                    </Col>

                    {selected?.type?.value === "name" ? (
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {selected?.type?.label || "Name"}
                          </Label>
                          <AvField
                            name="name"
                            placeholder="Eg. name1, name2, name3"
                            type="text"
                            errorMessage="Enter Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            onChange={handleChange}
                            value={master?.name || ""}
                          />
                        </div>
                      </Col>
                    ) : (
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Keyword</Label>
                          <AvField
                            name="keyword"
                            placeholder="Eg. one, two, three"
                            type="textarea"
                            rows="1"
                            errorMessage="Enter Keyword"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            value={master?.keyword || ""}
                            id="validationCustom01"
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                    )}

                    <Col md={3}>
                      <div className="mb-3">
                        <Label>Priority</Label>
                        <Select
                          name="priority"
                          //   value={selectedBranch}
                          onChange={e => handleSelect(e, "priority")}
                          options={[
                            { label: "High", value: "high" },
                            { label: "Medium", value: "medium" },
                            { label: "Low", value: "low" },
                          ]}
                          value={selected?.priority || null}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="mb-3">
                        <Label>Alert</Label>
                        <Select
                          name="alert"
                          //   value={selectedBranch}
                          onChange={e => handleSelect(e, "alert")}
                          options={[
                            { label: "Call", value: "call" },
                            { label: "Notification", value: "notification" },
                            { label: "Email", value: "email" },
                          ]}
                          classNamePrefix="select2-selection"
                          value={selected?.alert || null}
                        />
                      </div>
                    </Col>

                    <Col md="1" style={{ paddingTop: "5px" }}>
                      <Button color={`${master.id ? "warning" : "primary"}`} type="submit">
                        {master.id ? "Update" : "Submit"}
                      </Button>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="designationTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AlertKeyWords
