import React, { useState, useEffect, useRef } from 'react';
import { Card, CardBody, CardTitle, Row, Col, Container, Spinner, Alert } from 'reactstrap';
import $ from 'jquery';
import 'jquery-knob';
import Chart from 'chart.js/auto';
import GaugeComponent from 'react-gauge-component';
import { get } from "../../helpers/api_helper"
import Breadcrumbs from "../../components/Common/Breadcrumb.js";

const ListeningDashboard = () => {
    const [dashboardCards, setDashboardCards] = useState(null);
    const [platformData, setPlatformData] = useState(null);
    const [trendData, setTrendData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    const positiveKnobRef = useRef(null);
    const neutralKnobRef = useRef(null);
    const negativeKnobRef = useRef(null);
    const platformKnobRefs = useRef({});
    const chartRef = useRef(null);
    const chartInstance = useRef(null);

    const API_URL = process.env.REACT_APP_APIURL;

    const formatPercentage = (value) => {
      const numValue = parseFloat(value);
      return `${numValue % 1 >= 0.5 ? Math.ceil(numValue) : Math.floor(numValue)}%`;
    }

    // Fetch data from your API
    const fetchDashboardCards = async () => {
        try {
            setLoading(true);
            // Replace with your actual API endpoint
            const response = await get(`${API_URL}listening/dashboard/cards`);
            setDashboardCards(response);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch dashboard data');
            setLoading(false);
            console.error('Error fetching dashboard data:', err);
        }
    };

    const fetchDashboardPlatforms = async () => {
        try {
            setLoading(true);
            const response = await get(`${API_URL}listening/dashboard/platforms`);
            setPlatformData(response);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch platform data');
            setLoading(false);
            console.error('Error fetching platform data:', err);
        }
    }

    const fetchSentimentTrends = async () => {
      try {
            setLoading(true);
            const response = await get(`${API_URL}listening/dashboard/trends`);
            setTrendData(response);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch sentiment trends data');
            setLoading(false);
            console.error('Error fetching sentiment trends data:', err);
        }
    }

    useEffect(() => {
        fetchDashboardCards();
        fetchDashboardPlatforms();
        fetchSentimentTrends();
    }, []);

    useEffect(() => {
        // Initialize jQuery Knob after data is loaded
        if (dashboardCards && !loading) {
          // Positive sentiment knob
          $(positiveKnobRef.current).knob({
            readOnly: true,
            width: 150,
            height: 150,
            thickness: 0.2,
            fgColor: '#5BE12C',
            bgColor: '#f0f0f0',
            displayInput: true,
            font: 'Arial',
            fontWeight: 'bold',
            format: function (value) {
              return `${value}%`;
            }
          });
    
          // Neutral sentiment knob
          $(neutralKnobRef.current).knob({
            readOnly: true,
            width: 150,
            height: 150,
            thickness: 0.2,
            fgColor: '#F5CD19',
            bgColor: '#f0f0f0',
            displayInput: true,
            font: 'Arial',
            fontWeight: 'bold',
            format: function (value) {
              return `${value}%`;
            }
          });
    
          // Negative sentiment knob
          $(negativeKnobRef.current).knob({
            readOnly: true,
            width: 150,
            height: 150,
            thickness: 0.2,
            fgColor: '#EA4228',
            bgColor: '#f0f0f0',
            displayInput: true,
            font: 'Arial',
            fontWeight: 'bold',
            format: function (value) {
              return `${value}%`  ;
            }
          });
        }
      }, [dashboardCards, loading]);
      
      useEffect(() => {
        if (platformData && platformData.data) {
          
          // Initialize knobs for each platform
          platformData.data.forEach(platform => {
            const platformRef = platformKnobRefs.current[platform.platform];
            if (platformRef) {
              $(platformRef).knob({
                readOnly: true,
                width: 120,
                height: 120,
                thickness: 0.2,
                fgColor: getPlatformColor(platform.platform),
                bgColor: '#f0f0f0',
                displayInput: false, // Hide the built-in display since we'll use custom text
                font: 'Arial',
                fontWeight: 'bold'
              });
            }
          });
        }
      }, [platformData]);

      
      useEffect(() => {
        if (!trendData || !chartRef.current) return;
        
        const ctx = chartRef.current.getContext('2d');
        
        // Clean up any existing chart
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }
        
        // Create new chart
        chartInstance.current = new Chart(ctx, {
          type: 'line',
          data: {
            labels: trendData.data.labels,
            datasets: trendData.data.datasets.map(dataset => ({
              label: dataset.label,
              data: dataset.data,
              borderColor: dataset.color,
              backgroundColor: 'transparent',
              tension: 0.4,
              borderWidth: 3,
              pointBackgroundColor: dataset.color,
              pointBorderColor: '#fff',
              pointBorderWidth: 2,
              pointRadius: 4,
              pointHoverRadius: 6
            }))
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: 'top',
                labels: {
                  boxWidth: 20,
                  padding: 20
                }
              },
              tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                  label: function(context) {
                    return context.dataset.label + ': ' + context.raw + ' sentiments';
                  }
                }
              }
            },
            scales: {
              x: {
                grid: {
                  display: false
                },
                title: {
                  display: true,
                  text: 'Month'
                }
              },
              y: {
                beginAtZero: true,
                grid: {
                  color: '#f0f0f0'
                },
                title: {
                  display: true,
                  text: 'Sentiment Count'
                },
                ticks: {
                  callback: function(value) {
                    return value;
                  }
                }
              }
            }
          }
        });
        
        // Cleanup function
        return () => {
          if (chartInstance.current) {
            chartInstance.current.destroy();
          }
        };
      }, [trendData]);
      
      // Helper function to get color for each platform
      const getPlatformColor = (platform) => {
        switch(platform.toLowerCase()) {
          case 'google':
            return '#4285F4'; // Google blue
          case 'facebook':
            return '#3b5998'; // Facebook blue
          case 'instagram':
            return '#E1306C'; // Instagram pink
          case 'twitter':
            return '#1DA1F2'; // Twitter blue
          case 'linkedin':
            return '#0077B5'; // LinkedIn blue
          case 'youtube':
            return '#FF0000'; // YouTube red
          default:
            return '#6c757d'; // Default gray
        }
      };
      
      // Helper function to get platform icon class
      const getPlatformIcon = (platform) => {
        switch(platform.toLowerCase()) {
          case 'google':
            return 'fab fa-google';
          case 'facebook':
            return 'fab fa-facebook-f';
          case 'instagram':
            return 'fab fa-instagram';
          case 'twitter':
            return 'fab fa-twitter';
          case 'linkedin':
            return 'fab fa-linkedin-in';
          case 'youtube':
            return 'fab fa-youtube';
          default:
            return 'fas fa-globe'; // Default web icon
        }
      };
      
      // Helper function to render growth indicator
      const renderGrowthIndicator = (value) => {
        const numValue = Number(value) || 0;
        const isPositive = numValue >= 0;
        const icon = isPositive ? "mdi mdi-arrow-up-bold" : "mdi mdi-arrow-down-bold";
        const textColor = isPositive ? "text-success" : "text-danger";
        
        return (
          <span className={textColor}>
            <i className={icon}></i> {Math.abs(numValue)}%
          </span>
        );
      };
      
      // Get the data from dashboardCards if available
      const data = dashboardCards?.data;

      // Show loading state while data is being fetched
      if (loading) {
        return (
          <React.Fragment>
            <div className="page-content">  
              <div className="text-center my-5">
                <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
                <div className="mt-3">Loading dashboard data...</div>
              </div>
            </div>
          </React.Fragment>
        );
      }

      //Error State
      if (error) {
        return (
          <React.Fragment>
            <div className="page-content">
              <Alert color="danger" className="my-4">
                <h4 className="alert-heading">Error Loading Data</h4>
                <p>{error}</p>
                <hr />
                <p className="mb-0">
                  Please try refreshing the page or contact support if the issue persists.
                </p>
              </Alert>
            </div>
          </React.Fragment>
        )
      }

      // Render the dashboard once data is available
      return (
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              <Breadcrumbs title="Home" breadcrumbItem="Listening Dashboard" />
              
              <Row className="mt-8">
                {/* Gauge Meter Card */}
                <Col lg="6">
                  <Card className="h-100 border-0 shadow-sm">
                    <CardBody className="p-0">
                      <CardTitle tag="h5" className="p-3">Sentiment Position</CardTitle>
                      <div className="d-flex flex-column align-items-center">
                        <div style={{ width: '100%', maxWidth: '500px', height: '200px', position: 'relative', margin: '0 auto' }}>
                          <GaugeComponent
                            id="gauge-component1"
                            style={{ width: '100%', height: '100%' }}
                            type="semicircle"
                            arc={{
                              width: 0.3,
                              padding: 0.003,
                              gradient: true,
                              subArcs: [
                                {
                                  limit: 15.33,
                                  color: '#EA4228',
                                  showMark: false
                                },
                                {
                                  limit: 66.67,
                                  color: '#F5CD19',
                                  showMark: false
                                },
                                {
                                  limit: 100,
                                  color: '#5BE12C',
                                  showMark: false
                                }
                              ]
                            }}
                            value={data?.gaugeMeterValue}
                            pointer={{
                              type: "needle",
                              width: 5,
                              color: '#464A4F',
                              elastic: true,
                              animationDelay: 0
                            }}
                            labels={{
                              valueLabel: {
                                formatTextValue: value => '',  // Hide the built-in value label
                                style: { display: 'none' }
                              },
                              tickLabels: {
                                type: 'outer',
                                ticks: [
                                  { value: 0 },
                                  { value: 100 }
                                ],
                                defaultTickValueConfig: {
                                  formatTextValue: value => value + '%',
                                  style: { fontSize: 12 }
                                }
                              }
                            }}
                          />
                        </div>
                        <div className="text-center mt-2 mb-3">
                          <h1 className="display-5 fw-bold mb-2">{data?.gaugeMeterValue}%</h1>
                          <div className="mb-1">
                            Total Sentiments: {data?.total} {renderGrowthIndicator(data?.totalGrowth)}
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                
                {/* Sentiment Analysis Cards */}
                <Col lg="6">
                  <Card className="h-100 border-0 shadow-sm">
                    <CardBody>
                      <CardTitle tag="h5" className="mb-4">Sentiment Analysis</CardTitle>
                      <Row className="h-100 d-flex align-items-center justify-content-center">
                        {/* Positive Sentiment */}
                        <Col md="4" className="text-center">
                          <div className="d-flex flex-column align-items-center">
                            <input 
                              ref={positiveKnobRef}
                              type="text" 
                              value={formatPercentage(data?.positive?.percentage)} 
                              className="dial" 
                            />
                            <div className="mt-3">
                              <h6 className="text-success fw-bold mb-1">Positive</h6>
                              <div className="large">
                                {data?.positive?.count} sentiments <br />
                                {renderGrowthIndicator(data?.positive?.growth)}
                              </div>
                            </div>
                          </div>
                        </Col>
                        
                        {/* Neutral Sentiment */}
                        <Col md="4" className="text-center">
                          <div className="d-flex flex-column align-items-center">
                            <input 
                              ref={neutralKnobRef}
                              type="text" 
                              value={formatPercentage(data?.neutral?.percentage)} 
                              className="dial" 
                            />
                            <div className="mt-3">
                              <h6 className="text-warning fw-bold mb-1">Neutral</h6>
                              <div className="large">
                                {data?.neutral?.count} sentiments <br />
                                {renderGrowthIndicator(data?.neutral?.growth)}
                              </div>
                            </div>
                          </div>
                        </Col>
                        
                        {/* Negative Sentiment */}
                        <Col md="4" className="text-center">
                          <div className="d-flex flex-column align-items-center">
                            <input 
                              ref={negativeKnobRef}
                              type="text" 
                              value={formatPercentage(data?.negative?.percentage)} 
                              className="dial" 
                            />
                            <div className="mt-3">
                              <h6 className="text-danger fw-bold mb-1">Negative</h6>
                              <div className="large">
                                {data?.negative?.count} sentiments <br />
                                {renderGrowthIndicator(data?.negative?.growth)}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* Platform Analysis Card */}
              {platformData && (
                <Row className="mt-4">
                  <Col lg="12">
                    <Card className="mb-4">
                      <CardBody>
                        <CardTitle tag="h5" className="mb-4">Platform Wise Analysis</CardTitle>
                        <Row className="justify-content-center">
                          {platformData.data.map((platform, index) => (
                            <Col md={Math.min(12 / platformData.data.length, 3)} className="text-center mb-4" key={platform.platform}>
                              <div className="d-flex flex-column align-items-center">
                                <div style={{ position: 'relative' }}>
                                  <input 
                                    ref={el => platformKnobRefs.current[platform.platform] = el}
                                    type="text" 
                                    value={Math.round((platform.totalCount / platformData.summary.totalInteractions) * 100)} 
                                    className="dial" 
                                  />
                                  {/* Platform icon overlaid on the knob */}
                                  <div style={{ 
                                    position: 'absolute', 
                                    top: '50%', 
                                    left: '50%', 
                                    transform: 'translate(-50%, -50%)',
                                    zIndex: 2
                                  }}>
                                    <i className={getPlatformIcon(platform.platform)} style={{ 
                                      fontSize: '30px',
                                      color: getPlatformColor(platform.platform)
                                    }}></i>
                                  </div>
                                </div>
                                <div className="mt-3">
                                  <h6 style={{ color: getPlatformColor(platform.platform) }} className="mb-1">
                                    <strong>{platform.platform.charAt(0).toUpperCase() + platform.platform.slice(1)}</strong>
                                  </h6>
                                  <div className="large">
                                    {platform.totalCount.toLocaleString()} interactions
                                  </div>
                                  <div className="small">
                                    {Math.round((platform.totalCount / platformData.summary.totalInteractions) * 100)}% of total
                                  </div>
                                </div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                        <div className="text-center mt-2">
                          <div className="text-muted">
                            Total Interactions: <strong>{platformData.summary.totalInteractions.toLocaleString()}</strong> across <strong>{platformData.summary.totalPlatforms}</strong> platforms
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}

              {/* Sentiment Trend Chart */}
              {trendData && (
                <Row className="mt-0">
                  <Col lg="12">
                    <Card className="border-0 shadow-sm">
                      <CardBody>
                        <CardTitle tag="h5" className="mb-4">Sentiment Trends (Last 6 Months)</CardTitle>
                        <div className="chart-container" style={{ height: '350px' }}>
                          <canvas id="sentimentTrendChart" ref={chartRef}></canvas>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
            </Container>
          </div>
        </React.Fragment>
      );
};

export default ListeningDashboard;