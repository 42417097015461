import React, { useEffect, useState } from "react"
import { Card, CardBody, Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { setTitle } from "../../helpers/functions"
import { MDBDataTable } from "mdbreact"
import { get } from "../../helpers/api_helper"
import moment from "moment"
import "./styles.scss"

const Invoices = () => {
  const [rows, setRows] = useState([])

  useEffect(() => {
    handleTableData()
  }, [])

  const handleTableData = async () => {
    try {
      const response = await get("whatsapp/invoice/list")

      const data = response.data.map((item, idx) => {
        item.id = idx + 1

        item.month = item?.month && moment(item?.month, "YYYY-MM").format("MMMM YYYY")

        item.auth = item?.whatsapp?.authentication
        item.marketing = item?.whatsapp?.marketing
        item.utility = item?.whatsapp?.utility

        item.total = (item?.totalRate || "0").toFixed(2)

        item.paidStatus = (
          <div className="text-center ">
            <span
              className={`badge font-size-12 ms-2 ${
                item.paid ? "bg-soft-success" : "bg-soft-danger"
              } `}
            >
              {item.paid ? "Paid" : "Unpaid"}
            </span>
          </div>
        )

        return item
      })

      setRows(data)
    } catch (error) {
      console.log(error?.response?.data?.message || error?.message)
    }
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Month", field: "month" },
      { label: "Authentication", field: "auth" },
      { label: "Marketing", field: "marketing" },
      { label: "Utility", field: "utility" },

      {
        label: (
          <>
            Total <i className="fas fa-rupee-sign" />
          </>
        ),
        field: "total",
      },
      { label: "Status", field: "paidStatus" },
    ],
    rows,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem={setTitle("Invoice")} />

          <Card>
            <CardBody>
              <MDBDataTable
                id="whatsappInvoiceTable"
                responsive
                bordered
                data={data}
                info={false}
                paging={false}
                sortable={false}
                searching={false}
                disableRetreatAfterSorting={true}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Invoices
