import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { MDBDataTable } from "mdbreact"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Swal from "sweetalert2"
import { del, post, put, get } from "../../../helpers/api_helper"
import toastr from "toastr"
import ReactSelect from "react-select"

const FeedbackSubCategory = () => {
  document.title = "Feedback Sub Category"

  const [masterObject, setMasterObject] = useState({})
  const [tableData, setTableData] = useState([])
  const [selectedBranch, setSelectedBranch] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')
  const [branchOptions, setBranchOptions] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])

  const fetchBranchOptions = ()=> {
    get(`/options/branches`).then(res=>{
      setBranchOptions(res.data)
      setSelectedBranch(res.data[0])
      setMasterObject({...masterObject, branch: res?.data[0]?.value})
    }).catch(err=>{console.log(err)})
  }

  const fetchCategoryOptions = (value)=> {
    get(`/options/fb-category?branch=${value}`).then(res=>{
      setCategoryOptions(res.data)
    }).catch(err=>{console.log(err)})
  }

  useEffect(()=>{
    fetchCategoryOptions(masterObject.branch)
  },[masterObject.branch])

  const formRef = useRef()

  useEffect(() => {
    fetchBranchOptions()
    fetchTableData()
    // eslint-disable-next-line
  }, [])

  const fetchTableData = () => {
    get("feedback/sub-category").then(res => {
        const updatedData = res.data.map((item, idx) => {
        item.id = idx + 1
        item.branchName = item?.branch?.name
        item.categoryName = item?.category?.name
        item.action = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="uil-edit-alt"
              style={{
                fontSize: "1.2em",
                cursor: "pointer",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                toTop()
                handleUpdate(item)
              }}
            ></i>
            <i
              className="uil-trash-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
              onClick={() => {
                handleDelete(item._id)
              }}
            ></i>
          </div>
        )
        return item
      })
      if (res.data !== null) setTableData(updatedData)
    })
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`feedback/sub-category/${id}`)
          .then(res => {
            toastr.success(res.message)
            reset()
            fetchTableData()
          })
          .catch(err => {
            toastr.error(err.response.data.message)
          })
      }
    })
  }

  function handleChangeInput(name, value) {
    setMasterObject({ ...masterObject, [name]: value })
  }

  function handleUpdate(data) {
    const { name, _id, branch, category } = data
    setMasterObject({ _id, name, branch: branch?._id || '', category: category._id })
    branch?._id ? setSelectedBranch({label: branch.name, value: branch._id}) : setSelectedBranch('')
    category?._id ? setSelectedCategory({label: category.name, value: category._id}) : setSelectedCategory('')
  }

  function reset() {
    setMasterObject({branch: branchOptions[0]?.value})
    setSelectedBranch(branchOptions[0])
    setSelectedCategory('')
    formRef.current.reset()
  }

  function handleSubmit() {
    if (masterObject._id) {
      put(`feedback/sub-category`, masterObject)
        .then(res => {
          toastr.success(res.message)
          fetchTableData()
          reset()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
          return
        })
    } else {
      post(`feedback/sub-category`, masterObject)
        .then(res => {
          toastr.success(res.message)
          fetchTableData()
          reset()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
          return
        })
    }
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Branch",
        field: "branchName",
        sort: "asc",
        width: 270,
      },
      {
        label: "Category",
        field: "categoryName",
        sort: "asc",
        width: 270,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem="Feedback Sub-category" />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleSubmit()
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Branch</Label>
                          <ReactSelect
                            name="branch"
                            options={branchOptions}
                            placeholder="Branch"
                            value={selectedBranch || ""}
                            onChange={e => {
                              handleChangeInput("branch", e.value)
                              setSelectedBranch(e)
                            }}
                            isDisabled={branchOptions.length <= 1}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Category</Label>
                          <ReactSelect
                            name="category"
                            options={categoryOptions}
                            placeholder="Category"
                            value={selectedCategory || ""}
                            onChange={e => {
                              handleChangeInput("category", e.value)
                              setSelectedCategory(e)
                            }}
                            isDisabled={selectedBranch === ''}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Name</Label>
                          <AvField
                            name="name"
                            placeholder="Name"
                            type="text"
                            errorMessage="Enter Category"
                            validate={{ required: { value: true } }}
                            value={masterObject.name || ""}
                            onChange={e=> handleChangeInput('name', e.target.value)}
                          />
                        </div>
                      </Col>

                      <Col md="3" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          <Button
                            color={masterObject._id ? "warning" : "primary"}
                            className="me-2"
                            type="submit"
                          >
                            {masterObject._id ? "Update" : "Submit"}
                          </Button>

                          <Button
                            color="danger"
                            className="me-2"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="feedbackCategoryTable"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FeedbackSubCategory
