import React, { useState, useEffect, useRef } from "react"
import { MDBDataTable } from "mdbreact"
import axios from "axios"
import toastr from "toastr"
import SweetAlert from "react-bootstrap-sweetalert"
import { Row, Col, Card, CardBody, Button, Label, } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "./LeadType.scss"
import accessToken from "../../../helpers/jwt-token-access/accessToken"
import moment from "moment"


const Leadcategory = () => {
  const [designationObject, setDesignationObject] = useState({})
  const [designationIdTobeUpdated, setDesignationIdToBeUpdated] = useState(null)
  const [designationIdToBeDeleted, setDesignationIdToBeDeleted] = useState(null)
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(false)
  const [designationForTable, setDesignationForTable] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const setdesignationValue = useState("")[1]

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/"
  const formRef = useRef()
  
  useEffect(() => {
    handleTableData()
  }, [])

  function handleTableData() {
    var url = `${API_URL}leadtype/list`
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then(res => {
        
        var result = res.data?.category
        let designationData = []

        result?.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop()
                  preUpdateDesignation(item)
                }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setDesignationIdToBeDeleted(item._id)
                  setConfirmDeleteAlert(true)
                }}
              ></i>
            </div>
          )
          item.id = index + 1

          item.date = item?.up_date ? moment(item.up_date).format("DD-MM-YYYY") : item?.date ? moment(item.date).format("DD-MM-YYYY") : '';
          item.time = item?.up_time ? moment(item.up_time, "HHmmss").format("hh:mm a") : item?.time ? moment(item.time, "HHmmss").format("hh:mm a") : '';

          let values = item?.updated_by || item?.added_by || {};
          item.staff = values.firstName ? `${values.firstName} ${values.lastName || ''}` : values.firstName || '';

          item.name = item?.name || '';
          item.shortCode = item?.shortCode || '';

          designationData.push(item)
          return item;
        })
        setDesignationForTable(designationData)
      })
  }

  let preUpdateDesignation = item => {
    setdesignationValue(item?.name)
    setDesignationIdToBeUpdated(item._id)
    setDesignationObject(item)
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Time",
      //   field: "time",
      //   sort: "asc",
      //   width: 270,
      // },
      {
        label: "Type Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Code",
        field: "shortCode",
        sort: "asc",
        width: 150,
      },
      
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: designationForTable,
  }

  function handleChangeDesignation(e) {
    let name = e.target.name
    let value = e.target.value
    setdesignationValue(value)
    setDesignationObject({ ...designationObject, [name]: value })
  }

//   const handleValidSubmit = () => {
//     if (designationIdTobeUpdated) {
//       axios
//         .put(`${API_URL}leadtype`, designationObject, {
//           headers: {
//             "x-access-token": accessToken,
//           },
//         })
//         .then(res => {
//           if (res.data.code === 200) {
//             toastr.success("Updated successfully")
//             resetForm()
//             setdesignationValue("")
//             formRef.current.reset()
//             handleTableData()
//             setDesignationObject({})
//             setDesignationIdToBeUpdated(null)
//           } else {
//             toastr.error("Failed to update Type")
//             return
//           }
//         })
//         .catch(err => {
//           toastr.error(err.response.data.message)
//           return
//         })
//     } else {
//       axios
//         .post(`${API_URL}leadtype`, designationObject, {
//           headers: {
//             "x-access-token": accessToken,
//           },
//         })
//         .then(res => {
//           if (res.data.code === 200) {
//             toastr.success("Added successfully")
// resetForm()
//             handleTableData()
//             setdesignationValue("")
//             formRef.current.reset()
//             setDesignationObject({})
//           } else {
//             toastr.error("Failed to add Type")
//             return
//           }
//         })
//         .catch(err => {
//           toastr.error(err.response.data.message)
//           return
//         })
//     }
//   }

  const handleValidSubmit = () => {
    // Set loading state to true at the beginning of submission
    setIsLoading(true);

    if (designationIdTobeUpdated) {
      axios
        .put(`${API_URL}leadtype`, designationObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then(res => {
          if (res.data.code === 200) {
            toastr.success("Updated successfully")
            resetForm()
            setdesignationValue("")
            formRef.current.reset()
            handleTableData()
            setDesignationObject({})
            setDesignationIdToBeUpdated(null)
          } else {
            toastr.error("Failed to update Type")
          }
          // Set loading state back to false after response
          setIsLoading(false);
        })
        .catch(err => {
          toastr.error(err.response.data.message)
          // Also reset loading state on error
          setIsLoading(false);
        })
    } else {
      axios
        .post(`${API_URL}leadtype`, designationObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then(res => {
          if (res.data.code === 200) {
            toastr.success("Added successfully")
            resetForm()
            handleTableData()
            setdesignationValue("")
            formRef.current.reset()
            setDesignationObject({})
          } else {
            toastr.error("Failed to add Type")
          }
          // Set loading state back to false after response
          setIsLoading(false);
        })
        .catch(err => {
          toastr.error(err.response.data.message)
          // Also reset loading state on error
          setIsLoading(false);
        })
    }
  }

  const handleDeleteDesignation = () => {
    axios
      .delete(`${API_URL}leadtype/${designationIdToBeDeleted}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then(res => {
        if (res.data.code === 200) {
          toastr.success("Deleted successfully")
          if (designationObject._id === designationIdToBeDeleted) {
            setdesignationValue("")
            formRef.current.reset()
            setDesignationObject({})
            setDesignationIdToBeUpdated(null)
          }
          handleTableData()
        } else {
          toastr.error(res.data.message, "Failed to delete Type")
        }
      })
      .catch(err => {
        toastr.error(err.response.data.message)
      })
    setConfirmDeleteAlert(false)
  }

  const customStyles = {
    lineHeight: "1.8",
  }



  const reset = () => {
    if (formRef.current) { 
      formRef.current.reset();
    }
    setDesignationObject({});
    setdesignationValue("")
    toTop();
  };

  const resetForm = () => {
    setDesignationObject({}) 
    setdesignationValue("")   
  
    formRef.current.reset()
      toTop()
  }
  

  return (
    <>
      <React.Fragment>
        {confirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={handleDeleteDesignation}
            onCancel={() => setConfirmDeleteAlert(false)}
          >
            Are you sure you want to delete this?
          </SweetAlert>
        ) : null}
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Manage Lead Type" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      <Row>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Type Name<span className="text-danger"> *</span>
                            </Label>
                            <AvField
                              style={customStyles}
                              name="name"
                              placeholder="Lead type name"
                              type="text"
                              errorMessage="Enter Lead Type Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={designationObject?.name 
                                || ""
                              }
                              onChange={handleChangeDesignation}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Short Code<span className="text-danger"> *</span>
                            </Label>
                            <AvField
                              style={customStyles}
                              name="shortCode"
                              placeholder="Short Code"
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              value={designationObject?.shortCode || ""}
                              onChange={handleChangeDesignation}
                              errorMessage="Enter Short Code (only letters and numbers allowed)"
                              validate={{
                                required: { value: true, errorMessage: "Short Code is required" },
                                pattern: { value: '^[a-zA-Z0-9]+$', errorMessage: "Only letters and numbers are allowed" },
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-2" style={{ paddingTop: "25px" }}>
                            {designationIdTobeUpdated ? (
                              <Button
                                color="primary"
                                type="submit"
                                style={{ marginRight: "5%" }}
                                disabled={isLoading}
                              >
                                {isLoading ? "Updating..." : "Update"}
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                type="submit"
                                style={{ marginRight: "5%" }}
                                disabled={isLoading}
                              >
                                {isLoading ? "Submitting..." : "Submit"}
                              </Button>
                            )}
                            <Button color="danger" type="reset" onClick={reset}>
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <MDBDataTable
                      id="designationTableId"
                      responsive
                      bordered
                      data={data}
                      searching={true}
                      info={true}
                      disableRetreatAfterSorting={true}
                      entries={20}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    </>
  )
}

export default Leadcategory
