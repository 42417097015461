/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import TablePagination from "../TablePagination"
import Breadcrumbs from "../Breadcrumb"
import { Button, Card, CardBody, CardHeader, Col, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { del, get, post, put } from "../../../helpers/api_helper"
import { debounce } from "lodash"
import moment from "moment"
import { toTop } from "../../../helpers/functions"
import toastr from "toastr"

import "./style.scss"
import { API_URL } from "../../../config"
const NameOnlyTable = ({ urlPath, title }) => {
  const [masterObj, setMasterObj] = useState({})
  const [filterQuery, setFilterQuery] = React.useState("")
  const [tableData, setTableData] = useState([])
  const formRef = useRef()

  const handleChange = e => {
    let { name, value } = e.target
    setMasterObj(prev => ({ ...prev, [name]: value }))
  }

  const reset = () => {
    setMasterObj({})
    formRef?.current?.reset()
  }

  const limit = 10
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const handlePageChange = value => {
    setPage(value)
    fetchTableDataList(value)
  }

  const fetchTableDataList = async (currentPage = page, currentFilterQuery = filterQuery) => {
    try {
      const response = await get(
        `${API_URL}${urlPath}?page=${currentPage}&limit=${limit}&search=${currentFilterQuery}`
      )

      if (response) {
        const { data, count } = response

        data.map((doc, idx) => {
          doc.id = (currentPage - 1) * limit + idx + 1
          doc.date1 = moment(doc.date).format("DD-MM-YYYY")

          doc.action = (
            <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
              <i
                className="uil-edit-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  toTop()
                  handleEdit(doc)
                }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  handleDelete(doc?._id)
                }}
              ></i>
            </div>
          )

          return doc
        })

        const totalPage = Math.ceil(Number(count) / limit)
        setTotalPage(totalPage)
        setTableData(data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const debouncedFetchData = useCallback(debounce(fetchTableDataList, 300), [])

  useEffect(() => {
    debouncedFetchData(page, filterQuery)
    return debouncedFetchData.cancel
  }, [filterQuery])

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date",
        field: "date1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "action",
        sort: false,
        width: 100,
      },
    ],
    rows: tableData,
  }

  const handleSubmit = async () => {
    try {
      if (masterObj?._id) {
        let res = await put(`${API_URL}${urlPath}/${masterObj._id}`, {
          name: masterObj?.name ?? null,
        })
        if (res.message) {
          toastr.success(res.message)
        }
      } else {
        let res = await post(`${API_URL}${urlPath}`, masterObj)
        if (res.message) {
          toastr.success(res.message)
        }
      }

      fetchTableDataList(page)
      reset()
      toTop()
    } catch (error) {
      toastr.error(error?.message ?? "Something went wrong!")
    }
  }

  const handleEdit = data => {
    let obj = {
      _id: data?._id,
      name: data?.name,
    }

    setMasterObj(obj)
  }

  const handleDelete = async id => {
    try {
      await del(`${API_URL}${urlPath}/${id}`)
      fetchTableDataList(page)
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <div className="page-content">
      <div className="container-fluid nameOnlyTable">
        <Breadcrumbs title="Home" breadcrumbItem={title} />

        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <AvForm
                  ref={formRef}
                  className="needs-validation"
                  onValidSubmit={(e, v) => {
                    handleSubmit()
                  }}
                >
                  <Row>
                    <Col md="4">
                      <div>
                        <Label>{title} Name</Label>
                        <AvField
                          type="text"
                          placeholder={`Enter the ${title?.toLowerCase()} name`}
                          value={masterObj?.name ?? ""}
                          name="name"
                          onChange={handleChange}
                          errorMessage={`Please provide ${title?.toLowerCase()} name`}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </div>
                    </Col>

                    <Col md="4">
                      <div style={{ paddingTop: "28px" }}>
                        <Button
                          color={masterObj?._id ? "warning" : "success"}
                          style={{ marginRight: "10px", padding: "8px 30px" }}
                          type="submit"
                        >
                          {masterObj?._id ? "Update" : "Add"}
                        </Button>

                        <Button
                          style={{ padding: "8px 30px" }}
                          onClick={reset}
                          color="danger"
                          type="button"
                        >
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Card>
          <CardHeader>
            <h6 className="bold" style={{ textWrap: "nowrap" }}>
              {title} List
            </h6>

            <div className="extra-options">
              <Col md={3}>
                <input
                  name="search"
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={filterQuery}
                  onChange={e => setFilterQuery(e.target.value)}
                />
              </Col>

              <button
                type="button"
                onClick={() => setFilterQuery("")}
                style={{
                  padding: "7px 20px",
                  background: "#f46a6a",
                  borderRadius: "6px",
                  color: "white",
                  // marginTop: "10px",
                }}
              >
                Reset
              </button>
            </div>
          </CardHeader>
          <CardBody>
            {/* <Row className="mb-3">
              <Col md={3}>
                <input
                  name="search"
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={filterQuery}
                  onChange={e => setFilterQuery(e.target.value)}
                />
              </Col>
              <Col md={3}>
                <Button color="danger" type="button" onClick={() => setFilterQuery("")}>
                  Reset
                </Button>
              </Col>
            </Row> */}

            <>
              <TablePagination
                className="whatsapp-tableModule"
                data={data}
                page={page}
                onChange={handlePageChange}
                count={totalPage}
              />
            </>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

NameOnlyTable.prototype = {
  urlPath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
export default NameOnlyTable
