import { get, post, put, del } from '../helpers/api_helper';

const API_URL = process.env.REACT_APP_APIURL + 'campaign';

export const campaignService = {
  getCampaigns: async (filters = {}) => {
    try {
      const queryString = new URLSearchParams(filters).toString();
      return await get(`${API_URL}/campaigns?${queryString}`);
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error fetching campaigns');
    }
  },

  getCampaign: async (id) => {
    try {
      return await get(`${API_URL}/campaigns/${id}`);
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error fetching campaign');
    }
  },

  createCampaign: async (data) => {
    try {
      return await post(`${API_URL}/campaigns`, data);
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error creating campaign');
    }
  },

  updateCampaign: async (id, data) => {
    try {
      return await put(`${API_URL}/campaigns/${id}`, data);
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error updating campaign');
    }
  },

  deleteCampaign: async (id) => {
    try {
      return await del(`${API_URL}/campaigns/${id}`);
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error deleting campaign');
    }
  }
};

export default campaignService;