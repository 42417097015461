import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import moment from "moment";
import { Label } from "reactstrap";
import Select from "react-select";

const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

const DailyFollowers = () => {
  const labelColors = [
    "#FF4F58", "#4F58FF", "#FFCC00", "#58FF4F", "#FF58B3", "#00CCFF",
    "#FF6B3D", "#3D6B9D", "#9D406B", "#6B9D3D", "#D1C4E9", "#B36B00",
    "#8E8BFF", "#FFB6C1", "#C8E6C9", "#FF6347", "#20B2AA", "#FFD700",
    "#A9A9A9", "#F0E68C",
  ];

  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState([]);
  const [colors, setColors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState({});
  const [sortoption,setSortoption] = useState([])

  const sortByOptions = [
    { label: "SRV Infotech", value: 1 },
    { label: "Kannur Airport", value: 2 },
  ];

  const generateRandomColor = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return `#${randomColor.padStart(6, "0")}`;
  };

  const handleSortByChange = (e) => {
    setSortBy(e);
    fetchGraphData(e.value);
  };

const fetchMetapages = async()=>{
  try{
     const response = await axios.get(`${API_URL}options/metapage/list`)
    //  console.log("response of metapages",response.data.options)
     const options = response.data.options;

     
     setSortoption(options);
 
  
     if (options.length > 0) {
       setSortBy(options[0]); 
     }
  }catch(error){
console.log("Error",error)
  }
}
  const fetchGraphData = async (pageId) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${API_URL}report/v2/daily/followers/report?sortBy=${pageId}`
      );

      const { results } = response.data;

      const last30Days = [];
      for (let i = 29; i >= 0; i--) {
        last30Days.push(moment().subtract(i, "days").format("DD/MM"));
      }
      setLabels(last30Days);

      const newSeries = results.map((page, index) => {
        const fbData = new Array(30).fill(0); 
        const igData = new Array(30).fill(0); 

        page.facebookFollowers.forEach(follower => {
          const formattedDate = moment(follower.date).format("DD/MM");
          const dateIndex = last30Days.indexOf(formattedDate);
          if (dateIndex !== -1) {
            fbData[dateIndex] = follower.followersChange;
          }
        });
        page.instagramFollowers[0]?.values.forEach(follower => {
          const formattedDate = moment(follower.end_time).format("DD/MM");
          const dateIndex = last30Days.indexOf(formattedDate);
          if (dateIndex !== -1) {
            igData[dateIndex] = follower.value;
          }
        });

        return [
          {
            name: `${page.pageName} - Facebook`,
            data: fbData,
          },
          {
            name: `${page.pageName} - Instagram`,
            data: igData,
          },
        ];
      }).flat(); 

      let colorsArr = [...labelColors];
      if (newSeries.length > colorsArr.length) {
        for (let i = colorsArr.length; i <= newSeries.length; i++) {
          colorsArr.push(generateRandomColor());
        }
      } else {
        colorsArr = colorsArr.slice(0, newSeries.length);
      }

      setSeries(newSeries);
      setColors(colorsArr);
    } catch (error) {
      console.error("Error fetching graph data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGraphData(1);
    fetchMetapages()
  }, []);

  const options = {
    chart: { zoom: { enabled: false }, toolbar: { show: false } },
    colors: colors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3, 3, 3, 3],
      curve: "smooth",
    },
    grid: {
      row: {
        colors: ["transparent", "transparent"],
        opacity: 0.2,
      },
      borderColor: "#f1f1f1",
    },
    markers: {
      style: "inverted",
      size: 6,
    },
    xaxis: {
      categories: labels,
      title: {
        text: "Day",
      },
    },
    yaxis: {
      title: {
        text: "Followers Change",
      },
    },
    legend: {
      show: true,
      position: "top",
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  return (
    <>
      {loading === false && (
        <>
          <div className="d-flex justify-content-between">
            <h4 className="card-title mb-2">Daily Followers Report</h4>
            <div className="d-flex align-items-baseline gap-2">
              <Label>Sort By</Label>
              <Select
                // options={sortByOptions}
                value={sortBy}
                options={sortoption}
                name="sortBy"
                onChange={(e) => handleSortByChange(e)}
              />
            </div>
          </div>
          <ReactApexChart
            options={options}
            series={series}
            type="line"
            height="380"
            className="apex-charts"
          />
        </>
      )}
    </>
  );
};

export default DailyFollowers;
