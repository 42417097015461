import React, { useEffect, useRef, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Select from "react-select"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import PieChart from "./PieChart"
import grinningface from "../../../assets/fotos/grinningface.svg"
import slightlysmile from "../../../assets/fotos/slightlysmileface.svg"
import Neutralface from "../../../assets/fotos/neutralface.svg"
import confusedface from "../../../assets/fotos/confusedface.svg"
import cryingface from "../../../assets/fotos/cryingface.svg"
import { get } from "../../../helpers/api_helper"
import toastr from "toastr"
import moment from "moment"
import queryString from "query-string"
import { jsPDF } from "jspdf"
import html2canvas from "html2canvas"

// import { jsonToExcel } from "../../../helpers/functions"
function Graphicalreport() {
  const customStyles = {
    lineHeight: "1.8",
  }

  const formRef = useRef()
  const [fetchFeedback, setFetchfeedBack] = useState([])
  const [filterObject, setFilterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})
  const [branchOptions, setBranchOptions] = useState([])
  const [departmentOptions, setDepartmentOptions] = useState([])

  const [categoryOptions, setCategoryOptions] = useState([])
  const [subCategoryOptions, setSubCategoryOptions] = useState([])
  const [feedbackFormOption, setFeedbackFormOptions] = useState([])
  const [disableButton,setDisableButton] = useState(false)
  const [disableReset,setDisableReset] = useState(true)
  const [triggerRefresh,setTriggerRefresh] = useState(false)
  const [refresh,setRefresh] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [exportProgress, setExportProgress] = useState(0)
  const reportContainerRef = useRef(null)

  async function fetchTableData(page) {
    if(filterObject.from || filterObject.to){
      if(filterObject.from > filterObject.to || filterObject.from === null || filterObject.to === null){
        toastr.error('Invalid Date');
        return
      }
    }  
    const filterString = queryString.stringify(filterObject)
    get(
      `/feedback/report?${filterString}&page=${page}`
      // `/feedback/report?id=${filter.form?._id}&from=${filter.from}&to=${filter.to}`
    ).then(res => {
      if (res.success) { 
        let result = res?.data
        setFetchfeedBack(result)
        setDisableButton(true) 
        if(filterObject.form || filterObject.from || filterObject.to){
          setDisableReset(false)
          setTriggerRefresh(true)
        }else{
          setDisableReset(true)
          setTriggerRefresh(false)
        }
      }
    })
  }

  // const exportTableData = async () => {
  //   try {
  //     const response = await get(
  //       `/feedback/report?id=${filterObject?.form}&fromDate=${filterObject?.fromDate}&toDate=${filterObject?.toDate}&batch=${filterObject?.batch}&status=${filterObject?.status}&course=${filterObject?.course}&csp=${filterObject?.csp}`
  //     );
  
  //     const data = response.data;
  //     const dataExport = [];
  
  //     if (data && Array.isArray(data) && data.length > 0) {
  //       data.forEach((item) => {
  //         item?.questions.forEach((item2) => {
  //           const answerTemplates = {
  //             0: [
  //               { answer: 'YES', count: 0, percentage: '0%' },
  //               { answer: 'NO', count: 0, percentage: '0%' }
  //             ],
  //             1: [
  //               { answer: '1 Star', count: 0, percentage: '0%' },
  //               { answer: '2 Star', count: 0, percentage: '0%' },
  //               { answer: '3 Star', count: 0, percentage: '0%' },
  //               { answer: '4 Star', count: 0, percentage: '0%' },
  //               { answer: '5 Star', count: 0, percentage: '0%' }
  //             ],
  //             2: [
  //               { answer: 'Need Improvement', count: 0, percentage: '0%' },
  //               { answer: 'Average', count: 0, percentage: '0%' },
  //               { answer: 'Good', count: 0, percentage: '0%' },
  //               { answer: 'Best', count: 0, percentage: '0%' },
  //               { answer: 'Excellent', count: 0, percentage: '0%' }
  //             ]
  //           };
  
  //           answerTemplates[item2.type].forEach(template => {
  //             dataExport.push({
  //               Name: item.name,
  //               Question: item2.question,
  //               Answer: template.answer,
  //               Votes: template.count,
  //               Percentage: template.percentage
  //             });
  //           });
  
  //           item2.answers.forEach((item3) => {
  //             let answerText;
  //             if (item2.type == 0 && item3.answer == 1) {
  //               answerText = 'YES';
  //             } else if (item2.type == 0 && item3.answer == 2) {
  //               answerText = 'NO';
  //             } else if (item2.type == 1 && item3.answer >= 1 && item3.answer <= 5) {
  //               answerText = `${item3.answer} Star`;
  //             } else if (item2.type == 2 && item3.answer >= 1 && item3.answer <= 5) {
  //               const emojis = ['Need Improvement', 'Average', 'Good', 'Best', 'Excellent'];
  //               answerText = emojis[item3.answer - 1];
  //             }
  
  //             const entry = dataExport.find(
  //               d => d.Name === item.name && d.Question === item2.question && d.Answer === answerText
  //             );
  
  //             if (entry) {
  //               entry.Votes = item3.count;
  //               entry.Percentage = `${item3.percentage}%`;
  //             }
  //           });
  //         });
  //       });
  
  //       // jsonToExcel(dataExport, `Feedback-Reports-${moment().format("DD-MM-YY")}`);
  //     } else {
  //       toastr.info(`There are No Feedback to export`);
  //     }
  //   } catch (error) {
  //     console.error("Error exporting data:", error);
  //     toastr.error("An error occurred while exporting data");
  //   }
  // };
  
  const exportToPDF = async () => {
    setIsLoading(true);
    setExportProgress(0);

    try {
      if (!Array.isArray(fetchFeedback) || fetchFeedback.length === 0) {
        toastr.error('No data available to export');
        return;
      }

      // Create loading message element
      const loadingElement = document.createElement('div');
      loadingElement.style.position = 'fixed';
      loadingElement.style.top = '50%';
      loadingElement.style.left = '50%';
      loadingElement.style.transform = 'translate(-50%, -50%)';
      loadingElement.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
      loadingElement.style.color = 'white';
      loadingElement.style.padding = '20px 40px';
      loadingElement.style.borderRadius = '8px';
      loadingElement.style.zIndex = '9999';
      loadingElement.style.textAlign = 'center';
      loadingElement.innerHTML = `
        <div style="margin-bottom: 15px;">
          <i class="fa fa-spinner fa-spin" style="font-size: 24px;"></i>
        </div>
        <div style="font-size: 16px; margin-bottom: 10px;">
          Please wait while we prepare your report...
        </div>
        <div style="font-size: 14px; color: #ccc;">
          This may take a few minutes depending on the report size
        </div>
        <div style="font-size: 14px; margin-top: 10px; color: #4CAF50;">
          Progress: <span id="export-progress">0</span>%
        </div>
      `;
      document.body.appendChild(loadingElement);
      
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'a4',
        compress: true
      });

      const pageWidth = pdf.internal.pageSize.width;
      const pageHeight = pdf.internal.pageSize.height;
      const margin = 15;
      let yPos = margin;

      // Add title
      pdf.setFontSize(18);
      pdf.text('Question-wise Feedback Report', pageWidth / 2, yPos, { align: 'center' });
      yPos += 15;

      // Add filters if present
      if (Object.keys(filterObject).length > 0) {
        const filters = [];
        if (selectedFields.branch?.label) filters.push(['Branch', selectedFields.branch.label]);
        if (selectedFields.department?.label) filters.push(['Department', selectedFields.department.label]);
        if (selectedFields.category?.label) filters.push(['Category', selectedFields.category.label]);
        if (selectedFields.subCategory?.label) filters.push(['Sub-Category', selectedFields.subCategory.label]);
        if (selectedFields.form?.label) filters.push(['Form', selectedFields.form.label]);
        if (filterObject.fromDate) filters.push(['From Date', filterObject.fromDate]);
        if (filterObject.toDate) filters.push(['To Date', filterObject.toDate]);

        if (filters.length > 0) {
          pdf.setFontSize(10);
          const filterText = filters.map(([key, value]) => `${key}: ${value}`).join(' | ');
          const splitText = pdf.splitTextToSize(filterText, pageWidth - (2 * margin));
          pdf.text(splitText, margin, yPos);
          yPos += (splitText.length * 5) + 10;
        }
      }

      const totalElements = fetchFeedback.reduce((acc, form) => acc + (form.questions?.length || 0), 0);
      let processedElements = 0;

      // Process each feedback form
      for (const [formIndex, form] of fetchFeedback.entries()) {
        if (!form.questions || !Array.isArray(form.questions)) continue;

        // Add form name
        if (yPos > pageHeight - 50) {
          pdf.addPage();
          yPos = margin;
        }

        pdf.setFontSize(14);
        pdf.text(`Feedback Form: ${form.name || 'Unnamed Form'}`, margin, yPos);
        yPos += 10;

        // Process each question
        for (const [questionIndex, question] of form.questions.entries()) {
          if (!question.question) continue;

          const questionId = `${form.name}-${question.question}`;
          const containerElement = document.querySelector(`[data-question="${questionId}"]`);
          
          if (!containerElement) continue;

          // Add question text with number
            pdf.setFontSize(12);
            const questionText = `Q${questionIndex + 1}. ${question.question.replace(/^Q?\d+\.\s*/, '').trim()}`;
            const wrappedText = pdf.splitTextToSize(questionText, pageWidth - (2 * margin));
            
            if (yPos + (wrappedText.length * 5) + 100 > pageHeight) {
              pdf.addPage();
              yPos = margin;
            }

            pdf.text(wrappedText, margin, yPos);
            yPos += (wrappedText.length * 5) + 5;

            try {
              // Handle empty data case
              if (!question.answers || question.answers.length === 0) {
                pdf.setFontSize(10);
                pdf.text('No responses available for this question', margin, yPos);
                yPos += 10;
                continue;
              }

              // Capture table
              const tableElement = containerElement.querySelector('table');
              if (tableElement) {
                const tableCanvas = await html2canvas(tableElement, {
                  scale: 0.8,
                  logging: false,
                  useCORS: true,
                  backgroundColor: '#ffffff'
                });

                const imgWidth = (pageWidth - (2 * margin)) * 0.5; // Make table narrower
                const imgHeight = (tableCanvas.height * imgWidth) / tableCanvas.width;

                if (yPos + imgHeight > pageHeight - margin) {
                  pdf.addPage();
                  yPos = margin;
                }

                pdf.addImage(
                  tableCanvas.toDataURL('image/jpeg', 0.6),
                  'JPEG',
                  margin,
                  yPos,
                  imgWidth,
                  imgHeight,
                );

                // Position for chart next to table
                const chartX = margin + imgWidth + 5;
                
                // Capture chart
                const chartElement = containerElement.querySelector('.apexcharts-canvas');
                if (chartElement) {
                  const chartCanvas = await html2canvas(chartElement, {
                    scale: 0.8,
                    logging: false,
                    useCORS: true,
                    backgroundColor: '#ffffff'
                  });

                  const chartWidth = imgWidth; // Same width as table
                  const chartHeight = (chartCanvas.height * chartWidth) / chartCanvas.width;

                  pdf.addImage(
                    chartCanvas.toDataURL('image/jpeg', 0.6),
                    'JPEG',
                    chartX,
                    yPos,
                    chartWidth,
                    chartHeight
                  );

                  yPos += Math.max(imgHeight, chartHeight) + 15;
                } else {
                  yPos += imgHeight + 15;
                }
              }

            } catch (error) {
              console.error('Error processing question:', error);
              continue;
            }

          processedElements++;
          const progress = Math.round((processedElements / totalElements) * 100);
          setExportProgress(progress);
          document.getElementById('export-progress').textContent = progress;
        }
      }
      const filename = `Question_Wise_Report_${moment().format('DD-MM-YY')}.pdf`;
      pdf.save(filename);
      
      // Remove loading element
      document.body.removeChild(loadingElement);
      
      toastr.clear(); // Clear the "please wait" message
      toastr.success('PDF exported successfully!');
      
    } catch (error) {
      console.error('PDF export error:', error);
      toastr.error('Error exporting PDF: ' + error.message);
      // Remove loading element in case of error
      const loadingElement = document.querySelector('[id^="loading-overlay"]');
      if (loadingElement) {
        document.body.removeChild(loadingElement);
      }
    } finally {
      setIsLoading(false);
      setExportProgress(0);
    }
  };
  
  const fetchBranchOptions = ()=> {
    get(`/options/branches`).then(res=>{
      setBranchOptions(res.data)
    }).catch(err=>{console.log(err)})
  }

  const fetchDepartmentOptions = ()=> {
    get(`/options/lead-departments`).then(res=>{
      setDepartmentOptions(res.data)
    }).catch(err=>{console.log(err)})
  }


    const fetchCategoryOptions = (obj)=> {
    get(`/options/fb-category?branch=${obj?.branch}`).then(res=>{
      setCategoryOptions(res.data)
    }).catch(err=>{console.log(err)})
  }

    const fetchSubCategoryOptions = (obj)=> {
    get(`/options/fb-sub-category?branch=${obj?.branch}&category=${obj?.category}`).then(res=>{
      setSubCategoryOptions(res.data)
    }).catch(err=>{console.log(err)})
  }

  const fetchFeedbackForms = (obj)=> {
    get(`/options/fb-form?branch=${obj?.branch}&category=${obj?.category}&subCategory=${obj?.subCategory}`).then(res=>{
      setFeedbackFormOptions(res.data)
    }).catch(err=>{console.log(err)})
  }

  useEffect(()=>{
    fetchBranchOptions()
    fetchDepartmentOptions()
    fetchCategoryOptions()
    fetchSubCategoryOptions()
    fetchFeedbackForms()
    //eslint-disable-next-line
  },[])

  useEffect(()=> {
    fetchTableData()
    //eslint-disable-next-line
  },[filterObject])


  const handleSelectChange = (e, name)=> {
    const newObj = {...selectedFields}
    if(name === 'branch'){
      if(e !== null && e !== ''){
        delete newObj.category
        delete newObj.subCategory
        delete newObj.form
      }
    }else if(name === 'category'){
      if(e !== null && e !== ''){
        delete newObj.subCategory
        delete newObj.form
      }
    }else if(name ==='subCategory'){
      if(e !== null && e !== ''){
        newObj.form && delete newObj.form
      }
    }
    setSelectedFields({...newObj, [name]: e})
    handleValueChange(e?.value || '', name)
  }

  const handleValueChange = (value, name)=> {
    const newObj = {...filterObject}
    if(name === 'branch'){
      if(value !== null && value !== ''){
        delete newObj.category
        delete newObj.subCategory
        delete newObj.form
      }
      newObj.branch = value
      fetchCategoryOptions(newObj)
      fetchSubCategoryOptions(newObj)
      fetchFeedbackForms(newObj)
    }else if(name === 'category'){
      if(value !== null && value !== ''){
        delete newObj.subCategory
        delete newObj.form
      }
      newObj.category = value
      fetchSubCategoryOptions(newObj)
      fetchFeedbackForms(newObj)
    }else if(name ==='subCategory'){
      if(value !== null && value !== ''){
        delete newObj.form
      }
      newObj.subCategory = value
      fetchFeedbackForms(newObj)
    }else{
      newObj[name] = value
    }
    setFilterObject(newObj)
  }
  const reset = ()=> {
    setFilterObject({})
    setSelectedFields({})
    fetchBranchOptions()
    fetchDepartmentOptions()
    fetchCategoryOptions()
    fetchSubCategoryOptions()
    fetchFeedbackForms()
    formRef.current.reset()
  }

  const today = moment().format("YYYY-MM-DD")

  return (
    <div>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Question wise report" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                <AvForm ref={formRef}>
                <Row>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>Branch</Label>
                      <Select 
                        placeholder='Branch'
                        name="branch" 
                        options={branchOptions} 
                        onChange={(e)=>handleSelectChange(e, 'branch')}
                        value={selectedFields.branch || ''}
                        isClearable

                      />
                    </div>
                  </Col>
                  <Col md='3'>
                      <Label>Department</Label>
                        <Select
                          options={departmentOptions}
                          name='department'
                          placeholder='Department'
                          onChange={e=>handleSelectChange(e, 'department')}
                          value={selectedFields.department || ''}
                          isClearable
                        />
                      </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>Category</Label>
                      <Select 
                        placeholder='Category'
                        name="branch" 
                        options={categoryOptions} 
                        onChange={(e)=>handleSelectChange(e, 'category')}
                        value={selectedFields.category || ''}
                        isClearable

                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>Sub-category</Label>
                      <Select 
                        placeholder='Sub-category'
                        name="subCategory" 
                        options={subCategoryOptions} 
                        onChange={(e)=>handleSelectChange(e, 'subCategory')}
                        value={selectedFields.subCategory || ''}
                        isClearable

                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>Feedback Form</Label>
                      <Select
                        name="form"
                        options={feedbackFormOption}
                        type="text"
                        placeholder='Feedback Form'
                        onChange={(e)=>handleSelectChange(e, 'form')}
                        value={selectedFields.form || ''}
                        isClearable
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>From</Label>
                      <AvField
                        style={customStyles}
                        name="fromDate"
                        type="date"
                        max={filterObject.fromDate ? filterObject.fromDate : today}
                        onChange={(e)=>handleValueChange(e.target.value, 'fromDate')}
                        value={filterObject.fromDate || ''}
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>To</Label>
                      <AvField
                        style={customStyles}
                        name="toDate"
                        type="date"
                        min={filterObject.fromDate ? filterObject.fromDate : null}
                        max={today}
                        onChange={(e)=>handleValueChange(e.target.value, 'toDate')}
                        value={filterObject.toDate || ''}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3" style={{ paddingTop: "25px" }}>
                      <Button color="danger" onClick={reset}>
                        Reset
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          
          {/* Add export button when data is available */}
          {fetchFeedback.length > 0 && (
            <Row className="mb-3">
              <Col>
                <Button color="primary" onClick={exportToPDF} disabled={isLoading}>
                  {isLoading ? (
                    <>
                      <i className="fa fa-spinner fa-spin mr-2"></i> Generating PDF...{exportProgress}%
                    </>
                  ) : (
                    <>
                      <i className="fa fa-file-pdf-o mr-2"></i> Export Report to PDF
                    </>
                  )}
                </Button>
              </Col>
            </Row>
          )}
        <div id="report-container" ref={reportContainerRef}>
          <Row>
            {fetchFeedback.map((item, formIndex) => (
              <Col xl="12" key={formIndex}>
                <h6>Feedback Form : {item.name}</h6>
                <Card>
                  <CardBody>
                    <AvForm>
                      <Row>
                        {item?.questions?.map((el, questionIndex) => {
                          // Force sequential numbering for each form
                          const questionNumber = questionIndex + 1;
                          
                          const renderQuestionContent = () => (
                            <p className='fw-bold' style={{ fontSize: ".9rem" }}>
                              <span className="me-2">Q{questionNumber}.</span>
                              {/* Remove any existing Q1. or 1. prefixes */}
                              {el.question.replace(/^Q?\d+\.\s*/, '').trim()}
                            </p>
                          );
                          return (
                            <div 
                              data-question={`${item.name}-${el.question}`} 
                              className="d-flex justify-content-between" 
                              style={{
                                borderTop: questionNumber !== 1 && ('.5px dashed gray'), 
                                paddingTop:'10px'
                              }} 
                              key={`${formIndex}-${questionNumber}`}
                            >
                              {el.type === 1 ? (
                                <>
                                  <Col md="6">
                                    {renderQuestionContent()}
                                    <table
                                      style={{
                                        borderRadius: "10px",
                                        borderColor: "#80808017",
                                      }}
                                      className="table table-bordered"
                                    >
                                      <tbody>
                                        {Array.from(
                                          { length: 5 },
                                          (_, index) => {
                                            const starCount = 5 - index
                                            const item = el?.answers?.find(
                                              item => parseInt(item.answer) === starCount
                                            )

                                            return (
                                              <tr key={starCount}>
                                                <td
                                                  style={{ textAlign: "start" }}
                                                >
                                                  {Array.from(
                                                    { length: starCount },
                                                    (_, count) => (
                                                      <span
                                                        key={count}
                                                        style={{
                                                          fontSize: "15px",
                                                        }}
                                                      >
                                                        ⭐
                                                      </span>
                                                    )
                                                  )}
                                                </td>
                                                <td>
                                                  {item ? (
                                                    <>
                                                      {item.count} Votes (
                                                      {item.percentage.toFixed(2)}%)
                                                    </>
                                                  ) : (
                                                    <>0 Votes (0%)</>
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </Col>
                                </>
                              ) : el.type === 2 ? (
                                <>
                                  <Col md="6">
                                    {renderQuestionContent()}
                                    <table
                                      style={{
                                        borderRadius: "10px",
                                        borderColor: "#80808017",
                                      }}
                                      className="table table-bordered"
                                    >
                                      <tbody>
                                        {Array.from(
                                          { length: 5 },
                                          (_, index) => {
                                            const emoji = 5 - index
                                            const item = el?.answers?.find(
                                              item => parseInt(item.answer) === emoji
                                            )

                                            return (
                                              <tr key={emoji}>
                                                <td
                                                  style={{ textAlign: "start" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: "15px",
                                                    }}
                                                  >
                                                    {emoji === 5 ? (
                                                      <img
                                                        src={grinningface}
                                                        alt="Grinning face"
                                                      />
                                                    ) : emoji === 4 ? (
                                                      <img
                                                        src={slightlysmile}
                                                        alt="Grinning face"
                                                      />
                                                    ) : emoji === 3 ? (
                                                      <img
                                                        src={Neutralface}
                                                        alt="Grinning face"
                                                      />
                                                    ) : emoji === 2 ? (
                                                      <img
                                                        src={confusedface}
                                                        alt="Grinning face"
                                                      />
                                                    ) : emoji === 1 ? (
                                                      <img
                                                        src={cryingface}
                                                        alt="Grinning face"
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                  </span>
                                                </td>
                                                <td>
                                                  {item ? (
                                                    <>
                                                      {item.count} Votes (
                                                      {item.percentage.toFixed(2)}%)
                                                    </>
                                                  ) : (
                                                    <>0 Votes (0%)</>
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </Col>
                                </>
                              ) : el.type === 0 ? (
                                <>
                                  <Col md="6">
                                    {renderQuestionContent()}
                                    <table
                                      style={{
                                        borderRadius: "10px",
                                        borderColor: "#80808017",
                                        maxWidth:'65%'
                                      }}
                                      className="table table-bordered"
                                    >
                                      <tbody>
                                        {Array.from(
                                          { length: 2 },
                                          (_, index) => {
                                            const emoji = 2 - index
                                            const item = el?.answers?.find(
                                              item => parseInt(item.answer) === emoji
                                            )

                                            return (
                                              <tr key={emoji}>
                                                <td
                                                  style={{ textAlign: "start", padding:"10px !important" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: "15px",
                                                    }}
                                                  >
                                                    {emoji === 2
                                                      ? "No"
                                                      : emoji === 1
                                                      ? "Yes"
                                                      : ""}
                                                  </span>
                                                </td>
                                                <td style={{padding:'10px !important',textAlign: "end"}}>
                                                  {item ? (
                                                    <>
                                                      {item.count} Votes (
                                                      {item.percentage.toFixed(2)}%)
                                                    </>
                                                  ) : (
                                                    <>0 Votes (0%)</>
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </Col>
                                </>
                              ) : null}

                              {el.type !== 3 && (
                                <Col md="5">
                                  <PieChart 
                                    answers={el.answers} 
                                    type={el.type} 
                                    questionNumber={questionNumber} // Alternate between pie and donut
                                  />
                                </Col>
                              )}

                            </div>
                          )
                        })}
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Graphicalreport
