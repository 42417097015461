import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row, Button } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import moment from "moment";
import MiniWidget from "./mini-widget";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const API_URL = process.env.REACT_APP_APIURL || "http://localhost:4000/";

function SentimentSummary() {
  const [totalCounts, setTotalCounts] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const [sentimentCountGrouped, setSentimentCountGrouped] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: ''
  });

  const fetchData = async () => {
    if (loading) return;

    setLoading(true);
    try {
      const queryParams = new URLSearchParams();
      // Only add date parameters if both dates are set
      if (dateRange.startDate && dateRange.endDate) {
        queryParams.append('fromDate', dateRange.startDate);
        queryParams.append('toDate', dateRange.endDate);
      }

      const queryString = queryParams.toString();
      const urlSuffix = queryString ? `?${queryString}` : '';

      const [countsResponse, platformResponse] = await Promise.all([
        axios.get(`${API_URL}report/v2/sentiment/counts${urlSuffix}`),
        axios.get(`${API_URL}report/v2/sentiment/platforms${urlSuffix}`)
      ]);

      setTotalCounts(countsResponse.data.data);
      setSentimentCountGrouped(platformResponse.data.data);
      
      if (platformResponse.data.data && platformResponse.data.data.length > 0) {
        const totals = platformResponse.data.data.reduce((acc, platform) => {
          acc.positive += platform.data[0] || 0;
          acc.negative += platform.data[1] || 0;
          acc.neutral += platform.data[2] || 0;
          return acc;
        }, { positive: 0, negative: 0, neutral: 0 });
        
        setGraphData(totals);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dateRange]); // eslint-disable-line

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDateRange(prev => ({
      ...prev,
      [name]: value
    }))
  };

  const handleClearDate = (fieldName) => {
    setDateRange(prev => ({
      ...prev,
      [fieldName]: ''
    }))
  };

  const handleClearAllDates = () => {
    setDateRange({
      startDate: '',
      endDate: ''
    })
  };

  const hasDateSelection = dateRange.startDate || dateRange.endDate;

  const formatDate = (date, fullMonth = false) => {
    const options = fullMonth
      ? {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }
      : {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };

    return new Date(date).toLocaleString("en-US", options);
  };

  const [showFullMonth, setShowFullMonth] = useState(false);
  const toggleShowFullMonth = () => setShowFullMonth(!showFullMonth);

  const series = graphData ? [
    {
      name: 'Sentiment Counts',
      data: [
        graphData.positive,
        graphData.negative,
        graphData.neutral
      ],
    }
  ] : [];

  const option1 = {
    chart: {
      type: "bar",
      height: 600,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
        distributed: true,
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    tooltip: {
      enabled: true,
      shared: false,
      custom: function({ series, seriesIndex, dataPointIndex }) {
        const colors = ["#34c38f", "#f46a6a", "#FFCD69"];
        const labels = ['Positive', 'Negative', 'Neutral'];
        return '<div class="apexcharts-tooltip-box">' +
          '<span>' +
          '<span style="color: ' + colors[dataPointIndex] + '; font-size: 20px; line-height: 1;">●</span> ' +
          labels[dataPointIndex] +
          '<br/>' +
          '<span style="margin-left: 24px">Sentiment Counts: ' + series[seriesIndex][dataPointIndex] + '</span>' +
          '</span>' +
          '</div>';
      }
    },
    xaxis: {
      categories: ['Positive', 'Negative', 'Neutral'],
    },
    colors: ["#34c38f", "#f46a6a", "#FFCD69"],
  };
  
  const reports = totalCounts ? [
    {
      id: 1,
      icon: totalCounts.totalGrowth >= 0 ? "mdi mdi-arrow-up-bold" : "mdi mdi-arrow-down-bold",
      title: "Total",
      value: totalCounts.total || 0,
      prefix: "",
      suffix: "",
      badgeValue: `${totalCounts.totalGrowth}%`,
      decimal: 0,
      color: totalCounts.totalGrowth >= 0 ? "success" : "danger",
      icon1: "fas fa-check-double",
      desc: "since last week",
    },
    {
      id: 2,
      icon: totalCounts.positive.growth >= 0 ? "mdi mdi-arrow-up-bold" : "mdi mdi-arrow-down-bold",
      title: "Positive",
      value: totalCounts.positive.count || 0,
      decimal: 0,
      prefix: "",
      suffix: "",
      badgeValue: `${totalCounts.positive.growth}%`,
      color: totalCounts.positive.growth >= 0 ? "success" : "danger",
      icon1: "fas fa-plus-square",
      desc: "since last week",
    },
    {
      id: 3,
      icon: totalCounts.negative.growth >= 0 ? "mdi mdi-arrow-up-bold" : "mdi mdi-arrow-down-bold",
      title: "Negative",
      value: totalCounts.negative.count || 0,
      decimal: 0,
      prefix: "",
      suffix: "",
      badgeValue: `${totalCounts.negative.growth}%`,
      color: totalCounts.negative.growth >= 0 ? "success" : "danger",
      icon1: "fas fa-minus-square",
      desc: "since last week",
    },
    {
      id: 4,
      icon: totalCounts.neutral.growth >= 0 ? "mdi mdi-arrow-up-bold" : "mdi mdi-arrow-down-bold",
      title: "Neutral",
      value: totalCounts.neutral.count || 0,
      decimal: 0,
      prefix: "",
      suffix: "",
      badgeValue: `${totalCounts.neutral.growth}%`,
      color: totalCounts.neutral.growth >= 0 ? "success" : "danger",
      icon1: "fas fa-square",
      desc: "since last week",
    },
  ] : [];
  
  const renderPlatformCards = () => {
    if (!Array.isArray(sentimentCountGrouped)) return null;

    return (
      <Row>
        {sentimentCountGrouped.map((item, index) => (
          <Col md="6" lg="4" key={index} className="mb-4">
            <Card>
              <CardBody>
                <h5>Platform - {item.platform.toUpperCase()}</h5>
                <ReactApexChart
                  type="pie"
                  height="300"
                  className="apex-charts"
                  options={{
                    labels: item.labels,
                    colors: ["#34c38f", "#f46a6a", "#FFCD69"],
                    legend: {
                      show: true,
                      position: "bottom",
                      horizontalAlign: "center",
                      floating: false,
                      fontSize: "14px",
                      offsetY: 5,
                    },
                    responsive: [{
                      breakpoint: 600,
                      options: {
                        chart: {
                          height: 240,
                        },
                        legend: {
                          show: false,
                        },
                      },
                    }],
                  }}
                  series={item.data}
                />
                <div className="text-center mt-3">
                  <p className="mb-1">Total Interactions: {item.totalCount}</p>
                  <p className="mb-0">
                    Positive: {((item.data[0] / item.totalCount) * 100).toFixed(1)}% | 
                    Negative: {((item.data[1] / item.totalCount) * 100).toFixed(1)}% | 
                    Neutral: {((item.data[2] / item.totalCount) * 100).toFixed(1)}%
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Sentiment Report" />
          
          <Row className="mb-4">
            <Col md={8}>
              <div className="d-flex gap-3 align-items-end">
                <div className="form-group position-relative">
                  <label htmlFor="startDate" className="form-label">Start Date</label>
                  <div className="d-flex align-items-center">
                    <input
                      type="date"
                      id="startDate"
                      name="startDate"
                      className="form-control"
                      value={dateRange.startDate}
                      onChange={handleDateChange}
                    />
                    {dateRange.startDate && (
                      <Button
                        color="link"
                        className="p-0 ms-2"
                        onClick={() => handleClearDate('startDate')}
                      >
                        ✕
                      </Button>
                    )}
                  </div>
                </div>
                <div className="form-group position-relative">
                  <label htmlFor="endDate" className="form-label">End Date</label>
                  <div className="d-flex align-items-center">
                    <input
                      type="date"
                      id="endDate"
                      name="endDate"
                      className="form-control"
                      value={dateRange.endDate}
                      onChange={handleDateChange}
                      min={dateRange.startDate} // Prevent selecting end date before start date
                    />
                    {dateRange.endDate && (
                      <Button
                        color="link"
                        className="p-0 ms-2"
                        onClick={() => handleClearDate('endDate')}
                      >
                        ✕
                      </Button>
                    )}
                  </div>
                </div>
                {hasDateSelection && (
                  <Button
                    color="secondary"
                    outline
                    size="sm"
                    onClick={handleClearAllDates}
                    className="mb-2"
                  >
                    Clear All
                  </Button>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            {totalCounts && <MiniWidget reports={reports} />}
            
            <Col md="12">
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "260px" }}>
                      <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    series.length > 0 && (
                      <ReactApexChart
                        height="260"
                        options={option1}
                        series={series}
                        type="bar"
                        className="apex-charts"
                      />
                    )
                  )}
                </CardBody>
              </Card>
            </Col>

            <Col md="12">
              <h5 className="mb-4">Platform-wise Sentiment Breakup</h5>
              {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                renderPlatformCards()
              )}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SentimentSummary;