import React, { useEffect, useState, useRef } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import ReactSelect from "react-select"
import axios from "axios"
import { get, put, post } from "../../../helpers/api_helper"
import queryString from "query-string"
import { getDate, toTop } from "../../../helpers/functions"
import Select from "react-select"
import toastr from "toastr"
import { Tooltip } from "@mui/material"
import moment from "moment"
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/"

const AllResponse = () => {
  const [popupView, setPopupView] = useState(false)
  const [formData, setFormData] = useState({})
  // const handleCloseModal = () => setPopupView(false)
  const [loading, setLoading] = useState(false)
  const [modalDetails, setModalDetails] = useState(null)
  const [tableData, setTableData] = useState([])
  const limit = 18
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [filterObject, setFilterObject] = useState({})
  const [selectFilter, setSelectFilter] = useState({})
  const [filterTypeOption, setFilterTypeOption] = useState([])
  const [selectedFilterType, setSelectedFilterType] = useState([])
  const [filterSubTypeOption, setFilterSubTypeOption] = useState([])
  const [selectedFilterSubType, setSelectedFilterSubType] = useState([])
  const [filterDepartmentOption, setFilterDepartmentOption] = useState([])
  const [selectedFilterDepartment, setSelectedFilterDepartment] = useState([])
  const [filterSourceOption, setFilterSourceOption] = useState([])
  const [selectedFilterSource, setSelectedFilterSource] = useState([])
  const [tableModalData, setTableModalData] = useState([])
   const [departmentOption, setDepartmentOption] = useState([])
  const [image, setImage] = useState("")
  const todayDate = getDate()
  const filterRef = useRef(null)

  const statusOptions = [
    { label: "New", value: 0 },
    { label: "Pending", value: 1 },
    { label: "Closed", value: 2 },
    { label: "Dept.change", value: 3 },
    { label: "Reopen", value: 4 },
    { label: "Responded", value: 5 },
  ]
  const formRef = useRef()
  useEffect(() => {
    handleTableData()
    // setPage(1)
  }, [filterObject]) // eslint-disable-line

  const handleTableData = async (currentPage = page) => {
    try {
      const query = `?page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`
      console.log("filterObject", filterObject)
      console.log("query", query)
      const response = await axios.get(`${API_URL}response/v2/review/allresponse${query}`)
      const formattedData = response.data?.data?.map((item, index) => {
        return {
          id: index + 1,
          date: moment(item.date).format("DD-MM-YYYY") || "-----",
          uniqueId: item.uniqueId || "-----",
          source: item.sourceName || "-----",
          Name: item.firstName || "-----",
          Department: item.department ? item.department : "----",
          Type: item.typeData[0] ? item.typeData[0]?.name : "----",
          Status:
            item.follow_up_status === 0
              ? "New"
              : item.follow_up_status === 1
                ? "Pending"
                : item.follow_up_status === 2
                  ? "Closed"
                  : item.follow_up_status === 3
                    ? "Responded"
                    : item.follow_up_status === 4
                      ? "Deept Change"
                      : item.follow_up_status === 5
                        ? "Reopen"
                        : "-----",
          User: item.user || "-----",
          options: (
            <div>
              <Tooltip title="View" arrow placement="top">
                <i
                  style={{ color: "rgb(52, 55, 71)" }}
                  className="fas fa-eye eye-icon"
                  onClick={() => handleOpenModal(item)}
                ></i>
              </Tooltip>
            </div>
          ),
        }
      })
      setTableData(formattedData)
    } catch (error) { }
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Unique Id", field: "uniqueId" },
      { label: "Source", field: "source" },
      { label: "Name", field: "Name" },
      { label: "Department", field: "Department" },
      { label: "Type", field: "Type" },
      { label: "User", field: "User" },
      { label: "Status", field: "Status" },
      { label: "Actions", field: "options" },
    ],
    rows: tableData,
  }

  const viewdetail_data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Source",
        field: "source",
        width: 150,
      },
      {
        label: "Screenshot",
        field: "screenshot",
        width: 150,
      },
      {
        label: "Communication Type",
        field: "communication_type",
        width: 150,
      },
      {
        label: "Comments",
        field: "comments",
        width: 150,
      },
      {
        label: "User",
        field: "User",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        width: 150,
      },
    ],
    rows: tableModalData,
  }
  const handleFilterValueChange = e => {
    const { name, value } = e.target || e
    console.log("Updated value for", name, value)
    setFilterObject(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleFilterSelectChange = async ({ selected, name }) => {
    console.log(selected, "===========", name)
    const obj = { ...selectFilter }
    obj[name] = selected
    setSelectFilter(obj)
    if (name === "type") {
      setSelectedFilterType(selected)
    }
    if (name === "subType") {
      setSelectedFilterSubType(selected)
    }
    if (name === "department") {
      setSelectedFilterDepartment(selected)
    }

    handleFilterValueChange({ value: selected ? selected.value : "", name })
  }

  useEffect(() => {
    handlefilterTypeOptions()
    handlefilterSubTypeOptions()
    handlefilterDepartmentOptions()
    handlefilterSourceOptions()
    handledepartmentOptions()
  }, []) // eslint-disable-line

  const handlefilterTypeOptions = async (state = "") => {
    try {
      const response = await get(`options/responseType/list`)
      setFilterTypeOption(response.data)
    } catch (error) { }
  }
  const handlefilterSubTypeOptions = async (state = "") => {
    try {
      const response = await get(`options/responseSubType/list`)
      setFilterSubTypeOption(response.data)
    } catch (error) { }
  }
  const handlefilterDepartmentOptions = async (state = "") => {
    try {
      const response = await get(`options/department/list`)
      setFilterDepartmentOption(response.data)
    } catch (error) { }
  }

  const handlefilterSourceOptions = async (state = "") => {
    try {
      const response = await get(`options/source`)
      setFilterSourceOption(response.data)
    } catch (error) { }
  }

    const handledepartmentOptions = async () => {
      try {
        const response = await get(`options/department/list`)
        setDepartmentOption(response.data)
      } catch (error) { }
    }
  const reset = () => {
    // formRef.current.reset()
    filterRef.current.reset()
    toTop()
    setFilterObject({})
    setSelectFilter({
      type: null,
      subType: null,
      department: null,
      status: null,
    })
    setSelectedFilterType([])
    setSelectedFilterSubType([])
    setSelectedFilterDepartment([])
  }
  const handleOpenModal = async item => {
    setLoading(true)
    try {
      const response = await get(`response/v2/review/allResponse/details?id=${item._id}`)
      const { data } = response
      const modalData = data[0]

      setModalDetails(modalData)

      console.log("modal Data ", modalData)
      const followUpData = modalData.follow_up || []
      console.log("followUp Data", followUpData)
      const rows = followUpData.map((followUp, index) => ({
        id: index + 1,
        date: moment(followUp.date).format("DD-MM-YYYY") || "----",
        source: followUp.sourceDetails.name || "----",
        comments: followUp.comments || "----",
        User: followUp.addedByDetails?.firstName || "----",
        communication_type: followUp.communication_type === 1 ? "Out" : followUp.communication_type === 0 ? "In" : followUp.communication_type === 2 ? "Internal" : "",
        screenshot: followUp.screenshot ? (
          <img
            src={`${API_URL}/${followUp.screenshot}`}
            alt="Screenshot"
            style={{ maxWidth: "100px", maxHeight: "100px" }}
          />
        ) : (
          "No screenshot"
        ),
        status:
        followUp.status === 0
        ? "New"
        : followUp.status === 1
          ? "Pending"
          : followUp.status === 2
            ? "Closed"
            : followUp.status === 3
              ? "Responded"
              : followUp.status === 4
                ? "Dept Change"
                : followUp.status === 5
                  ? "Reopen"
                  : "-----",
      }))

      setTableModalData(rows)
      //     viewdetail_data.rows = rows
      setPopupView(true)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }
  const handleCloseModal = () => {
    setPopupView(false)
    setModalDetails(null)
  }
  const resetForm = () => {
    // formRef.current.reset()
    // filterRef.current.reset()
    toTop()
    setFormData({})
  }

  const handleStatusChange = async () => {
    try {
      // if (!formData.followUpStatus) {
      //   toastr.warning("Please select a status!")

      //   return;
      // }
      if (formData.followUpStatus === null || formData.followUpStatus === undefined) {
        console.log("followup status not selected");
        toastr.warning("Please select a status!");
        return;
      }

      // if (formData.communication_type === null || formData.communication_type === undefined) {
      //   toastr.warning("Please select a communication type!");
      //   return;
      // }

      if (!formData.comments?.trim()) {
        toastr.warning("Please enter comments!");
        return;
      }
      setLoading(true);
      const updatedFormData = {
        ...formData,
        responseId: modalDetails._id,
        screenshot: image,
      }
      await put("response/v2/review/status/update", updatedFormData)
      handleOpenModal()
      setFormData({})


      toastr.success("Status updated successfully!")
      handleCloseModal();
    } catch (error) { }
  }

  const uploadImage = async e => {
    const selectedFile = e.target.files[0]
    if (selectedFile) {
      try {
        const fd = new FormData()
        fd.append("screenshot", selectedFile)
        const response = await post(`response/v2/upload`, fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        if (response.status === 200) {
          toastr.success("File uploaded successfully")
          setImage(response.data.new_filename)
        } else {
          toastr.error(response.data.message || "Failed to upload image")
        }
      } catch (error) {
        toastr.error("An error occurred while uploading the file.")
        e.target.value = ""
      }
    }
  }
  return (
    <>
      <Modal show={popupView} isOpen={popupView} toggle={handleCloseModal} size="xl" centered>
        <div className="modal-header">
          <div className="modal-title">
            <h5 style={{ marginBottom: "0px" }}>Details</h5>
          </div>
          <button
            style={{ marginBottom: "2px" }}
            type="button"
            onClick={handleCloseModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body pt-0">
          {loading ? (
            <div></div>
          ) : modalDetails ? (
            <>
              <Row>
                <Col md={12}>
                  <Row>
                    <Col md="12">
                      <table className="table table-borderd lead-info table-striped">
                        <tbody>
                          <tr>
                            <td>Name</td>
                            <th style={{ textAlign: "left" }}>
                              : {modalDetails.firstName || "----"}
                            </th>
                            <td>Mobile</td>
                            <th style={{ textAlign: "left" }}>: {modalDetails.mobile || "----"}</th>
                            <td>Email</td>
                            <th style={{ textAlign: "left" }}>: {modalDetails.email || "----"}</th>
                            {/* <td>District</td>
                            <th style={{ textAlign: "left" }}>: {modalDetails.district || '----'}</th> */}
                          </tr>
                          <tr>
                            <td>Send To</td>
                            <th style={{ textAlign: "left" }}>
                              : {modalDetails.department || "----"}
                            </th>
                            <td>Source</td>
                            <th style={{ textAlign: "left" }}>
                              : {modalDetails.sourceName || "----"}
                            </th>
                            {/* <td>Campaign</td>
                            <th style={{ textAlign: "left" }}>: {modalDetails.campaign || '----'}</th> */}
                            <td>Date</td>
                            <th style={{ textAlign: "left" }}>: {moment(modalDetails.date).format("DD-MM-YYYY") || "----"}</th>
                          </tr>
                          <tr>
                            <td>Priority</td>
                            <th style={{ textAlign: "left" }}>
                              : {modalDetails.priority || "----"}
                            </th>
                            <td>Type</td>
                            <th style={{ textAlign: "left" }}>: {modalDetails.type || "----"}</th>
                            <td>Sub Type</td>
                            <th style={{ textAlign: "left" }}>
                              : {modalDetails.subType || "----"}
                            </th>
                          </tr>
                          <tr>
                            <td>Staff</td>
                            <th style={{ textAlign: "left" }}>: {modalDetails.user || "----"}</th>
                            <td>Comments</td>
                            <th style={{ textAlign: "left" }}>
                              : {modalDetails.comments || "----"}
                            </th>
                            <td>Location</td>
                            <th style={{ textAlign: "left" }}>
                              : {modalDetails.location || "----"}
                            </th>
                          </tr>
                          <tr>
                            <td>Unique Id</td>
                            <th style={{ textAlign: "left" }}>: {modalDetails.uniqueId || "----"}</th>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col md="12" lg="12">
                  <Card>
                    <CardBody>
                      <AvForm
                        onValidSubmit={(e, values) => {
                          handleStatusChange(values)
                        }}
                      >
                        <Row>
                          <Col md="2">
                            <div className="mb-3">
                              <Label>Status</Label>
                              <ReactSelect
                                name="followUpStatus"
                                options={[
                                  { label: "New", value: 0 },
                                  { label: "Pending", value: 1 },
                                  { label: "Closed", value: 2 },
                                  { label: "Dept.change", value: 3 },
                                  { label: "Reopen", value: 4 },
                                  { label: "Responded", value: 5 },
                                ]}
                                // validate={{ required: { value: true } }}
                                // errorMessage="Select Status"
                                value={{
                                  label: formData.followUpStatusLabel || "Select Status",
                                  value: formData.followUpStatus || "",
                                }}
                                onChange={selectedOption =>
                                  setFormData(prev => ({
                                    ...prev,
                                    followUpStatus: selectedOption.value,
                                    followUpStatusLabel: selectedOption.label,
                                  }))
                                }
                              />
                            </div>
                          </Col>
                            {formData.followUpStatus === 3 && (
                              <Col md="2">
                                <div className="mb-3">
                                  <Label>Send To</Label>
                                  <ReactSelect
                                    name="send_to"
                                    options={departmentOption}
                                    value={
                                      formData.sendTo
                                        ? departmentOption.find(option => option.value === formData.sendTo)
                                        : ""
                                    }
                                    onChange={selectedOption =>
                                      setFormData(prev => ({
                                        ...prev,
                                        sendTo: selectedOption ? selectedOption.value : "",
                                      }))
                                    }
                                  />
                                </div>
                              </Col>
                            )}
                          <Col md="2">
                            <div className="mb-3">
                              <Label>Upload</Label>
                              <AvField
                                name="screenshot"
                                type="file"
                                accept="image/*"
                                onChange={uploadImage}
                              //         validate={{ required: { value: true } }}
                              // errorMessage="Please select a file"
                              // onChange={e =>
                              //   setFormData(prev => ({
                              //     ...prev,
                              //     file: e.target.files[0],
                              //   }))
                              // }
                              />
                            </div>
                          </Col>

                          <Col md="2">
                            <div className="mb-3">
                              <Label>Comments</Label>
                              <AvField
                                name="comments"
                                rows={1}
                                type="textarea"
                                placeholder="Comments"
                                value={formData.comments || ""}
                                // validate={{ required: { value: true } }}
                                onChange={e =>
                                  setFormData(prev => ({
                                    ...prev,
                                    comments: e.target.value,
                                  }))
                                }
                              />
                            </div>
                          </Col>
                          <Col md="2">
                            <div className="mb-3">
                              <Label>Communication Type</Label>
                              <ReactSelect
                                name="communication_type"
                                options={[
                                  { label: "In", value: 0 },
                                  { label: "Out", value: 1 },
                                  { label: "Internal", value: 2 },
                                ]}
                                validate={{ required: { value: true } }}
                                value={{
                                  label:
                                    formData.communicationTypeLabel || "Select Communication Type",
                                  value: formData.communication_type || "",
                                }}
                                onChange={selectedOption =>
                                  setFormData(prev => ({
                                    ...prev,
                                    communicationType: selectedOption.value,
                                    communicationTypeLabel: selectedOption.label,
                                  }))
                                }
                              />
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3" style={{ paddingTop: "25px" }}>
                              <Button style={{ marginRight: "3%" }} color="primary" type="submit">
                                Submit
                              </Button>
                              <Button style={{ marginRight: "3%" }} color="danger" type="reset" onClick={resetForm}>
                                Reset
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="12">
                  <Card>
                    <CardBody>
                      <MDBDataTable
                        id=""
                        responsive
                        bordered
                        data={viewdetail_data}
                        searching={true}
                        info={true}
                        disableRetreatAfterSorting={true}
                        entries={20}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <div>No data found</div>
          )}
        </div>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="All Response" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm ref={filterRef}>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>From date</Label>
                          <AvField
                            type="date"
                            name="fromDate"
                            value={filterObject.fromDate || ""}
                            onChange={handleFilterValueChange}
                            max={todayDate}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>To date</Label>
                          <AvField
                            type="date"
                            name="toDate"
                            value={filterObject.toDate || ""}
                            onChange={handleFilterValueChange}
                            min={filterObject.fromDate || ""}
                            max={todayDate}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Type</Label>
                          <Select
                            menuPosition="fixed"
                            options={filterTypeOption}
                            value={selectedFilterType}
                            onChange={selected =>
                              handleFilterSelectChange({
                                selected,
                                name: "type",
                              })
                            }
                          />
                        </div>
                      </Col>
                      {/* <Col md="2">
                        <div className="mb-3">
                          <Label>Sub Type</Label>
                          <Select
                            menuPosition="fixed"
                            // value={filterSubTypeOption.find(
                            //   option => option.value === filterObject.type
                            // )}
                            value={selectedFilterSubType}
                            options={filterSubTypeOption}
                            onChange={selected =>
                              handleFilterSelectChange({ selected, name: "subType" })
                            }
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col> */}
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Department</Label>
                          <Select
                            menuPosition="fixed"
                            // value={filterDepartmentOption.find(
                            //   option => option.value === filterObject.type
                            // )}
                            value={selectedFilterDepartment}
                            options={filterDepartmentOption}
                            onChange={selected =>
                              handleFilterSelectChange({ selected, name: "department" })
                            }
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Status</Label>
                          <Select
                            // isMulti
                            isClearable
                            menuPosition="fixed"
                            options={statusOptions}
                            value={selectFilter.status || ""}
                            onChange={selected =>
                              handleFilterSelectChange({ selected, name: "status" })
                            }
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Source</Label>
                          <Select
                            menuPosition="fixed"
                            // value={filterDepartmentOption.find(
                            //   option => option.value === filterObject.type
                            // )}
                            value={setSelectedFilterSource}
                            options={filterSourceOption}
                            onChange={selected =>
                              handleFilterSelectChange({ selected, name: "source" })
                            }
                            errorMessage="Select Source"
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          <Button color="danger" type="submit" onClick={reset}>
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="Managetableid1"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default AllResponse
