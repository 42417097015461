import React, { useState, useEffect, useRef } from 'react';
import { get } from '../../../helpers/api_helper';
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import toastr from 'toastr';
import moment from "moment"
import queryString from "query-string"
import { jsPDF } from "jspdf"
import html2canvas from 'html2canvas';
import { Card, CardHeader, CardTitle, CardBody, Row, Col, Button, ButtonGroup, Label } from 'reactstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const WeeklyFeedbackReport = () => {
  const formRef = useRef()
  const [fetchFeedback, setFetchfeedBack] = useState([])
  const [filterObject, setFilterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})
  const [branchOptions, setBranchOptions] = useState([])
  const [departmentOptions, setDepartmentOptions] = useState([])

  const [categoryOptions, setCategoryOptions] = useState([])
  const [subCategoryOptions, setSubCategoryOptions] = useState([])
  const [feedbackFormOption, setFeedbackFormOptions] = useState([])
  const [disableButton,setDisableButton] = useState(false)
  const [disableReset,setDisableReset] = useState(true)
  const [triggerRefresh,setTriggerRefresh] = useState(false)
  const [monthOptions, setMonthOptions] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [weekOptions, setWeekOptions] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [weeklyData, setWeeklyData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [exportProgress, setExportProgress] = useState(0);
  const chartContainerRef = useRef(null);

  const customStyles = {
    lineHeight: "1.8",
  }

  const generateYearOptions = () => {
    const startYear = 2020; // Or whatever year you want to start from
    const currentYear = new Date().getFullYear();
    const years = [];
    
    // Generate options from start year to current year
    for (let year = currentYear; year >= startYear; year--) {
      years.push({
        value: year.toString(),
        label: year.toString()
      });
    }
    setYearOptions(years);
  };

  const fetchMonthOptions = () => {
    if (!selectedYear) {
      setMonthOptions([]);
      return;
    }

    const months = moment.months().map((month, index) => ({
      label: `${month} ${selectedYear.value}`,
      value: (index + 1).toString().padStart(2, '0')
    }));
    setMonthOptions(months);
  };

  const fetchWeekOptions = (month) => {
    if (!month || !selectedYear) return;
    
    const weeks = [];
    const year = selectedYear.value;
    
    // Create date for first day of selected month/year
    const monthStart = moment(`${year}-${month}-01`, "YYYY-MM-DD").startOf('month');
    const monthEnd = moment(monthStart).endOf('month');
    
    // Get first day of first week that contains any day of the month
    let currentDate = moment(monthStart).startOf('week');
    
    while (currentDate.isSameOrBefore(monthEnd)) {
      const weekStart = moment(currentDate);
      const weekEnd = moment(currentDate).endOf('week');
      const weekNum = currentDate.isoWeek();
      
      // Only include weeks that overlap with our selected month
      if (!(weekEnd.isBefore(monthStart) || weekStart.isAfter(monthEnd))) {
        weeks.push({
          value: weekNum,
          label: `Week ${weekNum} (${weekStart.format('MMM DD')} - ${weekEnd.format('MMM DD')})`,
          startDate: weekStart.format('YYYY-MM-DD'),
          endDate: weekEnd.format('YYYY-MM-DD')
        });
      }
      
      currentDate.add(1, 'week');
    }

    setWeekOptions(weeks);
    setSelectedWeek(null);
  };

  const handleYearChange = (option) => {
    setSelectedYear(option);
    setSelectedMonth(null);
    setSelectedWeek(null);
    setWeekOptions([]);
    
    const newObj = { ...filterObject };
    if (option) {
      newObj.year = option.value;
    } else {
      delete newObj.year;
    }
    
    delete newObj.month;
    delete newObj.week;
    delete newObj.weekStartDate;
    delete newObj.weekEndDate;
    setFilterObject(newObj);
  };

  const handleMonthChange = (option) => {
    setSelectedMonth(option);
    if (option && selectedYear) {
      fetchWeekOptions(option.value);
    } else {
      setWeekOptions([]);
      setSelectedWeek(null);
    }

    const newObj = { ...filterObject };
    if (option && selectedYear) {
      newObj.month = option.value;
      newObj.year = selectedYear.value;
      delete newObj.week;
      delete newObj.weekStartDate;
      delete newObj.weekEndDate;
    } else {
      delete newObj.month;
      delete newObj.week;
      delete newObj.weekStartDate;
      delete newObj.weekEndDate;
    }
    setFilterObject(newObj);
  };

  const handleWeekChange = (option) => {
    setSelectedWeek(option);
    const newObj = { ...filterObject };
    if (option) {
      newObj.week = option.value;
      newObj.weekStartDate = option.startDate;
      newObj.weekEndDate = option.endDate;
    } else {
      delete newObj.week;
      delete newObj.weekStartDate;
      delete newObj.weekEndDate;
    }
    setFilterObject(newObj);
  };

  async function fetchTableData(page) {
    if(filterObject.fromDate || filterObject.toDate){
      if(filterObject.fromDate > filterObject.toDate || filterObject.fromDate === null || filterObject.toDate === null){
        toastr.error('Invalid Date');
        return;
      }
    }

    // Create a new filter object for the API call
    const apiFilterObject = { ...filterObject };
    
    // Only include year in the filter if it's selected
    if (selectedYear) {
      apiFilterObject.year = selectedYear.value;
    }

    // Only include month and week if both year and month are selected
    if (selectedYear && selectedMonth) {
      apiFilterObject.month = selectedMonth.value;
      if (selectedWeek) {
        apiFilterObject.week = selectedWeek.value;
        apiFilterObject.weekStartDate = selectedWeek.startDate;
        apiFilterObject.weekEndDate = selectedWeek.endDate;
      }
    }

    const filterString = queryString.stringify(apiFilterObject);

    try {
      const response = await get(`/feedback/week-report?${filterString}`);
      if (response.success) {
        const processedData = processWeeklyData(response.data);
        setWeeklyData(processedData);
        setFetchfeedBack(response.data);
        
        setDisableButton(true);
        setDisableReset(!!filterObject.form || !!filterObject.fromDate || !!filterObject.toDate);
        setTriggerRefresh(!!filterObject.form || !!filterObject.fromDate || !!filterObject.toDate);
      }
    } catch (error) {
      toastr.error('Error fetching data');
      console.error(error);
    }
  }

  const processWeeklyData = (data) => {
    if (!data || !Array.isArray(data)) return [];
    
    // Process each form's questions
    return data.map(form => {
      let questionNumber = 0;
      const processedQuestions = form.questions.map(questionData => {
        questionNumber += 1;
        // Process each day's data for the current question
        return questionData.dayWiseData.map(dayData => {
          if (questionData.type === 0) { // Yes/No type
            return {
              day: dayData.dayName,
              question: `Q${questionNumber}. ${questionData.question}`,
              questionType: questionData.type,
              formName: form.formName,
              yes: dayData.sentiments.Yes || 0,
              no: dayData.sentiments.No || 0
            };
          } else { // Rating or Emoji type
            return {
              day: dayData.dayName,
              question: `Q${questionNumber}. ${questionData.question}`,
              questionType: questionData.type,
              formName: form.formName,
              excellent: dayData.sentiments.Excellent || 0,
              best: dayData.sentiments.Best || 0,
              good: dayData.sentiments.Good || 0,
              average: dayData.sentiments.Average || 0,
              needs_improvement: dayData.sentiments["Needs Improvement"] || 0
            };
          }
        });
      });
      
      return {
        formName: form.formName,
        questions: processedQuestions.flat()
      };
    });
  };
  
  const createLoadingOverlay = () => {
    const loadingElement = document.createElement('div');
    loadingElement.style.position = 'fixed';
    loadingElement.style.top = '50%';
    loadingElement.style.left = '50%';
    loadingElement.style.transform = 'translate(-50%, -50%)';
    loadingElement.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
    loadingElement.style.color = 'white';
    loadingElement.style.padding = '20px 40px';
    loadingElement.style.borderRadius = '8px';
    loadingElement.style.zIndex = '9999';
    loadingElement.style.textAlign = 'center';
    loadingElement.innerHTML = `
      <div style="margin-bottom: 15px;">
        <i class="fa fa-spinner fa-spin" style="font-size: 24px;"></i>
      </div>
      <div style="font-size: 16px; margin-bottom: 10px;">
        Please wait while we prepare your report...
      </div>
      <div style="font-size: 14px; color: #ccc;">
        This may take a few minutes depending on the report size
      </div>
      <div style="font-size: 14px; margin-top: 10px; color: #4CAF50;">
        Progress: <span id="export-progress">0</span>%
      </div>
    `;
    document.body.appendChild(loadingElement);
    
    return {
      updateProgress: (progress) => {
        const progressElement = document.getElementById('export-progress');
        if (progressElement) {
          progressElement.textContent = progress;
        }
      },
      remove: () => {
        document.body.removeChild(loadingElement);
      }
    };
  };

  const exportToPDF = async () => {
    setIsLoading(true);
    setExportProgress(0);
    const loadingOverlay = createLoadingOverlay();

    if (!weeklyData.length || !chartContainerRef.current) {
      toastr.error('No data available to export');
      setIsLoading(false);
      return;
    }

    try {
      toastr.info('Preparing PDF export, please wait...');
      
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'a4',
        compress: true
      });

      const pageWidth = pdf.internal.pageSize.width;
      const pageHeight = pdf.internal.pageSize.height;
      const margin = 15;
      let yPos = margin;

      // Initial setup for header
      pdf.setFontSize(18);
      pdf.text('Weekly Feedback Report', pageWidth / 2, yPos, { align: 'center' });
      yPos += 15;

      // Add filters more compactly
      if (Object.keys(filterObject).length > 0) {
        const filters = [
          ['Branch', selectedFields.branch?.label],
          ['Department', selectedFields.department?.label],
          ['Category', selectedFields.category?.label],
          ['Sub-Category', selectedFields.subCategory?.label],
          ['Form', selectedFields.form?.label],
          ['Year', selectedYear?.value],
          ['Month', selectedMonth?.label],
          ['Week', selectedWeek?.label],
          ['From Date', filterObject.fromDate],
          ['To Date', filterObject.toDate]
        ].filter(([_, value]) => value);

        pdf.setFontSize(10);
        let filterText = filters.map(([key, value]) => `${key}: ${value}`).join(' | ');
        
        // Split long filter text into multiple lines if needed
        const maxWidth = pageWidth - (2 * margin);
        const splitText = pdf.splitTextToSize(filterText, maxWidth);
        pdf.text(splitText, margin, yPos);
        yPos += (splitText.length * 5) + 10;
      }

      // Prepare all chart data first
      const allCharts = [];
      let totalCharts = 0;

      for (const formData of weeklyData) {
        const uniqueQuestions = Array.from(new Set(formData.questions.map(q => q.question)))
          .map(question => ({
            formName: formData.formName,
            question,
            questionType: formData.questions.find(q => q.question === question).questionType,
            data: formData.questions.filter(q => q.question === question)
          }));
        
        allCharts.push(...uniqueQuestions);
        totalCharts += uniqueQuestions.length;
      }

      // Process charts in batches
      const BATCH_SIZE = 3; // Process 3 charts at a time
      for (let i = 0; i < allCharts.length; i += BATCH_SIZE) {
        const batch = allCharts.slice(i, Math.min(i + BATCH_SIZE, allCharts.length));
        
        // Process each batch in parallel
        const batchPromises = batch.map(async (chart, batchIndex) => {
          const chartIndex = i + batchIndex;
          const chartElement = chartContainerRef.current.querySelectorAll('.mb-5')[chartIndex];
          
          if (!chartElement) return null;

          const canvas = await html2canvas(chartElement.querySelector('.recharts-wrapper'), {
            scale: 0.8, // Reduced scale for better performance
            logging: false,
            useCORS: true,
            allowTaint: true,
            backgroundColor: '#ffffff',
            imageTimeout: 0
          });

          return {
            canvas,
            formName: chart.formName,
            question: chart.question
          };
        });

        const batchResults = await Promise.all(batchPromises);

        // Add batch results to PDF
        for (const result of batchResults.filter(Boolean)) {
          if (yPos > pageHeight - 50) {
            pdf.addPage();
            yPos = margin;
          }

          // Add form name if it's changed
          if (!pdf.previousFormName || pdf.previousFormName !== result.formName) {
            pdf.setFontSize(14);
            pdf.text(`Form: ${result.formName}`, margin, yPos);
            yPos += 10;
            pdf.previousFormName = result.formName;
          }

          // Add question
          pdf.setFontSize(12);
          const questionText = pdf.splitTextToSize(result.question, pageWidth - (2 * margin));
          pdf.text(questionText, margin, yPos);
          yPos += (questionText.length * 5) + 5;

          // Calculate image dimensions
          const imgWidth = pageWidth - (2 * margin);
          const imgHeight = (result.canvas.height * imgWidth) / result.canvas.width;

          // Check if chart needs a new page
          if (yPos + imgHeight > pageHeight - margin) {
            pdf.addPage();
            yPos = margin;
          }

          // Add chart image
          pdf.addImage(
            result.canvas.toDataURL('image/jpeg', 0.6),
            'JPEG',
            margin,
            yPos,
            imgWidth,
            imgHeight,
            undefined,
            'FAST'
          );

          yPos += imgHeight + 15;

          // Update progress
          const progress = Math.round(((i + batchResults.indexOf(result) + 1) / totalCharts) * 100);
          setExportProgress(progress);
          loadingOverlay.updateProgress(progress);


          // Clean up canvas
          result.canvas.remove();
        }
      }

      pdf.save('Weekly_Feedback_Report.pdf');
      loadingOverlay.remove();
      toastr.success('PDF exported successfully');
    } catch (error) {
      console.error('PDF export error:', error);
      toastr.error('Error exporting PDF');
      loadingOverlay.remove();
    } finally {
      setIsLoading(false);
      setExportProgress(0);
    }
  };

  // const exportToPDF = async () => {
  //   setIsLoading(true);
    
  //   if (!weeklyData.length || !chartContainerRef.current) {
  //     toastr.error('No data available to export');
  //     setIsLoading(false);
  //     return;
  //   }

  //   try {
  //     toastr.info('Preparing PDF export, please wait...');
      
  //     const content = chartContainerRef.current;
  //     const canvas = await html2canvas(content, {
  //       scale: 2,
  //       logging: false,
  //       useCORS: true,
  //       windowWidth: content.scrollWidth,
  //       windowHeight: content.scrollHeight
  //     });

  //     const imgData = canvas.toDataURL('image/png');
      
  //     // Initialize PDF
  //     const pdf = new jsPDF('p', 'mm', 'a4');
  //     const pageWidth = pdf.internal.pageSize.getWidth();
  //     const pageHeight = pdf.internal.pageSize.getHeight();
      
  //     // Calculate number of pages needed
  //     const imgWidth = pageWidth;
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     const pageCount = Math.ceil(imgHeight / pageHeight);
      
  //     // Add pages and split image across them
  //     let heightLeft = imgHeight;
  //     let position = 0;
      
  //     for (let i = 0; i < pageCount; i++) {
  //       // Add new page if not first page
  //       if (i > 0) {
  //         pdf.addPage();
  //       }
        
  //       const heightOnThisPage = Math.min(pageHeight, heightLeft);
        
  //       pdf.addImage(
  //         imgData,
  //         'PNG',
  //         0,
  //         position,
  //         imgWidth,
  //         imgHeight,
  //         undefined,
  //         'FAST'
  //       );
        
  //       heightLeft -= pageHeight;
  //       position -= pageHeight;
  //     }

  //     pdf.save('Weekly_Feedback_Report.pdf');
  //     toastr.success('PDF exported successfully');
  //   } catch (error) {
  //     console.error('PDF export error:', error);
  //     toastr.error('Error exporting PDF');
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const fetchBranchOptions = ()=> {
    get(`/options/branches`).then(res=>{
      setBranchOptions(res.data)
    }).catch(err=>{console.log(err)})
  }

  const fetchDepartmentOptions = ()=> {
    get(`/options/lead-departments`).then(res=>{
      setDepartmentOptions(res.data)
    }).catch(err=>{console.log(err)})
  }


    const fetchCategoryOptions = (obj)=> {
    get(`/options/fb-category?branch=${obj?.branch}`).then(res=>{
      setCategoryOptions(res.data)
    }).catch(err=>{console.log(err)})
  }

    const fetchSubCategoryOptions = (obj)=> {
    get(`/options/fb-sub-category?branch=${obj?.branch}&category=${obj?.category}`).then(res=>{
      setSubCategoryOptions(res.data)
    }).catch(err=>{console.log(err)})
  }

  const fetchFeedbackForms = (obj) => {
    // Build query params based on selected filters
    const params = new URLSearchParams();
    if (obj?.branch) params.append('branch', obj.branch);
    if (obj?.category) params.append('category', obj.category);
    if (obj?.subCategory) params.append('subCategory', obj.subCategory);
    
    // Make API call with filters
    get(`/options/fb-form?${params.toString()}`).then(res => {
      setFeedbackFormOptions(res.data);
    }).catch(err => {
      console.log(err);
      toastr.error('Error fetching feedback forms');
    });
  };

  // const fetchFeedbackForms = (obj)=> {
  //   get(`/options/fb-form?branch=${obj?.branch}&category=${obj?.category}&subCategory=${obj?.subCategory}`).then(res=>{
  //     setFeedbackFormOptions(res.data)
  //   }).catch(err=>{console.log(err)})
  // }

  useEffect(()=>{
    generateYearOptions()
    fetchBranchOptions()
    fetchDepartmentOptions()
    fetchCategoryOptions()
    fetchSubCategoryOptions()
    fetchFeedbackForms()
  },[])

  useEffect(() => {
    fetchMonthOptions();
  }, [selectedYear]);

  useEffect(()=> {
    fetchTableData()
  },[filterObject])


  const handleSelectChange = (e, name) => {
    const newObj = {...selectedFields};
    
    if (name === 'form') {
      // Clear any existing form data when changing form selection
      setWeeklyData([]);
    }
    
    setSelectedFields({...newObj, [name]: e});
    handleValueChange(e?.value || '', name);
    
    // Refresh form options when relevant filters change
    if (['branch', 'category', 'subCategory'].includes(name)) {
      fetchFeedbackForms({
        ...filterObject,
        [name]: e?.value
      });
    }
  };

  const handleValueChange = (value, name)=> {
    const newObj = {...filterObject}
    if(name === 'branch'){
      if(value !== null && value !== ''){
        delete newObj.category
        delete newObj.subCategory
        delete newObj.form
      }
      newObj.branch = value
      fetchCategoryOptions(newObj)
      fetchSubCategoryOptions(newObj)
      // fetchFeedbackForms(newObj)
    }else if(name === 'category'){
      if(value !== null && value !== ''){
        delete newObj.subCategory
        delete newObj.form
      }
      newObj.category = value
      fetchSubCategoryOptions(newObj)
      // fetchFeedbackForms(newObj)
    }else if(name ==='subCategory'){
      if(value !== null && value !== ''){
        delete newObj.form
      }
      newObj.subCategory = value
      // fetchFeedbackForms(newObj)
    }else{
      newObj[name] = value
    }
    setFilterObject(newObj)
  }
  const  reset = () => {
    setFilterObject({});
    setSelectedFields({});
    setSelectedYear(null);
    setSelectedMonth(null);
    setSelectedWeek(null);
    setWeekOptions([]);
    setWeeklyData([]);
    // fetchBranchOptions();
    // fetchDepartmentOptions();
    // fetchCategoryOptions();
    // fetchSubCategoryOptions();
    // fetchFeedbackForms();
    // fetchMonthOptions();
    formRef.current.reset();
  };

  const renderCharts = () => {
    if (!weeklyData.length) return null;

    return (
      <div ref={chartContainerRef}>
        {weeklyData.map(formData => (
          <Card className="mt-4" key={formData.formName}>
            <CardHeader>
              <CardTitle tag="h4">Feedback Form: {formData.formName}</CardTitle>
            </CardHeader>
            <CardBody>
              {formData.questions.reduce((acc, curr) => {
                const existingQuestion = acc.find(q => q.question === curr.question);
                if (!existingQuestion) {
                  const questionData = formData.questions.filter(q => q.question === curr.question);
                  acc.push({
                    question: curr.question,
                    questionType: curr.questionType,
                    data: questionData
                  });
                }
                return acc;
              }, []).map(questionGroup => (
                <div key={questionGroup.question} className="mb-5">
                  <h5>{questionGroup.question}</h5>
                  <ResponsiveContainer width="100%" height={500}>
                    <BarChart 
                      data={questionGroup.data}
                      barSize={65}
                    >
                      <XAxis dataKey="day" />
                      <YAxis 
                        label={{ value: "Feedback Count", angle: -90, position: "insideLeft" }}
                        domain={[0, 'auto']}
                        allowDecimals={false}
                      />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend />
                      {questionGroup.questionType === 0 ? (
                        <>
                          <Bar dataKey="yes" stackId="a" fill="#4CAF50" name="Yes" />
                          <Bar dataKey="no" stackId="a" fill="#F44336" name="No" />
                        </>
                      ) : (
                        <>
                          <Bar dataKey="excellent" stackId="a" fill="#8884d8" name="Excellent" />
                          <Bar dataKey="best" stackId="a" fill="#82ca9d" name="Best" />
                          <Bar dataKey="good" stackId="a" fill="#ffc658" name="Good" />
                          <Bar dataKey="average" stackId="a" fill="#FF6B6B" name="Average" />
                          <Bar dataKey="needs_improvement" stackId="a" fill="#A569BD" name="Needs Improvement" />
                        </>
                      )}
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              ))}
            </CardBody>
          </Card>
        ))}
      </div>
    );
  };

  const today = moment().format("YYYY-MM-DD")

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Home" breadcrumbItem="Weekly Feedback Report" />
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <AvForm ref={formRef}>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Branch</Label>
                        <Select
                          placeholder='Branch'
                          name="branch"
                          options={branchOptions}
                          onChange={(e) => handleSelectChange(e, 'branch')}
                          value={selectedFields.branch || ''}
                          isClearable
                        />
                      </div>
                    </Col>
                    <Col md='3'>
                      <Label>Department</Label>
                      <Select
                        options={departmentOptions}
                        name='department'
                        placeholder='Department'
                        onChange={e => handleSelectChange(e, 'department')}
                        value={selectedFields.department || ''}
                        isClearable
                      />
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Category</Label>
                        <Select
                          placeholder='Category'
                          name="branch"
                          options={categoryOptions}
                          onChange={(e) => handleSelectChange(e, 'category')}
                          value={selectedFields.category || ''}
                          isClearable
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Sub-category</Label>
                        <Select
                          placeholder='Sub-category'
                          name="subCategory"
                          options={subCategoryOptions}
                          onChange={(e) => handleSelectChange(e, 'subCategory')}
                          value={selectedFields.subCategory || ''}
                          isClearable
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Feedback Form</Label>
                        <Select
                          name="form"
                          options={feedbackFormOption}
                          type="text"
                          placeholder='Feedback Form'
                          onChange={(e) => handleSelectChange(e, 'form')}
                          value={selectedFields.form || ''}
                          isClearable
                        />
                      </div>
                    </Col>
                    <Col sm="3">
                      <Label>Year</Label>
                      <Select
                        options={yearOptions}
                        onChange={handleYearChange}
                        value={selectedYear}
                        placeholder="Select Year"
                        isClearable
                      />
                    </Col>
                    <Col sm="3">
                      <Label>Month</Label>
                      <Select
                        options={monthOptions}
                        onChange={handleMonthChange}
                        value={selectedMonth}
                        placeholder="Select Month"
                        isDisabled={!selectedYear}
                        isClearable
                      />
                    </Col>
                    <Col sm="3">
                      <Label>Week</Label>
                      <Select
                        options={weekOptions}
                        onChange={handleWeekChange}
                        value={selectedWeek}
                        placeholder="Select Week"
                        isDisabled={!selectedMonth || !selectedYear}
                        isClearable
                      />
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>From</Label>
                        <AvField
                          style={customStyles}
                          name="fromDate"
                          type="date"
                          max={filterObject.fromDate ? filterObject.fromDate : today}
                          onChange={(e) => handleValueChange(e.target.value, 'fromDate')}
                          value={filterObject.fromDate || ''}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>To</Label>
                        <AvField
                          style={customStyles}
                          name="toDate"
                          type="date"
                          min={filterObject.fromDate ? filterObject.fromDate : null}
                          max={today}
                          onChange={(e) => handleValueChange(e.target.value, 'toDate')}
                          value={filterObject.toDate || ''}
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3" style={{ paddingTop: "25px" }}>
                        <Button color="danger" onClick={reset}>
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {weeklyData.length > 0 && (
            <div>
              <Row className="mb-3">
                <Col>
                  <Button color="primary" onClick={exportToPDF} disabled={isLoading}>
                    {isLoading ? (
                      <>
                        <i className="fa fa-spinner fa-spin mr-2"></i> Generating PDF...{exportProgress}%
                      </>
                    ) : (
                      <>
                        <i className="fa fa-file-pdf-o mr-2"></i> Export Report to PDF
                      </>
                    )}
                  </Button>
                </Col>
              </Row>

              {renderCharts()}
            </div>
        )}
      </div>
    </div>
  );
};

export default WeeklyFeedbackReport;