import axios from "axios"
import { AccessToken } from "./backend_helper"

import { API_URL } from "../config"
import Cookies from "js-cookie"
import { getCookieName } from "./functions"
import { getToken, storeToken } from "../store/auth/login/saga"

const token = async () => {
  const accesstoken = await getToken("_tkn")
  if (accesstoken) {
    return accesstoken
  } else {
    return Cookies.get(`_${getCookieName()}`)
  }
}

//apply base url for axios

axios.defaults.withCredentials = true
axios.defaults.baseURL = API_URL

const axiosApi = axios.create({
  baseURL: API_URL,
  withCredentials: true,
})

axiosApi.interceptors.response.use(
  response => {
    if (response.config.url === "/auth/refreshToken") {
      storeToken(response.data.accessToken, "tkn")
    }
    return response
  },

  async error => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      try {
        AccessToken({ refreshToken: await token() })
        const response = await axios(error.config)
        return response
      } catch (err) {
        console.error("Token refresh failed:", err)
        // Cookies.remove(`_${getCookieName()}`)
        // localStorage.removeItem("authUser")
        return Promise.reject(err)
      }
    }
    return Promise.reject(error)
  }
)

axiosApi.interceptors.request.use(
  async config => {
    const token = await getToken("tkn")
    if (token) {
      config.headers["x-access-token"] = token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

export const get = async (url, config = {}) => {
  try {
    const response = await axiosApi.get(url, { ...config })
    return response.data
  } catch (error) {
    return Promise.reject(error.response.data)
    // throw error;
  }
}

export const post = async (url, data, config = {}) => {
  try {
    const response = await axiosApi.post(url, data, { ...config })
    return response.data
  } catch (error) {
    return Promise.reject(error.response.data)
    // throw error;
  }
}

export const put = async (url, data, config = {}) => {
  try {
    const response = await axiosApi.put(url, data, { ...config })
    return response.data
  } catch (error) {
    return Promise.reject(error.response.data)
    // throw error;
  }
}

export const del = async (url, config = {}) => {
  try {
    const response = await axiosApi.delete(`${url}`, { ...config })
    return response.data
  } catch (error) {
    return Promise.reject(error.response.data)
    // throw error;
  }
}
