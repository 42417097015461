import React, { useState, useEffect, useRef, useMemo } from "react";
import { Row, Col, Card, CardBody, CardHeader, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import moment from "moment";
import { get, post, put, del } from "../../../helpers/api_helper.js";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2"

const Template = () => {
  const API_URL = process.env.REACT_APP_APIURL + 'template'

  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    english: "",
    hindi: "",
    malayalam: "",
    keywords: []
  });
  const [isEditing, setIsEditing] = useState(false);
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});
  const [tagInput, setTagInput] = useState("");
  const [variables, setVariables] = useState([]);
  const [loadingVariables, setLoadingVariables] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const formRef = useRef();

  const columns = [
    {
      label: "#",
      field: "index",
      sort: "asc",
      width: 50
    },
    {
      label: "Date",
      field: "date",
      sort: "asc",
      width: 100
    },
    {
      label: "Time",
      field: "time",
      sort: "asc",
      width: 100
    },
    {
      label: "Template Name",
      field: "name",
      sort: "asc",
      width: 150
    },
    {
      label: "Added By",
      field: "staff",
      sort: "asc",
      width: 150
    },
    {
      label: "Actions",
      field: "actions",
      sort: false,
      width: 100
    }
  ];

  const fetchTemplates = async () => {
    try {
      setLoading(true);
      const response = await get(`${API_URL}/templates`);
      
      // Handle both possible response structures
      const templatesData = response.data?.templates || response?.templates;
      
      if (templatesData) {
        setTemplates(templatesData);
      } else {
        console.error('Invalid response structure:', response);
        toastr.error("Invalid response structure from server");
      }
    } catch (error) {
      console.error('Error details:', error);
      toastr.error(error.response?.data?.message || "Error fetching templates");
    } finally {
      setLoading(false);
    }
  };

  const fetchVariables = async () => {
    try {
      setLoadingVariables(true);
      const response = await get(`${API_URL}/list`);
      
      if (response.data?.variables || response?.variables) {
        setVariables(response.data?.variables || response?.variables);
      } else {
        console.error('Invalid variables response structure:', response);
        toastr.error("Error loading variables");
      }
    } catch (error) {
      console.error('Error fetching variables:', error);
      toastr.error(error.response?.data?.message || "Error loading variables");
    } finally {
      setLoadingVariables(false);
    }
  };

  useEffect(() => {
    fetchTemplates();
    fetchVariables();
  }, []); //eslint-disable-line

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    if (touched[name]) {
      validateField(name, value);
    }
  };

  const validateField = (name, value) => {
    // let error = "";
    switch (name) {
      case 'name':
        if (!value) {
          return 'Name is required';
        } else if (value.length < 2) {
          return 'Name must be at least 2 characters long';
        } else if (value.length > 50) {
          return 'Name cannot exceed 50 characters';
        } else if (/^\d+$/.test(value)) {
          return 'Name cannot contain only numbers';
        } else if (!/^[a-zA-Z0-9\s-]*$/.test(value)) {
          return 'Name can only contain letters, numbers, spaces, and hyphens';
        }
        return '';

      case 'english':
        return !value ? 'English content is required' : '';

      default:
        return '';
    }
    // setErrors(prev => ({
    //   ...prev,
    //   [name]: error
    // }));
    // return '';
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setTouched(prev => ({
      ...prev,
      [name]: true
    }));
    setErrors(prev => ({
      ...prev,
      [name]: validateField(name, value)
    }));
  };

  // Function to handle variable click - copies to clipboard
  const handleVariableClick = (variable) => {
    navigator.clipboard.writeText(variable)
      .then(() => toastr.success("Variable copied to clipboard"))
      .catch(() => toastr.error("Failed to copy variable"));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const newErrors = {};
    Object.keys(formData).forEach(key => {
      if (key !== 'keywords') { // Don't validate keywords array
        const error = validateField(key, formData[key]);
        if (error) {
          newErrors[key] = error;
        }
      }
    });

    if (Object.keys(newErrors).length === 0) {
      try {
        // Set submitting to true at the start of submission
        setSubmitting(true);
        
        const dataToSubmit = {
          ...formData,
          keywords: formData.keywords || [] // Ensure keywords is an array
        };

        if (isEditing) {
          await put(`${API_URL}/templates/${selectedTemplate._id}`, dataToSubmit);
          toastr.success("Template updated successfully");
        } else {
          await post(`${API_URL}/templates`, dataToSubmit);
          toastr.success("Template created successfully");
        }
        resetForm();
        fetchTemplates();
      } catch (error) {
        toastr.error(error.response?.data?.message || "Operation failed");
      } finally {
        // Set submitting to false after operation completes (success or error)
        setSubmitting(false);
      }
    } else {
      setErrors(newErrors);
      setTouched(Object.keys(formData).reduce((acc, key) => ({
        ...acc,
        [key]: true
      }), {}));
    }
  };

  const handleView = (template) => {
    setSelectedTemplate(template);
    setModalVisible(true);
  };

  const handleEdit = (template) => {
    setFormData({
      name: template.name,
      english: template.english,
      hindi: template.hindi,
      malayalam: template.malayalam,
      keywords: Array.isArray(template.keywords) ? [...template.keywords] : []
    });
    setSelectedTemplate(template);
    setIsEditing(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure you want to delete this template?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`${API_URL}/templates/${id}`)
          .then(res => {
            fetchTemplates()
            toastr.success(res.message || "Template deleted successfully");
          })
          .catch(err => {
            toastr.error(err.response?.data?.message || "Error deleting template");
          })
      }
    })
  }

  const resetForm = () => {
    setFormData({
      name: "",
      english: "",
      hindi: "",
      malayalam: "",
      keywords: []
    });
    setIsEditing(false);
    setSelectedTemplate(null);
    setTouched({});
    setErrors({});
    setTagInput("");
    setSubmitting(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  const handleAddKeyword = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission
      if (tagInput.trim()) {
        setFormData(prev => ({
          ...prev,
          keywords: [...prev.keywords, tagInput.trim()]
        }));
        setTagInput('');
      }
    }
  };

  const handleRemoveKeyword = (indexToRemove) => {
    setFormData(prev => ({
      ...prev,
      keywords: prev.keywords.filter((_, index) => index !== indexToRemove)
    }));
  };

  const formatTimeFor12Hour = (time24) => {
    if (!time24) return 'N/A';
    
    // Handle case where time includes seconds
    const [hours24, minutes, seconds] = time24.split(':');
    const hours = parseInt(hours24, 10);
    const period = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours;
    
    return `${hours12}:${minutes} ${period}`;
  };

  const formatDateTime = (dateTime) => {
    if (!dateTime) return 'N/A';
    
    const date = moment(dateTime).format("DD-MM-YYYY");
    const time24 = moment(dateTime).format("HH:mm:ss");
    const time12 = formatTimeFor12Hour(time24);
    
    return `${date} ${time12}`;
  };

  const tableData = useMemo(() => {
    const rows = templates?.map((template, index) => ({
      index: index + 1,
      date: template.createdAt ? moment(template.createdAt).format("DD-MM-YYYY") : "",
      // time: template.createdAt ? moment(template.createdAt).format("HH:mm") : "",
      time: formatTimeFor12Hour(moment(template.createdAt).format("HH:mm")),
      name: template.name || "",
      staff: template.addedby ? 
        `${template.addedby.firstName || ""} ${template.addedby.lastName || ""}`.trim() : 
        "N/A",
      actions: (
        <div className="d-flex justify-content-center gap-3">
          <Button color="link" className="p-0" onClick={() => handleView(template)}>
            <FontAwesomeIcon icon={faEye} color='#3D3D3D' />
          </Button>
          <Button color="link" className="p-0" onClick={() => handleEdit(template)}>
            <FontAwesomeIcon icon={faPenToSquare} color='#3D3D3D' />
          </Button>
          <Button color="link" className="p-0" onClick={() => handleDelete(template._id)}>
            <FontAwesomeIcon icon={faTrash} color='#3D3D3D' />
          </Button>
        </div>
      ),
    }));

    return {
      columns,
      rows: rows || []
    };
  }, [templates]); //eslint-disable-line

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Home" breadcrumbItem="Template" />

        {/* Add/Edit Form */}
        <Row>
          <Col xl={9}>
            <Card>
              <CardHeader className="border-bottom">
                <h4 className="card-title mb-0 fw-bold">
                  {isEditing ? "Edit Template" : "Add Template"}
                </h4>
              </CardHeader>
              <CardBody>
                <form ref={formRef} onSubmit={handleSubmit}>
                <Row className="mb-4">
                    <Col md={6}>
                      <div className="mb-3">
                        <Label>
                          Template Name<span className="text-danger"> *</span>
                        </Label>
                        <input
                          name="name"
                          className={`form-control ${errors.name && touched.name ? 'is-invalid' : ''}`}
                          value={formData.name}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          placeholder="Enter template name"
                          style={{ height: '60px' }}
                        />
                        {errors.name && touched.name && (
                          <div className="invalid-feedback">{errors.name}</div>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label>
                          Template English<span className="text-danger"> *</span>
                        </Label>
                        <textarea
                          name="english"
                          className={`form-control ${errors.english && touched.english ? 'is-invalid' : ''}`}
                          value={formData.english}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          placeholder="Enter content in English"
                          rows={4}
                        />
                        {errors.english && touched.english && (
                          <div className="invalid-feedback">{errors.english}</div>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label>Template Hindi</Label>
                        <textarea
                          name="hindi"
                          className="form-control"
                          value={formData.hindi}
                          onChange={handleInputChange}
                          placeholder="Enter content in Hindi"
                          rows={4}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label>Template Malayalam</Label>
                        <textarea
                          name="malayalam"
                          className="form-control"
                          value={formData.malayalam}
                          onChange={handleInputChange}
                          placeholder="Enter content in Malayalam"
                          rows={4}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="align-items-end">
                    <Col md={8}>
                      <div className="mb-0">
                        <Label>Keywords</Label>
                        <div className="d-flex flex-wrap gap-2 mb-2">
                          {formData.keywords && formData.keywords.map((keyword, index) => (
                            <div key={index} className="badge bg-primary d-flex align-items-center p-2 me-2 mb-2" style={{ fontSize: '0.95rem' }}>
                              <span className="me-2">{keyword}</span>
                              <Button
                                close
                                size="sm"
                                onClick={() => handleRemoveKeyword(index)}
                                className="text-white opacity-75 hover:opacity-100"
                              />
                            </div>
                          ))}
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          value={tagInput}
                          onChange={(e) => setTagInput(e.target.value)}
                          onKeyPress={handleAddKeyword}
                          placeholder="Type and press Enter to add keywords"
                        />
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="text-end mb-0">
                        <Button color="primary" type="submit" className="me-2" disabled={submitting}>
                          {submitting ? "Processing..." : (isEditing ? "Update" : "Submit")}
                        </Button>
                        <Button color="danger" onClick={resetForm}>
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-2 fw-bold">Variables</h4>
              </CardHeader>
              <CardBody>
                {loadingVariables ? (
                  <div className="text-center">Loading variables...</div>
                ) : variables.length > 0 ? (
                  <div className="variables-list">
                    {variables.map((variable, index) => (
                      <div
                        key={index}
                        className="variable-item mb-2 p-2 bg-light rounded cursor-pointer hover:bg-gray-200 transition-colors"
                        onClick={() => handleVariableClick(variable.name)}
                        style={{ cursor: 'pointer' }}
                        title="Click to copy"
                      >
                        {variable.name}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center text-muted">No variables available</div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Template List */}
        <Row>
          <Col xl={12}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-2 fw-bold">Template List</h4>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div className="text-center">Loading...</div>
                ) : (
                  <MDBDataTable
                    responsive
                    bordered
                    data={tableData}
                    searching={true}
                    paging={true}
                    info={true}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* View Modal */}
        <Modal
          isOpen={modalVisible}
          toggle={() => setModalVisible(!modalVisible)}
          size="lg"
          centered
        >
          <ModalHeader toggle={() => setModalVisible(false)}>
            Template Details
          </ModalHeader>
          <ModalBody>
            {selectedTemplate && (
              <div className="px-2">
                <Row className="g-3">
                  {/* First row - Template Name and Created At */}
                  <Col md={6}>
                    <div className="border rounded p-3 h-100">
                      <div className="text-primary fw-bold mb-2">Template Name</div>
                      <div>{selectedTemplate.name}</div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="border rounded p-3 h-100">
                    <div className="text-primary fw-bold mb-2">Created At</div>
                      <div>
                        {formatDateTime(selectedTemplate.createdAt)}
                      </div>
                    </div>
                  </Col>

                  {/* Second row - English Content and Hindi Content */}
                  <Col md={6}>
                    <div className="border rounded p-3 h-100">
                      <div className="text-primary fw-bold mb-2">English Content</div>
                      <div>{selectedTemplate.english || "N/A"}</div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="border rounded p-3 h-100">
                      <div className="text-primary fw-bold mb-2">Hindi Content</div>
                      <div>{selectedTemplate.hindi || "N/A"}</div>
                    </div>
                  </Col>

                  {/* Third row - Malayalam Content and Created By */}
                  <Col md={6}>
                    <div className="border rounded p-3 h-100">
                      <div className="text-primary fw-bold mb-2">Malayalam Content</div>
                      <div>{selectedTemplate.malayalam || "N/A"}</div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="border rounded p-3 h-100">
                      <div className="text-primary fw-bold mb-2">Created By</div>
                      <div>
                        {selectedTemplate.addedby
                          ? `${selectedTemplate.addedby.firstName} ${selectedTemplate.addedby.lastName}`
                          : "N/A"}
                      </div>
                    </div>
                  </Col>

                  {/* Fourth row - Keywords (full width) */}
                  <Col md={12}>
                    <div className="border rounded p-3">
                      <div className="text-primary fw-bold mb-2">Keywords</div>
                      {selectedTemplate.keywords && selectedTemplate.keywords.length > 0 ? (
                        <div className="d-flex flex-wrap gap-2">
                          {selectedTemplate.keywords.map((keyword, index) => (
                            <span 
                              key={index} 
                              className="badge bg-primary p-2" 
                              style={{ fontSize: '0.95rem' }}
                            >
                              {keyword}
                            </span>
                          ))}
                        </div>
                      ) : (
                        <span className="text-muted">No keywords added</span>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModalVisible(false)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default Template;