import { io } from "socket.io-client"
import cookie from "js-cookie"
import { API_URL } from "./config"

let socket = null

export const initializeSocket = async (onConnect, onDisconnect) => {
  try {
    const accessToken = cookie.get("_token")

    socket = io(API_URL, {
      auth: {
        token: accessToken,
      },
      withCredentials: true,
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 5000,
      transports: ["websocket"],
    })

    socket.on("connect", () => {
      console.log("Connected to WebSocket server")
      if (onConnect) onConnect()
    })

    socket.on("disconnect", reason => {
      console.log("Disconnected:", reason)
      if (onDisconnect) onDisconnect()
    })
  } catch (error) {
    console.error("Error initializing socket:", error)
  }
}

export const getSocket = () => socket // Function to retrieve socket instance

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect()
    console.log("Socket disconnected")
    socket = null
  }
}
