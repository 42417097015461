import React, { useState, useEffect } from "react"
import { Container } from "reactstrap"
import CountUp from "react-countup"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./style.scss"
import ReactApexChart from "react-apexcharts"
// import moment from "moment"
import { useNavigate } from "react-router-dom"
// import { Navigate } from "react-router-dom"
import { Card, CardBody, Row, Col } from "reactstrap"
import MonthlyGraph from "./MonthlyGraph.js"
import DailyGraph from "./DailyGraph.js"

import { get } from "../../helpers/api_helper.js"

const Dashboard = () => {
  const navigate = useNavigate()
  const [DashboardData, setDashboardData] = useState([])
  // const [series, setSeries] = useState([]);
  // const year = moment().format("YYYY")

  // const series = graphData?.map((item) => item.series);
  const series = [
    {
      name: "Facebook",
      data: [24, 114, 68, 109, 53, 22],
    },
    {
      name: "Twitter",
      data: [0, 0, 0, 28, 136, 39],
    },
    {
      name: "Instagram",
      data: [23, 51, 18, 65, 33, 10],
    },
  ]

  const options = {
    dataLabels: {
      enabled: !1,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    // series: series,
    colors: ["#5b73e8", "#f1b44c", "#98d8a9"],
    xaxis: {
      type: "yearmonth",
      // categories: [year + "-01", year + "-02", year + "-03", year + "-04", year + "-05", year + "-06", year + "-07", year + "-08", year + "-09", year + "-10", year + "-11", year + "-12"],
      categories: ["2023-09", "2023-10", "2023-11", "2023-12", "2024-01", "2024-02"],
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "yyyy-mm",
      },
    },
  }

  const getData = async () => {
    try {
      const result = await get(`fb-dashboard/cards`)
      setDashboardData(result)
    } catch (err) {
      console.error("Failed to fetch data")
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const iconStyle = {
    fontSize: "30px",
    color: "#333333",
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="BOM Social" breadcrumbItem="Dashboard" />
          <Row>
          <Col
              md={6}
              xl={3}
              key={1}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/latest_feedback")
              }}
            >
              <Card>
                <CardBody>
                  <div className="float-end mt-2">
                    <p className="text-muted mb-0">
                      <span>
                        <i 
                          className={"fas fa-book icon-dashboard-1"}
                          style={iconStyle}  
                        ></i>
                      </span>
                    </p>
                  </div>
                  <div>
                    <h4 className="mb-1 mt-1">
                      <span>
                        <CountUp
                          end={DashboardData?.totalfeedbacks}
                          separator=","
                          prefix={""}
                          suffix={""}
                          decimals={0}
                        />
                      </span>
                    </h4>
                    <p className="text-muted mb-0">{"TOTAL FEEDBACKS"}</p>
                  </div>
                  {/* <p className="text-muted mt-3 mb-0"><span className={"text-" + report.color + " me-1"}><i className={report.icon + " me-1"}></i>{report.badgeValue}</span>{" "}{report.desc}
              </p> */}
                </CardBody>
              </Card>
            </Col>
            <Col
              md={6}
              xl={3}
              key={2}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/latest_feedback")
              }}
            >
              <Card>
                <CardBody>
                  <div className="float-end mt-2">
                    <p className="text-muted mb-0">
                      <span>
                        <i 
                          className={"fas fa-calendar-alt icon-dashboard-1"}
                          style={iconStyle}  
                        ></i>
                      </span>
                    </p>
                  </div>
                  <div>
                    <h4 className="mb-1 mt-1">
                      <span>
                        <CountUp
                          end={DashboardData?.monthlyFeedbacks}
                          separator=","
                          prefix={""}
                          suffix={""}
                          decimals={0}
                        />
                      </span>
                    </h4>
                    <p className="text-muted mb-0">{"FEEDBACKS (THIS MONTH)"}</p>
                  </div>
                  {/* <p className="text-muted mt-3 mb-0"><span className={"text-" + report.color + " me-1"}><i className={report.icon + " me-1"}></i>{report.badgeValue}</span>{" "}{report.desc}
              </p> */}
                </CardBody>
              </Card>
            </Col>
            <Col
              md={6}
              xl={3}
              key={1}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/latest_feedback")
              }}
            >
              <Card>
                <CardBody>
                  <div className="float-end mt-2">
                    <p className="text-muted mb-0">
                      <span>
                        <i 
                          className={"fas fa-regular fa-calendar"}
                          style={iconStyle}  
                        ></i>
                      </span>
                    </p>
                  </div>
                  <div>
                    <h4 className="mb-1 mt-1">
                      <span>
                        <CountUp
                          end={DashboardData?.todayCount}
                          separator=","
                          prefix={""}
                          suffix={""}
                          decimals={0}
                        />
                      </span>
                    </h4>
                    <p className="text-muted mb-0">{"FEEDBACKS (TODAY)"}</p>
                  </div>
                  {/* <p className="text-muted mt-3 mb-0"><span className={"text-" + report.color + " me-1"}><i className={report.icon + " me-1"}></i>{report.badgeValue}</span>{" "}{report.desc}
              </p> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Row>
                <Col xl={12}>
                  <Card>
                    <CardBody>
                      <MonthlyGraph />
                    </CardBody>
                  </Card>
                </Col>

                <Col xl={12}>
                  <Card>
                    <CardBody>
                      <DailyGraph />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            {/* 
            <Col className="" xl={12}>
              <Card>
                <ReactApexChart
                  options={options}
                  series={series}
                  type="area"
                  height="350"
                  className="apex-charts "
                />
              </Card>
            </Col> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
