import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Card, CardBody, Button, Label, CardHeader } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import Breadcrumbs from "../../../../components/Common/Breadcrumb.js";
import moment from "moment";
import toastr from "toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { get, put } from "../../../../helpers/api_helper.js";
import axios from "axios";
import Swal from "sweetalert2";

const SourceSub = () => {
  const API_URL = process.env.REACT_APP_APIURL + 'source'

  const [sourceSubs, setSourceSubs] = useState([]);
  const [sourceMains, setSourceMains] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedSource, setSelectedSource] = useState(null);
  const [formData, setFormData] = useState({
    displayName: "",
    sourceMain: null,
    iconFile: null
  });
  const [isEditing, setIsEditing] = useState(false);
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});
  const [preview, setPreview] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const columns = [
    {
      label: "#",
      field: "index",
      sort: "asc",
      width: 50,
    },
    {
      label: "Date",
      field: "date",
      sort: "asc",
      width: 100,
    },
    {
      label: "Source",
      field: "source",
      sort: "asc",
      width: 150,
    },
    {
      label: "Name",
      field: "name",
      sort: "asc",
      width: 150,
    },
    {
      label: "Icon",
      field: "icon",
      sort: false,
      width: 100,
    },
    {
      label: "Actions",
      field: "actions",
      sort: false,
      width: 100,
    },
  ];

  const fetchSourceSubs = async () => {
    try {
      setLoading(true);
      const response = await get(`${API_URL}/sub`);
      setSourceSubs(response.sourceSub || []);
    } catch (error) {
      console.error('Error fetching source sub types:', error);
      toastr.error(error.response?.data?.message || "Error fetching source sub types");
    } finally {
      setLoading(false);
    }
  };

  const fetchSourceMains = async () => {
    try {
      const response = await get(`${API_URL}/main`);
      const options = (response.sourceMain || [])
      .map(source => ({
        value: source._id,
        label: source.displayName
      }))
      .sort((a, b) => a.label.localeCompare(b.label)); // Sort alphabetically by label
      setSourceMains(options);
    } catch (error) {
      console.error('Error fetching sources:', error);
      toastr.error("Error loading sources");
    }
  };

  useEffect(() => {
    fetchSourceSubs();
    fetchSourceMains();
  }, []); //eslint-disable-line

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'iconFile') {
      setFormData({
        ...formData,
        [name]: files[0]
      });
      setPreview(URL.createObjectURL(files[0]));
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
    
    if (touched[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: validateField(name, files ? files[0] : value)
      }));
    }
  };

  const handleSelectChange = (selectedOption) => {
    setFormData({
      ...formData,
      sourceMain: selectedOption
    });

    if (touched.sourceMain) {
      setErrors(prev => ({
        ...prev,
        sourceMain: validateField('sourceMain', selectedOption)
      }));
    }
  };

  const validateField = (name, value) => {
    switch (name) {
      case 'displayName':
        if (!value) {
          return 'Name is required';
        } else if (value.length < 2) {
          return 'Name must be at least 2 characters long';
        } else if (value.length > 50) {
          return 'Name cannot exceed 50 characters';
        } else if (/^\d+$/.test(value)) {
          return 'Name cannot contain only numbers';
        } else if (!/^[a-zA-Z0-9\s-]*$/.test(value)) {
          return 'Name can only contain letters, numbers, spaces, and hyphens';
        }
        return '';

      case 'sourceMain':
        return !value ? 'Source is required' : '';
        
      default:
        return '';
    }
  };

  const handleBlur = (field = null) => {
    if (field === 'sourceMain') {
      setTouched(prev => ({
        ...prev,
        sourceMain: true
      }));
      setErrors(prev => ({
        ...prev,
        sourceMain: validateField('sourceMain', formData.sourceMain)
      }));
    } else if (field?.target) {
      const { name, value, files } = field.target;
      setTouched(prev => ({
        ...prev,
        [name]: true
      }));
      setErrors(prev => ({
        ...prev,
        [name]: validateField(name, files ? files[0] : value)
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const newErrors = {};
    Object.keys(formData).forEach(key => {
      const error = validateField(key, formData[key]);
      if (error) {
        newErrors[key] = error;
      }
    });

    setTouched({
      displayName: true,
      sourceMain: true,
      iconFile: true
    });
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        setSubmitting(true);

        const formPayload = new FormData();
        formPayload.append('displayName', formData.displayName);
        formPayload.append('sourceMain', formData.sourceMain.value);
        if (formData.iconFile) {
          formPayload.append('icon', formData.iconFile);
        }

        if (isEditing && selectedSource) {
          const response = await axios.put(
            `${API_URL}/sub/${selectedSource._id}`,
            formPayload,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              }
            }
          );
  
          if (response.data.success) {
            toastr.success("Source sub updated successfully");
            resetForm();
            await fetchSourceSubs(); // Make sure to await this
          } else {
            toastr.error(response.data.message || "Update failed");
            setSubmitting(false);
          }
        } else {
          const response = await axios.post(
            `${API_URL}/sub`,
            formPayload,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              }
            }
          );
  
          if (response.data.success) {
            toastr.success("Source sub created successfully");
            resetForm();
            await fetchSourceSubs();
          } else {
            toastr.error(response.data.message || "Creation failed");
            setSubmitting(false);
          }
        }
      } catch (error) {
        console.error('Operation error:', error);
        console.error('Error response:', error.response?.data);
        toastr.error(error.response?.data?.message || "Operation failed");
      } finally {
        setSubmitting(false);
      }
    }
  };

  const handleEdit = (source) => {
    setFormData({
      displayName: source.displayName,
      sourceMain: {
        value: source.sourceMain._id,
        label: source.sourceMain.displayName
      },
      iconFile: null
    });
    setSelectedSource(source);
    setIsEditing(true);
    setPreview(`${process.env.REACT_APP_APIURL}${source.iconFileName}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  
  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure you want to delete this source sub-type?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        put(`${API_URL}/sub/${id}`, { status: 1 })
          .then(res => {
            fetchSourceSubs()
            toastr.success(res.message || "Source Sub deleted successfully");
          })
          .catch(err => {
            toastr.error(err.response?.data?.message || "Error deleting source sub");
          })
      }
    })
  }

  const resetForm = () => {
    setFormData({
      displayName: "",
      sourceMain: null,
      iconFile: null
    });
    setIsEditing(false);
    setSelectedSource(null);
    setTouched({});
    setErrors({});
    setPreview(null);
    setSubmitting(false);
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) fileInput.value = '';
  };

  const tableData = useMemo(() => {
    const rows = sourceSubs.map((source, index) => ({
      index: index + 1,
      date: moment(source.createdAt).format("DD-MM-YYYY"),
      source: source.sourceMain?.displayName || 'N/A',
      name: source.displayName,
      icon: (
        <img 
          src={`${process.env.REACT_APP_APIURL}${source.iconFileName}`}
          // alt={source.displayName}
          alt="No Icon"
          style={{ width: '40px', height: '40px', objectFit: 'contain' }}
        />
      ) || 'No icon',
      actions: (
        <div className="d-flex justify-content-center align-items-center gap-3">
          <Button
            color="link"
            className="p-0 me-2"
            onClick={() => handleEdit(source)}
          >
            <FontAwesomeIcon icon={faPenToSquare} color='#3D3D3D' />
          </Button>
          <Button
            color="link"
            className="p-0"
            onClick={() => handleDelete(source._id)}
          >
            <FontAwesomeIcon icon={faTrash} color='#3D3D3D' />
          </Button>
        </div>
      ),
    }));

    return {
      columns,
      rows
    };
  }, [sourceSubs]);

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Home" breadcrumbItem="Source Sub" />
        
        {/* Add/Edit Form */}
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h4 className="card-title mb-3 fw-bold">
                  {isEditing ? "Edit Source Sub Type" : "Add Source Sub Type"}
                </h4>
              </CardHeader>
              <CardBody>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="4">
                      <div className="mb-3">
                        <Label>
                          Source<span className="text-danger"> *</span>
                        </Label>
                        <Select
                          value={formData.sourceMain}
                          onChange={handleSelectChange}
                          onBlur={() => handleBlur('sourceMain')}
                          options={sourceMains}
                          className={`basic-single ${errors.sourceMain && touched.sourceMain ? 'is-invalid' : ''}`}
                          classNamePrefix="select"
                          placeholder="Select Source"
                          isClearable
                        />
                        {errors.sourceMain && touched.sourceMain && (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            {errors.sourceMain}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mb-3">
                        <Label>
                          Sub Type Name<span className="text-danger"> *</span>
                        </Label>
                        <input
                          name="displayName"
                          className={`form-control ${errors.displayName && touched.displayName ? 'is-invalid' : ''}`}
                          type="text"
                          value={formData.displayName}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          placeholder="Enter Sub Type Name"
                        />
                        {errors.displayName && touched.displayName && (
                          <div className="invalid-feedback">
                            {errors.displayName}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mb-3">
                        <Label>Icon</Label>
                        <input
                          name="iconFile"
                          className="form-control"
                          type="file"
                          accept="image/*"
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                        />
                        {errors.iconFile && touched.iconFile && (
                          <div className="invalid-feedback">
                            {errors.iconFile}
                          </div>
                        )}
                        {preview && (
                          <img
                            src={preview}
                            alt="Preview"
                            style={{
                              marginTop: '10px',
                              width: '50px',
                              height: '50px',
                              objectFit: 'contain'
                            }}
                          />
                        )}
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3" style={{ paddingTop: "28px" }}>
                        <Button color="primary" type="submit" className="me-2" disabled={submitting}>
                          {submitting ? "Processing..." : (isEditing ? "Update" : "Submit")}
                        </Button>
                        <Button color="danger" onClick={resetForm}>
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Source Sub List */}
        <Row>
          <Col className="col-12">
            <Card>
              <CardHeader>
                <h4 className="card-title mb-3 fw-bold">Source Sub Type List</h4>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div className="text-center">Loading...</div>
                ) : (
                  <MDBDataTable
                    responsive
                    bordered
                    data={tableData}
                    searching={true}
                    paging={true}
                    info={true}
                    noRecordsFoundLabel="No source sub types found"
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SourceSub;