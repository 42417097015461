import React, { useEffect, useState } from "react"
import srvlogo from "../../../assets/fotos/srv11.png"
import "./style.scss"
import WHATSAPP_BACKGROUND from "../../../assets/images/whatsapp_background.jpg"

import URL_ICON from "../../../assets/images/whatsapp/url.svg"
import CALL_ICON from "../../../assets/images/whatsapp/call.svg"
import OFFER_CODE_ICON from "../../../assets/images/whatsapp/offercode.svg"
import REPLAY_ICON from "../../../assets/images/whatsapp/replay.svg"
import { Tooltip } from "@mui/material"
import { whatsappTextToHtml } from "../../../helpers/functions"

const Phone_Frame = ({ masterObj, headerMediaType, file, header, body, footer, buttons }) => {
  const [data, setData] = useState({
    file: null,
    header: null,
    body: null,
  })

  useEffect(() => {
  
    setData({ file: file, header: header?.paragraph, body: JSON.stringify(body?.paragraph) })


    if (data?.header && masterObj?.variableHeaderText) {
      masterObj.variableHeaderText?.map(data => {
        if (data?.value) {
          setData(prev => ({
            ...prev,
            header: prev.header?.replace(
              data?.replacement,
              data?.value || data?.value != "" ? data?.value : data?.replacement
            ),
          }))
        }
      })
    }
    if (data?.body) {
      if (masterObj?.variableBodyText) {
        masterObj.variableBodyText?.map(data => {
          if (data?.value) {
            setData(prev => ({
              ...prev,
              body: prev.body?.replace(
                data?.replacement,
                data?.value || data?.value != "" ? data?.value : data?.replacement
              ),
            }))
          }
        })
      }
      if (masterObj?.exampleBodyText?.length > 0) {
        const placeholderRegex = /\{\{(.*?)\}\}/g
        let paragraph = body?.paragraph
        const placeholders = [...body?.paragraph?.matchAll(placeholderRegex)].map(match => match[1])

        for (let i = 0; i < placeholders.length; i++) {
          const replacement = `{{${i + 1}}}`
          let value = masterObj?.exampleBodyText[i]
          console.log(replacement, value)
          if (value) paragraph = paragraph?.replace(replacement, value)
        }
        setData(prev => ({
          ...prev,
          body: paragraph,
        }))
      }

      if (masterObj?.exampleHeaderText?.length > 0) {
        setData(prev => ({
          ...prev,
          header: header?.paragraph?.replaceAll(
            /\{\{(.*?)\}\}/g,
            masterObj?.exampleHeaderText[0] ?? "{{1}}"
          ),
        }))
      }
    }
  }, [masterObj])
  const getButtons = btn => {
    let { type, text, url, phone_number, offer_code, mode, exampleButtonText } = btn
    if (type === "URL") {
      if (mode) {
        url = url?.replace(/\{\{(.*?)\}\}/g, exampleButtonText ?? "")
      }

      return (
        <a   href={url} target="_blank" rel="noreferrer">
          <button>
            <img src={URL_ICON} alt="button icons" /> {text ?? "Visit"}
          </button>
        </a>
      )
    }

    if (type === "PHONE_NUMBER") {
      return (
        <a href={`tel:${phone_number}`} target="_blank" rel="noreferrer">
          <button>
            <img src={CALL_ICON} alt="button icons" /> {text ?? "Call Us"}
          </button>
        </a>
      )
    }

    if (type === "COPY_CODE") {
      return (
        <button>
          <Tooltip title={offer_code}>
            <img src={OFFER_CODE_ICON} alt="button icons" /> {text ?? "Replay"}
          </Tooltip>
        </button>
      )
    }

    if (type === "QUICK_REPLY") {
      return (
        <button>
          <Tooltip title={text}>
            <img src={REPLAY_ICON} alt="button icons" /> {text ?? "Offer code"}
          </Tooltip>
        </button>
      )
    }
  }

  const getFileContent = (url, type) => {
    if (type === "image") return <img src={url} alt="uploaded-content" />
    else if (type === "video") return <video controls src={url} alt="uploaded-content" />
    else if (type === "audio") return <audio controls src={url} />
    else <object data={url} />
  }

  return (
    <div className="phone_frame one">
      <div className="meta">
        <div className="img">
          <img src={srvlogo} alt="#" />
        </div>
        <h1>Srv infotech</h1>
      </div>

      <div className="message-body" style={{ backgroundImage: `url(${WHATSAPP_BACKGROUND})` }}>
        {(data?.header || data?.body || footer || buttons?.length > 0) && (
          <div className={"chat_item"}>
            <div className="chat_item_content">
              {file && (
                <div className="file-content">
                  {getFileContent(masterObj?.headerUrl, masterObj?.headerMediaType)}
                </div>
              )}

              {header && (
                <div
                  className="chat_header"
                  dangerouslySetInnerHTML={{
                    __html: whatsappTextToHtml(data?.header),
                  }}
                />
              )}
              {body && (
                <div
                  className="chat_msg"
                  dangerouslySetInnerHTML={{
                    __html: whatsappTextToHtml(data?.body),
                  }}
                />
              )}
              {footer && <div className="chat_footer">{footer}</div>}

              {buttons?.length > 0 && (
                <div className="chat_buttons">{buttons?.map(btn => getButtons(btn))}</div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="chat-content-footer">
        <button className="addFilees">
          <i class="fa fa-plus"></i>
        </button>
        <input type="text" disabled={true} placeholder="Type a message here" />
        <button disabled={true} class="btnSendMsg" id="sendMsgBtn">
          <i class="fa fa-paper-plane"></i>
        </button>
      </div>
    </div>
  )
}

export default Phone_Frame
