/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react"
import TablePagination from "../../../../components/Common/TablePagination"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { BulkUploadModal, ContactList } from "../../../../components/Whatsapp"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import { debounce } from "lodash"
import moment from "moment"

import "./style.scss"
import { API_URL } from "../../../../config"
import { get } from "../../../../helpers/api_helper"
import * as mainConfig from "../../config"
import { Tooltip } from "@mui/material"
const index = ({ urlPath, title }) => {
  const [filterQuery, setFilterQuery] = useState("")
  const [tableData, setTableData] = useState([])
  const [file, setFile] = useState(null)

  const limit = 10
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const handlePageChange = value => {
    setPage(value)
    fetchTableDataList(value)
  }

  const [show, setShow] = useState(false)
  const [bulkId, setBulkId] = useState("")
  const toggle = () => {
    if (show) setBulkId("")
    setShow(!show)
  }

  const [bulkModel, setBulkModel] = useState(false)
  const toggleBulk = () => {
    setBulkModel(!bulkModel)
  }
  const viewSavedContacts = id => {
    setShow(true)
    setBulkId(id)
  }

  const getStatus = (status = "", error = "Error occur") => {
    if (status === 0) return <span className="badge bg-success font-size-12">Success</span>
    if (status === 1) return <span className="badge bg-info font-size-12">Processing</span>
    if (status === 2)
      return (
        <span className="badge bg-danger font-size-12" style={{ cursor: "pointer" }}>
          <Tooltip title={`Failed reason: ${error}`}>Failed</Tooltip>
        </span>
      )
    return "---"
  }

  const fetchTableDataList = async (currentPage = page, currentFilterQuery = filterQuery) => {
    let query = new URLSearchParams("search", currentFilterQuery).toString()
    try {
      const response = await get(
        `${mainConfig.ROUTE.manage({
          bulkLog: true,
        })}/?page=${currentPage}&limit=${limit}&${query}`
      )

      if (response) {
        const { data, count } = response

        data.map((doc, idx) => {
          doc.id = (currentPage - 1) * limit + idx + 1
          doc.date1 = moment(doc.date).format("DD-MM-YYYY")
          doc.status = getStatus(doc?.status, doc?.errorMessage)
          doc.count = `${doc?.savedCount}/${doc?.totalCount}`
          doc.category = (
            <span className="badge bg-primary font-size-12">{doc.category?.name ?? "---"}</span>
          )

          doc.action = (
            <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
              <Tooltip title="Download Uploaded Sheet">
                <a href={`${API_URL}${doc.uploaded}`} download target="_blank" rel="noreferrer">
                  <i
                    className="uil-file-download-alt"
                    style={{ fontSize: "1.5em", cursor: "pointer" }}
                  ></i>
                </a>
              </Tooltip>
              <Tooltip title="View Uploaded Contacts">
                <span onClick={() => viewSavedContacts(doc?._id)}>
                  <i className="uil-eye" style={{ fontSize: "1.5em", cursor: "pointer" }}></i>
                </span>
              </Tooltip>
            </div>
          )

          return doc
        })

        const totalPage = Math.ceil(Number(count) / limit)
        setTotalPage(totalPage)
        setTableData(data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const debouncedFetchData = useCallback(
    debounce(() => fetchTableDataList(), 300),
    []
  )

  useEffect(() => {
    debouncedFetchData(page, filterQuery)
    return debouncedFetchData.cancel
  }, [filterQuery])

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date",
        field: "date1",
        sort: "asc",
        width: 150,
      },
      {
        label: "UniqueId",
        field: "uniqueId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Category",
        field: "category",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Count (Saved/Uploaded)",
        field: "count",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "action",
        sort: false,
        width: 100,
      },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <div className="container-fluid nameOnlyTable">
        <Breadcrumbs title="Home" breadcrumbItem="Whatsapp Bulk Upload List" disableHeader={true} />

        <Card>
          <CardHeader>
            <h6 className="bold" style={{ textWrap: "nowrap" }}>
              Whatsapp Bulk Upload List
            </h6>

            <div className="extra-options">
              <Col md={3}>
                <input
                  name="search"
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={filterQuery}
                  onChange={e => setFilterQuery(e.target.value)}
                />
              </Col>

              <button
                type="button"
                onClick={() => setFilterQuery("")}
                style={{
                  padding: "7px 20px",
                  background: "#f46a6a",
                  borderRadius: "6px",
                  color: "white",
                  // marginTop: "10px",
                }}
              >
                Reset
              </button>

              <div className="input-output">
                <Tooltip title="Import (.xls, .xlsx)" arrow placement="bottom">
                  <Button onClick={toggleBulk}>
                    <i className="uil-import" />
                    <span>{file ? "Imported" : "Import"}</span>
                  </Button>
                </Tooltip>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            {/* <Row className="mb-3">
              <Col md={3}>
                <input
                  name="search"
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={filterQuery}
                  onChange={e => setFilterQuery(e.target.value)}
                />
              </Col>
              <Col md={3}>
                <Button color="danger" type="button" onClick={() => setFilterQuery("")}>
                  Reset
                </Button>
              </Col>
            </Row> */}

            <>
              <TablePagination
                className="whatsapp-tableModule"
                data={data}
                page={page}
                onChange={handlePageChange}
                count={totalPage}
              />
            </>
          </CardBody>
        </Card>

        <Modal isOpen={show} className="contact-list-model">
          <ModalHeader toggle={toggle}>Bulk Upload Contact List</ModalHeader>
          <ModalBody>
            <ContactList bulkUploadId={bulkId} />
          </ModalBody>
        </Modal>
      </div>

      <BulkUploadModal
        sample={true}
        show={bulkModel}
        toggle={toggleBulk}
        setFile={setFile}
        urlPath={"whatsapp/contact/bulk-upload/"}
        selectOptions={"options/contactCategory"}
      />
    </div>
  )
}

export default index
