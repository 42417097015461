/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Card, CardBody, CardHeader, Col, Label, Row } from "reactstrap"
import "../../style.scss"
import "./style.scss"
import Select from "react-select"

import * as config from "./config"
import * as MainConfig from "../../config"

import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import { CreateBroadcast } from "../../../../components/Whatsapp"
import CountUp from "react-countup"
import { get } from "../../../../helpers/api_helper"
// import { API_URL } from "../../../../config"
  import axios from "axios"
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/"
const index = () => {
  const [dashboardCount, setDashboardCount] = useState(config.INITIAL_DASHBOARD_COUNT)
  const [options, setOptions] = useState(config.INITIAL_OPTIONS)
  const [data, setData] = useState([]);
  const [period, setPeriod] = useState("30");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterObj, setFilterObj] = useState({
    period: "30",
    fromDate: null,
    toDate: null,
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        let url = `${API_URL}whatsapp/dashboard?period=${filterObj.period}`;
  
        if (filterObj.fromDate && filterObj.toDate) {
          url = `${API_URL}whatsapp/dashboard?startDate=${filterObj.fromDate}&endDate=${filterObj.toDate}`;
        }
  
        const response = await axios.get(url);
        setData(response.data.data || []);
      } catch (error) {
        console.error("Error fetching report data:", error);
      }
    };
  
    fetchData();
  }, [filterObj]);
  
  const handlePeriodChange = (e) => {
    
    setPeriod(e.target.value);
    setStartDate(null);
    setEndDate(null);
  };


  useEffect(() => {
    optionsFetch(MainConfig.OPTIONS_ROUTE.campaign, "campaign")
  }, [])

  const optionsFetch = async (OPTIONS_PATH, NAME) => {
    try {
      let res = await get(`${API_URL}${OPTIONS_PATH}`)
      if (res.data) setOptions(prev => ({ ...prev, [NAME]: res.data }))
    } catch (error) {
      console.error(`${NAME} option fetch failed`)
    }
  }

  // const [filterObj, setFilterObj] = useState({})

  const resetFilter = () => setFilterObj({  
    period: "30",
    fromDate: null,
    toDate: null,})

  const handleChangeFilter = (selectedOption, field) => {
    const value = field === "period" ? selectedOption.value : selectedOption.target.value;
  
    setFilterObj(prev => {
      let updatedFilters = { ...prev, [field]: value };
  
      if (field === "period") {
        updatedFilters.fromDate = null;
        updatedFilters.toDate = null;
      }
  
      return updatedFilters;
    });
  };
  
  const reports = [
    {
      id: 1,
      icon: "./icons/send.svg",
      title: "Send",
      value:data?.send,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 30,
      chartwidth: 30,
      prefix: "",
      suffix: "",
      // badgeValue: "0%",
      color: "success",
      // desc: "This Month",
      series: config.series,
      options: config.options1,
    },
    {
      id: 2,
      icon: "./icons/delivered.svg",
      title: "Delivered",
      value: data?.delivered,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 30,
      chartwidth: 30,
      prefix: "",
      suffix: "",
      // badgeValue: "0%",
      color: "success",
      // desc: "This Month",

      series: config.series,
      options: config.options1,
    },
    {
      id: 3,
      icon: "./icons/read.svg",
      title: "Read",
      value: data?.read,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 30,
      chartwidth: 30,
      prefix: "",
      suffix: "",
      // badgeValue: "0%",
      color: "success",
      // desc: "This Month",
      series: config.series,
      options: config.options1,
    },
    {
      id: 4,
      icon: "./icons/replied.svg",
      title: "Replied",
      value: data?.replied,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 30,
      chartwidth: 30,
      prefix: "",
      suffix: "",
      // badgeValue: "0%",
      color: "success",
      // desc: "This Month",
      series: config.series,
      options: config.options1,
    },
    {
      id: 5,
      icon: "./icons/sending.svg",
      title: "Sending",
      value:data?.sending,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 30,
      chartwidth: 30,
      prefix: "",
      suffix: "",
      // badgeValue: "0%",
      color: "success",
      // desc: "This Month",
      series: config.series,
      options: config.options1,
    },
    {
      id: 6,
      icon: "./icons/failed.svg",
      title: "Failed",
      value: data?.failed,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 30,
      chartwidth: 30,
      prefix: "",
      suffix: "",
      // badgeValue: "0%",
      color: "success",
      // desc: "This Month",
      series: config.series,
      options: config.options1,
    },
    {
      id: 7,
      icon: "./icons/processing.svg",
      title: "Processing",
      value: data?.processing,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 30,
      chartwidth: 30,
      prefix: "",
      suffix: "",
      // badgeValue: "0%",
      color: "success",
      // desc: "This Month",
      series: config.series,
      options: config.options1,
    },
    {
      id: 8,
      icon: "./icons/queued.svg",
      title: "Queued",
      value: data?.queue,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 30,
      chartwidth: 30,
      prefix: "",
      suffix: "",
      // badgeValue: "0%",
      color: "success",
      // desc: "This Month",
      series: config.series,
      options: config.options1,
    },
  ]

  return (
    <div className="page-content">
      <div className="container-fluid whatsapp-container whatsapp-container-2">
        <Breadcrumbs title="Home" breadcrumbItem="Broadcast Analytics" />

        <Card>
          <CardHeader>
            <h6 className="bold" style={{ textWrap: "nowrap" }}>
              Dashboard
            </h6>

            <AvForm className="extra-options">
              {/* <Col md={2} className="mb-3">
                <Label>Campaign</Label>
                <Select
                  options={options.campaign}
                  value={filterObj?.campaign ?? ""}
                  onChange={e => handleChangeFilter(e, "campaign")}
                />
              </Col> */}
              <Col md={2} className="mb-3">
  <Label>Period</Label>
  <Select
  options={options.period}
  value={options.period.find(opt => opt.value === filterObj.period)}
  onChange={e => handleChangeFilter(e, "period")}
/>

</Col>

<Col md={2}>
  <Label>Start Date</Label>
  <AvField
    name="fromDate"
    type="date"
    max={moment().format("YYYY-MM-DD")}
    placeholder="From Date"
    value={filterObj.fromDate ?? ""}
    onChange={e => handleChangeFilter(e, "fromDate")}
  />
</Col>

<Col md={2}>
  <Label>End Date</Label>
  <AvField
    name="toDate"
    type="date"
    value={filterObj.toDate ?? ""}
    onChange={e => handleChangeFilter(e, "toDate")}
    min={filterObj.fromDate}
    max={moment().format("YYYY-MM-DD")}
    placeholder="To Date"
  />
</Col>

              <button
                type="button"
                onClick={resetFilter}
                style={{
                  padding: "7px 20px",
                  background: "#f46a6a",
                  borderRadius: "6px",
                  color: "white",
                  marginTop: "10px",
                }}
              >
                Reset
              </button>
            </AvForm>
          </CardHeader>
          <CardBody>
            <div className="dashboard-count row d-none">
              {config.dashboardItems.map((dataField, key) => (
                <Col md={3} key={key}>
                  <div className="card-counter">
                    <div className="img">
                      <img src={dataField.icon} alt="icon" />
                    </div>
                    <span className="count-numbers">{dashboardCount[dataField.key]}</span>
                    <span className="count-name">{dataField.label}</span>
                  </div>
                </Col>
              ))}
            </div>
            <Row>
              {reports.map((report, key) => (
                <Col md={6} xl={3} key={key}>
                  <Card>
                    <CardBody>
                      <div className="float-end mt-2">
                        <img
                          style={{ marginTop: "2px", fontSize: "30px", width: "30px" }}
                          src={report.icon}
                          alt=""
                        />
                      </div>
                      <div>
                        <h4 className="mb-1 mt-1">
                          <span>
                            <CountUp
                              end={report.value}
                              separator=","
                              prefix={report.prefix}
                              suffix={report.suffix}
                              decimals={report.decimal}
                            />
                          </span>
                        </h4>
                        <p className="text-muted mb-0">{report.title}</p>
                      </div>
                      {/* <p className="text-muted mt-3 mb-0">
                        <span className={"text-" + report.color + " me-1"}>
                          <i className={report.icon + " me-1"}></i>
                          {report.badgeValue}
                        </span>{" "}
                        {report.desc}
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}    
export default index
