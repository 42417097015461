import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import leadService from '../../../services/leadService';
import followupService from '../../../services/followupService.js';
import {
  Alert,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import Select from 'react-select';
import { MDBDataTable } from 'mdbreact';
import moment from 'moment';
import debounce from 'lodash.debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toastr from "toastr";
import SearchSelect from '../../../components/Common/Select.jsx';
import { faEye, faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import Breadcrumbs from "../../../components/Common/Breadcrumb.js"
import './LeadManagement.scss';
import Pagination from '../../Leads/Pagination.jsx'

const checkDuplicateLead = debounce(async (leadData, setFormErrors, setIsDuplicateChecking) => {
  if (!leadData.name?.trim() || !leadData.mobileNo?.trim() || !leadData.leadType?.value) {
    return;
  }

  setIsDuplicateChecking(true);
  try {
    const response = await leadService.checkDuplicateLead({
      name: leadData.name,
      mobileNo: leadData.mobileNo,
      leadType: leadData.leadType.value,
      _id: leadData._id // Include if updating
    });

    if (response.isDuplicate) {
      setFormErrors(prev => ({
        ...prev,
        duplicate: `A lead already exists with the same name (${response.existingLead.name}), mobile number (${response.existingLead.mobileNo}), and lead type (${response.existingLead.leadType})`
      }));
      return true;
    } else {
      setFormErrors(prev => {
        const { duplicate, ...rest } = prev;
        return rest;
      });
      return false;
    }
  } catch (error) {
    console.error('Error checking duplicate:', error);
    toastr.error("Error checking for duplicate leads");
    return false;
  } finally {
    setIsDuplicateChecking(false);
  }
}, 500);

const LeadManagement = () => {
  const API_URL = process.env.REACT_APP_APIURL;

  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [leads, setLeads] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalLeads: 0
  });

  const [selectedLeadId, setSelectedLeadId] = useState(null);
  const [selectedLead, setSelectedLead] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [historyData, setHistoryData] = useState([]);

  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [leadTypes, setLeadTypes] = useState([]);
  const [sources, setSources] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [assignedTo, setAssignedTo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  const [formLoading, setFormLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDuplicateChecking, setIsDuplicateChecking] = useState(false);

   // Constants and Options
  const statusFilterOptions = [
    { value: 'Call Later', label: 'Call Later' },
    { value: 'Wrong Number', label: 'Wrong Number' },
    { value: 'Message Sent', label: 'Message Sent' },
    { value: 'Not Responding', label: 'Not Responding' },
    { value: 'Interested', label: 'Interested' },
    { value: 'Not Interested', label: 'Not Interested' }
  ];

  const levelOptions = [
    { value: 'P', label: 'P' },
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' }
  ];

  const priorityOptions = [
    { value: 'High', label: 'High' },
    { value: 'Medium', label: 'Medium' },
    { value: 'Low', label: 'Low' }
  ];

  const assignStatusOptions = [
    { value: 'assigned', label: 'Assigned'},
    { value: 'not_assigned', label: 'Not Assigned'}
  ];

  const statusOptions = [
    "Call Later",
    "Wrong Number",
    "Message Sent",
    "Not Responding",
    "Interested",
    "Not Interested"
  ];
  const COMPLETION_STATUSES = ['Interested'];

  const initialFormState = {
    mobileNo: '',
    name: '',
    email: '',
    leadSource: '',
    department: '',
    designation: '',
    leadType: '',
    level: '',
    priority: '',
    address: '',
    followUpDate: '',
    followUpTime: '',
    comments: '',
    campaign: '',
    assignTo: ''
  };

  const [formData, setFormData] = useState(initialFormState);
  const [formDataHistory, setFormDataHistory] = useState({
      status: null,
      nextFollowup: '',
      nextFollowupTime: '',
      comments: ''
    });
  const [filters, setFilters] = useState({
    from: '',
    to: '',
    level: null,
    department: null,
    source: null,
    staff: null,
    campaign: null,
    assignStatus: null,
    leadStatus: null,
    page: 1,
    limit: 10
  });

  const [tableData, setTableData] = useState({
    columns: [
      {
        label: '#',
        field: 'index',
        sort: 'asc',
        width: 50
      },
      {
        label: 'Date',
        field: 'date',
        sort: 'asc',
        width: 150
      },
      {
        label: 'ID',
        field: 'uniqueId',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Mobile',
        field: 'mobile',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Department',
        field: 'department',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Level',
        field: 'level',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Source',
        field: 'source',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Assign To',
        field: 'assignTo',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Campaign',
        field: 'campaign',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Actions',
        field: 'actions',
        sort: false,
        width: 100
      }
    ],
    rows: []
  })

  const formatTimeFor12Hour = (time24) => {
    if (!time24) return 'N/A';
    
    // Handle case where time includes seconds
    const [hours24, minutes, seconds] = time24.split(':');
    const hours = parseInt(hours24, 10);
    const period = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours;
    
    return `${hours12}:${minutes} ${period}`;
  };

  const statusSelectOptions = statusOptions.map(status => ({
    value: status,
    label: status
  }));

  useEffect(() => {
    const fetchDropdownData = async () => {
      setIsLoading(true);
      setFetchError(null);
      try {
        const [deptResponse, desigResponse, typesResponse, assignResponse, sourceResponse, campaignResponse] = await Promise.all([
          leadService.getDepartments(),
          leadService.getDesignations(),
          leadService.getLeadTypes(),
          leadService.getAssignedTo(),
          leadService.getSource(),
          leadService.getCampaign()
        ]);

       // Transform responses to react-select format
       const formatOptions = (data, labelKey = 'name') => {
        if (!Array.isArray(data)) return [];
        return data.map(item => ({
          value: item._id,
          label: item[labelKey],
          ...item
        }));
      };

      setDepartments(formatOptions(deptResponse.data || deptResponse));
      setDesignations(formatOptions(desigResponse.data || desigResponse));
      setLeadTypes(formatOptions(typesResponse.data || typesResponse));
      setAssignedTo(formatOptions(assignResponse.data || assignResponse, 'label'));
      setSources(formatOptions(sourceResponse.data || sourceResponse));
      setCampaigns(formatOptions(campaignResponse.data || campaignResponse));

    } catch (error) {
      console.error('Error fetching dropdown data:', error);
      setFetchError('Failed to load form data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  fetchDropdownData();
}, []);

  useEffect(() => {
    const savedLead = localStorage.getItem('selectedLead');
    if (savedLead) {
      setFormData(JSON.parse(savedLead)); // Populate form data
      setIsEditing(true);
      localStorage.removeItem('selectedLead'); // Clear it after use
    }
  }, []);

  const fetchHistory = async (leadId) => {
    try {
      const response = await fetch(`${API_URL}followups/history/${leadId}`);
      const data = await response.json();
      if (data.success) {
        setHistoryData(data.history);
      }
    } catch (error) {
      console.error('Error fetching history:', error);
    }
  };

  const handleAssignTo = async (leadId, staffMemberId) => {
    try {
      // Ensure staffMemberId is not an empty string
      const assignToId = staffMemberId || null;
      
      await leadService.assignLead(leadId, assignToId);
      
      // Refresh the leads list to reflect the new assignment
      fetchLeads(filters);
      
      // Show success toast
      toastr.success("Lead assigned successfully");
    } catch (error) {
      console.error('Error assigning lead:', error);
      toastr.error("Failed to assign lead");
    }
  };

  // const handleInputChange = (name, value) => {
  //   setFormData(prev => ({
  //     ...prev,
  //     [name]: value
  //   }));
    
  //   if (formErrors[name]) {
  //     setFormErrors(prev => ({
  //       ...prev,
  //       [name]: ''
  //     }));
  //   }
  // };
  
  const handleInputChange = (name, value) => {
    const updatedFormData = {
      ...formData,
      [name]: value
    };
    setFormData(updatedFormData);
    
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }

    // Check for duplicates when name, mobile, or lead type changes
    if (['name', 'mobileNo'].includes(name) || 
    (name === 'leadType' && value?.value)) {
      // Only check if we have all required fields
      if (updatedFormData.name && 
          updatedFormData.mobileNo && 
          updatedFormData.leadType?.value) {
        checkDuplicateLead(updatedFormData, setFormErrors, setIsDuplicateChecking);
      }
    }
  };

  const handleSearchSelect = (selected) => {
    if (selected) {
      const updatedFormData = {
        ...formData,
        mobileNo: selected.mobileNo || '',
        name: selected.name || '',
        email: selected.email || '',
        address: selected.address || ''
      };
      setFormData(updatedFormData);
      
      // Check for duplicates when populating from search
      if (updatedFormData.name && updatedFormData.mobileNo && formData.leadType?.value) {
        checkDuplicateLead(updatedFormData, setFormErrors, setIsDuplicateChecking);
      }
    }
  };

  const handleSelectChange = (option, { name }) => {
    handleInputChange(name, option);
    handleBlur(name, option);
    
    // Handle level-priority relation
    // if (name === 'level') {
    //   if (option?.value === '0') {
    //     // Show priority field and set default priority
    //     handleInputChange('priority', priorityOptions[0]);
    //   } else {
    //     // Clear priority when level is not 0
    //     handleInputChange('priority', null);
    //   }
    // }
  };

  const validateForm = () => {
    const errors = {};
    
    // Required field validations
    // if (!formData.mobileNo) {
    //   errors.mobileNo = 'Mobile number is required';
    // } else if (!/^\d{10}$/.test(formData.mobileNo)) {
    //   errors.mobileNo = 'Mobile number must be 10 digits';
    // }

    if(formData.mobileNo) {
      if(!/^\d+$/.test(formData.mobileNo)) {
        errors.mobileNo = 'Mobile Number must contain digits only'
      } else if (formData.mobileNo.length !== 10) {
        errors.mobileNo = 'Mobile number must be exactly 10 digits';
      }
    }
    
    if (!formData.name || formData.name.trim() === '') {
      errors.name = 'Name is required';
    } else if (/^\d+$/.test(formData.name)) {
      errors.name = 'Name cannot contain numbers';
    } else if (!/^[a-zA-Z0-9\s]*$/.test(formData.name)) {
      errors.name = 'Name can only contain letters, numbers, and spaces';
    }
  
    if (!formData.leadSource) {
      errors.leadSource = 'Lead source is required';
    }
  
    if (!formData.level) {
      errors.level = 'Level is required';
    }
  
    if (!formData.department) {
      errors.department = 'Department is required';
    }

    if (!formData.address) {
      errors.address = 'Address is required';
    }

    if (!formData.leadType) {
      errors.leadType = 'Lead type is required';
    }

    if (formErrors.duplicate) {
      errors.duplicate = formErrors.duplicate;
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(formLoading) return;

    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length > 0) {
      // Scroll to error message if there's a duplicate error
      if (errors.duplicate) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      return;
    }

  try {
    setFormLoading(true);
    
    const duplicateResponse = await leadService.checkDuplicateLead({
      name: formData.name,
      mobileNo: formData.mobileNo,
      leadType: formData.leadType,
      _id: formData._id // Include if updating
    });
    
    // Check the isDuplicate property from the response
    if (duplicateResponse.isDuplicate) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      toastr.error("Cannot save duplicate lead");
      return;
    }
      
      // Transform form data for API
      const apiFormData = {
        ...formData,
        leadSource: formData.leadSource?.value,
        department: formData.department?.value,
        designation: formData.designation?.value,
        leadType: formData.leadType?.value,
        level: formData.level?.value,
        priority: formData.priority?.value,
        campaign: formData.campaign?.value,
        assignTo: formData.assignTo?.value
      };
      
      if (isEditing) {
        await leadService.updateLead(formData._id, apiFormData);
        toastr.success("Lead Updated Successfully");
      } else {
        await leadService.createLead(apiFormData);
        toastr.success("Lead Created Successfully");
      }

      handleReset();
      navigate('/leads');
      fetchLeads();
    } catch (error) {
      console.error("Error submitting lead:", error);
      toastr.error("Error saving lead");
    } finally {
      setFormLoading(false);
    }
  };

  const handleSubmitHistory = async (e) => {
    e.preventDefault();
    if (!selectedLeadId) {
      console.error('No lead selected');
      return;
    }
    setLoading(true);

    try {
      const response = await fetch(`${API_URL}followups/history/${selectedLeadId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...formDataHistory,
          status: formDataHistory.status?.value || '', // Extract value from select option
          isCompleted: COMPLETION_STATUSES.includes(formDataHistory.status?.value)
        })
      });

      const data = await response.json();
      
      if (data.success) {
        // Reset form
        setFormDataHistory({
          status: null,
          nextFollowup: '',
          nextFollowupTime: '',
          comments: ''
        });
        
        // Refresh history
        await fetchHistory(selectedLeadId);
        
        fetchLeads(filters);
      
        toastr.success('Follow-up history added successfully');
      }
    } catch (error) {
      console.error('Error adding follow-up:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleResetHistory = () => {
    setFormDataHistory({
      status: null,
      nextFollowup: '',
      nextFollowupTime: '',
      comments: ''
    });
  };

  useEffect(() => {
    // Add animation class after mount
    const timer = setTimeout(() => {
      setModalVisible(true);
    }, 50);

    return () => clearTimeout(timer);
  }, []);

  const handleBlur = (name, value) => {
    let error = '';
    
    // For Select components, value will be an object with a value property
    const actualValue = value?.value || value;
    
    switch (name) {
      // case 'mobileNo':
      //   if (!actualValue) {
      //     error = 'Mobile number is required';
      //   } else if (!/^\d{10}$/.test(actualValue)) {
      //     error = 'Mobile number must be 10 digits';
      //   }
      //   break;
      
      case 'mobileNo':
        if(actualValue) {
          if (!/^\d+$/.test(actualValue)) {
            error = 'Mobile number must contain only digits';
          } else if (actualValue.length !== 10) {
            error = 'Mobile number must be exactly 10 digits';
          }
        }
        break;
        
      case 'name':
        if (!actualValue || actualValue.trim() === '') {
          error = 'Name is required';
        } else if (/^\d+$/.test(actualValue)) {
          error = 'Name cannot contain only numbers';
        } else if (!/^[a-zA-Z0-9\s]*$/.test(actualValue)) {
          error = 'Name can only contain letters, numbers and spaces';
        }
        break;
        
      case 'leadSource':
        if (!actualValue) {
          error = 'Lead Source is required';
        }
        break;
        
      case 'level':
        if (!actualValue) {
          error = 'Level is required';
        }
        break;
        
      case 'department':
        if (!actualValue) {
          error = 'Department is required';
        }
        break;
        
      case 'address':
        if (!actualValue || actualValue.trim() === '') {
          error = 'Address is required';
        }
        break;
  
      case 'leadType':
        if (!actualValue) {
          error = 'Lead Type is required';
        }
        break;
        
      default:
        break;
    }
  
    setFormErrors(prev => ({
      ...prev,
      [name]: error
    }));
  
    return error;
  };

  const handleView = async (leadId) => {
    try {
      const response = await followupService.getFollowupHistory(leadId);
      if (response.success) {
        setSelectedLead(response.lead);
        setSelectedLeadId(leadId);
        setHistoryData(response.history);
        setModalVisible(true);
      } else {
        toastr.error('Error loading lead details');
      }
    } catch (error) {
      console.error('Error fetching lead details:', error);
      toastr.error('Error loading lead details');
    }
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedLead(null);
  };

  const handleFilterReset = async () => {
    const initialFilters = {
      from: '',
      to: '',
      level: null,
      department: null,
      source: null,
      staff: null,
      campaign: null,
      assignStatus: null,
      leadStatus: null,
      page: 1,
      limit: 10
    };
    
    setFilters(initialFilters);
    await fetchLeads(initialFilters);
  };

  const handleEdit = async (leadId) => {
    try {
      const response = await leadService.getLead(leadId);
      const formattedData = {
        ...response,
        // Format leadSource
        leadSource: response.leadSource ? {
          value: response.leadSource._id,
          label: response.leadSource.displayName,
          ...response.leadSource
        } : null,
        
        // Format level
        level: response.level ? {
          value: response.level,
          label: response.level
        } : null,
        
        // Format priority
        priority: response.priority ? {
          value: response.priority,
          label: response.priority
        } : null,
        
        // Format department - assumes department is an object with _id and name
        department: response.department ? {
          value: response.department._id,
          label: response.department.name,
          ...response.department
        } : null,
        
        // Format designation
        designation: response.designation ? {
          value: response.designation._id,
          label: response.designation.name,
          ...response.designation
        } : null,
        
        // Format leadType
        leadType: response.leadType ? {
          value: response.leadType._id,
          label: response.leadType.name,
          ...response.leadType
        } : null,
        
        // Format campaign
        campaign: response.campaign ? {
          value: response.campaign._id,
          label: response.campaign.name,
          ...response.campaign
        } : null,
      };
      setFormData(formattedData);
      setIsEditing(true);
      // Scroll to top of the page where the form is
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (error) {
      console.error('Error fetching lead details:', error);
    }
  }; 

  function handleDelete(leadId) {
    return Swal.fire({
      title: "Are you sure you want to delete this lead?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        leadService.deleteLead(leadId)
          .then(res => {
            fetchLeads()
            toastr.success(res.message || "Lead deleted successfully");
          })
          .catch(err => {
            toastr.error(err.response?.data?.message || "Error deleting lead");
          })
      }
    })
  }

  const fetchLead = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_URL}leads/${id}`);
      const data = await response.json();
      setFormData(data);
    } catch (error) {
      console.error('Error fetching lead:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchLeads = useCallback(async (filterParams = {}) => {
    try {
      setTableLoading(true);
      setError(null);

      const apiFilters = {
        ...filters,
        ...filterParams,
        level: filterParams.level?.value || filters.level?.value || '',
        department: filterParams.department?.value || filters.department?.value || '',
        source: filterParams.source?.value || filters.source?.value || '',
        staff: filterParams.staff?.value || filters.staff?.value || '',
        campaign: filterParams.campaign?.value || filters.campaign?.value || '',
        assignStatus: filterParams.assignStatus?.value || filters.assignStatus?.value || '',
        leadStatus: filterParams.leadStatus?.value || filters.leadStatus?.value || '',
        populate: 'true'
      };

      const response = await leadService.getLeads(apiFilters);
      
      if (!response || !response.leads) {
        throw new Error('Invalid response format');
      }

      // Calculate starting index for current page
      const startIndex = (response.currentPage - 1) * apiFilters.limit;

      const formattedRows = response.leads.map((lead, index) => ({
        index: startIndex + index + 1,
        date: moment(lead.createdAt).format('DD/MM/YYYY'),
        uniqueId: lead.uniqueId,
        name: lead.name,
        mobile: lead.mobileNo || 'N/A',
        department: lead.department?.name || 'N/A',
        level: lead.level || 'N/A',
        source: lead.leadSource?.displayName || 'N/A',
        assignTo: (
          <select
            name="assignTo"
            value={lead.assignTo?._id || ''}
            onChange={(e) => handleAssignTo(lead._id, e.target.value)}
            className="form-select"
          >
            <option value="">Select...</option>
            {Array.isArray(assignedTo) &&
              assignedTo.map(assign => {
                const displayName = [assign.firstName, assign.lastName]
                  .filter(Boolean)
                  .join(' ')
                  .trim() || assign.label || 'Unknown';

                return (
                  <option key={assign._id} value={assign._id}>
                    {displayName}
                  </option>
                );
              })}
          </select>
        ),
        campaign: lead.campaign?.name || 'N/A',
        actions: (
          <div className="d-flex justify-content-around align-items-center">
            <button
              onClick={() => handleView(lead._id)}
              className="btn btn-link text-primary p-1"
              title="View & Followup"
            >
              <FontAwesomeIcon icon={faEye} color='#3D3D3D' />
            </button>
            <button
              onClick={() => handleEdit(lead._id)}
              className="btn btn-link text-success p-1"
              title="Edit Lead"
            >
              <FontAwesomeIcon icon={faPenToSquare} color='#3D3D3D' />
            </button>
            <button
              onClick={() => handleDelete(lead._id)}
              className="btn btn-link text-danger p-1"
              title="Delete Lead"
            >
              <FontAwesomeIcon icon={faTrash} color='#3D3D3D' />
            </button>
          </div>
        )
      }));

      setTableData(prev => ({
        ...prev,
        rows: formattedRows
      }));

      // Update pagination state with the response data
      setPagination({
        currentPage: response.currentPage || 1,
        totalPages: response.totalPages || 1,
        totalLeads: response.totalLeads || 0
      });

      // Update filters with the current page
      setFilters(prev => ({
        ...prev,
        page: response.currentPage,
        limit: apiFilters.limit
      }));

      // Store the raw leads data for export
      setLeads(response.leads);

    } catch (error) {
      console.error('Error fetching leads:', error);
      setError('Failed to fetch leads data');
      toastr.error('Error loading leads');
    } finally {
      setTableLoading(false);
    }
  }, [assignedTo]);

  useEffect(() => {
    if (assignedTo.length > 0 && !id && !isEditing) {
      fetchLeads(filters);
    }
  }, [assignedTo, id, isEditing, fetchLeads]);

  useEffect(() => {
    if(id) {
      fetchLead();
    }
  }, [id]);

  const handleFilterChange = async (e) => {
    const { name, value } = e.target;
    const updatedFilters = {
      ...filters,
      [name]: value,
      page: 1
    };
    
    setFilters(updatedFilters);
    await fetchLeads(updatedFilters);
  };

  // // Add this useEffect to fetch initial data
  // useEffect(() => {
  //   if (assignedTo.length > 0) {
  //     fetchLeads();
  //   }
  // }, [assignedTo, fetchLeads]);

  const handleFilterSelectChange = async (name, option) => {
    const updatedFilters = {
      ...filters,
      [name]: option,
      page: 1
    };
    
    setFilters(updatedFilters);
    await fetchLeads(updatedFilters);
  };

  const handleExport = async (e) => {
    e.preventDefault();

    if(isLoading) return;

    try {
      // Show loading indicator
      setIsLoading(true);
      setError(null);

      // Format filters to extract just the values from select options
      const formattedFilters = {
        ...filters,
        department: filters.department?.value || '',
        designation: filters.designation?.value || '',
        leadType: filters.leadType?.value || '',
        assignTo: filters.assignTo?.value || '',
        source: filters.source?.value || '',
        staff: filters.staff?.value || '',
        level: filters.level?.value || '',
        campaign: filters.campaign?.value || '',
        assignStatus: filters.assignStatus?.value || '',
      };
  
      // Implement chunk-based export for large datasets
      const exportLeadsInChunks = async () => {
        const chunkSize = 1000; // Number of records to fetch per chunk
        let currentPage = 1;
        let allExportData = [];
  
        while (true) {
          // Fetch leads in chunks
          const chunkData = await leadService.getLeads({ 
            ...formattedFilters, 
            page: currentPage,
            limit: chunkSize,
            populate: 'true'
          });
  
          // Transform the chunk of leads
          const chunkExportData = chunkData.leads.map(lead => ({
            Date: moment(lead.createdAt).format('DD/MM/YYYY'),
            ID: lead.uniqueId,
            Name: lead.name,
            Mobile: lead.mobileNo || 'N/A',
            Email: lead.email || 'N/A',
            Department: lead.department?.name || 'N/A',
            // Designation: lead.designation?.name || 'N/A',
            Level: lead.level || 'N/A',
            Priority: lead.priority || 'N/A',
            Source: lead.leadSource?.displayName || 'N/A',
            Campaign: lead.campaign?.name || 'N/A',
            'Lead Type': lead.leadType?.name || 'N/A',
            'Assigned To': lead.assignTo ? 
            `${lead.assignTo.firstName || ''} ${lead.assignTo.lastName || ''}`.trim() : 
            'Not Assigned',
            'Follow Up Date': lead.followUpDate || 'N/A',
            'Follow Up Time': lead.followUpTime || 'N/A',
            Address: lead.address || 'N/A',
            Comments: lead.comments || 'N/A',
          }));
  
          // Append chunk to total export data
          allExportData = [...allExportData, ...chunkExportData];
  
          // Break if no more leads or less than chunk size
          if (chunkData.leads.length < chunkSize) {
            break;
          }
  
          // Increment page for next chunk
          currentPage++;
        }
  
        // Create worksheet
        const worksheet = XLSX.utils.json_to_sheet(allExportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Leads");
        
        // Export the file
        XLSX.writeFile(workbook, `leads_export_${new Date().toISOString().split('T')[0]}.xlsx`);
      };
      // Execute chunk-based export
      await exportLeadsInChunks();
  
    } catch (error) {
      console.error('Error exporting leads:', error);
      setError('Failed to export leads');
    } finally {
      // Hide loading indicator
      setIsLoading(false);
    }
  };

  const handleReset = (e) => {
    if (e) e.preventDefault();

    sessionStorage.removeItem('reviewLeadData');

    setFormData(initialFormState);
    setFormErrors({});
    setIsEditing(false);
  };

  const renderForm = () => (
    <Form onSubmit={handleSubmit}>
      {formErrors.duplicate && (
        <Alert color="danger" className="mb-3">
          {formErrors.duplicate}
        </Alert>
      )}

      <Row>
        <Col md={6}>
          <FormGroup>
          <Label>Search Existing Lead</Label>
          <SearchSelect
            value={null}
            api="leads/search"
            onChange={handleSearchSelect}
            getOptionLabel={(option) => 
              `${option.name} (${option.mobileNo})${option.email ? ` - ${option.email}` : ''}`
            }
            getOptionValue={(option) => option.mobileNo}
            placeholder="Search by Name, Email, or Mobile..."
          />
          </FormGroup>
        </Col>
      </Row>  
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label for="mobileNo">
              Mobile No.
              {/* <span className="text-danger"> *</span> */}
            </Label>
            <Input
              type="text"
              name="mobileNo"
              id="mobileNo"
              value={formData.mobileNo}
              onChange={(e) => {
                // Only allow digits
                const value = e.target.value.replace(/\D/g, '');
                // Limit to 10 digits
                if (value.length <= 10) {
                  handleInputChange('mobileNo', value);
                }
              }}
              onBlur={(e) => handleBlur('mobileNo', e.target.value)}
              invalid={!!formErrors.mobileNo}
              placeholder='Enter Mobile No'
            />
            {formErrors.mobileNo && (
              <div className="invalid-feedback">
                {formErrors.mobileNo}
              </div>
            )}
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="name">
              Name<span className="text-danger"> *</span>
            </Label>
            <Input
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={(e) => {
                const value = e.target.value;
                // Remove any special characters on input
                const sanitizedValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
                handleInputChange('name', sanitizedValue);
              }}
              onBlur={(e) => handleBlur('name', e.target.value)}
              invalid={!!formErrors.name}
              placeholder='Enter Name'
            />
            {formErrors.name && (
              <div className="invalid-feedback">
                {formErrors.name}
              </div>
            )}
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="email">
              Email
            </Label>
            <Input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={(e) => handleInputChange('email', e.target.value)}
              invalid={!!formErrors.email}
              placeholder='Enter Email ID'
            />
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="leadSource">
              Lead Source<span className="text-danger"> *</span>
            </Label>
            <Select
              name="leadSource"
              value={formData.leadSource}
              options={sources}
              onChange={(option) => handleSelectChange(option, { name: 'leadSource' })}
              onMenuClose={() => handleBlur('leadSource', formData.leadSource)}
              className={formErrors.leadSource ? 'is-invalid' : ''}
              isClearable
            />
            {formErrors.leadSource && (
              <div className="invalid-feedback">
                {formErrors.leadSource}
              </div>
            )}
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="level">
              Level<span className="text-danger"> *</span>
            </Label>
            <Select
              name="level"
              value={formData.level}
              options={levelOptions}
              onChange={(option) => handleSelectChange(option, { name: 'level' })}
              onMenuClose={() => handleBlur('level', formData.level)}
              className={formErrors.level ? 'is-invalid' : ''}
              isClearable
            />
            {formErrors.level && (
              <div className="invalid-feedback">
                {formErrors.level}
              </div>
            )}
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="priority">
              Priority
            </Label>
            <Select
              name="priority"
              value={formData.priority}
              options={priorityOptions}
              onChange={(option) => handleSelectChange(option, { name: 'priority' })}
              className={formErrors.priority ? 'is-invalid' : ''}
              isClearable
            />
          </FormGroup>
        </Col>        

        <Col md={3}>
          <FormGroup>
            <Label for="department">
              Department<span className="text-danger"> *</span>
            </Label>
            <Select
              name="department"
              value={formData.department}
              options={departments}
              onChange={(option) => handleSelectChange(option, { name: 'department' })}
              onMenuClose={() => handleBlur('department', formData.department)}
              className={formErrors.department ? 'is-invalid' : ''}
              isLoading={isLoading}
              isClearable
            />
            {formErrors.department && (
              <div className="invalid-feedback">
                {formErrors.department}
              </div>
            )}
          </FormGroup>
        </Col>

        {/* <Col md={3}>
          <FormGroup>
            <Label for="designation">
              Designation
            </Label>
            <Select
              name="designation"
              value={formData.designation}
              options={designations}
              onChange={(option) => handleSelectChange(option, { name: 'designation' })}
              className={formErrors.designation ? 'is-invalid' : ''}
              isLoading={isLoading}
              isClearable
            />
            {formErrors.designation && (
              <div className="invalid-feedback">
                {formErrors.designation}
              </div>
            )}
          </FormGroup>
        </Col> */}

        <Col md={3}>
          <FormGroup>
            <Label for="leadType">
              Lead Type<span className="text-danger"> *</span>
            </Label>
            <Select
              name="leadType"
              value={formData.leadType}
              options={leadTypes}
              onChange={(option) => handleSelectChange(option, { name: 'leadType' })}
              onMenuClose={() => handleBlur('leadType', formData.leadType)}
              className={formErrors.leadType ? 'is-invalid' : ''}
              isLoading={isLoading}
              isClearable
            />
            {formErrors.leadType && (
              <div className="invalid-feedback">
                {formErrors.leadType}
              </div>
            )}
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="campaign">
              Campaign
            </Label>
            <Select
              name="campaign"
              value={formData.campaign}
              options={campaigns}
              onChange={(option) => handleSelectChange(option, { name: 'campaign' })}
              className={formErrors.campaign ? 'is-invalid' : ''}
              isClearable
            />
            {formErrors.campaign && (
              <div className="invalid-feedback">
                {formErrors.campaign}
              </div>
            )}
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="followUpDate">
              Follow Up Date
            </Label>
            <Input
              type="date"
              name="followUpDate"
              id="followUpDate"
              value={formData.followUpDate}
              min={moment().format('YYYY-MM-DD')} //set minimum date to today
              onChange={(e) => handleInputChange('followUpDate', e.target.value)}
              invalid={!!formErrors.followUpDate}
            />
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="followUpTime">
              Follow Up Time
            </Label>
            <Input
              type="time"
              name="followUpTime"
              id="followUpTime"
              value={formData.followUpTime}
              onChange={(e) => handleInputChange('followUpTime', e.target.value)}
              invalid={!!formErrors.followUpTime}
              className="form-control"
              disabled={!formData.followUpDate} // Disable if no date is selected
            />
            {formData.followUpTime && !formData.followUpDate && (
              <div className="text-danger small mt-1">
                Please select a follow-up date first
              </div>
            )}
          </FormGroup>
        </Col>

        <Col md={6}>
          <FormGroup>
            <Label for="address">
              Address<span className="text-danger"> *</span>
            </Label>
            <Input
              type="textarea"
              name="address"
              id="address"
              value={formData.address}
              onChange={(e) => handleInputChange('address', e.target.value)}
              onBlur={(e) => handleBlur('address', e.target.value)}
              invalid={!!formErrors.address}
              style={{ minHeight: '120px' }}
              className={formErrors.address ? 'is-invalid' : ''}
              placeholder="Enter Address"
            />
            {formErrors.address && (
              <div className="invalid-feedback">
                {formErrors.address}
              </div>
            )}
          </FormGroup>
        </Col>

        <Col md={6}>
          <FormGroup>
            <Label for="comments">
              Comments
            </Label>
            <Input
              type="textarea"
              name="comments"
              id="comments"
              value={formData.comments}
              onChange={(e) => handleInputChange('comments', e.target.value)}
              invalid={!!formErrors.comments}
              style={{ minHeight: '120px' }}
              placeholder="Enter Comments"
            />
          </FormGroup>
        </Col>
      </Row>
      <div className="d-flex gap-2 mt-3">
        <Button 
          type="submit" 
          color="primary"
          disabled={formLoading || isDuplicateChecking}
        >
          {formLoading ? (isEditing ? 'Updating...' : 'Submitting...') : (isEditing ? 'Update' : 'Submit')}
        </Button>
        <Button
          type="button"
          color="danger"
          onClick={handleReset}
          disabled={formLoading}
        >
          Reset
        </Button>
      </div>
    </Form>
  );

  const renderModal = () => (
    <Modal
      isOpen={modalVisible && selectedLead !== null}
      toggle={handleCloseModal}
      size="xl"
      className="lead-details-modal"
      style={{ maxWidth: '1400px', width: '95%' }}
      scrollable
    >
      <ModalHeader toggle={handleCloseModal} className="bg-light text-white">
        <h5 className="mb-0">Lead Details - {selectedLead?.name}</h5>
      </ModalHeader>
      <ModalBody className="bg-light">
        {selectedLead && (  
          <>
          <Card className="shadow-sm">
            <CardBody className="p-4">
              <Row className="g-4">
                {/* Personal Information Section */}
                <Col md={4}>
                  <h6 className="fw-bold mb-3 text-primary">Personal Information</h6>
                  <div className="details-section">
                    <Row className="mb-1 py-1 border-bottom">
                      <Col sm={4} className="text-muted fw-bold">ID:</Col>
                      <Col sm={8} className="text-dark">{selectedLead.uniqueId}</Col>
                    </Row>
                    <Row className="mb-1 py-1 border-bottom">
                      <Col sm={4} className="text-muted fw-bold">Name:</Col>
                      <Col sm={8} className="text-dark">{selectedLead.name}</Col>
                    </Row>
                    <Row className="mb-1 py-1 border-bottom">
                      <Col sm={4} className="text-muted fw-bold">Mobile:</Col>
                      <Col sm={8} className="text-dark">{selectedLead.mobileNo || 'N/A'}</Col>
                    </Row>
                    <Row className="mb-1 py-1 border-bottom">
                      <Col sm={4} className="text-muted fw-bold">Email:</Col>
                      <Col sm={8} className="text-dark">{selectedLead.email || '* Not Provided *'}</Col>
                    </Row>
                    <Row className="mb-0 py-1">
                      <Col sm={4} className="text-muted fw-bold">Address:</Col>
                      <Col sm={8} className="text-dark">{selectedLead.address || 'N/A'}</Col>
                    </Row>
                  </div>
                </Col>

                {/* Lead Information Section */}
                <Col md={4}>
                  <h6 className="fw-bold mb-3 text-primary">Lead Information</h6>
                  <div className="details-section">
                    <Row className="mb-1 py-1 border-bottom">
                      <Col sm={4} className="text-muted fw-bold">Lead Source:</Col>
                      <Col sm={8} className="text-dark">{selectedLead.leadSource?.displayName || 'N/A'}</Col>
                    </Row>
                    <Row className="mb-1 py-1 border-bottom">
                      <Col sm={4} className="text-muted fw-bold">Lead Type:</Col>
                      <Col sm={8} className="text-dark">{selectedLead.leadType?.name || 'N/A'}</Col>
                    </Row>
                    <Row className="mb-1 py-1 border-bottom">
                      <Col sm={4} className="text-muted fw-bold">Campaign:</Col>
                      <Col sm={8} className="text-dark">{selectedLead.campaign?.name || 'N/A'}</Col>
                    </Row>
                    <Row className="mb-1 py-1 border-bottom">
                      <Col sm={4} className="text-muted fw-bold">Level:</Col>
                      <Col sm={8} className="text-dark">{selectedLead.level || 'N/A'}</Col>
                    </Row>
                    <Row className="mb-0 py-1">
                      <Col sm={4} className="text-muted fw-bold">Priority:</Col>
                      <Col sm={8} className="text-dark">{selectedLead.priority || 'N/A'}</Col>
                    </Row>
                  </div>
                </Col>

                {/* Combined Product and Follow-Up Details Section */}
                <Col md={4}>
                  <h6 className="fw-bold mb-3 text-primary">Product and Follow-Up Details</h6>
                  <div className="details-section">
                    <Row className="mb-1 py-1 border-bottom">
                      <Col sm={4} className="text-muted fw-bold">Department:</Col>
                      <Col sm={8} className="text-dark">{selectedLead.department?.name || 'N/A'}</Col>
                    </Row>
                    <Row className="mb-1 py-1 border-bottom">
                      <Col sm={4} className="text-muted fw-bold">Assigned To:</Col>
                      <Col sm={8} className="text-dark">
                        {selectedLead.assignTo 
                        ? [selectedLead.assignTo.firstName, selectedLead.assignTo.lastName]
                        .filter(Boolean)
                        .join(' ')
                        .trim()
                        : 'Not Assigned'}
                      </Col>
                    </Row>
                    <Row className="mb-1 py-1 border-bottom">
                      <Col sm={4} className="text-muted fw-bold">Follow Up Date:</Col>
                      <Col sm={8} className="text-dark">{selectedLead.followUpDate ? moment(selectedLead.followUpDate).format('DD-MM-YYYY') : 'N/A'}</Col>
                    </Row>
                    <Row className="mb-1 py-1 border-bottom">
                      <Col sm={4} className="text-muted fw-bold">Follow Up Time:</Col>
                      <Col sm={8} className="text-dark">{formatTimeFor12Hour(selectedLead.followUpTime) || 'N/A'}</Col>
                    </Row>
                    <Row className="mb-0 py-1">
                      <Col sm={4} className="text-muted fw-bold">Comments:</Col>
                      <Col sm={8} className="text-dark">
                        {selectedLead.comments || 'N/A'}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <div className="mt-3 ">
            <div className="mt-3">            
              <h6>Follow Up</h6>
              <Card className="p-3">
                <Col md={12}>
                  <form onSubmit={handleSubmitHistory}>
                    <Row className="g-3">
                      <Col md={3}>
                        <FormGroup>
                          <Label>Status</Label>
                          <Select
                              value={formDataHistory.status}
                              onChange={(selectedOption) => 
                                setFormDataHistory({
                                  ...formDataHistory, 
                                  status: selectedOption
                                })
                              }
                              options={statusSelectOptions}
                              placeholder="Select Status"
                              className="basic-select"
                              classNamePrefix="select"
                              required
                              isClearable
                            />
                        </FormGroup>
                      </Col>
                      
                      <Col md={3}>
                        <FormGroup>
                          <Label>Next Followup Date</Label>
                          <Input
                            type="date"
                            value={formDataHistory.nextFollowup}
                            min={moment().format('YYYY-MM-DD')}
                            onChange={(e) => setFormDataHistory({...formDataHistory, nextFollowup: e.target.value})}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={3}>
                        <FormGroup>
                          <Label>Next Followup Time</Label>
                            <Input
                              type="time"
                              name="nextFollowupTime"
                              id="nextFollowupTime"
                              value={formDataHistory.nextFollowupTime}
                              onChange={(e) => setFormDataHistory({...formDataHistory, nextFollowupTime: e.target.value})}
                              disabled={!formDataHistory.nextFollowup} // Disable if no date is selected
                            />
                        </FormGroup>
                      </Col>
                      
                      <Col md={3}>
                        <FormGroup>
                          <Label>Comments</Label>
                          <Input
                            type="textarea"
                            value={formDataHistory.comments}
                            onChange={(e) => setFormDataHistory({...formDataHistory, comments: e.target.value})}
                            placeholder='Enter Comments'
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="mt-3">
                      <Button color="primary" type="submit" disabled={loading} className="me-2">
                        {loading ? 'Submitting...' : 'Submit'}
                      </Button>
                      <Button color="danger" type="button" onClick={handleResetHistory}>
                        Reset
                      </Button>
                    </div>
                  </form>
                </Col>
              </Card>
            </div>

            <div className="mt-3">
            <h6>Follow Up History</h6>
              <Card className="p-3">
                <Table striped bordered responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Date</th>
                      <th>Next Followup Date</th>
                      <th>Next Followup Time</th>
                      <th>Comments</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {historyData.map((history, index) => (
                      <tr key={history._id}>
                        <td>{index + 1}</td>
                        <td>{moment(history.createdAt).format('DD/MM/YYYY')}</td>
                        <td>{moment(history.nextFollowup).format('DD/MM/YYYY') || 'N/A'}</td>
                        <td>{formatTimeFor12Hour(history.nextFollowupTime) || 'N/A'}</td>
                        <td>{history.comments || 'N/A'}</td>
                        <td>{history.status}</td>
                      </tr>
                    ))}
                    {historyData.length === 0 && (
                      <tr>
                        <td colSpan="5" className="text-center">No Records Available</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card>
            </div>
          </div>
          </>
        )}
      </ModalBody>
      <ModalFooter className="py-2">
        <Button color="secondary" size="sm" onClick={handleCloseModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
   
  const renderFilters = () => (
    <Form className="mb-4">
      <Row className="g-3">
        <Col md={12} className="mb-3">
          <Row className="g-3">
            <Col md={2}>
              <FormGroup>
                <Label className="form-label">From</Label>
                <Input
                  type="date"
                  name="from"
                  value={filters.from}
                  onChange={handleFilterChange}
                  className="select-sm"
                />
              </FormGroup>
            </Col>
  
            <Col md={2}>
              <FormGroup>
                <Label className="form-label">To</Label>
                <Input
                  type="date"
                  name="to"
                  value={filters.to}
                  onChange={handleFilterChange}
                  className="select-sm"
                />
              </FormGroup>
            </Col>
  
            <Col md={2}>
              <FormGroup>
                <Label className="form-label">Level</Label>
                <Select
                  name="level"
                  value={filters.level}
                  options={levelOptions}
                  onChange={(option) => handleFilterSelectChange('level', option)}
                  isClearable
                  placeholder="Select..."
                  className="select-sm"
                />
              </FormGroup>
            </Col>
  
            <Col md={2}>
              <FormGroup>
                <Label className="form-label">Department</Label>
                <Select
                  name="department"
                  value={filters.department}
                  options={departments}
                  onChange={(option) => handleFilterSelectChange('department', option)}
                  isClearable
                  isLoading={isLoading}
                  placeholder="Select..."
                  className="select-sm"
                />
              </FormGroup>
            </Col>
  
            <Col md={2}>
              <FormGroup>
                <Label className="form-label">Source</Label>
                <Select
                  name="source"
                  value={filters.source}
                  options={sources}
                  onChange={(option) => handleFilterSelectChange('source', option)}
                  isClearable
                  placeholder="Select..."
                  className="select-sm"
                />
              </FormGroup>
            </Col>
  
            <Col md={2}>
              <FormGroup>
                <Label className="form-label">Staff</Label>
                <Select
                  name="staff"
                  value={filters.staff}
                  options={assignedTo}
                  onChange={(option) => handleFilterSelectChange('staff', option)}
                  isClearable
                  isLoading={isLoading}
                  placeholder="Select..."
                  className="select-sm"
                />
              </FormGroup>
            </Col>

            <Col md={2}>
              <FormGroup>
                <Label className="form-label">Assign Status</Label>
                <Select
                  name="assignStatus"
                  value={filters.assignStatus}
                  options={assignStatusOptions}
                  onChange={(option) => handleFilterSelectChange('assignStatus', option)}
                  isClearable
                  placeholder="Select..."
                  className="select-sm"
                />
              </FormGroup>
            </Col>

            <Col md={2}>
              <FormGroup>
                <Label className="form-label">Lead Status</Label>
                <Select
                  name="leadStatus"
                  value={filters.leadStatus}
                  options={statusFilterOptions}
                  onChange={(option) => handleFilterSelectChange('leadStatus', option)}
                  isClearable
                  placeholder="Select..."
                  className="select-sm"
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col md={12}>
          <div className="d-flex gap-2 mb-3">
            <Button
              color="danger"
              size="btn-sm"
              onClick={handleFilterReset}
            >
              Reset
            </Button>
            <Button
              color="warning"
              size="btn-sm"
              onClick={handleExport}
              disabled={isLoading}
            >
              Export
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );

  return (
  <div className="page-content">  
    <Container fluid className="py-2">
      <Breadcrumbs title="Home" breadcrumbItem="Add New Lead" />

      {/* Add/Edit Lead Form */}
      <Card className="mb-4">
        {/* <CardHeader>
          <h6 className="bold">{isEditing ? 'Edit Lead' : 'Add New Lead'}</h6>
        </CardHeader> */}
        <CardBody>
          {renderForm()}
        </CardBody>
      </Card>

      {/* Leads List */}
      <Card>
        <CardHeader>
          <h6 className="bold">Leads List</h6>
        </CardHeader>
        <CardBody>
          {renderFilters()}
          {tableLoading ? (
            <div className="text-center py-3">Loading...</div>
          ) : (
            <>
              <MDBDataTable
                data={tableData}
                paging={false}
                striped
                bordered
                hover
                responsive
              />
              
              <Pagination
                currentPage={pagination.currentPage}
                totalPages={pagination.totalPages}
                totalLeads={pagination.totalLeads}
                onPageChange={(page) => {
                  const updatedFilters = {
                    ...filters,
                    page: page
                  };
                  setFilters(updatedFilters);
                  fetchLeads(updatedFilters);
                }}
                onEntriesChange={(newLimit) => {
                  const updatedFilters = {
                    ...filters,
                    limit: newLimit,
                    page: 1 // Reset to first page when changing entries per page
                  };
                  setFilters(updatedFilters);
                  fetchLeads(updatedFilters);
                }}
                entriesPerPage={filters.limit}
              />
            </>
          )}
        </CardBody>
      </Card>

      {renderModal()}
    </Container>
  </div>  
  );
};

export default LeadManagement;