import React from "react"
import ReactApexChart from "react-apexcharts"

const PieChart = ({ answers, type, questionNumber }) => {
  let requiredAnswers
  if(type === 0){
    requiredAnswers = ['2', '1'];
  } else if(type === 1 || type === 2){
    requiredAnswers = ['5', '4', '3', '2', '1'];
  }

  const answerMap = new Map();
  answers?.forEach(item => {
    answerMap.set(item.answer, item.count);
  });

  const finalArray = requiredAnswers?.map(answer => {
    const count = answerMap?.has(answer) ? answerMap?.get(answer) : 0;
    return { answer, count };
  });

  let series
  let labelArray
  let colors

  if (finalArray && finalArray.length > 0 && type !== undefined) {
    if (type === 0) {
      labelArray = finalArray?.map(item => item.answer === '2' ? "No" : item.answer === '1' ? "Yes" : '')
      colors = ["#F44336", "#4CAF50"] // Red for No, Green for Yes
    } else if (type === 1 || type === 2) {
      labelArray = finalArray?.map(item => {
        return item.answer === '5' ? 'Excellent'
          : item.answer === '4' ? 'Best'
          : item.answer === '3' ? 'Good'
          : item.answer === '2' ? 'Average'
          : item.answer === '1' ? 'Need Improvement'
          : ''
      })
      colors = ["#8884D8", "#82CA9d", "#FFC658", "#FF6B6B", "#A569BD"]
    }
    series = finalArray?.map(item => item.count)
  }

  const options = {
    labels: labelArray,
    colors: colors,
    legend: {
      show: !0,
      position: "left",
      horizontalAlign: "right",
      verticalAlign: "middle",
      floating: !1,
      fontSize: "14px",
      offsetX: 0,
      itemMargin: {
        horizontal: 0,
        vertical: 13,
      },
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: !1,
          },
        },
      },
    ],
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type={questionNumber % 2 === 0 ? "donut" : "pie"}
      height="240"
      className="apex-charts"
    />
  )
}

export default PieChart