import React, { useEffect, useRef, useState } from "react";
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Tooltip } from "@mui/material";
import toastr from "toastr";

import Pagination from "../Pagination.jsx";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import BulkUploadDetails from "./Details.jsx";

import {
  dateConverter,
  numberToString,
  renderTableValue,
  setTitle,
  timeConverter,
  toTop,
} from "../../../helpers/functions.js";
import { get, post } from "../../../helpers/api_helper";
import { API_URL } from "../../../config";

const LeadBulkUpload = () => {
  setTitle("Lead Bulk Upload");

  const formRef = useRef();

  const [masterObject, setMasterObject] = useState({});
  const [tableData, setTableData] = useState([]);

  // const limit = 100;
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const [modal, setModal] = useState({ show: false, id: null });
  const [loading, setLoading] = useState(false);

  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [totalEntries, setTotalEntries] = useState(0);

  useEffect(() => {
    handleTableData(1, entriesPerPage);
  }, []); // eslint-disable-line

const handleTableData = async (currentPage, limit) => {
  try {
    setLoading(true);
    const response = await get(`bulk-upload?page=${currentPage}&limit=${limit}`);
    
    if (response && response.data) {
      const { count = 0, data = [] } = response.data;
      setTotalEntries(count);
      
      const formattedData = data.map((item, index) => {

        const serialNumber = (currentPage -1) * limit + (index + 1);

        return {
          ...item,
          id: serialNumber,
          date: dateConverter(item.date || ''),
          time: timeConverter(item.time || ''),
          staff: renderTableValue(item.addedBy ? `${item.addedBy.firstName} ${item.addedBy.lastName}`.trim() : '--'),
          total: numberToString(item.count?.total || 0),
          success: numberToString(item.count?.success || 0),
          failed: numberToString(item.count?.failed || 0),
          options: item.status === 0 ? (
            <div className="d-flex align-items-center gap-2">
              <Tooltip title="File" arrow placement="top">
                <a href={`${API_URL}${item?.fileName}`} download>
                  <i className="fa fa-file-excel excel-icon"></i>
                </a>
              </Tooltip>
              <Tooltip title="View" arrow placement="top">
                <i
                  className="fas fa-eye eye-icon"
                  onClick={() => handleToggleModal({ show: true, id: item._id })}
                ></i>
              </Tooltip>
            </div>
          ) : (
            <Button size="sm" color="warning" disabled>
              Pending
            </Button>
          )
        }
      });

      const calculatedTotalPages = Math.ceil(count / limit);
      setTotalPage(calculatedTotalPages);
      setTableData(formattedData);
    }
  } catch (error) {
    console.error("Error fetching table data:", error);
    toastr.error("Failed to fetch table data");
  } finally {
    setLoading(false);
  }
};

const handleSubmit = async (event, values) => {
  try {
    setLoading(true);

    if (!masterObject.file) {
      toastr.error("Please select a file");
      return;
    }

    // Create FormData correctly
    const formData = new FormData();
    formData.append("file", masterObject.file);
    
    if (masterObject.remarks) {
      formData.append("remarks", masterObject.remarks);
    }

    const response = await post("bulk-upload", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.success) {
      reset();
      toastr.success(response.message);
      // setTimeout(handleTableData, 2000);
      await handleTableData(1, entriesPerPage);
    } else {
      toastr.error(response.message || "Upload failed");
      setLoading(false);
    }
  } catch (error) {
    console.error("Upload error:", error);
    toastr.error(error.response?.data?.message || "Upload failed");
    setLoading(false);
  }
};

  const handleValueChange = async ({ value, name }) => {
    setMasterObject(prev => ({ ...prev, [name]: value }));
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    handleTableData(newPage, entriesPerPage);
  };

  const handleEntriesChange = (newLimit) => {
    setEntriesPerPage(newLimit);
    setPage(1); // Reset to first page when changing entries per page
    handleTableData(1, newLimit);
  };

  const handleToggleModal = ({ show = false, id = null }) => {
    setModal({ show, id });
    // if (!show) handleTableData();
  };

  const reset = () => {
    formRef.current.reset();
    setMasterObject({});
    setPage(1);
    setLoading(false);
    handleTableData(1, entriesPerPage);
    toTop();
  };

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Time", field: "time" },
      { label: "Total", field: "total" },
      { label: "Success", field: "success" },
      { label: "Failed", field: "failed" },
      { label: "Remarks", field: "remarks" },
      { label: "Added By", field: "staff" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Home" breadcrumbItem="Lead Bulk Upload" />
        <Card>
          <CardBody>
          <AvForm ref={formRef} onValidSubmit={handleSubmit} encType="multipart/form-data">
            <Row>
                <Col md={3}>
                <Label>Upload File</Label>
                <Tooltip title="(.xls, .xlsx)" arrow placement="bottom">
                    <div>
                    <AvField
                        name="file"
                        type="file"
                        onChange={(e) => {
                        const file = e.target.files[0];
                        handleValueChange({
                            value: file,
                            name: "file",
                        });
                        }}
                        accept=".xls, .xlsx"
                        style={{ lineHeight: "1.8" }}
                        validate={{ required: { value: true } }}
                        errorMessage="Please select a file to import"
                    />
                    </div>
                </Tooltip>
                </Col>
                <Col md={3}>
                  <Label>Remarks</Label>
                  <AvField
                    rows={1}
                    name="remarks"
                    type="textarea"
                    placeholder="Remarks"
                    value={masterObject.remarks || ""}
                    onChange={e => handleValueChange(e.target)}
                  />
                </Col>
                <Col md={2}>
                  <div className="d-flex" style={{ marginTop: "26px" }}>
                    <Button color="primary" className="me-2" disabled={loading}>
                      {loading ? "Submitting..." : "Submit"}
                    </Button>
                    <Button color="danger" type="button" onClick={reset}>
                      Reset
                    </Button>
                  </div>
                </Col>
                <Col md={3} style={{ marginTop: "26px" }}>
                  <Tooltip title="Download sample file">
                    <a
                      href={`${API_URL}sample/bulk_upload_sample.xlsx`}
                      download
                      style={{ fontSize: "1rem", width: "fit-content" }}
                      className="d-flex align-items-center btn btn-success"
                    >
                      <i
                        style={{ fontSize: "13px" }}
                        className="fa fa-solid fa-download"
                      />
                      <span style={{ marginLeft: "5px", fontSize: "13px" }}>
                        Download Sample Sheet
                      </span>
                    </a>
                  </Tooltip>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  {data.columns.map((column, index) => (
                    <th key={index}>{column.label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={data.columns.length} className="text-center">
                      Loading...
                    </td>
                  </tr>
                ) : tableData.length === 0 ? (
                  <tr>
                    <td colSpan={data.columns.length} className="text-center">
                      No data available
                    </td>
                  </tr>
                ) : (
                  tableData.map((row, index) => (
                    <tr key={index}>
                      {data.columns.map((column, colIndex) => (
                        <td key={colIndex}>{row[column.field]}</td>
                      ))}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <Pagination 
            currentPage={page}
            totalPages={totalPage}
            totalLeads={totalEntries}
            onPageChange={handlePageChange}
            onEntriesChange={handleEntriesChange}
            entriesPerPage={entriesPerPage}
          />
          </CardBody>
        </Card>
      </Container>

      <BulkUploadDetails
        isOpen={modal.show}
        onToggle={() => handleToggleModal({})}
        id={modal.id}
      />
    </div>
  );
};

export default LeadBulkUpload;