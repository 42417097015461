import React, { useEffect, useState } from "react"
import { Container, Row, Col, Button, Label } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import MiniWidget from "./mini-widget"
import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table"
import { Link } from "react-router-dom"
import { AvField, AvForm } from "availity-reactstrap-validation"

import Select from "react-select"
import LineApexChart from "./chartapex"
import accessToken from "../../helpers/jwt-token-access/accessToken"

import {
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardTitle,
} from "reactstrap"
import "./Googlemybussiness.scss"
import axios from "axios"
import { get } from "../../helpers/api_helper"

const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/"

function Googlemybussiness() {
  const [selectedObject, setSelectedObject] = useState({
    interval: "weekly",
    intervalCount: "10",
    location: null
  })

  const [selectedGraph, setSelectedGraph] = useState({
    interval: "weekly",
    intervalCount: 10,
    location: null
  })

  const [masterObject, setMasterObject] = useState([])
  const [nameObject, setNameObject] = useState(null)
  const [fetchCards, setFetchCards] = useState([])
  const [fetchGraph, setFetchGraph] = useState({})
  const [locations, setLocations] = useState([])

  useEffect(() => {
    selectedObject?.location !== null && handleTableData()
    //eslint-disable-next-line
  }, [selectedObject])
  
  useEffect(() => {
    handleNameData()
    fetchLocation()
    handleGraphData(selectedGraph.interval, selectedGraph.intervalCount)
    //eslint-disable-next-line
  }, [])
  
  useEffect(()=>{
    selectedObject?.location !== null && fetchData()
    //eslint-disable-next-line
  },[selectedObject.location])

  const fetchLocation = ()=> {
    get(`google/active-locations`).then(res=>{
      setLocations(res.data)
      setSelectedObject({...selectedObject, location: res.data[0]?.value})
    })
  }

  function handleTableData() {
    var url = `google/metrics/dashboard/table?interval=${selectedObject.interval}&intervalCount=${selectedObject.intervalCount}&locationId=${selectedObject.location}`
      get(url).then(res => {
        setMasterObject(res.data)
      })
    }

  function handleNameData() {
    var url = `google/metrics/names`
    get(url).then(res => {
        setNameObject(res.data)
      })
  }

  const intervalNames = {
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
  }

  function handleGraphData(interval, intervalCount) {
    var url = `google/metrics/dashboard/graph?interval=${interval}&intervalCount=${intervalCount}`
    get(url)
      .then(res => {
        setFetchGraph(res?.data?.data)
      })
      .catch(error => {
        console.error("Error while fetching graph data:", error)
        // Handle the error as needed
      })
  }

  const fetchData = () => {
    var url = `google/metrics/dashboard/cards`
    get(url)
      .then(res => {
        setFetchCards(res?.data)
      })
  }

  const series1 = [50]

  const options1 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series2 = [70]

  const options2 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series3 = [55]

  const options3 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series4 = [33]

  const options4 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const reports = fetchCards?.map(item => {
    return {
      id: 1,
      icon: item.percentageDifference < 0 ? "mdi mdi-arrow-down-bold" : "mdi mdi-arrow-up-bold",
      icon2: item.iconClassName,
      title: item.metricDisplayName,
      value: item.value,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 60,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: Math.abs(item.percentageDifference) + "%",
      color: item.percentageDifference < 0 ? "danger" : "success",
      desc: "since last month ",
      series: series1,
      options: options1,
    }
  })

  const [selectedSort, setSelectedSort] = useState("Weekly")

  const handleSelectGraph = interval => {
    setSelectedGraph({
      interval,
      intervalCount: 10, // Reset interval count when the interval changes, adjust as needed
    })
    handleGraphData(interval, 10)
  }

  // Function to handle item selection
  const handleSortSelect = num => {
    if (num == "Weekly") {
      setSelectedObject({
        ...selectedObject,
        interval: "weekly",
        intervalCount: "10",
      })
      setSelectedSort("Weekly")
    }

    if (num == "Daily") {
      setSelectedSort("Daily")
      setSelectedObject({
        ...selectedObject,
        interval: "daily",
        intervalCount: "10",
      })
    }

    if (num == "Monthly") {
      setSelectedSort("Monthly")
      setSelectedObject({
        ...selectedObject,
        interval: "monthly",
        intervalCount: "6",
      })
    }
  }

  return (
    <div>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Dashboard" />
          {/* Filters */}
          {/* <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>From Date</Label>
                          <AvField
                            name="Domain"
                            className="form-control"
                            type="date"
                            placeholder=" Name"
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>To Date</Label>
                          <AvField
                            name="Description"
                            className="form-control"
                            type="date"
                            placeholder="Mobile No."
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Branch</Label>
                          <Select
                            name="Domain Code"
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col>

                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          <Button color="danger" type="submit">
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row> */}

          {/* GMB Cards */}
          <Row>
            <MiniWidget reports={reports} />
          </Row>

          {/* GMB Table */}
          <Card>
            <CardBody>
              <div className=" mb-2" style={{ display: "flex", justifyContent: "space-between", alignItems  :'center' }}>
                <div style={{display:'flex', alignItems:'baseline', gap:'10px'}}>
                  <Label>Select Location</Label>
                  <Select 
                    placeholder='Select Location' 
                    onChange={(e)=>setSelectedObject({...selectedObject, location: e.value})} 
                    value={locations.find(item=> item.value === selectedObject.location)} 
                    options={locations}
                    />
                  </div>
                <UncontrolledDropdown>
                  <DropdownToggle
                    style={{ cursor: "pointer" }}
                    tag="a"
                    className="text-reset"
                    id="dropdownMenuButton5"
                    caret
                  >
                    <span className="fw-semibold">Sort By:</span>{" "}
                    <span className="text-muted">
                      {selectedSort}
                      <i className="mdi mdi-chevron-down ms-1"></i>
                    </span>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem onClick={() => handleSortSelect("Daily")}>Daily</DropdownItem>
                    <DropdownItem onClick={() => handleSortSelect("Weekly")}>Weekly</DropdownItem>
                    <DropdownItem onClick={() => handleSortSelect("Monthly")}>Monthly</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              {selectedSort === "Daily" ? (
                <div className="table-responsive gmbtable">
                  <Table lg="12" className="mytable1 table table-bordered  ">
                    <Thead>
                      <Tr>
                        <th class="sticky-col">
                          <p> Business</p>
                          <p>
                            <i className="fas fa-users"></i>{" "}
                          </p>
                        </th>

                        {masterObject?.map((item, index) => (
                          <Th>{item.day}</Th>
                        ))}
                      </Tr>
                    </Thead>

                    <Tbody>
                      {masterObject.length > 0  ? nameObject?.map((item, index) => (
                        <Tr>
                          <th style={{ textAlign: "left" }} className="sticky-col">
                            {item.displayName}
                          </th>

                          {masterObject?.map((dayobj, index) => (
                            <td style={{}}>
                              {
                                dayobj.metricData.find(
                                  metric => metric.metricDisplayName === item.displayName
                                ).value
                              }
                            </td>
                          ))}
                        </Tr>
                      )) : <th className="p-5"><span className="m-5">No Data found</span></th>}
                    </Tbody>
                  </Table>
                </div>
              ) : null}

              {selectedSort === "Weekly" ? (
                <div className="table-responsive gmbtable">
                  <Table lg="12" className="mytable1 table table-bordered  ">
                    <Thead>
                      <Tr>
                        <th class="sticky-col">
                          <p> Business</p>
                          <p>
                            <i className="fas fa-users"></i>{" "}
                          </p>
                        </th>

                        {masterObject?.map((item, index) => (
                          <Th>{item.week}</Th>
                        ))}
                      </Tr>
                    </Thead>

                    <Tbody>
                      {masterObject.length > 0  ? nameObject?.map((item, index) => (
                        <Tr>
                          <Th className="sticky-col">{item.displayName}</Th>

                          { masterObject?.map((weekobj, index) => (
                            <td>
                              {
                                weekobj.metricData.find(
                                  metric => metric.metricDisplayName === item.displayName
                                ).value
                              }
                            </td>
                          ))   }
                        </Tr>
                      )): <th className="p-5"><span className="m-5">No Data found</span></th>}
                    </Tbody>
                  </Table>
                </div>
              ) : null}

              {selectedSort === "Monthly" ? (
                <div className="table-responsive gmbtable">
                  <Table lg="12" className="mytable1 table table-bordered  ">
                    <Thead>
                      <Tr>
                        <th class="sticky-col">
                          <p> Business</p>
                          <p>
                            <i className="fas fa-users"></i>{" "}
                          </p>
                        </th>
                        {/* <Th>Sep-23</Th>
                      <Th>Oct-23</Th>
                      <Th>Nov-23</Th>
                      <Th>Dec-23</Th>
                      <Th>Jan-24</Th>
                      <Th>Feb-24</Th> */}

                        {masterObject?.map((item, index) => (
                          <Th>{item.month}</Th>
                        ))}
                      </Tr>
                    </Thead>

                    <Tbody>
                      {masterObject.length > 0  ? nameObject?.map((item, index) => (
                        <Tr>
                          <Th className="sticky-col">{item.displayName}</Th>

                          {masterObject?.map((monthobj, index) => (
                            <td>
                              {
                                monthobj.metricData.find(
                                  metric => metric.metricDisplayName === item.displayName
                                ).value
                              }
                            </td>
                          ))}
                        </Tr>
                      )) : <th className="p-5"><span className="m-5">No Data found</span></th>}
                    </Tbody>
                  </Table>
                </div>
              ) : null}
            </CardBody>
          </Card>

          {/* GMB Graph */}
          {/* <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div
                    className=" mb-2"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <CardTitle className="mb-4">GMB</CardTitle>
                    </div>
                    <div>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          style={{ cursor: "pointer" }}
                          tag="a"
                          className="text-reset"
                          id="dropdownMenuButton5"
                          caret
                        >
                          <span className="fw-semibold">Sort By:</span>{" "}
                          <span className="text-muted">
                            {intervalNames[selectedGraph.interval]}
                            <i className="mdi mdi-chevron-down ms-1"></i>
                          </span>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem onClick={() => handleSelectGraph("daily")}>
                            Daily
                          </DropdownItem>
                          <DropdownItem onClick={() => handleSelectGraph("weekly")}>
                            Weekly
                          </DropdownItem>
                          <DropdownItem onClick={() => handleSelectGraph("monthly")}>
                            Monthly
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                  {Object.keys(fetchGraph).length > 0 && <LineApexChart graphData={fetchGraph} />}
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </Container>
      </div>
    </div>
  )
}

export default Googlemybussiness
