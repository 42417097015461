import React, { useEffect, useState, useRef } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import * as XLSX from "xlsx"

import grinningface from "../../../assets/fotos/grinningface.svg"
import slightlysmile from "../../../assets/fotos/slightlysmileface.svg"
import Neutralface from "../../../assets/fotos/neutralface.svg"
import confusedface from "../../../assets/fotos/confusedface.svg"
import cryingface from "../../../assets/fotos/cryingface.svg"

import queryString from "query-string"
import "./feedbackLog.scss"
import { get } from "../../../helpers/api_helper"
import moment from "moment"
import Pagination from "./Pagination.jsx"

function Latestfeedback() {
  const customStyles = {
    lineHeight: "1.8",
  }

  const toggle = (start = true) => {
    var body = document.body

    if (start) {
      body.classList.remove("vertical-collpsed")
      body.classList.remove("sidebar-enable")
      body.setAttribute("data-sidebar-size", "sm")
    } else {
      body.classList.add("vertical-collpsed")
      body.classList.add("sidebar-enable")
      body.setAttribute("data-sidebar-size", "lg")
    }
  }

  useEffect(() => {
    toggle()

    return () => {
      toggle(false)
    }
    // eslint-disable-next-line
  }, [])

  const renderAnswer = (type, value) => {
    if (type === 0) {
      switch (value) {
        case "1":
          return "Yes"
        case "2":
          return "No"
        default:
          return null
      }
    } else if (type === 1) {
      return Array(parseInt(value))
        .fill(null)
        .map((_, index) => <span key={index} style={{ fontSize: "15px" }}>⭐</span>)
    } else if (type === 2) {
      let returnValue = ""
      switch (value) {
        case "1":
          returnValue = cryingface
          break
        case "2":
          returnValue = confusedface
          break
        case "3":
          returnValue = Neutralface
          break
        case "4":
          returnValue = slightlysmile
          break
        case "5":
          returnValue = grinningface
          break
        default:
          returnValue = null
      }
      return <img src={returnValue} alt="emoji" />
    } else if (type === 3) {
      return value
    }
  }

  const replaceTableData = value => {
    if (value !== "" && value !== undefined && value !== null) {
      return value
    } else {
      return "-----"
    }
  }

  const formRef = useRef(null)
  const [popupView, setPopupView] = useState(false)
  const [tableData, setTableData] = useState([])
  const [modalData, setModalData] = useState({})
  const [filterObject, setFilterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})
  const [branchOptions, setBranchOptions] = useState([])
  const [departmentOptions, setDepartmentOptions] = useState([])

  const [categoryOptions, setCategoryOptions] = useState([])
  const [subCategoryOptions, setSubCategoryOptions] = useState([])
  const [feedbackFormOption, setFeedbackFormOptions] = useState([])
  const handleCloseModal = () => setPopupView(false)

  // Pagination state
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [entriesPerPage, setEntriesPerPage] = useState(20)

  const fetchBranchOptions = () => {
    get(`/options/branches`)
      .then(res => {
        setBranchOptions(res.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const fetchDepartmentOptions = () => {
    get(`/options/lead-departments`)
      .then(res => {
        setDepartmentOptions(res.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const fetchCategoryOptions = obj => {
    get(`/options/fb-category?branch=${obj?.branch}`)
      .then(res => {
        setCategoryOptions(res.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const fetchSubCategoryOptions = obj => {
    get(`/options/fb-sub-category?branch=${obj?.branch}&category=${obj?.category}`)
      .then(res => {
        setSubCategoryOptions(res.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const fetchFeedbackForms = obj => {
    get(`/options/fb-form?branch=${null}&category=${obj?.category}&subCategory=${obj?.subCategory}`)
      .then(res => {
        setFeedbackFormOptions(res.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const fetchTableData = async (page = 1, limit = entriesPerPage) => {
    try {
      const filterString = queryString.stringify({
        ...filterObject,
        page: page,
        limit: limit
      });
  
      const response = await get(`feedback/log?${filterString}`);
  
      if (!response.data || response.data.length === 0) {
        setTableData([]);
        setTotalItems(0);
        setTotalPages(1);
      } else {
        const transformedData = response.data.map((item, index) => ({
          ...item,
          index: ((page - 1) * limit) + index + 1,
          date: moment(item.date).format("DD-MM-YYYY"),
          time: moment(item.time, "HH:mm:ss").format("hh:mm A"),
          branch: replaceTableData(item.branch),
          department: replaceTableData(item.department),
          category: replaceTableData(item.category),
          subCategory: replaceTableData(item.subCategory),
          name: replaceTableData(item.name),
          email: replaceTableData(item.email),
          mobile: replaceTableData(item.mobile),
          form: replaceTableData(item.form),
          action: (
            <div>
              <i
                className="fas fa-eye"
                onClick={() => fetchFeedbackById(item._id)}
                style={{ fontSize: "1em", cursor: "pointer" }}
              />
            </div>
          ),
        }));

        setTableData(transformedData);
        setTotalItems(response.count || 0);
        setTotalPages(response.pagination?.totalPages || 1);
        setCurrentPage(page);
      }
    } catch (err) {
      console.error(err);
      setTableData([]);
      setTotalItems(0);
      setTotalPages(1);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchTableData(page, entriesPerPage);
  };

  const handleEntriesChange = (newLimit) => {
    setEntriesPerPage(newLimit);
    fetchTableData(1, newLimit); // Reset to first page when changing entries per page
  };

  useEffect(() => {
    fetchTableData(currentPage, entriesPerPage);
    // eslint-disable-next-line
  }, [filterObject])

  useEffect(() => {
    fetchBranchOptions();
    fetchDepartmentOptions();
    fetchCategoryOptions();
    fetchSubCategoryOptions();
    fetchFeedbackForms();
    // eslint-disable-next-line
  }, [])

  const fetchFeedbackById = id => {
    get(`feedback/log/${id}`)
      .then(res => {
        const data = res.data
        setModalData(data)
        setPopupView(true)
      })
      .catch(err => console.error(err))
  }

  const handleSelectChange = (e, name) => {
    const newObj = { ...selectedFields }
    if (name === "branch") {
      if (e !== null && e !== "") {
        delete newObj.category
        delete newObj.subCategory
        delete newObj.form
      }
    } else if (name === "category") {
      if (e !== null && e !== "") {
        delete newObj.subCategory
        delete newObj.form
      }
    } else if (name === "subCategory") {
      if (e !== null && e !== "") {
        newObj.form && delete newObj.form
      }
    }
    setSelectedFields({ ...newObj, [name]: e })
    handleValueChange(e?.value || "", name)
  }

  const handleValueChange = (value, name) => {
    const newObj = { ...filterObject }
    if (name === "branch") {
      if (value !== null && value !== "") {
        delete newObj.category
        delete newObj.subCategory
        delete newObj.form
      }
      newObj.branch = value
      fetchCategoryOptions(newObj)
      fetchSubCategoryOptions(newObj)
      fetchFeedbackForms(newObj)
    } else if (name === "category") {
      if (value !== null && value !== "") {
        delete newObj.subCategory
        delete newObj.form
      }
      newObj.category = value
      fetchSubCategoryOptions(newObj)
      fetchFeedbackForms(newObj)
    } else if (name === "subCategory") {
      if (value !== null && value !== "") {
        delete newObj.form
      }
      newObj.subCategory = value
      fetchFeedbackForms(newObj)
    } else {
      newObj[name] = value
    }
    setFilterObject(newObj)
  }

  const handleExport = async (e) => {
    e.preventDefault();
    try {
       // Format filters to create query string
      const filterString = queryString.stringify({
        ...filterObject,
        // Add any additional parameters needed for export
        limit: 1000, // Use a larger limit for exports
      });

      // Fetch all data for export
      const exportDataInChunks = async () => {
        let currentPage = 1;
        let allExportData = [];
        let hasMoreData = true;

        while (hasMoreData) {
          // Fetch feedback data in chunks
          const response = await get(`feedback/log?${filterString}&page=${currentPage}&limit=1000`);

          if (!response.data || response.data.length === 0) {
            hasMoreData = false;
            break;
          }

          // Transform the chunk of feedback data for export
          const chunkExportData = response.data.map(item => ({
            Date: moment(item.date).format('DD-MM-YYYY'),
            Time: moment(item.time, "HH:mm:ss").format("hh:mm A"),
            UniqueId: item.uniqueId || 'N/A',
            Form: item.form || 'N/A',
            Branch: item.branch || 'N/A',
            Department: item.department || 'N/A',
            Category: item.category || 'N/A',
            'Sub Category': item.subCategory || 'N/A',
            Name: item.name || 'N/A',
            Email: item.email || 'N/A',
            Mobile: item.mobile || 'N/A',
            // 'Average Rating': item.avgRating || 'N/A'
            // Include any additional fields you want in export
          }));

          // Append chunk to total export data
          allExportData = [...allExportData, ...chunkExportData];
          
          // Go to next page
          currentPage++;
          
          // If we got less than 1000 records, we've reached the end
          if (response.data.length < 1000) {
            hasMoreData = false;
          }
        }

        // Create worksheet
        const worksheet = XLSX.utils.json_to_sheet(allExportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Feedback Log");
        
        // Export the file
        XLSX.writeFile(workbook, `feedback_export_${new Date().toISOString().split('T')[0]}.xlsx`);
      };

      // Execute chunk-based export
      await exportDataInChunks();
    } catch (error) {
      console.error('Error in exporting feedback data: ', error);
    }
  }

  const reset = () => {
    setFilterObject({})
    setSelectedFields({})
    fetchBranchOptions()
    fetchDepartmentOptions()
    fetchCategoryOptions()
    fetchSubCategoryOptions()
    fetchFeedbackForms()
    formRef.current.reset()
    setCurrentPage(1)
    fetchTableData(1, entriesPerPage)
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "index",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "UniqueId",
        field: "uniqueId",
        width: 150,
      },
      {
        label: "Form",
        field: "form",
        width: 150,
      },
      {
        label: "Branch",
        field: "branch",
        width: 150,
      },
      {
        label: "Department",
        field: "department",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        width: 150,
      },
      {
        label: "Mobile No.",
        field: "mobile",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        width: 150,
      },
    ],
    rows: tableData,
  }

  const today = moment().format("YYYY-MM-DD")

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Feedback Log" />
          <Card>
            <CardBody>
              <AvForm ref={formRef}>
                <Row>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>Branch</Label>
                      <Select
                        placeholder="Branch"
                        name="branch"
                        options={branchOptions}
                        onChange={e => handleSelectChange(e, "branch")}
                        value={selectedFields.branch || ""}
                        isClearable
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>Department</Label>
                      <Select
                        options={departmentOptions}
                        name="department"
                        placeholder="Department"
                        onChange={e => handleSelectChange(e, "department")}
                        value={selectedFields.department || ""}
                        isClearable
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>Feedback Form</Label>
                      <Select
                        name="form"
                        options={feedbackFormOption}
                        type="text"
                        placeholder="Feedback Form"
                        onChange={e => handleSelectChange(e, "form")}
                        value={selectedFields.form || ""}
                        isClearable
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>From</Label>
                      <AvField
                        style={customStyles}
                        name="fromDate"
                        type="date"
                        max={filterObject.fromDate ? filterObject.fromDate : today}
                        onChange={e => handleValueChange(e.target.value, "fromDate")}
                        value={filterObject.fromDate || ""}
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>To</Label>
                      <AvField
                        style={customStyles}
                        name="toDate"
                        type="date"
                        min={filterObject.fromDate ? filterObject.fromDate : null}
                        max={today}
                        onChange={e => handleValueChange(e.target.value, "toDate")}
                        value={filterObject.toDate || ""}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3" style={{ paddingTop: "28px" }}>
                      <Button color="danger" onClick={reset} className="me-2">
                        Reset
                      </Button>
                      <Button color="warning" onClick={handleExport}>
                        Export
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
              <Row>
                <Col className="col-12">
                  <MDBDataTable
                    id="feedbackLogTableId"
                    bordered
                    responsive
                    searching={true}
                    paging={false}  // Disable built-in pagination
                    data={data}
                  />

                  {/* Custom Pagination Component */}
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    totalItems={totalItems}
                    entriesPerPage={entriesPerPage}
                    onPageChange={handlePageChange}
                    onEntriesChange={handleEntriesChange}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>

      {popupView && (
        <Modal
          show={popupView}
          isOpen={popupView}
          toggle={() => setPopupView(!popupView)}
          size="lg"
          centered={true}
          className="lead-modal"
        >
          <div className="modal-header">
            <div className="modal-title">
              <h6 style={{ marginBottom: "0px" }} className="modal-title ">
                <h6>Feedback Details</h6>
              </h6>
            </div>
            <button
              style={{ marginBottom: "2px" }}
              type="button"
              onClick={handleCloseModal}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <Col md="12">
              <table className="table table-borderd lead-info table-striped">
                <tbody>
                  <tr>
                    <td>Date</td>
                    <th>: </th>
                    <th>{moment(modalData.date).format("DD-MM-YYYY")}</th>
                    <td>UniqueId</td>
                    <th>: </th>
                    <th>{modalData?.uniqueId}</th>
                  </tr>
                  <tr>
                    {modalData?.name && (
                      <>
                        <td>Name</td>
                        <th>: </th>
                        <th>{modalData?.name}</th>
                      </>
                    )}

                    {modalData?.mobile && (
                      <>
                        <td>Mobile</td>
                        <th>: </th>
                        <th>{modalData?.mobile}</th>
                      </>
                    )}
                  </tr>
                  {modalData?.email && (
                    <tr>
                      <td>Email</td>
                      <th>: </th>
                      <th>{modalData?.email}</th>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>

            <div>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th style={{ width: "120px", textAlign: "left" }}>Type</th>
                    <th style={{ width: "320px", textAlign: "left" }}>Question</th>
                    <th style={{ textAlign: "left" }}>Answer</th>
                    <th style={{ textAlign: "left" }}>Reason</th>
                  </tr>
                </thead>
                <tbody>
                  {modalData?.questions?.map((item, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "left" }}>
                        {item?.type === 0
                          ? "Yes/No"
                          : item.type === 1
                          ? "Star Rating"
                          : item.type === 2
                          ? "Emoji Rating"
                          : item.type === 3
                          ? "Text"
                          : ""}
                      </td>
                      <td style={{ textAlign: "left" }}>{item?.english}</td>
                      <td style={{ textAlign: "left" }}>{renderAnswer(item.type, item.answer)}</td>
                      <td style={{ textAlign: "left" }}>
                        {item?.reason !== "false" && item?.reason !== "true"
                          ? item.reason
                          : "-----"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default Latestfeedback