import { post } from "../helpers/api_helper"

const API_URL = process.env.REACT_APP_APIURL + 'leads'
const API_URL_USER = process.env.REACT_APP_APIURL

export const leadService = {

  getDepartments: async () => {
    try {
      const response = await fetch(`${API_URL_USER}options/lead-departments`);
      if (!response.ok) {
        console.error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching departments:', error);
      throw error;
    }
  },

  getDesignations: async () => {
    try {
      const response = await fetch(`${API_URL_USER}options/lead-designations`);
      if (!response.ok) {
        console.error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching designations:', error);
      throw error;
    }
  },

  getLeadTypes: async () => {
    try {
      const response = await fetch(`${API_URL_USER}options/lead-types`);
      if (!response.ok) {
        console.error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching lead types:', error);
      throw error;
    }
  },

  getAssignedTo: async () => {
    try {
      const response = await fetch(`${API_URL_USER}options/assign-to`);
      if (!response.ok) {
        console.error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching Staff:', error);
      throw error;
    }
  },

  getSource: async () => {
    try {
      const response = await fetch(`${API_URL_USER}options/source`);
      if (!response.ok) {
        console.error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching Source:', error);
      throw error;
    }
  },

  getCampaign: async () => {
    try {
      const response = await fetch(`${API_URL_USER}options/campaign`);
      if (!response.ok) {
        console.error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching Campaign:', error);
      throw error;
    }
  },

  getLeads: async (filters = {}) => {
    try {
      const mappedFilters = {
        page: filters.page || 1,
        limit: filters.limit || 10,
        // ...(filters.date && { date: filters.date }),
        // ...(filters.priority && { priority: filters.priority }),
        ...(filters.department && { department: filters.department }),
        ...(filters.level && { level: filters.level }),
        ...(filters.source && { source: filters.source }),
        ...(filters.staff && { staff: filters.staff }),
        ...(filters.campaign && { campaign: filters.campaign }),
        ...(filters.assignStatus && { assignStatus: filters.assignStatus }),
        ...(filters.leadStatus && { leadStatus: filters.leadStatus}),
        ...(filters.from && { from: filters.from }),
        ...(filters.to && { to: filters.to }),
        populate: 'true'
      };
  
      const queryParams = new URLSearchParams();

      Object.entries(mappedFilters)
        .filter(([_, value]) => value != null && value !== '')
        .forEach(([key, value]) => {
          queryParams.append(key, value);
        });

      const response = await fetch(`${API_URL}?${queryParams}`);
      
      if (!response.ok) {
        console.error(`HTTP error! status: ${response.status}`);
      }
      
      return await response.json();
    } catch (error) {
      console.error('Error fetching leads:', error);
      throw error;
    }
  },

  getLead: async (id) => {
    const response = await fetch(`${API_URL}/${id}`);
    return response.json();
  },

  createLead: async (leadData) => {
    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(leadData),
      });
      
      if (!response.ok) {
        console.error(`HTTP error! status: ${response.status}`);
      }
      
      return await response.json();
    } catch (error) {
      console.error('Error creating lead:', error);
      throw error;
    }
  },

  updateLead: async (id, data) => {
    const response = await fetch(`${API_URL}/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response.json();
  },

  deleteLead: async (id) => {
    const response = await fetch(`${API_URL}/${id}`, {
      method: 'DELETE',
    });
    return response.json();
  },

  assignLead: async (leadId, staffMemberId) => {
    try {
      const response = await fetch(`${API_URL}/${leadId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ assignTo: staffMemberId }),
      });
      
      if (!response.ok) {
        console.error(`HTTP error! status: ${response.status}`);
      }
      
      return await response.json();
    } catch (error) {
      console.error('Error assigning lead:', error);
      throw error;
    }
  },

  getTrashLeads: async (filters = {}) => {
    try {
      const mappedFilters = {
        page: filters.page || 1,
        limit: filters.limit || 10,
        ...(filters.department && { department: filters.department }),
        ...(filters.level && { level: filters.level }),
        ...(filters.source && { source: filters.source }),
        ...(filters.staff && { staff: filters.staff }),
        ...(filters.campaign && { campaign: filters.campaign }),
        ...(filters.assignStatus && { assignStatus: filters.assignStatus }),
        ...(filters.from && { from: filters.from }),
        ...(filters.to && { to: filters.to }),
        populate: 'true'
      };
  
      const queryParams = new URLSearchParams();
  
      Object.entries(mappedFilters)
        .filter(([_, value]) => value != null && value !== '')
        .forEach(([key, value]) => {
          queryParams.append(key, value);
        });
  
      // Make sure we're using the correct endpoint for trash
      const response = await fetch(`${API_URL}/trash?${queryParams}`);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      return await response.json();
    } catch (error) {
      console.error('Error fetching trash leads:', error);
      throw error;
    }
  },
  
  restoreLead: async (id) => {
    try {
      const response = await fetch(`${API_URL}/restore/${id}`, {
        method: 'PUT'
      });
      
      if (!response.ok) {
        console.error(`HTTP error! status: ${response.status}`);
      }
      
      return await response.json();
    } catch (error) {
      console.error('Error restoring lead:', error);
      throw error;
    }
  },

  checkDuplicateLead: async (data)=> {
    try {
      const response = await post(`${API_URL}/duplicate`, data);
      return response
    } catch (error) {
      console.error('Error in duplicating lead check:', error);
      throw error;
    }
  } 

};

export default leadService;