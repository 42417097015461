import React, { useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
import "./style.scss"
import GmbLogin from "./gmbLogin"
import FacebookLogin from "./FacebookLogin"
import InstagramLogin from "./InstagramLogin"

const ConnectButton = ({ status, onClick, index }) => {
  return (
    <Button
      className={`${status === 1 ? "bg-soft-success" : "bg-soft-danger"}`}
      color={status === 1 ? "success" : "danger"}
      onClick={() => onClick(index)}
    >
      {status === 1 ? "Connected" : "Disconnected"}
    </Button>
  )
}
function SocialConfig() {
  const [buttonStatus, setButtonStatus] = useState([1, 1, 1, 1, 1])

  const handleButtonStatusChange = index => {
    const newStatus = buttonStatus[index] === 1 ? 0 : 1
    const arrayStatus = [...buttonStatus]
    arrayStatus[index] = newStatus
    setButtonStatus(arrayStatus)
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Social Configuration" />
          <Row>
            <Col md="12">
              <GmbLogin />
            </Col>
            <Col className="col-12">
              <FacebookLogin />
            </Col>
            <Col md="12">
              <InstagramLogin />
            </Col>
            <Col md="12">
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-3 p-2">
                      <img src="/sm-x.svg" width="40" alt="logo" />
                      <h6 className="m-0">Twitter</h6>
                    </div>
                    <div>
                      <ConnectButton
                        index={2}
                        status={buttonStatus[2]}
                        onClick={handleButtonStatusChange}
                      />
                    </div>
                  </div>
                  <div>
                    <span className="fw-bold font-size-14">Rizin Srv </span>
                    <span> Last Synced : 12.48 pm</span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="12">
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-3 p-2">
                      <img src="/sm-linkedin.svg" width="40" alt="logo" />
                      <h6 className="m-0">LinkedIn</h6>
                    </div>
                    <div>
                      <ConnectButton
                        index={4}
                        status={buttonStatus[4]}
                        onClick={handleButtonStatusChange}
                      />
                    </div>
                  </div>
                  <div className="d-flex gap-2">
                    <span className="fw-bold font-size-14">Rizin Srv </span>
                    <div>
                      {" "}
                      Last Synced : <span className="fw-bold">12.48 pm</span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default SocialConfig
