import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
// import Reviewbar from './Reviewbar'

import stars from "../../../assets/fotos/Star 1.svg"
import calender from "../../../assets/fotos/Group 44.svg"
// import srv1 from "../../../assets/fotos/Rectangle 7.svg"
// import srv2 from "../../../assets/fotos/Rectangle 8.svg"
// import srv3 from "../../../assets/fotos/Rectangle 9.svg"
// import playstore from "../../../assets/fotos/Group.svg"
import google from "../../../assets/fotos/Group 8.svg"
// import apple from "../../../assets/fotos/_Group_3.svg"
// import business from "../../../assets/fotos/Group 31.svg"
// import bing from "../../../assets/fotos/Group 35.svg"
// import comment from "../../../assets/fotos/Group 36.svg"
// import star from "../../../assets/fotos/Group 37.svg"

// import { Button } from "reactstrap"
import { Link, useLocation, useNavigate } from "react-router-dom"
import Dropdown from "react-bootstrap/Dropdown"
import moment from "moment"
import toastr from "toastr"
import _ from "lodash"
import { post, put } from "../../../helpers/api_helper"

const replyData = [
  {
    id: 0,
    name: "We truly appreciate your feedback!",
  },
  {
    id: 1,
    name: "We apologize for the inconvenience caused. We'll address it right away.",
  },
  {
    id: 2,
    name: "It's wonderful to hear that! Thank you for sharing your thoughts.",
  },
  {
    id: 3,
    name: "We're thrilled that you're satisfied. Thanks for letting us know!",
  },
  {
    id: 4,
    name: "Thank you for your kind words! We're always here to assist you.",
  },
  {
    id: 5,
    name: "We regret any trouble this may have caused. Please reach out for assistance.",
  },
  {
    id: 6,
    name: "We’re grateful for your positive review. It means a lot to us!",
  },
  {
    id: 7,
    name: "Our sincerest apologies for the inconvenience. We’ll make it right.",
  },
  {
    id: 8,
    name: "Thank you for taking the time to share your experience with us!",
  },
  {
    id: 9,
    name: "We value your feedback and will work on improving your experience.",
  },
  {
    id: 10,
    name: "Thank you for your trust in us. We're here to serve you better!",
  },
]

function Reviewreply() {
  const location = useLocation()

  const [master, setMaster] = useState({})
  
  const navigate = useNavigate()
  
  const reviewData = location.state

  useEffect(() => {
    setMaster({ id: reviewData._id, comment: "" })
    
  }, [reviewData])

  const customStyles1 = {
    // borderColor: "#e5e5e5",
    height: "auto",
    border: "1px solid #ced4da",
  }

  const [open, setOpen] = useState(1)
  function openClick(num) {
    setOpen(num)
  }

  function handleClick(comment) {
    setMaster(pre => ({ ...pre, comment }))
  }

  const handleChange = e => {
    const { name, value } = e.target
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (!master.comment) {
      toastr.error("Please enter comment")
      return
    }

    post(`google/reviews/reply`, master)
      .then(res => {
        console.log(res)
        toastr.success(res.message)
      })
      .catch(err => {
        console.log(err?.response?.data)
      })
  }

  const [selectedSentiment, setSelectedSentiment] = useState("Positive")

  const handleSelectSentiment = eventKey => {
    put(`google/reviews/sentiment`, {
      id: reviewData._id,
      sentiment: eventKey,
    })
      .then(res => {
        setSelectedSentiment(eventKey)
        toastr.success(res.message)
      })
      .catch(err => {
        console.log(err?.response?.data)
        toastr.error(err?.response?.data?.message || "Something went wrong")
      })
  }

  console.log(master)
  return (
    <div>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Reviews" />

          {/* <div className='reviews'>
                            <ul className='platforms' >

                                <li onClick={() => openClick(1)} className={`${open === 1 ? "active" : ""}`}>
                                    <div>
                                        <span className='fa fa-globe'></span>
                                        <span className='mainfont'>All</span>

                                        <ul className='ikon'>

                                            <li>
                                                <img className='subikon' src={comment} alt="" /> 371
                                            </li>
                                            <li><img className='subikon' src={star} alt="" /> 4.9
                                            </li>
                                        </ul>
                                    </div>
                                </li>

                                <li onClick={() => openClick(2)} className={`${open === 2 ? "active" : ""}`}>
                                    <div >


                                        <span className='fa'><img style={{ height: "20px" }} src={google} alt="" /></span>
                                        <span className='mainfont'>Google Reviews</span>


                                        <ul className='ikon'>

                                            <li>
                                                <img className='subikon' src={comment} alt="" /> 371
                                            </li>
                                            <li><img className='subikon' src={star} alt="" /> 4.9
                                            </li>
                                        </ul>


                                    </div>
                                </li>

                                <li onClick={() => openClick(3)} className={`${open === 3 ? "active" : ""}`}>
                                    <div >
                                        <span className='fa'><img style={{ height: "20px" }} src={playstore} alt="" /></span>
                                        <span className='mainfont'>Play Store</span>


                                        <ul className='ikon'>

                                            <li>
                                                <img className='subikon' src={comment} alt="" /> 371
                                            </li>
                                            <li><img className='subikon' src={star} alt="" /> 4.9
                                            </li>
                                        </ul>


                                    </div>
                                </li>

                                <li onClick={() => openClick(4)} className={`${open === 4 ? "active" : ""}`}>
                                    <div >
                                        <span className='fa'><img style={{ height: "20px" }} src={apple} alt="" /></span>
                                        <span className='mainfont'>App Store</span>


                                        <ul className='ikon'>

                                            <li>
                                                <img className='subikon' src={comment} alt="" /> 371
                                            </li>
                                            <li><img className='subikon' src={star} alt="" /> 4.9
                                            </li>
                                        </ul>


                                    </div>
                                </li>

                                <li onClick={() => openClick(5)} className={`${open === 5 ? "active" : ""}`}>
                                    <div >
                                        <span className='fa'><img style={{ height: "20px" }} src={business} alt="" /></span>
                                        <span className='mainfont'>Google Business</span>


                                        <ul className='ikon'>

                                            <li>
                                                <img className='subikon' src={comment} alt="" /> 371
                                            </li>
                                            <li><img className='subikon' src={star} alt="" /> 4.9
                                            </li>
                                        </ul>


                                    </div>
                                </li>

                                <li onClick={() => openClick(6)} className={`${open === 6 ? "active" : ""}`}>
                                    <div>
                                        <span className='fa'><img style={{ height: "20px" }} src={bing} alt="" /></span>
                                        <span className='mainfont'>Bing</span>


                                        <ul className='ikon'>

                                            <li>
                                                <img className='subikon' src={comment} alt="" /> 371
                                            </li>
                                            <li><img className='subikon' src={star} alt="" /> 4.9
                                            </li>
                                        </ul>


                                    </div>
                                </li>

                            </ul>



                            <div className='reviewbar '>

                                <Dropdown className='dropdown0'>
                                    <Dropdown.Toggle className='Dropdown1' style={{ backgroundColor: "white", color: "#1a71b5" }} variant="info" id="dropdown-basic">
                                        <span class="fa fa-angle-down"></span> Recent First
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                                <div class="input-group" style={{ width: "auto" }}>
                                    <input type="text" className='form-control' placeholder='Search For Reviews.' />
                                    <Button color="info" type="submit" style={{ backgroundColor: "#1a71b5" }}>
                                        Search
                                    </Button>
                                </div>




                                <div className='revnextpage' >

                                    <div class="pagination">
                                        <Link to="">&laquo;</Link>
                                        <Link to="" href="#">1</Link>
                                        <Link to="" class="nextpage2" href="#">2</Link>
                                        <Link to="" href="#" className='nextcontinue'>....</Link>
                                        <Link to="" href="#">20</Link>
                                        <Link to="" href="#">&raquo;</Link>
                                    </div>


                                </div>



                                <div className='revpagecontent'>
                                    page <div>0</div> of  20
                                </div>

                                <div className='revpagelist' >
                                    <div>Show items</div>

                                    <Dropdown className=''>
                                        <Dropdown.Toggle className='Dropdown1' style={{ backgroundColor: "white", color: "#1a71b5" }} variant="info" id="dropdown-basic">
                                            <span class="fa fa-angle-down"></span> 0
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>


                                </div>


                            </div>







                        </div> */}

          <div className="reviewbox">
            <div className="revlist revlist1">
              <div className="revhead">
                <div className="captions">
                  <div className="profile">
                    <span class="fab ">{reviewData?.reviewerName[0].toUpperCase()}</span>
                    <i>
                      <img src={google} alt="" />
                    </i>
                  </div>

                  <div className="revname">
                    <div className="revtitle">{reviewData.reviewerName}</div>
                    {/* <span>20 reviews 3 photos</span> */}
                    <div>
                      {Array.from({ length: reviewData.rating }, (_, index) => (
                        <img
                          style={{ height: "18px", paddingBottom: "4px" }}
                          key={index}
                          src={stars}
                          alt=""
                        />
                      ))}
                      {/* <img src={stars} alt="" /> */}
                    </div>
                  </div>
                </div>

                <div className="date">
                  <span class="fa ">
                    {" "}
                    <img src={calender} alt="" />{" "}
                  </span>

                  {moment(reviewData?.createTime, "YYYY-MM-DD HH:mm:ss").format(
                    "DD/MM/YYYY, hh:mm A"
                  )}

                  <div>
                    <Dropdown
                      onSelect={eventKey => {
                        handleSelectSentiment(eventKey)
                      }}
                    >
                      <Dropdown.Toggle
                        className="butn1 dropdown1"
                        style={{ backgroundColor: "white", color: "#1a71b5" }}
                        variant="info"
                        id="dropdown-basic"
                      >
                        {selectedSentiment && _.capitalize(selectedSentiment)}{" "}
                        <span className="fa fa-angle-down"></span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item eventKey="neutral">Neutral</Dropdown.Item>
                        <Dropdown.Item eventKey="positive">Positive</Dropdown.Item>
                        <Dropdown.Item eventKey="negative">Negative</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="revcontent">
                <div>{reviewData?.content}</div>
                {/* 
                <div className="revimage">
                  <img className="revimages" src={srv1} alt="" />
                  <img className="revimages" src={srv2} alt="" />
                  <img className="revimages" src={srv3} alt="" />
                </div> */}
              </div>

              <div className="revfooter revfooter1">
                <div className="revtool"></div>
                {/* <div className="revtool">
                  <div>
                    {" "}
                    <span className="far fa-thumbs-up"></span> Like
                  </div>
                  <div>
                    <span className="far fa-edit"></span> Edit
                  </div>
                </div> */}

                <div className="revtool">
                  <div>
                    <span className="fas fa-plus-circle"></span> Lead
                  </div>
                  <div>
                    <span className="fas fa-plus-circle"></span> Response
                  </div>
                </div>
              </div>
            </div>

            {/*  */}
            <div className="revreplyarea">
              <form onSubmit={handleSubmit} method="post">
                <textarea
                  value={master?.comment || ""}
                  onChange={handleChange}
                  style={customStyles1}
                  rows={5}
                  className="form-control"
                  placeholder="Reply.."
                  name="comment"
                  id=""
                ></textarea>
                <div style={{ color: "#666666bd", marginTop: "5px" }}>
                  Respond to review with a suggested reply. You can edit your reply before sending.
                </div>

                <div className="replybutton">
                  <button
                    type="button"
                    className="replybutn"
                    onClick={() => {
                      navigate(-1)
                    }}
                  >
                    Discard
                  </button>
                  <button type="submit" className="replybutn1">
                    Publish Reply
                  </button>
                </div>
              </form>
              {/* {reviewData?.followreviewReply?.comment && (
                <ul className="revreplycontent">
                  <li>
                    <button onClick={() => handleClick(reviewData?.reviewReply?.comment)} className="replycontent">
                      <span className="replyicon">
                        <i className="uil-plus"></i>
                      </span>
                      <span>{reviewData?.reviewReply?.comment}</span>
                    </button>
                  </li>
                </ul>
              )} */}
{reviewData?.follow_up && (
               <ul className="revreplycontent">
                {reviewData?.follow_up.map(item => (
                  <li>
                    <button onClick={e => handleClick(item.comments)} className="replycontent">
                      <span className="replyicon">
                        <i className="uil-plus"></i>
                      </span>
                      <span>{item.comments}</span>
                    </button>
                  </li>
                ))}
              </ul>)}


              <ul className="revreplycontent">
                {replyData.map(item => (
                  <li>
                    <button onClick={e => handleClick(item.name)} className="replycontent">
                      <span className="replyicon">
                        <i className="uil-plus"></i>
                      </span>
                      <span>{item.name}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reviewreply
