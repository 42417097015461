import * as action from "./actionTypes"

export const getPrivileges = data => {
  return {
    type: action.GET_PRIVILEGES,
    payload: data,
  }
}

export const getPrivilegesSuccessful = data => {
  return {
    type: action.GET_PRIVILEGES_SUCCESS,
    payload: data.data,
  }
}

export const getModules = data => {
  return {
    type: action.GET_MODULES,
    payload: data,
  }
}

export const getModulesSuccessful = data => {
  return {
    type: action.GET_MODULES_SUCCESS,
    payload: data.data,
  }
}

export const getUsersOptions = data => {
  return {
    type: action.GET_USERS,
    payload: data,
  }
}

export const getUsersSuccessful = data => {
  return {
    type: action.GET_USERS_SUCCESS,
    payload: data.user,
  }
}

export const getStatesOptions = data => {
  return {
    type: action.GET_STATES,
    payload: data,
  }
}

export const getStatesSuccessful = data => {
  return {
    type: action.GET_STATES_SUCCESS,
    payload: data.states,
  }
}
export const getCountriesOptions = data => {
  return {
    type: action.GET_COUNTRIES,
    payload: data,
  }
}

export const getCountriesSuccessful = data => {
  return {
    type: action.GET_COUNTRIES_SUCCESS,
    payload: data.countries,
  }
}

export const getDistrictOptions = data => {
  return {
    type: action.GET_DISTRICTS,
    payload: data,
  }
}

export const getDistrictSuccessful = data => {
  return {
    type: action.GET_DISTRICTS_SUCCESS,
    payload: data.districts,
  }
}

export const getCompaniesOptions = data => {
  return {
    type: action.GET_COMPANIES,
    payload: data,
  }
}

export const getCompaniesSuccessful = data => {
  return {
    type: action.GET_COMPANIES_SUCCESS,
    payload: data.companies,
  }
}

export const getBranches = data => {
  return {
    type: action.GET_BRANCHES,
    payload: data,
  }
}

export const getBranchesSuccessful = data => {
  return {
    type: action.GET_BRANCHES_SUCCESS,
    payload: data.branches,
  }
}
export const getCategory = data => {
  return {
    type: action.GET_CATEGORY,
    payload: data,
  }
}

export const getCategorySuccessful = data => {
  return {
    type: action.GET_CATEGORY_SUCCESS,
    payload: data.categories,
  }
}
