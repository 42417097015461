import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import followupService from '../../../services/followupService.js';
import { MDBDataTable } from 'mdbreact';
import moment from 'moment';
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import classnames from 'classnames';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toastr from "toastr"
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Breadcrumbs from "../../../components/Common/Breadcrumb.js"
import Pagination from '../../Leads/Pagination.jsx'
import './Followup.scss';

const FollowUps = () => {
  const API_URL = process.env.REACT_APP_APIURL;

  const { id } = useParams();
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalLeads: 0
  });

  const [selectedLead, setSelectedLead] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [sources, setSources] = useState([]);
  const [assignedTo, setAssignedTo] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  const [tableLoading, setTableLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [historyData, setHistoryData] = useState([]);
  const [formDataHistory, setFormDataHistory] = useState({
    status: null,
    nextFollowup: '',
    nextFollowupTime: '',
    comments: ''
  });
  const [selectedLeadId, setSelectedLeadId] = useState(null);
  const [debouncedFilters, setDebouncedFilters] = useState({
    name: '',
    mobileNo: ''
  });
  const [activeTab, setActiveTab] = useState('today');
  const [allFollowups, setAllFollowups] = useState({
    today: [],
    pending: [],
    upcoming: []
  });

  const [hasInitialDataLoaded, setHasInitialDataLoaded] = useState(false);

  // Constants and Options
  const levelOptions = [
    { value: 'P', label: 'P' },
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' }
  ];

  const assignStatusOptions = [
    { value: 'assigned', label: 'Assigned'},
    { value: 'not_assigned', label: 'Not Assigned'}
  ];

  const statusOptions = [
    "Call Later",
    "Wrong Number",
    "Message Sent",
    "Not Responding",
    "Interested",
    "Not Interested"
  ];

  const COMPLETION_STATUSES = ['Interested'];

  const initialFormState = {
    mobileNo: '',
    name: '',
    email: '',
    leadSource: '',
    department: '',
    designation: '',
    leadType: '',
    level: '',
    priority: '',
    address: '',
    followUpDate: '',
    followUpTime: '',
    comments: '',
    campaign: '',
    assignTo: ''
  };
  const [filters, setFilters] = useState({
    name: '',
    mobileNo: '',
    from: '',
    to: '',
    level: null,
    department: null,
    assignStatus: null,
    source: null,
    staff: null,
    status: null,
    page: 1,
    limit: 10
  });
  const [formData, setFormData] = useState(initialFormState);
  const [tableData, setTableData] = useState({
    columns: [
      {
        label: '#',
        field: 'index',
        sort: 'asc',
        width: 50
      },
      {
        label: 'Date',
        field: 'date',
        sort: 'asc',
        width: 150
      },
      {
        label: 'ID',
        field: 'uniqueId',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Mobile',
        field: 'mobile',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Department',
        field: 'department',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Level',
        field: 'level',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Source',
        field: 'source',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Assign To',
        field: 'assignTo',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Campaign',
        field: 'campaign',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Actions',
        field: 'actions',
        sort: false,
        width: 100
      }
    ],
    rows: []
  });

  const statusSelectOptions = statusOptions.map(status => ({
    value: status,
    label: status
  }));

  const formatTimeFor12Hour = (time24) => {
    if (!time24) return 'N/A';
    
    // Handle case where time includes seconds
    const [hours24, minutes, seconds] = time24.split(':');
    const hours = parseInt(hours24, 10);
    const period = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours;
    
    return `${hours12}:${minutes} ${period}`;
  };

  useEffect(() => {
      const fetchDropdownData = async () => {
        setIsLoading(true);
        setFetchError(null);
        try {
          const [deptResponse, desigResponse, assignResponse, sourceResponse] = await Promise.all([
            followupService.getDepartments(),
            followupService.getDesignations(),
            followupService.getAssignedTo(),
            followupService.getSource()
          ]);
  
         // Transform responses to react-select format
         const formatOptions = (data, labelKey = 'name') => {
          if (!Array.isArray(data)) return [];
          return data.map(item => ({
            value: item._id,
            label: item[labelKey],
            ...item
          }));
        };
  
        setDepartments(formatOptions(deptResponse.data || deptResponse));
        setDesignations(formatOptions(desigResponse.data || desigResponse));
        setAssignedTo(formatOptions(assignResponse.data || assignResponse, 'label'));
        setSources(formatOptions(sourceResponse.data || sourceResponse));
  
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
        setFetchError('Failed to load form data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchDropdownData();
  }, []);

  useEffect(() => {
    // Add animation class after mount
    const timer = setTimeout(() => {
      setModalVisible(true);
    }, 50);

    return () => clearTimeout(timer);
  }, []);

  const handleAssignTo = async (leadId, staffMemberId) => {
    try {
      setTableLoading(true); // Use table loading instead of general loading
  
      const response = await followupService.updateAssignment(leadId, staffMemberId);
      
      if (response.success) {
        // Fetch the updated data instead of trying to update local state
        await fetchAllFollowups(filters);
        toastr.success("Lead assigned successfully");
      } else {
        toastr.error(response.message || "Failed to assign lead");
      }
    } catch (error) {
      console.error('Error assigning lead:', error);
      toastr.error("Failed to assign lead");
    } finally {
      setTableLoading(false);
    }
  };

  const handleView = async (leadId) => {
    try {
      const response = await followupService.getFollowupHistory(leadId);
      if (response.success) {
        setSelectedLead(response.lead);
        setSelectedLeadId(leadId);
        setHistoryData(response.history);
        setModalVisible(true);
      } else {
        toastr.error('Error loading lead details');
      }
    } catch (error) {
      console.error('Error fetching lead details:', error);
      toastr.error('Error loading lead details');
    }
  };

  // useEffect(() => {
  //   if (selectedLeadId) {
  //     fetchHistory(selectedLeadId);
  //   }
  // }, [selectedLeadId]);

  const fetchHistory = async (leadId) => {
    try {
      const response = await fetch(`${API_URL}followups/history/${leadId}`);
      const data = await response.json();
      if (data.success) {
        setHistoryData(data.history);
      }
    } catch (error) {
      console.error('Error fetching history:', error);
    }
  };

  const fetchLead = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_URL}leads/${id}`);
      const data = await response.json();
      setFormData(data);
    } catch (error) {
      console.error('Error fetching lead:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllFollowups = useCallback(async (filterParams = {}) => {
    // if (hasInitialDataLoaded) return; // Don't fetch if data is already loaded

    try {
      setTableLoading(true);
      setError(null);

      const apiFilters = {
        page: filterParams.page || 1,
        limit: filterParams.limit || 10,
        name: filterParams.name?.trim() || '',
        mobileNo: filterParams.mobileNo?.trim() || '',
        from: filterParams.from || '',
        to: filterParams.to || '',
        level: filterParams.level?.value || '',
        department: filterParams.department?.value || '',
        source: filterParams.source?.value || '',
        staff: filterParams.staff?.value || '',
        assignStatus: filterParams.assignStatus?.value || '',
        status: filterParams.status?.value || '',
        type: activeTab,
        populate: 'true'
      };

      // Fetch all three types in parallel
      const types = ['today', 'pending', 'upcoming'];
      const responses = await Promise.all(
        types.map(type =>
          followupService.getFollowUps({
            ...apiFilters,
            type
          })
        )
      );

      // Process responses and store formatted data for each type
      const formattedData = {};
      types.forEach((type, index) => {
        const response = responses[index];
        if (!response || !response.leads) {
          throw new Error(`Invalid response format for ${type}`);
        }

        const startIndex = (response.currentPage - 1) * apiFilters.limit;
        const formattedRows = response.leads.map((lead, idx) => ({
          index: startIndex + idx + 1,
          date: moment(lead.createdAt).format('DD/MM/YYYY'),
          uniqueId: lead.uniqueId,
          time: lead.followUpTime || 'N/A',
          name: lead.name,
          mobile: lead.mobileNo || 'N/A',
          department: lead.department?.name || 'N/A',
          level: lead.level || 'N/A',
          source: lead.leadSource?.displayName || 'N/A',
          assignTo: (
            <select
              name="assignTo"
              value={lead.assignTo?._id || ''}
              onChange={(e) => handleAssignTo(lead._id, e.target.value)}
              className="form-select"
            >
              <option value="">Select...</option>
              {Array.isArray(assignedTo) &&
                assignedTo.map(assign => {
                  const displayName = [assign.firstName, assign.lastName]
                  .filter(Boolean)
                  .join(' ')
                  .trim() || assign.label || 'Unknown';

                  return (
                    <option key={assign._id} value={assign._id}>
                      {displayName}
                    </option>
                  )
              })}
            </select>
          ),
          campaign: lead.campaign?.name || 'N/A',
          actions: (
            <div className="d-flex justify-content-around align-items-center">
              <button
                onClick={() => handleView(lead._id)}
                className="btn btn-link text-primary p-1"
                title="Follow Up"
              >
                <FontAwesomeIcon icon={faPlus} color='#3D3D3D' />
              </button>
            </div>
          )
        }));

        formattedData[type] = {
          rows: formattedRows,
          pagination: {
            currentPage: response.currentPage,
            totalPages: response.totalPages,
            totalLeads: response.totalLeads
          }
        };
      });

      setAllFollowups(formattedData);
      setHasInitialDataLoaded(true);

      // Set initial data for active tab
      setTableData(prev => ({
        ...prev,
        rows: formattedData[activeTab].rows
      }));

      setPagination(formattedData[activeTab].pagination);

    } catch (error) {
      console.error('Error fetching leads:', error);
      setError('Failed to fetch leads data');
      toastr.error('Error loading leads');
    } finally {
      setTableLoading(false);
    }
  }, [assignedTo, activeTab]);

  const toggleTab = (tab) => {
    if (activeTab !== tab && allFollowups[tab]) {
      setActiveTab(tab);
      setTableData(prev => ({
        ...prev,
        rows: allFollowups[tab].rows
      }));
      setPagination(allFollowups[tab].pagination);
    }
  };

  // useEffect(() => {
  //   if (assignedTo.length > 0 && !id) {
  //     fetchAllFollowups(filters);
  //   }
  // }, [assignedTo, id, fetchAllFollowups]);

  useEffect(() => {
    if (assignedTo.length > 0 && !id && !hasInitialDataLoaded) {
      fetchAllFollowups(filters);
    }
  }, [assignedTo, id, hasInitialDataLoaded, fetchAllFollowups]);

  useEffect(() => {
      if(id) {
        fetchLead();
      }
    }, [id]);

    const handleSubmitHistory = async (e) => {
      e.preventDefault();
      if (!selectedLeadId) {
        console.error('No lead selected');
        return;
      }
      setLoading(true);
  
      try {
        const response = await fetch(`${API_URL}followups/history/${selectedLeadId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ...formDataHistory,
            status: formDataHistory.status?.value || '', // Extract value from select option
            isCompleted: COMPLETION_STATUSES.includes(formDataHistory.status?.value)
          })
        });
  
        const data = await response.json();
        
        if (data.success) {
          // Reset form
          setFormDataHistory({
            status: null,
            nextFollowup: '',
            nextFollowupTime: '',
            comments: ''
          });
          
          // Refresh history
          await fetchHistory(selectedLeadId);
          
          setHasInitialDataLoaded(false);
          await fetchAllFollowups(filters);
        
          toastr.success('Follow-up history added successfully');
          handleCloseModal();
        }
      } catch (error) {
        console.error('Error adding follow-up:', error);
      } finally {
        setLoading(false);
      }
    };

    const handleResetHistory = () => {
      setFormDataHistory({
        status: null,
        nextFollowup: '',
        nextFollowupTime: '',
        comments: ''
      });
    };

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        if (filters.name !== debouncedFilters.name || filters.mobileNo !== debouncedFilters.mobileNo) {
          fetchAllFollowups({
            ...filters,
            type: activeTab,
            name: filters.name,
            mobileNo: filters.mobileNo
          });
        }
      }, 500); // 500ms delay
    
      return () => clearTimeout(timeoutId);
    }, [filters.name, filters.mobileNo, activeTab]);

    const handleFilterChange = (e) => {
      const { name, value } = e.target;
      
      // If it's name or mobileNo, only update the filters state
      if (name === 'name' || name === 'mobileNo') {
        setFilters(prev => ({
          ...prev,
          [name]: value,
          page: 1
        }));
      } else {
        // For other filters, update and fetch immediately
        const updatedFilters = {
          ...filters,
          [name]: value,
          page: 1
        };
        setFilters(updatedFilters);
        setHasInitialDataLoaded(false);
        fetchAllFollowups({
          ...updatedFilters,
          type: activeTab
        });
      }
    };

  // Add this useEffect to fetch initial data
    // useEffect(() => {
    //   if (assignedTo.length > 0) {
    //     fetchLeads();
    //   }
    // }, [assignedTo, fetchLeads]);

    const handleFilterSelectChange = async (name, selectedOption) => {
      const updatedFilters = {
          ...filters,
          [name]: selectedOption,
          page: 1
      };

      setFilters(updatedFilters);
      setHasInitialDataLoaded(false);
      // await fetchAllFollowups({
      //   ...updatedFilters,
      //   type: activeTab
      // });
    };

    const handleCloseModal = () => {
      setModalVisible(false);
      setSelectedLead(null);
      setSelectedLeadId(null);
    };

    const handleFilterReset = async () => {
      const initialFilters = {
        name: '',
        mobileNo: '',
        from: '',
        to: '',
        level: null,
        department: null,
        source: null,
        staff: null,
        assignStatus: null,
        status: null,
        page: 1,
        limit: 10
      };
      
      setFilters(initialFilters);
      setHasInitialDataLoaded(false);
      // await fetchAllFollowups({
      //   ...initialFilters,
      //   type: activeTab
      // });
    };
    
    const renderFilters = () => (
      <Form className="mb-4">
        <Row className="g-3">
          <Col md={12} className="mb-3">
            <Row className="g-3">
              <Col md={2}>
                <FormGroup>
                  <Label className="form-label">From</Label>
                  <Input
                    type="date"
                    name="from"
                    value={filters.from}
                    onChange={handleFilterChange}
                    className="select-sm"
                  />
                </FormGroup>
              </Col>
    
              <Col md={2}>
                <FormGroup>
                  <Label className="form-label">To</Label>
                  <Input
                    type="date"
                    name="to"
                    value={filters.to}
                    onChange={handleFilterChange}
                    className="select-sm"
                  />
                </FormGroup>
              </Col>

              <Col md={3}>
                <FormGroup>
                  <Label className="form-label">Name</Label>
                  <Input
                    type="text"
                    name="name"
                    value={filters.name}
                    onChange={handleFilterChange}
                    placeholder="Enter name"
                  />
                </FormGroup>
              </Col>

              <Col md={3}>
                <FormGroup>
                  <Label className="form-label">Mobile No.</Label>
                  <Input
                    type="text"
                    name="mobileNo"
                    value={filters.mobileNo}
                    onChange={handleFilterChange}
                    placeholder="Enter mobile number"
                  />
                </FormGroup>
              </Col>
    
              <Col md={2}>
                <FormGroup>
                  <Label className="form-label">Level</Label>
                  <Select
                    name="level"
                    value={filters.level}
                    options={levelOptions}
                    onChange={(option) => handleFilterSelectChange('level', option)}
                    isClearable
                    placeholder="Select..."
                    className="select-sm"
                  />
                </FormGroup>
              </Col>
    
              <Col md={2}>
                <FormGroup>
                  <Label className="form-label">Department</Label>
                  <Select
                    name="department"
                    value={filters.department}
                    options={departments}
                    onChange={(option) => handleFilterSelectChange('department', option)}
                    isClearable
                    isLoading={isLoading}
                    placeholder="Select..."
                    className="select-sm"
                  />
                </FormGroup>
              </Col>
    
              <Col md={2}>
                <FormGroup>
                  <Label className="form-label">Source</Label>
                  <Select
                    name="source"
                    value={filters.source}
                    options={sources}
                    onChange={(option) => handleFilterSelectChange('source', option)}
                    isClearable
                    placeholder="Select..."
                    className="select-sm"
                  />
                </FormGroup>
              </Col>
    
              <Col md={2}>
                <FormGroup>
                  <Label className="form-label">Staff</Label>
                  <Select
                    name="staff"
                    value={filters.staff}
                    options={assignedTo}
                    onChange={(option) => handleFilterSelectChange('staff', option)}
                    isClearable
                    isLoading={isLoading}
                    placeholder="Select..."
                    className="select-sm"
                  />
                </FormGroup>
              </Col>

              <Col md={2}>
                <FormGroup>
                  <Label className="form-label">Assign Status</Label>
                  <Select
                    name="assignStatus"
                    value={filters.assignStatus}
                    options={assignStatusOptions}
                    onChange={(option) => handleFilterSelectChange('assignStatus', option)}
                    isClearable
                    placeholder="Select..."
                    className="select-sm"
                  />
                </FormGroup>
              </Col>

              <Col md={2}>
                <FormGroup>
                  <Label className="form-label">Lead Status</Label>
                  <Select
                    name="status"
                    value={filters.status}
                    options={statusSelectOptions}
                    onChange={(option) => handleFilterSelectChange('status', option)}
                    isClearable
                    placeholder="Select..."
                    className="select-sm"
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md={12}>
            <div className="d-flex gap-2 mb-3">
              <Button
                color="danger"
                size="btn-sm"
                onClick={handleFilterReset}
              >
                Reset
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    );

    const renderModal = () => (
      <Modal
        isOpen={modalVisible && selectedLead !== null}
        toggle={handleCloseModal}
        size="xl"
        className="lead-details-modal"
        style={{ maxWidth: '1400px', width: '95%' }}
        scrollable
      >
        <ModalHeader toggle={handleCloseModal} className="bg-light text-white">
          <h5 className="mb-0">Follow Up Details - {selectedLead?.name}</h5>
        </ModalHeader>
        <ModalBody className="bg-light">
          {selectedLead && (
            <>
            <Card className="shadow-sm">
              <CardBody className="p-4">
                <Row className="g-4">
                  {/* Personal Information Section */}
                  <Col md={4}>
                    <h6 className="fw-bold mb-3 text-primary">Personal Information</h6>
                    <div className="details-section">
                      <Row className="mb-1 py-1 border-bottom">
                        <Col sm={4} className="text-muted fw-bold">ID:</Col>
                        <Col sm={8} className="text-dark">{selectedLead.uniqueId}</Col>
                      </Row>
                      <Row className="mb-1 py-1 border-bottom">
                        <Col sm={4} className="text-muted fw-bold">Name:</Col>
                        <Col sm={8} className="text-dark">{selectedLead.name}</Col>
                      </Row>
                      <Row className="mb-1 py-1 border-bottom">
                        <Col sm={4} className="text-muted fw-bold">Mobile:</Col>
                        <Col sm={8} className="text-dark">{selectedLead.mobileNo || 'N/A'}</Col>
                      </Row>
                      <Row className="mb-1 py-1 border-bottom">
                        <Col sm={4} className="text-muted fw-bold">Email:</Col>
                        <Col sm={8} className="text-dark">{selectedLead.email || '* Not Provided *'}</Col>
                      </Row>
                      <Row className="mb-0 py-1">
                        <Col sm={4} className="text-muted fw-bold">Address:</Col>
                        <Col sm={8} className="text-dark">{selectedLead.address || 'N/A'}</Col>
                      </Row>
                    </div>
                  </Col>
    
                  {/* Lead Information Section */}
                  <Col md={4}>
                    <h6 className="fw-bold mb-3 text-primary">Lead Information</h6>
                    <div className="details-section">
                      <Row className="mb-1 py-1 border-bottom">
                        <Col sm={4} className="text-muted fw-bold">Lead Source:</Col>
                        <Col sm={8} className="text-dark">{selectedLead.leadSource?.displayName || 'N/A'}</Col>
                      </Row>
                      <Row className="mb-1 py-1 border-bottom">
                        <Col sm={4} className="text-muted fw-bold">Lead Type:</Col>
                        <Col sm={8} className="text-dark">{selectedLead.leadType?.name || 'N/A'}</Col>
                      </Row>
                      <Row className="mb-1 py-1 border-bottom">
                        <Col sm={4} className="text-muted fw-bold">Campaign:</Col>
                        <Col sm={8} className="text-dark">{selectedLead.campaign?.name || 'N/A'}</Col>
                      </Row>
                      <Row className="mb-1 py-1 border-bottom">
                        <Col sm={4} className="text-muted fw-bold">Level:</Col>
                        <Col sm={8} className="text-dark">{selectedLead.level || 'N/A'}</Col>
                      </Row>
                      <Row className="mb-0 py-1">
                        <Col sm={4} className="text-muted fw-bold">Priority:</Col>
                        <Col sm={8} className="text-dark">{selectedLead.priority || 'N/A'}</Col>
                      </Row>
                    </div>
                  </Col>
    
                  {/* Combined Product and Follow-Up Details Section */}
                  <Col md={4}>
                    <h6 className="fw-bold mb-3 text-primary">Product and Follow-Up Details</h6>
                    <div className="details-section">
                      <Row className="mb-1 py-1 border-bottom">
                        <Col sm={4} className="text-muted fw-bold">Department:</Col>
                        <Col sm={8} className="text-dark">{selectedLead.department?.name || 'N/A'}</Col>
                      </Row>
                      <Row className="mb-1 py-1 border-bottom">
                        <Col sm={4} className="text-muted fw-bold">Assigned To:</Col>
                        <Col sm={8} className="text-dark">
                          {selectedLead.assignTo 
                          ? [selectedLead.assignTo.firstName, selectedLead.assignTo.lastName]
                          .filter(Boolean)
                          .join(' ')
                          .trim() 
                          : 'Not Assigned'}
                        </Col>
                      </Row>
                      <Row className="mb-1 py-1 border-bottom">
                        <Col sm={4} className="text-muted fw-bold">Follow Up Date:</Col>
                        <Col sm={8} className="text-dark">{selectedLead.followUpDate ? moment(selectedLead.followUpDate).format('DD-MM-YYYY') : 'N/A'}</Col>
                      </Row>
                      <Row className="mb-1 py-1 border-bottom">
                        <Col sm={4} className="text-muted fw-bold">Follow Up Time:</Col>
                        <Col sm={8} className="text-dark">{formatTimeFor12Hour(selectedLead.followUpTime) || 'N/A'}</Col>
                      </Row>
                      <Row className="mb-0 py-1">
                        <Col sm={4} className="text-muted fw-bold">Comments:</Col>
                        <Col sm={8} className="text-dark">
                          {selectedLead.comments || 'N/A'}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="mt-3">
              <div className="mt-3">
              <h6>Follow-up</h6>
                <Card className="p-3">
                    <Col md={12}>
                      <form onSubmit={handleSubmitHistory}>
                        <Row className="g-3">
                          <Col md={3}>
                            <FormGroup>
                              <Label>Status</Label>
                              <Select
                                value={formDataHistory.status}
                                onChange={(selectedOption) => 
                                  setFormDataHistory({
                                    ...formDataHistory, 
                                    status: selectedOption
                                  })
                                }
                                options={statusSelectOptions}
                                placeholder="Select Status"
                                className="basic-select"
                                classNamePrefix="select"
                                required
                                isClearable
                              />
                            </FormGroup>
                          </Col>
                          
                          <Col md={3}>
                            <FormGroup>
                              <Label>Next Followup Date</Label>
                              <Input
                                type="date"
                                value={formDataHistory.nextFollowup}
                                min={moment().format('YYYY-MM-DD')}
                                onChange={(e) => setFormDataHistory({...formDataHistory, nextFollowup: e.target.value})}
                              />
                            </FormGroup>
                          </Col>
      
                          <Col md={3}>
                            <FormGroup>
                              <Label>Next Followup Time</Label>
                                <Input
                                  type="time"
                                  name="nextFollowupTime"
                                  id="nextFollowupTime"
                                  value={formDataHistory.nextFollowupTime}
                                  onChange={(e) => setFormDataHistory({...formDataHistory, nextFollowupTime: e.target.value})}
                                  disabled={!formDataHistory.nextFollowup} // Disable if no date is selected
                                />
                            </FormGroup>
                          </Col>
                          
                          <Col md={3}>
                            <FormGroup>
                              <Label>Comments</Label>
                              <Input
                                type="textarea"
                                value={formDataHistory.comments}
                                onChange={(e) => setFormDataHistory({...formDataHistory, comments: e.target.value})}
                                placeholder='Enter Comments'
                              />
                            </FormGroup>
                          </Col>
                        </Row>
      
                        <div className="mt-3">
                          <Button color="primary" type="submit" disabled={loading} className="me-2">
                            {loading ? 'Submitting...' : 'Submit'}
                          </Button>
                          <Button color="danger" type="button" onClick={handleResetHistory}>
                            Reset
                          </Button>
                        </div>
                      </form>
                    </Col>
                </Card>
              </div>
    
              <div className="mt-3">
              <h6>Follow-up History</h6>
                <Card className="p-3">
                  <Table striped bordered responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Next Followup Date</th>
                        <th>Next Followup Time</th>
                        <th>Comments</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {historyData.map((history, index) => (
                        <tr key={history._id}>
                          <td>{index + 1}</td>
                          <td>{moment(history.createdAt).format('DD/MM/YYYY')}</td>
                          <td>{moment(history.nextFollowup).format('DD/MM/YYYY') || 'N/A'}</td>
                          <td>{formatTimeFor12Hour(history.nextFollowupTime) || 'N/A'}</td>
                          <td>{history.comments || 'N/A'}</td>
                          <td>{history.status}</td>
                        </tr>
                      ))}
                      {historyData.length === 0 && (
                        <tr>
                          <td colSpan="5" className="text-center">No Records Available</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Card>
              </div>
            </div>
            </>
          )}
        </ModalBody>
        <ModalFooter className="py-2">
          <Button color="secondary" size="sm" onClick={handleCloseModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );

    return (
    <div className="page-content"> 
      <Container fluid className="py-2">
        <Breadcrumbs title="Home" breadcrumbItem="Follow Up" />
      
        <Card>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'today' })}
                  onClick={() => toggleTab('today')}
                >
                  Today's Followups
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'pending' })}
                  onClick={() => toggleTab('pending')}
                >
                  Pending Followups
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'upcoming' })}
                  onClick={() => toggleTab('upcoming')}
                >
                  Upcoming Followups
                </NavLink>
              </NavItem>
            </Nav>
          <CardBody>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={activeTab}>
                <div className="mt-4">
                  {renderFilters()}
                  <Card>
                    <CardBody>
                      {tableLoading ? (
                        <div className="text-center py-3">Loading...</div>
                      ) : (
                        <>
                          <MDBDataTable
                            data={tableData}
                            paging={false}
                            striped
                            bordered
                            hover
                            responsive
                          />
                          
                          <Pagination
                            currentPage={pagination.currentPage}
                            totalPages={pagination.totalPages}
                            totalLeads={pagination.totalLeads}
                            onPageChange={(page) => {
                              const updatedFilters = {
                                ...filters,
                                page: page
                              };
                              setFilters(updatedFilters);
                              fetchAllFollowups(updatedFilters);
                            }}
                            onEntriesChange={(newLimit) => {
                              const updatedFilters = {
                                ...filters,
                                limit: newLimit,
                                page: 1
                              };
                              setFilters(updatedFilters);
                              fetchAllFollowups(updatedFilters);
                            }}
                            entriesPerPage={filters.limit}
                          />
                        </>
                      )}
                    </CardBody>
                  </Card>
                </div>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
        
        
        {renderModal()}
      </Container>
    </div>  
    );
};

export default FollowUps;