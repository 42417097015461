import { get, post, put, del } from "../helpers/api_helper";

const API_URL = process.env.REACT_APP_APIURL + 'product-category'

export const productCategoryService = {
    getCategories: async (filters = {}) => {
        try {
          const queryString = new URLSearchParams(filters).toString();
          return await get(`${API_URL}/product-categories?${queryString}`);
        } catch (error) {
          throw new Error(error.message || 'Error Fetching Product Categories');
        }
      },

    createCategory: async (data) => {
        try {
          return await post(`${API_URL}/product-categories`, data);
        } catch (error) {
          throw new Error(error.message);
        }
      },
      
    updateCategory: async (id, data) => {
        try {
          return await put(`${API_URL}/product-categories/${id}`, data);
        } catch (error) {
          throw new Error(error.message || 'Error Updating Category');
        }
      },
      
    deleteCategory: async (id) => {
        try {
          return await del(`${API_URL}/product-categories/${id}`);
        } catch (error) {
          throw new Error(error.message || 'Error Deleting Category');
        }
      } 

}

export default productCategoryService;
