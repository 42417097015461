import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import { Label } from "reactstrap";
import Select from "react-select";
import moment from "moment";
import { AvField, AvForm } from "availity-reactstrap-validation";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

const ConversationReport = () => {
  const labelColors = [
     "#4F58FF","#FF4F58", "#FFCC00", "#58FF4F", "#FF58B3", "#00CCFF",
    "#FF6B3D", "#3D6B9D", "#9D406B", "#6B9D3D", "#D1C4E9", "#B36B00",
    "#8E8BFF", "#FFB6C1", "#C8E6C9", "#FF6347", "#20B2AA", "#FFD700",
    "#A9A9A9", "#F0E68C",
  ];

  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState([]);
  const [colors, setColors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState("MARKETING");
  const [fromDate, setFromDate] = useState(moment().subtract(31, "days").format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));

  const sortByOptions = [
    { label: "Marketing", value: "MARKETING" },
    { label: "Utility", value: "UTILITY" },
    // { label: "All", value: "ALL" }
  ];

  const generateRandomColor = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return `#${randomColor.padStart(6, "0")}`;
  };

  const handleSortByChange = (e) => {
    setSortBy(e.value);
    fetchGraphData(e.value);
  };

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };
  
  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };
  
  useEffect(() => {
    fetchGraphData(sortBy);
  }, [fromDate, toDate, sortBy]);
  

  const fetchGraphData = async (category) => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}whatsapp/conversation/report`, {
        params: {
          category: category,
          fromDate: fromDate,
          toDate: toDate,
        },
      });
  
      const { data } = response;
      const categories = [];
      const categorySeries = {}; 
      const uniqueColors = {}; 
  
      data?.data.forEach((categoryItem, index) => {
        if (categoryItem.messages && categoryItem.messages.length > 0) {
          if (!uniqueColors[categoryItem.category]) {
            uniqueColors[categoryItem.category] = labelColors[index] || generateRandomColor();
          }
  
          categoryItem.messages.forEach((message) => {
            if (!categories.includes(message.date)) {
              categories.push(message.date);
            }
  
            if (!categorySeries[categoryItem.category]) {
              categorySeries[categoryItem.category] = [];
            }
  
            categorySeries[categoryItem.category].push({
              x: message.date,
              y: message.count,
            });
          });
        }
      });
  
      const seriesDataArray = Object.keys(categorySeries).map((category) => ({
        name: category,
        data: categorySeries[category],
      }));
  
      setLabels(categories.length ? categories : ["No Data"]);
      setSeries(seriesDataArray);
      setColors(Object.values(uniqueColors)); 
    } catch (error) {
      console.error("Error fetching graph data:", error);
      setLabels(["Error fetching data"]);
      setSeries([{ name: "Message Count", data: [] }]);
      setColors([]);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchGraphData("MARKETING");
  }, []);

  const options = {
    chart: { zoom: { enabled: false }, toolbar: { show: false } },
    colors: colors,
    dataLabels: { enabled: false },
    stroke: { width: [3], curve: "smooth" },
    grid: {
      row: {
        colors: ["transparent", "transparent"],
        opacity: 0.2,
      },
      borderColor: "#f1f1f1",
    },
    markers: {
      style: "inverted",
      size: 6,
    },
    xaxis: {
      categories: labels,
      title: { text: "Day" },
    },
    yaxis: {
      title: { text: "Message Count" },
    },
    legend: {
      show: true,
      position: "top",
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            toolbar: { show: false },
          },
          legend: { show: false },
        },
      },
    ],
  };

  return (
    <>
      {loading ? (
        <Box sx={{ width: '100%' }}>
        <CircularProgress />
      </Box>
      ) : (
        <div>
          <div className="d-flex justify-content-between">
            <h4 className="card-title mb-2">All Conversation</h4>
            <div className="d-flex align-items-baseline gap-2">
              <Label>Category</Label>
              <Select
                value={sortByOptions.find(option => option.value === sortBy)  || sortByOptions[0]}
                options={sortByOptions}
                onChange={handleSortByChange}
              />
              <Label>From Date</Label>
              <AvForm>
                <AvField
                  name="fromDate"
                  type="date"
                  value={fromDate}
                  onChange={handleFromDateChange}
                />
              </AvForm>
              <Label>To Date</Label>
              <AvForm>
                <AvField
                  name="toDate"
                  type="date"
                  value={toDate}
                  onChange={handleToDateChange}
                />
              </AvForm>
            </div>
          </div>
          <ReactApexChart
            options={options}
            series={series}
            type="line"
            height="380"
            className="apex-charts"
          />
        </div>
      )}
    </>
  );
};

export default ConversationReport;
