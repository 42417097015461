import { EditorView } from "codemirror"
import { en } from "suneditor/src/lang"
import plugins from "suneditor/src/plugins"
import katex from "katex"
import { picmo } from "suneditor-picmo-emoji"

export const generatePlaceholderExample = (paragraph = null) => {
  try {
    if (!paragraph || typeof paragraph !== "string") {
      throw new Error("Invalid input: A valid paragraph string is required.")
    }

    const placeholderRegex = /\{\{(.*?)\}\}/g
    const placeholders = paragraph.match(placeholderRegex)

    const replacementDetails = []

    if (!placeholders) {
      return replacementDetails
    }

    let counter = 1
    placeholders.forEach(placeholder => {
      replacementDetails.push(`Enter the content for ${placeholder}`)
      counter++
    })

    return replacementDetails
  } catch (error) {
    return { error: error.message }
  }
}

export const customPlugins = {
  customLink: toggle => ({
    name: "customLink",
    display: "command",
    title: "Link",
    buttonClass: "se-btn",
    innerHTML: '<i class="fa fa-link"></i>',
    add: function (core) {
      const context = core.context
      context.customLink = {}
    },
    action: () => {
      toggle("url")
    },
  }),
  bodyAttribute: toggle => ({
    name: "bodyAttribute",
    display: "command",
    title: "Attribute",
    buttonClass: "se-btn",
    innerHTML: '<i class="fa fa-cogs"></i>',
    add: function (core) {
      const context = core.context
      context.customLink = {}
    },
    action: () => {
      toggle("bodyAttribute")
    },
  }),
}

export const sunEditorOptions = (toggle, customPluginsToAdd = [], remove = false) => {
  let pluginsConfig = [plugins, picmo, ...customPluginsToAdd.map(plugin => plugin(toggle))]

  let buttonList = []

  if (remove) {
    buttonList = [
      [...customPluginsToAdd.map(plugin => plugin(toggle).name)],
      ["undo", "redo"],
      [
        "%380",
        [
          [
            ":t-More Text-default.more_text",
            ...customPluginsToAdd.map(plugin => plugin(toggle).name),
          ],
          ["undo", "redo"],
        ],
      ],
    ]
  } else {
    buttonList = [
      [
        "picmo",
        "bold",
        "italic",
        "strike",
        ...customPluginsToAdd.map(plugin => plugin(toggle).name),
      ],
      ["undo", "redo"],
      [
        "%380",
        [
          [
            ":t-More Text-default.more_text",
            "picmo",
            "bold",
            "italic",
            "strike",
            ...customPluginsToAdd.map(plugin => plugin(toggle).name),
          ],
          ["undo", "redo"],
        ],
      ],
    ]
  }

  return {
    plugins: pluginsConfig,
    EditorView: {
      src: EditorView,
      options: { indentWithTabs: true, tabSize: 2 },
    },
    katex: katex,
    lang: en,
    showPathLabel: false,
    charCounter: false,
    resizingBar: false,
    enterKey: false,
    shiftEnterKey: false,
    height: "auto",
    buttonList: buttonList,
  }
}
