const INIT_STATE = {
  refresh: false,
}

const refreshPage = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "REFRESH_PAGE":
      return {
        ...state,
        refresh: action.payload,
      }
    default:
      return state
  }
}

export default refreshPage
