import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { MDBDataTable } from "mdbreact"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { API_URL } from "../../../config"
import Swal from "sweetalert2"
import { del, post, put, get } from "../../../helpers/api_helper"
import toastr from "toastr"
import axios from "axios"

const Modules = () => {
  document.title = "Modules"

  const [modulesObject, setModulesObject] = useState({})
  const [modulesForTable, setModulesForTable] = useState([])

  const formRef = useRef()

  useEffect(() => {
    getModule()
    // eslint-disable-next-line
  }, [])

  const getModule = () => {
    get("module").then(data => {
      data.modules.map((data, idx) => {
        data.id = idx + 1

        data.image = (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <img
              // src={`${API_URL}uploads/module_images/${data.icon}`}
              src={`${API_URL}${data.icon}`}
              alt={data.shortCode}
              height="23"
            ></img>
          </div>
        )

        data.action = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="uil-edit-alt"
              style={{
                fontSize: "1.2em",
                cursor: "pointer",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                toTop()
                handleUpdate(data)
              }}
            ></i>
            <i
              className="uil-trash-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
              onClick={() => {
                handleDelete(data._id)
              }}
            ></i>
          </div>
        )

        return data
      })
      if (data.modules !== null) setModulesForTable(data.modules)
    })
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`module/${id}`)
          .then(res => {
            toastr.success(res.message)
            reset()
            getModule()
          })
          .catch(err => {
            toastr.success(err.response.data.message)
          })
      }
    })
  }

  function handleChangeInput(e) {
    let { name, value } = e.target
    setModulesObject({ ...modulesObject, [name]: value })
  }

  function handleUpdate(data) {
    const { moduleName, shortCode, icon, _id } = data
    setModulesObject({ id: _id, moduleName, shortCode, icon })
  }

  function reset() {
    setModulesObject({})
    formRef.current.reset()
  }

  const uploadImage = e => {
    const fd = new FormData()
    fd.append("module_image", e.target.files[0])
    axios
      .post(`${API_URL}module/image`, fd, {
        // onUploadProgress: data => {
        //   // setUploadProgress(Math.round((100 * data.loaded) / data.total));
        // },
      })
      .then(response => {
        if (response.data.status === 200) {
          setModulesObject({
            ...modulesObject,
            icon: response.data.data.filename,
          })
        } else {
          toastr.error(response.data.message)
        }
      })
  }

  function handleSubmit() {
    if (modulesObject.id) {
      put(`${API_URL}module`, modulesObject)
        .then(res => {
          toastr.success("Modules updated successfully")
          getModule()
          reset()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
          return
        })
    } else {
      post(`module`, modulesObject)
        .then(res => {
          toastr.success("Modules added succesfully")
          getModule()
          reset()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
          return
        })
    }
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Module Name",
        field: "moduleName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Code",
        field: "shortCode",
        sort: "asc",
        width: 270,
      },
      {
        label: "Icon",
        field: "image",
        sort: "asc",
        width: 270,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: modulesForTable,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem="Modules" />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleSubmit()
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Module Name
                          </Label>

                          <AvField
                            name="moduleName"
                            placeholder="Module Name"
                            type="text"
                            errorMessage="Enter Module Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={modulesObject.moduleName || ""}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Shortcode</Label>

                          <AvField
                            name="shortCode"
                            placeholder="Shortcode"
                            type="text"
                            errorMessage="Enter Shortcode"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={modulesObject.shortCode || ""}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md={modulesObject?.icon ? 2 : 3}>
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Icon</Label>
                          {modulesObject?.icon ? (
                            <div div className="img-wraps d-flex flex-column">
                              {modulesObject.icon && (
                                <img
                                  width={150}
                                  height={150}
                                  alt={modulesObject.shortCode}
                                  // src={`${API_URL}uploads/module_images/${modulesObject.icon}`}
                                  src={`${API_URL}${modulesObject.icon}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={() =>
                                  setModulesObject(pre => ({
                                    ...pre,
                                    icon: null,
                                  }))
                                }
                                type="button"
                                style={{ width: "150px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="icon"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={uploadImage}
                              rows="1"
                            />
                          )}
                        </div>
                      </Col>
                      <Col md="3" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          <Button
                            color={modulesObject.id ? "warning" : "primary"}
                            className="me-2"
                            type="submit"
                          >
                            {modulesObject.id ? "Update" : "Submit"}
                          </Button>

                          <Button
                            color="danger"
                            className="me-2"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="modulesTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Modules
