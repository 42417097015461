import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { get } from "../../helpers/api_helper.js";
import moment from "moment";
import { Label } from "reactstrap";
import Select from "react-select"

const MonthlyGraph = () => {

  const labelColors = [
    "#FF4F58",  // Red
    "#4F58FF",  // Blue
    "#FFCC00",  // Yellow
    "#58FF4F",  // Green
    "#FF58B3",  // Orange
    "#00CCFF",  // Cyan
    "#FF6B3D",  // Muted orange
    "#3D6B9D",  // Muted blue
    "#9D406B",  // Muted maroon
    "#6B9D3D",  // Muted olive green
    "#D1C4E9",  // Lavender
    "#B36B00",  // Dark mustard yellow
    "#8E8BFF",  // Light periwinkle
    "#FFB6C1",  // Light pink
    "#C8E6C9",  // Soft mint green
    "#FF6347",  // Tomato red
    "#20B2AA",  // Light sea green
    "#FFD700",  // Golden yellow
    "#A9A9A9",  // Dark gray
    "#F0E68C",  // Khaki
  ];
  
  const [series, setSeries] = useState({})
  const [labels, setLabels] = useState([])
  const [colors, setColors] = useState([])
  const [loading, setLoading] = useState(true)
  const [sortBy, setSortBy] = useState({label:'Department', value: 1},)

  const sortByOptions = [
    {label:'Department', value: 1},
    {label:'Branch', value: 2},
    {label:'Forms', value: 3},
  ]
  
  function generateRandomColor() {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return `#${randomColor.padStart(6, '0')}`;
  }

  const handleSortByChange = (e)=> {
    setSortBy(e)
    fetchGraphData(e.value)
  }

  const fetchGraphData = async (sortType) => {
    get(`fb-dashboard/line-graph?sortBy=${sortType}`).then(res=> {
      setSeries(res.data)
      const lastSixMonths = []
      for (let i = 5; i >= 0; i--) {
        lastSixMonths.push(moment().subtract(i, "months").format("MMM-YY"))
      }
      setLabels(lastSixMonths)
      const keys = Object.keys(res.data)
      let colorsArr = []
      if(keys.length > colorsArr.length){
        for (let i = colorsArr.length; i <= keys.length; i++) {
          const newClr = generateRandomColor()
          colorsArr.push(newClr)
        }
      }else{
        colorsArr = colorsArr.slice(0, keys.length)
      }
      setColors(colorsArr)
    }).catch(err=> console.error(err)).finally(()=>setLoading(false))
    
  }

  console.log(series)
  console.log(colors)


  useEffect(() => {
    fetchGraphData(1);
    //eslint-disable-next-line
  }, []);

  
  const options = {
    chart: { zoom: { enabled: false }, toolbar: { show: false } },
    colors: colors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3, 3, 3, 3],  
      curve: 'straight',
    },
    grid: {
      row: {
        colors: ['transparent', 'transparent'],
        opacity: 0.2,
      },
      borderColor: '#f1f1f1',
    },
    markers: {
      style: 'inverted',
      size: 6,
    },
    xaxis: {
      categories: labels, 
      title: {
        text: 'Month',
      },
    },
    yaxis: {
      title: {
        text: 'Feedbacks',
      },
    },
    legend: {
      show: true,
      position: 'top',
      // horizontalAlign: 'right',
      // floating: true,
      // offsetY: -10,
      // offsetX: 5,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };


  return (
    <>
    {loading === false && 
    <>
    <div className="d-flex justify-content-between"><h4 className="card-title mb-2">Monthly Feedbacks</h4>
     <div className="d-flex align-items-baseline gap-2">
      <Label>Sort By</Label>
      <Select options={sortByOptions} value={sortBy} name='sortBy' onChange={(e)=>handleSortByChange(e)}/>
     </div>
    </div>
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      height="380"
      className="apex-charts"
    /></>}</>
  );
};

export default MonthlyGraph;
