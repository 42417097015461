import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { MDBDataTable } from "mdbreact"
import toastr from "toastr"
import { Pagination } from "@mui/material"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
} from "reactstrap"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./index.scss"
import Dropdown from "react-bootstrap/Dropdown"
import Select from "react-select"
import { getAllInteractions } from "../../store/actions"
import { getDate, getFirstday } from "../../helpers/functions"

import axios from "axios"
import moment from "moment"
import io from "socket.io-client"

import accessToken from "../../helpers/jwt-token-access/accessToken"
import Cookies from "js-cookie"
import { get, post } from "../../helpers/api_helper"
import { AvField, AvForm } from "availity-reactstrap-validation"

import ReactSelect from "react-select"

import LeadModal from "./LeadModal"
import DataTable from "../../components/Common/DataTable"
import google from "../../assets/fotos/Group 8.svg"
const SocialListening = () => {

  const token = Cookies.get("_token")
  const [filterObject, setFilterObject] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  })

  const [interactionIdToBeIgnored, setInteractionIdToBeIgnored] = useState(null)
  const [confirmIgnoreAlert, setConfirmIgnoreAlert] = useState(null)
  const [viewModal, setViewModal] = useState({ show: false, id: null })
  const [formData, setFormData] = useState({
    reviewerName: "",
    comments: "",
    source: "",
    sub_category: "",
  })
  const [tableRows, setTableRows] = useState([])
  const [replyData, setReplyData] = useState({})

  const [followupRows, setFollowupRows] = useState([])

  const { interactions, count } = useSelector(state => state.interactions)

  const dispatch = useDispatch()
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/"

  const [sourceOptions, setSourceOptions] = useState([])
  const [selectedSourceOption, setSelectedSourceOption] = useState(null)

  const [typeOptions, setTypeOptions] = useState([])
  const [typeOption, setTypeOption] = useState([])
  const [selectedTypeOption, setSelectedTypeOption] = useState(null)

  const [selectedStatusOption, setSelectedStatusOption] = useState(null)

  const [selectedSentimentOption, setSelectedSentimentOption] = useState(null)

  const [departmentOptions, setDepartmentOptions] = useState([])
  const [selectedDepartmentOption, setSelectedDepartmentOption] = useState(null)

  const [templateOptions, setTemplateOptions] = useState([])

  const [modalData, setModalData] = useState(null)

  const [master, setMaster] = useState({})
  const [selected, setSelected] = useState({})

  const [leadModal, setLeadModal] = useState({
    isOpen: false,
    socialData: null,
  })
  // const [selectedComment, setSelectedComment] = useState("")

  // const [page, setPage] = useState(1)
  // const [limit, setLimit] = useState(20)
  const [ignore_cursor, setIgnoreCursor] = useState("pointer");
  const [globe_cursor, setGlobeCursor] = useState("pointer");
  const [hide_cursor, setHideCursor] = useState("pointer");
  const [isDeleted, setIsDeleted] = useState(false);
  // const [hideCursor, setHideCursor] = useState("default");
const [hideClass, setHideClass] = useState("");
const [hideLabel, setHideLabel] = useState("");
   const [pagination, setPagination] = useState({ page: 1, limit: 40 })
    // const [counts, setCounts] = useState(0)
    const { page, limit } = pagination; //
    const renderTooltip = (message) => (
      <Tooltip id="tooltip">{message}</Tooltip>
    );

    useEffect(() => {
      console.log("interactions..........", interactions);

      const canHideItem = interactions.find(el => el?.sourceActionObj?.canHide);
    
      if (canHideItem) {
        setHideCursor("pointer");
        if (canHideItem?.infoOnHide?.isHidden) {
          console.log("snsrsnsr...................");
          setHideClass("fas fa-eye");
          setHideLabel("Unhide");
        } else {
          console.log("snsr1813...............");
          setHideClass("fas fa-eye-slash");
          setHideLabel("Hide");
        }
      } else {
        setHideCursor("not-allowed");
      }
    }, [interactions]); 
    
    
  useEffect(() => {
    setMaster({ id: replyData._id, remarks: "" })
  }, [replyData])
  // Socket.io logic
  useEffect(() => {
    const sockets = io(`${API_URL}?token=${token}`, {
      withCredentials: true,
    })

    sockets.on("interaction", () => {
      dispatch(getAllInteractions(filterObject))
    })

    return () => sockets.disconnect()
  }, [])

  useEffect(() => {
    getSourceOptions()
    getDepartmentOptions()
    getTemplateOptions()
  }, [])

  useEffect(() => {
    if (selectedSourceOption) {
      console.log("SNSR working..........")
      getTypeOptions(selectedSourceOption.value)
    }
  }, [selectedSourceOption])

  useEffect(() => {
    if(viewModal.show && selectedSourceOption){
      getTypeOption(selectedSourceOption.value);
      console.log("1813 working............")
    }
  }, [viewModal.show])

  useEffect(() => {
    dispatch(getAllInteractions({ ...filterObject, page, limit }));
  }, [filterObject, departmentOptions, pagination]);

  const updateSentiment = (sentiment, item) => {
    const data = {
      id: item?._id,
      sentiment: sentiment,
    }

    axios
      .post(`${API_URL}listening/updateSentiment`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then(res => {
        if (res.status === 200) {
          toastr.success(res.data.message)
          dispatch(getAllInteractions(filterObject))
        } else {
          toastr.error(res.data.message)
          return
        }
      })
  }

  const getSourceOptions = () => {
    axios.get(`${API_URL}listening/option/source`).then(res => {
      setSourceOptions(res?.data?.data || [])
    })

    
  }


  const getTypeOptions = source => {
    console.log("snsr1813sry")
    axios.get(`${API_URL}listening/option/type?source=${source}`).then(res => {
      setTypeOptions(res?.data?.data || [])
    })
  }

  const getDepartmentOptions = () => {
    axios.get(`${API_URL}listening/option/department`).then(res => {
      setDepartmentOptions(res?.data?.data || [])
    })
  }
  const getTemplateOptions = () => {
    axios.get(`${API_URL}listening/template`).then(res => {
      let data = res?.data?.data || []
      data = data.map(item => ({ ...item, label: item.name, value: item._id }))

      setTemplateOptions(data)
    })
  }

  const resetFilter = () => {
    setFilterObject({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    })

    setSelectedSourceOption(null)
    setSelectedTypeOption(null)
    setSelectedStatusOption(null)
    setSelectedSentimentOption(null)
    setSelectedDepartmentOption(null)
    setTypeOptions([])
    setPagination({ page: 1, limit: 40 })
  }

  const handleFilterInput = e => {
    const { name, value } = e.target

    setFilterObject(preVal => {
      return {
        ...preVal,
        [name]: value,
      }
    })
  }

  const handleLeadCreation = interaction => {
    setLeadModal({
      isOpen: true,
      socialData: {
        authorName: interaction.authorName,
        text: interaction.text,
        sourceMain: interaction.sourceMainObj.displayName,
        _id: interaction._id,
      },
    })
  }

  const handleTableData = () => {
    let tableRows = []

    interactions?.forEach((interaction, index) => {
      let tableRow = {}

      tableRow.id = <span className="table-col-id">{(page - 1) * limit + index + 1}</span>

      tableRow.date = (
        <p className="table-col-date">
          {moment(interaction.date).format("MMM DD")}
          <br></br>
          {moment(interaction.time, "HH:mm:ss").format("hh:mm A")}
        </p>
      )

      // tableRow.source = (
      //   <div className="table-col-source">
      //     <OverlayTrigger
      //       placement="top"
      //       overlay={
      //         <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
      //           {interaction.sourceMainObj.displayName}
      //         </Tooltip>
      //       }
      //     >
      //       <img
      //         style={{ cursor: "pointer" }}
      //         src={`${API_URL}uploads/sourceMain/${interaction.sourceMainObj.iconFileName}`}
      //         height="20"
      //         alt={interaction.sourceMainObj.displayName}
      //       />
      //     </OverlayTrigger>
      //     <OverlayTrigger
      //       placement="top"
      //       overlay={
      //         <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
      //           {interaction.sourceSubObj.displayName}
      //         </Tooltip>
      //       }
      //     >
      //       <img
      //         style={{ cursor: "pointer" }}
      //         src={`${API_URL}uploads/sourceSub/${interaction.sourceSubObj.iconFileName}`}
      //         height="20"
      //         alt={interaction.sourceSubObj.displayName}
      //       />
      //     </OverlayTrigger>
      //   </div>
      // )

      tableRow.source = (
        <div className="table-col-source">
          {interaction.sourceMainObj ? (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                  {interaction.sourceMainObj.displayName || "Unknown Source"}
                </Tooltip>
              }
            >
              <img
                style={{ cursor: "pointer" }}
                src={`${API_URL}${interaction.sourceMainObj.iconFileName || "default.png"}`}
                height="20"
                alt={interaction.sourceMainObj.displayName || "Unknown Source"}
              />
            </OverlayTrigger>
          ) : null}

          {interaction.sourceSubObj ? (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                  {interaction.sourceSubObj.displayName || "Unknown Sub-source"}
                </Tooltip>
              }
            >
              <img
                style={{ cursor: "pointer" }}
                src={`${API_URL}${interaction.sourceSubObj.iconFileName || "default.png"}`}
                height="20"
                alt={interaction.sourceSubObj.displayName || "Unknown Sub-source"}
              />
            </OverlayTrigger>
          ) : null}
        </div>
      )

      tableRow.name = (
        <span className="table-col-name">{interaction.authorName || "Unknown Author"}</span>
      )

      // tableRow.text = (
      //   <div className="table-col-text">
      //     <OverlayTrigger
      //       placement="top"
      //       overlay={
      //         <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
      //           {interaction.text}
      //         </Tooltip>
      //       }
      //     >
      //       <div>
      //         {interaction?.text?.length > 40
      //           ? interaction.text.substring(0, 40) + "..."
      //           : interaction.text}
      //       </div>
      //     </OverlayTrigger>
      //   </div>
      // )

      tableRow.text = (
        <div className="table-col-text">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                {interaction.text || "No text available"}
              </Tooltip>
            }
          >
            <div>
              {interaction?.text
                ? interaction.text.length > 40
                  ? interaction.text.substring(0, 40) + "..."
                  : interaction.text
                : "No text available"}
            </div>
          </OverlayTrigger>
        </div>
      )

      tableRow.sentiment = (
        <div className="table-col-sentiment">
          <Dropdown>
            <Dropdown.Toggle
              className="butn1 dropdown1"
              style={{
                backgroundColor: getColorForSentiment(interaction.sentiment),
                color: "white",
                border: "none", // Remove default border
              }}
              variant="info"
              id="dropdown-basic"
            >
              {interaction.sentiment}
              <span class="fa fa-angle-down"></span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => updateSentiment("positive", interaction)}>
                Positive
              </Dropdown.Item>
              <Dropdown.Item onClick={() => updateSentiment("neutral", interaction)}>
                Neutral
              </Dropdown.Item>
              <Dropdown.Item onClick={() => updateSentiment("negative", interaction)}>
                Negative
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )

      tableRow.status = (
        <p className="table-col-status">{getStatusNameFromStatus(interaction.status)}</p>
      )

      var globe_cursor = "not-allowed"
      var reply_cursor = "not-allowed"
      var hide_cursor = "not-allowed"
      var hide_class
      var hide_label
      var delete_cursor = "not-allowed"
      var ignore_cursor = "pointer"

      var isDeleted = interaction.infoOnDelete.isDeleted

      if (interaction.url) {
        globe_cursor = "pointer"
      }

      if (interaction.sourceActionObj?.canReply) {
        reply_cursor = "pointer"
      }

      if (interaction.sourceActionObj?.canHide) {
        hide_cursor = "pointer"

        if (interaction.infoOnHide?.isHidden) {
          hide_class = "fas fa-eye"
          hide_label = "Unhide"
        } else {
          hide_class = "fas fa-eye-slash"
          hide_label = "Hide"
        }
      }

      if (interaction.sourceActionObj?.canDelete) {
        delete_cursor = "pointer"
      }

      tableRow.action = (
        <div className="table-col-action">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                  Add Lead
                </Tooltip>
              }
            >
              <i
                className="fas fa-user-plus" // Using user-plus icon for lead creation
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2rem",
                  marginRight: "0.2rem",
                }}
                onClick={() => handleLeadCreation(interaction)}
              ></i>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                  Response
                </Tooltip>
              }
            >
              <i
                className="fas fa-reply"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2rem",
                  marginRight: "0.2rem",
                }}
                onClick={() => {
                  handleViewModal(interaction)
                }}
              ></i>
            </OverlayTrigger>

            {reply_cursor !== "not-allowed" && isDeleted === false ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                    Reply
                  </Tooltip>
                }
              >
                <i
                  className="fas fa-comment-medical"
                  style={{
                    fontSize: "1em",
                    cursor: reply_cursor,
                    marginLeft: "0.2rem",
                    marginRight: "0.2rem",
                  }}
                  onClick={() => {
                    handleClickReplay(interaction)
                    // if (reply_cursor === "pointer") {
                    //   handleClickopenForm(interaction)
                    // }
                  }}
                ></i>
              </OverlayTrigger>
            ) : (
              ""
            )}

            {hide_cursor !== "not-allowed" && isDeleted === false ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                    {hide_label}
                  </Tooltip>
                }
              >
                <i
                  className={hide_class}
                  style={{
                    fontSize: "1em",
                    cursor: hide_cursor,
                    marginLeft: "0.2rem",
                    marginRight: "0.2rem",
                  }}
                  onClick={() => {
                    if (hide_cursor === "pointer") {
                      handleHideUnhide(interaction._id, interaction.infoOnHide.isHidden)
                    }
                  }}
                ></i>
              </OverlayTrigger>
            ) : (
              ""
            )}

            {delete_cursor !== "not-allowed" && isDeleted === false ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                    Delete
                  </Tooltip>
                }
              >
                <i
                  className="fa fa-trash"
                  style={{
                    fontSize: "1em",
                    cursor: delete_cursor,
                    marginLeft: "0.2rem",
                    marginRight: "0.2rem",
                  }}
                  onClick={() => {
                    if (delete_cursor === "pointer") {
                      handleDelete(interaction._id)
                    }
                  }}
                ></i>
              </OverlayTrigger>
            ) : (
              ""
            )}

            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                  Ignore
                </Tooltip>
              }
            >
              <i
                className="fas fa-ban"
                style={{
                  fontSize: "1em",
                  cursor: ignore_cursor,
                  marginLeft: "0.2rem",
                  marginRight: "0.2rem",
                }}
                onClick={() => {
                  if (ignore_cursor === "pointer") {
                    setInteractionIdToBeIgnored(interaction._id)
                    setConfirmIgnoreAlert(true)
                  }
                }}
              ></i>
            </OverlayTrigger>

            {globe_cursor !== "not-allowed" && isDeleted === false && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                    Open
                  </Tooltip>
                }
              >
                <i
                  className="fas fa-globe"
                  style={{
                    fontSize: "1em",
                    cursor: globe_cursor,
                    marginLeft: "0.2rem",
                    marginRight: "0.2rem",
                  }}
                  onClick={() => {
                    window.open(interaction.url)
                  }}
                ></i>
              </OverlayTrigger>
            )}
          </div>
        </div>
      )

      tableRow.departmentView = (
        <div>
          <Select
            placeholder="Select"
            value={interaction?.department?.value ? interaction.department : null}
            isClearable
            onChange={selected => {
              handleAssignDepartment(interaction._id, selected?.value || null)
            }}
            options={departmentOptions}
            classNamePrefix="select2-selection"
            menuPosition="fixed"
          />
        </div>
      )

      tableRows.push(tableRow)
    })

    setTableRows(tableRows)
  }

  const handleHideUnhide = (interactionId, isHidden) => {
    const toHide = !isHidden

    axios
      .put(
        `${API_URL}listening/hideInteraction`,
        {
          interactionId,
          toHide,
        },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then(res => {
        toastr.success(res.data.message)

        dispatch(getAllInteractions(filterObject))
      })
      .catch(error => {
        toastr.error(error.response.data.message, "Failed to hide/unhide interaction")
      })
  }

  const handleIgnore = interactionId => {
    axios
      .put(
        `${API_URL}listening/ignoreInteraction/`,
        {
          interactionId,
        },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then(res => {
        toastr.success(res.data.message)

        dispatch(getAllInteractions(filterObject))

        setInteractionIdToBeIgnored(null)
      })
      .catch(error => {
        toastr.error(error.response.data.message, "Failed to ignore interaction")
      })
  }

  const handleDelete = interactionId => {
    axios
      .put(
        `${API_URL}listening/deleteInteraction`,
        {
          interactionId,
        },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then(res => {
        toastr.success(res.data.message)

        dispatch(getAllInteractions(filterObject))
      })
      .catch(error => {
        toastr.error(error.response.data.message, "Failed to delete interaction")
      })
  }

  const handleAssignDepartment = async (interactionId, departmentId) => {
    try {
      const response = await post(`listening/assignDepartment`, { interactionId, departmentId })

      dispatch(getAllInteractions(filterObject))

      toastr.success(response.message)
    } catch (error) {}
  }

  function handleClick(remarks) {
    setMaster(prev => ({ ...prev, remarks: remarks.comments }))
  }

  const handleClickReplay = async interaction => {
    const response = await get(`listening/interaction/${interaction._id}`)
    setReplyData(response)
    // setSelectedComment(item.comments);
    let data = response?.data?.followup || []

    data = data.map((item, index) => {
      return {
        id: index + 1,
        text: item.text,

        sentiment: (
          <span
            className="badge font-size-12"
            style={{ backgroundColor: getColorForSentiment(item.sentiment) }}
          >
            {item.sentiment}
          </span>
        ),
        date: (
          <p className="table-col-date">
            {moment(item.date).format("MMM DD")}
            <br></br>
            {moment(item.time, "HH:mm:ss").format("hh:mm A")}
          </p>
        ),
        status: <p className="table-col-status">{getStatusNameFromStatus(item.status)}</p>,
      }
    })
    setFollowupRows(data)
    setModalData({ interaction })
  }

  useEffect(() => {
    handleTableData()
  }, [interactions])

  useEffect(() => {
    const key = selected?.language?.key
    if (selected && key) {
      const data = { ...master }

      data["remarks"] = selected["template"][key]

      data.remarks = data?.remarks?.replace(
        "##name##",
        modalData?.interaction?.authorName || "User"
      )
      setMaster(data)
    }
  }, [selected])

  const handleValidSubmit = (e, values) => {
    try {
      if (!modalData?.interaction?._id) toastr.warning("Something went wrong. Please try again")

      const sourceMain = modalData.interaction.sourceMainObj
      const sourceSub = modalData.interaction.sourceSubObj

      const url = API_ROUTES[`${sourceMain.name}:${sourceSub.name}`]
      const data = {
        id: modalData.interaction._id,
        text: values.text,
      }

      post(url, data)
        .then(res => {
          toastr.success(res.message)
          setMaster({})
          setModalData(null)
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    } catch (error) {
      console.log(error)
      toastr.error("Something went wrong. Please try again")
    }
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
      },
      {
        label: "Source",
        field: "source",
        sort: "asc",
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
      },
      {
        label: "Message",
        field: "text",
        sort: "asc",
      },
      {
        label: "Sentiment",
        field: "sentiment",
        sort: "asc",
      },
      {
        label: "Department",
        field: "departmentView",
        sort: "asc",
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
      },
    ],
    rows: tableRows,
  }

  const getColorForSentiment = sentiment => {
    switch (sentiment) {
      case "Positive":
        return "#34c38f" // Green
      case "Neutral":
        return "#f1b44b" // Yellow
      case "Negative":
        return "#f46a6a" // Red
      default:
        return "#FFFFFF" // Default: White (Not used)
    }
  }

  const getStatusNameFromStatus = status => {
    switch (status) {
      case 0:
        return "New"
      case 3:
        return "Hide"
      case 4:
        return "Deleted"
      default:
        return "default_val"
    }
  }

  const followupData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
      },
      {
        label: "Message",
        field: "text",
        sort: "asc",
      },
      {
        label: "Sentiment",
        field: "sentiment",
        sort: "asc",
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
      },
    ],
    rows: followupRows,
  }

  const API_ROUTES = {
    "instagram:comment": "instagram/comment-replay",
    "facebook:comment": "facebook/comment-replay",
    "instagram:mention": "instagram/mention-replay",
    "facebook:comment:hide": "facebook/comment/hide",
  }

  const handleModalSelect = (el, name) => {
    const data = { [name]: el }
    const selectedData = { [name]: el }

    if (name === "template" && !selected?.language) {
      selectedData["language"] = { label: "English", key: "english", value: 1 }
    }

    setSelected(pre => ({ ...pre, ...selectedData }))
    setMaster(pre => ({ ...pre, ...data }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const responseData = {
      listeningId: formData.listeningId,
      comments: formData.comments,
      source: formData.source,
      sub_category: formData.sub_category,
      department: formData.department,
    }
    try {
      axios.post(`${API_URL}response/v2/listening/replay/response`, responseData).then(() => {
        // alert(res.data.message || "Response created successfully!");
        toggle()
      })
    } catch (error) {}
  }

  const toggle = () => {
    // setViewModal({ show: false, id: null });
    setViewModal(prev => ({
      ...prev,
      show: !prev.show,
    }))
  }

  const handleChange = e => {
    const { name, value } = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  // const getTypeOption = sourceId => {
  //   console.log("snsr1813",sourceId)
  //   if (!sourceId) return
  //   const source_id = sourceId.value
  //   axios.get(`${API_URL}listening/option/type?source=${source_id}`).then(res => {

  //     console.log("response............",res)
  //     setTypeOption(res?.data?.data || [])
  //   })
  // } 
  const handleViewModal = (
    listeningId,
    authorName,
    comments,
    source,
    sub_category,
    show = false
  ) => {
    console.log("subcategory",sub_category)
    console.log("typeOptions",typeOption)
    const selectedSource = sourceOptions.find(option => option.value === source) || null;
    const subcategory = typeOption.find(option => option.value === sub_category) || null
  console.log("subcategory....",subcategory)
    setViewModal({ show: true })
    setFormData({
      listeningId: listeningId || "",
      reviewerName: authorName || "",
      comments: comments || "",
      source: selectedSource,
      sub_category:typeOption.find(option => option.value === sub_category) || null,
    })
    if (selectedSource) {
      getTypeOption(selectedSource).then((newOptions) => {
        setFormData(prev => ({
          ...prev,
          sub_category: newOptions.find(option => option.value === sub_category) || null,
        }));
      });
    }
  }

  const getTypeOption = sourceId => {
    console.log("snsr1813", sourceId);
    if (!sourceId) return Promise.resolve([]); 
  
    const source_id = sourceId.value;
    return axios.get(`${API_URL}listening/option/type?source=${source_id}`)
      .then(res => {
        console.log("response............", res);
        setTypeOption(res?.data?.data || []);
        return res?.data?.data || []; 
      })
      .catch(error => {
        console.error("Error fetching type options:", error);
        return []; 
      });
  };
  

  useEffect(() => {
    getSourceOptions()
  }, [])
  useEffect(() => {
    console.log("formData are", formData)
    if (formData.source) {
      getTypeOption(formData.source)
    }
  }, [formData.source])

  useEffect(() => {
    setPagination((prev) => ({ ...prev, page: 1 })); 
  }, [filterObject]);
  
  return (
    <React.Fragment>
      {confirmIgnoreAlert ? (
        <SweetAlert
          title=""
          confirmButtonText="Ignore"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            handleIgnore(interactionIdToBeIgnored)

            setConfirmIgnoreAlert(false)
          }}
          onCancel={() => setConfirmIgnoreAlert(false)}
        >
          Are you sure you want to ignore it?
        </SweetAlert>
      ) : null}

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Social Listening" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* Filters */}
                  <Row>
                    <Col md="10">
                      <Row>
                        <Col md="2" className="mb-2">
                          <Label>From</Label>
                          <input
                            name="from_date"
                            type="date"
                            className="form-control"
                            onChange={handleFilterInput}
                            validate={{ required: { value: true } }}
                            value={filterObject?.from_date}
                          />
                        </Col>

                        <Col md="2" className="mb-2">
                          <Label>To</Label>
                          <input
                            name="to_date"
                            type="date"
                            className="form-control"
                            onChange={handleFilterInput}
                            validate={{ required: { value: true } }}
                            value={filterObject?.to_date}
                          />
                        </Col>

                        <Col md="2" className="mb-2">
                          <Label>Source</Label>
                          <Select
                            placeholder="Select"
                            value={selectedSourceOption}
                            validate={{ required: { value: true } }}
                            onChange={selected => {
                              console.log("selected Source :", selected)
                              setSelectedSourceOption(selected)

                              setFilterObject(preVal => {
                                return {
                                  ...preVal,
                                  source: selected.value,
                                }
                              })
                            }}
                            options={sourceOptions}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col>

                        <Col md="2" className="mb-2">
                          <Label>Type</Label>
                          <Select
                            placeholder="Select"
                            value={selectedTypeOption}
                            validate={{ required: { value: true } }}
                            onChange={selected => {
                              console.log("selected Type :", selected)
                              setSelectedTypeOption(selected)

                              setFilterObject(preVal => {
                                return {
                                  ...preVal,
                                  type: selected.value,
                                }
                              })
                            }}
                            options={typeOptions}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col>

                        <Col md="2" className="mb-2">
                          <Label>Status</Label>
                          <Select
                            placeholder="Select"
                            value={selectedStatusOption}
                            onChange={selected => {
                              setSelectedStatusOption(selected)

                              setFilterObject(preVal => {
                                return {
                                  ...preVal,
                                  status: selected.value,
                                }
                              })
                            }}
                            options={[
                              { label: "New", value: 0 },
                              { label: "Hide", value: 3 },
                              { label: "Deleted", value: 4 },
                            ]}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col>

                        <Col md="2" className="mb-2">
                          <Label>Sentiment</Label>
                          <Select
                            placeholder="Select"
                            value={selectedSentimentOption}
                            onChange={selected => {
                              setSelectedSentimentOption(selected)

                              setFilterObject(preVal => {
                                return {
                                  ...preVal,
                                  sentiment: selected.value,
                                }
                              })
                            }}
                            options={[
                              { label: "Positive", value: "positive" },
                              { label: "Neutral", value: "neutral" },
                              { label: "Negative", value: "negative" },
                            ]}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col>

                        {/* <Col md="2" className="mb-2">
                          <Label>Department</Label>
                          <Select
                            placeholder="Select"
                            value={selectedDepartmentOption}
                            onChange={selected => {
                              setSelectedDepartmentOption(selected)

                              setFilterObject(preVal => {
                                return {
                                  ...preVal,
                                  department: selected.value,
                                }
                              })
                            }}
                            options={departmentOptions}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col> */}
                      </Row>
                    </Col>

                    <Col md="2" style={{ marginTop: "26px" }}>
                      <div>
                        <Button className="mx-2" color="danger" type="reset" onClick={resetFilter}>
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  {/* Table */}
                  {/* <MDBDataTable
                    key={departmentOptions}
                    id="interactionsTable"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  /> */}
                  {/* <DataTable
                    data={data}
                    count={count}
                    page={page}
                    limit={limit}
                    onPageChange={e => setPage(e)}
                    onLimitChange={e => setLimit(e)}
                    id="contacttableid1sss"
                  /> */}
                  <div className="reviewbox">
                    {interactions.length > 0 ? (

                      interactions?.map(el => {
                        // console.log("SNSRSRY1813",el)
                        const isHidden = el.infoOnHide?.isHidden || false;
                        return (
                          <div className="revlist ">
                            <div className="revhead">
                              <div className="captions">
                                <div className="profile">
                                  <span
                                    // class="fab"
                                    style={{
                                      display: "inline-flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      color: "white",
                                      fontWeight: "bold",
                                      fontSize: "18px",
                                      borderRadius: "50%",
                                      width: "30px",
                                      height: "48px",
                                      textAlign: "center",

                                    }}

                                  >{el.authorName[0].toUpperCase()}</span>
                                  {/* <i>
                                     <img src={google} alt="" />
                                   </i> */}
                                </div>
                                <div className="revname">
                                  <div className="d-flex">
                                    <div className="revtitle">{el.authorName}</div>
                                    {/* <span>20 reviews 3 photos</span> */}
                                    <div style={{ marginLeft: "5px" }}>
                                      {el.sourceMainObj ? (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                                              {el.sourceMainObj.displayName || "Unknown Source"}
                                            </Tooltip>
                                          }
                                        >
                                          <img
                                            style={{ cursor: "pointer" }}
                                            src={`${API_URL}${el.sourceMainObj.iconFileName || "default.png"}`}
                                            height="20"
                                            alt={el.sourceMainObj.displayName || "Unknown Source"}
                                          />
                                        </OverlayTrigger>
                                      ) : null}
                                    </div>
                                    <div style={{ marginLeft: "5px" }}>
                                      {el.sourceMainObj ? (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                                              {el.sourceSubObj.displayName || "Unknown Sub-source"}
                                            </Tooltip>
                                          }
                                        >
                                          <img
                                            style={{ cursor: "pointer" }}
                                            src={`${API_URL}${el.sourceSubObj.iconFileName || "default.png"}`}
                                            height="20"
                                            alt={el.sourceSubObj.displayName || "Unknown Sub-source"}
                                          />
                                        </OverlayTrigger>
                                      ) : null}
                                    </div>
                                    <div style={{ marginLeft: "5px" }}><span class="badge text-bg-secondary fs-6 p-1">{getStatusNameFromStatus(el.status)}</span></div>
                                  </div>

                                  <div className="revcontent">
                                    <div>{el.text}</div>
                                  </div>
                                </div>
                              </div>

                              <div style={{ display: "flex", gap: "5px" }} className="date">
                                {/* <span class="fa "> */}
                                {" "}
                                {/* <img src={calender} alt="" />{" "} */}

                                {/* </span> */}
                                <span style={{ whiteSpace: "nowrap" }}>
                                  {moment(el.date).format("DD MMM YYYY")}
                                </span>
                                <span style={{ whiteSpace: "nowrap" }}>
                                  {moment(el.time, "HH:mm:ss").format("hh:mm a")}
                                </span>
                              </div>

                              <div className="sentiment">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    className="butn1 dropdown1"
                                    style={{
                                      backgroundColor: getColorForSentiment(el.sentiment),
                                      color: "white",
                                      border: "none", // Remove default border
                                    }}
                                    variant="info"
                                    id="dropdown-basic"
                                  >
                                    {el.sentiment}
                                    <span class="fa fa-angle-down"></span>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => updateSentiment("positive", el)}>
                                      Positive
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => updateSentiment("neutral", el)}>
                                      Neutral
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => updateSentiment("negative", el)}>
                                      Negative
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>

                            </div>

                            {el?.reviewReply?.comment && (
                              <div className="own-reply">
                                <div className="inner-reply">
                                  <div className="reply-title">
                                    <b> Response from the owner</b>
                                    <span className="date">
                                      &nbsp;
                                      {moment(el?.reviewReply?.updateTime).endOf("day").fromNow()}
                                    </span>
                                  </div>
                                  <span>{el?.reviewReply?.comment || ""}</span>
                                </div>
                              </div>
                            )}

                            <div className="revfooter">
                              <div>
                                <Select
                                  placeholder="Department"
                                  value={el?.department?.value ? el.department : null}
                                  isClearable
                                  onChange={selected => {
                                    handleAssignDepartment(el._id, selected?.value || null)
                                  }}
                                  options={departmentOptions}
                                  classNamePrefix="select2-selection"
                                  menuPosition="fixed"
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      minHeight: "32px",
                                      height: "30px",
                                      padding: "0px 8px",
                                    }),
                                    valueContainer: (provided) => ({
                                      ...provided,
                                      padding: "0px 6px",
                                    }),
                                    input: (provided) => ({
                                      ...provided,
                                      margin: "0px",
                                    }),
                                    indicatorsContainer: (provided) => ({
                                      ...provided,
                                      height: "30px",
                                    }),
                                  }}
                                />
                              </div>
                              <div className="revtool">
                                {/* <div>
                              
                                         <span className="far fa-thumbs-up"></span> Like
                                       </div> */}
                                {/* <span
                                   // onClick={() => handleReply(el)}
                                   className="cursor-pointer p-1">
                                   <span className="fas fa-reply"></span> Reply
                                 </span> */}
                                {/* <div>
                                         <span className="far fa-edit"></span> Edit
                                       </div> */}


                              </div>

                              <div className="revtool">
                                <OverlayTrigger placement="top" overlay={renderTooltip("Lead")}>
                                  <div onClick={() => handleLeadCreation(el)} className="cursor-pointer">
                                    <span className="fas fa-user-plus"></span>

                                    {/* Lead */}
                                  </div>
                                </OverlayTrigger>
                                <OverlayTrigger placement="top" overlay={renderTooltip("Response")}>
                                  <div onClick={() => handleViewModal(el._id, el.authorName, el.text, el.sourceMainObj?._id, el.sourceSubObj?._id, true)}>
                                    <span className="fas fa-reply"></span>
                                    {/* Response */}
                                  </div>
                                </OverlayTrigger>
                                <OverlayTrigger placement="top" overlay={renderTooltip("Ignore")}>
                                  <div
                                    onClick={() => {
                                      if (ignore_cursor === "pointer") {
                                        setInteractionIdToBeIgnored(el._id)
                                        setConfirmIgnoreAlert(true)
                                      }
                                    }} className="cursor-pointer">
                                    <span className="fas fa-ban"></span>
                                    {/* Ignore */}
                                  </div>
                                </OverlayTrigger>
                                {globe_cursor !== "not-allowed" && isDeleted === false && (
                                  <OverlayTrigger placement="top" overlay={renderTooltip("Open")}>
                                    <div onClick={() => { window.open(el.url) }}>
                                      <span className="fas fa-globe"></span>
                                      {/* Open */}
                                    </div>
                                  </OverlayTrigger>
                                )}
                                {hide_cursor !== "not-allowed" && !isDeleted && (
                                  <OverlayTrigger placement="top" overlay={<Tooltip>{isHidden ? "Unhide" : "Hide"}</Tooltip>}>
                                    <div
                                      onClick={() => {
                                        if (hide_cursor === "pointer") {
                                          handleHideUnhide(el._id, isHidden);
                                        }
                                      }}
                                      className="cursor-pointer"
                                    >
                                      <span className={isHidden ? "fas fa-eye" : "fas fa-eye-slash"}></span>
                                    </div>
                                  </OverlayTrigger>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      })
                    ) : (
                      <div className="d-flex justify-content-center align-items-center py-4">
                        No reviews found.
                      </div>
                    )}
                    <div className="d-flex justify-content-center py-2 pb-4">
                      <Pagination
                        page={page}
                        count={Math.ceil(count / limit)}
                        // count="1813"
                        shape="rounded"
                        size="small"
                        onChange={(e, value) =>
                          setPagination((prev) => ({ ...prev, page: value }))
                        }
                      />
                    </div>
                  </div>
                            
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        show={modalData !== null}
        isOpen={modalData !== null}
        toggle={() => {
          setModalData(null)
          setMaster({})
        }}
        size="xl"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5 style={{ marginBottom: "0px" }}>Details</h5>
          </div>
          {/* <h3 className="modal-title mt-0">Lead Details - {leadDetails?.lead_unique_id}</h3> */}
          <button
            style={{ marginBottom: "2px" }}
            type="button"
            onClick={() => {
              setModalData(null)
              setMaster({})
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body pt-0">
          <Row>
            <Col md={12}>
              <Row>
                <Col md="12" lg="12">
                  <AvForm onValidSubmit={handleValidSubmit}>
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Template</Label>
                          <ReactSelect
                            name="template"
                            type="text"
                            onChange={el => handleModalSelect(el, "template")}
                            options={templateOptions}
                            value={selected?.template || null}
                            errorMessage="Enter service"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Language</Label>
                          <ReactSelect
                            name="language"
                            placeholder="Select"
                            onChange={el => handleModalSelect(el, "language")}
                            value={selected?.language || null}
                            type="text"
                            options={[
                              {
                                label: "English",
                                key: "english",
                                value: 1,
                              },
                              {
                                label: "Hindi",
                                key: "hindi",
                                value: 2,
                              },
                              {
                                label: "Malayalam",
                                key: "malayalam",
                                value: 3,
                              },
                            ]}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Comments</Label>
                          <AvField
                            name="text"
                            rows={1}
                            type="textarea"
                            placeholder="Comments"
                            errorMessage="Enter Description"
                            value={master?.remarks || ""}
                            validate={{
                              required: { value: true, errorMessage: "Comments is required" },
                              // minLength: { value: 5 },
                            }}
                          />
                        </div>
                      </Col>

                      <Col className="mt-4 pt-1">
                        <div className="d-flex gap-2">
                          <Button color="primary" type="submit">
                            Submit
                          </Button>
                          <Button
                            onClick={() => {
                              setSelected({})
                              setMaster({})
                            }}
                            color="danger"
                            type="reset"
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </Col>
              </Row>

              {/* {reviewData?.reviewReply?.comment && (
                    <ul className="revreplycontent">
                      <li>
                        <button onClick={() => handleClick(reviewData?.reviewReply?.comment)} className="replycontent">
                          <span className="replyicon">
                            <i className="uil-plus"></i>
                          </span>
                          <span>{reviewData?.reviewReply?.comment}</span>
                        </button>
                      </li>
                    </ul>
                   )} */}

              <ul className="revreplycontent" style={{ padding: 0, margin: 0, listStyle: "none" }}>
                {replyData?.data?.followup?.[0]?.responseData?.[0]?.follow_up?.length > 0 ? (
                  replyData.data.followup[0].responseData[0].follow_up.map(item => (
                    <li
                      key={item._id}
                      style={{ margin: "2px 0", padding: "4px 8px", fontSize: "12px" }}
                    >
                      <button
                        onClick={() => handleClick(item)}
                        className="replycontent"
                        style={{
                          padding: "4px 6px",
                          fontSize: "13px",
                          lineHeight: 1,
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        <span className="replyicon" style={{ fontSize: "10px" }}>
                          <i className="uil-plus"></i>
                        </span>
                        <span>{item.comments}</span>
                      </button>
                    </li>
                  ))
                ) : (
                  <p></p>
                )}
              </ul>

              <Row>
                <MDBDataTable
                  id="followupInteractionsTable"
                  responsive
                  bordered
                  data={followupData}
                  searching={true}
                  disableRetreatAfterSorting={true}
                  entries={20}
                />
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>
      <LeadModal
        isOpen={leadModal.isOpen}
        toggle={() => setLeadModal({ isOpen: false, socialData: null })}
        socialData={leadModal.socialData}
      />
      <Modal isOpen={viewModal.show} toggle={toggle}>
        <ModalHeader toggle={toggle}>Response</ModalHeader>
        <Form onSubmit={handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label for="reviewerName">Name</Label>
              <Input
                type="text"
                id="reviewerName"
                name="reviewerName"
                value={formData.reviewerName}
                readOnly
              />
            </FormGroup>
            <FormGroup>
              <Label for="comments">Comments</Label>
              <Input
                type="textarea"
                id="comments"
                name="comments"
                value={formData.comments}
                onChange={handleChange}
                placeholder="Enter your comments"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="source">Source</Label>
              <Select
                id="source"
                name="source"
                value={sourceOptions.find(option => option.value === formData.source) || formData.source}
                onChange={selectedOption =>
                  handleChange({ target: { name: "source", value: selectedOption.value } })
                }
                options={sourceOptions}
                getOptionLabel={e => e.label}
                getOptionValue={e => e.value}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="sub_category">Sub Category</Label>
              <Select
                id="sub_category"
                name="sub_category"
                value={typeOptions.find(option => option.value === formData.sub_category) || formData.sub_category}
                onChange={selectedOption =>
                  handleChange({ target: { name: "sub_category", value: selectedOption.value } })
                }
                options={typeOptions}
                getOptionLabel={e => e.label}
                getOptionValue={e => e.value}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="department">Department</Label>
              <Select
                id="department"
                name="department"
                value={departmentOptions.find(option => option.value === formData.department)}
                onChange={selectedOption =>
                  handleChange({ target: { name: "department", value: selectedOption.value } })
                }
                options={departmentOptions}
                getOptionLabel={e => e.label}
                getOptionValue={e => e.value}
                required
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary">
              Submit
            </Button>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default SocialListening
