import React, { useEffect, useState, useRef } from "react";
import { AvField, AvForm } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import axios from "axios";
import toastr from "toastr";
import { getDate, toTop } from "../../../helpers/functions";

const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

const ResponseSourceReport = () => {
  const todayDate = getDate();
  const filterRef = useRef(null);

  const [data, setData] = useState({
    columns: [
      { label: "#", field: "id", sort: "asc" },
      { label: "Source", field: "sourceName", sort: "asc" },
      { label: "Type", field: "typeName", sort: "asc" },
      { label: "Total Responses", field: "totalResponses", sort: "asc" },
      { label: "Closed Responses", field: "closedResponses", sort: "asc" },
      { label: "Avg Resolution Time (Days)", field: "avgResolutionTime", sort: "asc" },
    ],
    rows: [],
  });

  const [filterObject, setFilterObject] = useState({});
  // const [filterTypeOption, setFilterTypeOption] = useState([]);
  // const [filterSubTypeOption, setFilterSubTypeOption] = useState([]);
  const [filterDepartmentOption, setFilterDepartmentOption] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({
    type: null,
    subType: null,
    department: null,
  });

  const fetchResponseReport = async () => {
    try {
      const response = await axios.get(`${API_URL}response/v2/review/responseSourceReport`, {
        params: filterObject,
      });
  
      let sourceIndex = 1; 
      const formattedRows = [];
      
 
      response.data.data.forEach((source) => {
    
        source.types.forEach((type, typeIndex) => {
          formattedRows.push({
            id: typeIndex === 0 ? sourceIndex : "",
            sourceName: typeIndex === 0 ? source.sourceName : "", 
            typeName: type.typeName,
            totalResponses: type.totalResponses,
            closedResponses: type.closedResponses,
            avgResolutionTime: type.avgResolutionDays?.toFixed(2) || "N/A",
          });
        });
  
        sourceIndex++;
      });
  
      setData((prevData) => ({ ...prevData, rows: formattedRows }));
    } catch (error) {
      toastr.error("Failed to fetch response report");
    }
  };
  
  
  
  
  
  
  

  const handleFilterChange = (name, value) => {
    setFilterObject((prev) => ({ ...prev, [name]: value }));
    setSelectedFilter((prev) => ({ ...prev, [name]: value }));
  };

  const resetFilters = () => {
    filterRef.current?.reset();
    setFilterObject({});
    setSelectedFilter({ type: null, subType: null, department: null });
    toTop();
  };

  const fetchFilterOptions = async (url, setter) => {
    try {
      const response = await axios.get(url);
      setter(response.data.data);
    } catch (error) {
      toastr.error("Failed to fetch filter options");
    }
  };

  useEffect(() => {
    // fetchFilterOptions(`${API_URL}options/responseType/list`, setFilterTypeOption);
    // fetchFilterOptions(`${API_URL}options/responseSubType/list`, setFilterSubTypeOption);
    fetchFilterOptions(`${API_URL}options/department/list`, setFilterDepartmentOption);
  }, []);

  useEffect(() => {
    fetchResponseReport();
  }, [filterObject]);

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Home" breadcrumbItem="Response Source Report" />
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <AvForm onSubmit={(e) => e.preventDefault()} ref={filterRef}>
                  <Row>
                    <Col md="2">
                      <Label>From Date</Label>
                      <AvField
                        type="date"
                        name="fromDate"
                        value={filterObject.fromDate || ""}
                        onChange={(e) => handleFilterChange("fromDate", e.target.value)}
                        max={todayDate}
                      />
                    </Col>
                    <Col md="2">
                      <Label>To Date</Label>
                      <AvField
                        type="date"
                        name="toDate"
                        value={filterObject.toDate || ""}
                        onChange={(e) => handleFilterChange("toDate", e.target.value)}
                        min={filterObject.fromDate || ""}
                        max={todayDate}
                      />
                    </Col>
                    {/* <Col md="2">
                      <Label>Type</Label>
                      <Select
                        menuPosition="fixed"
                        options={filterTypeOption}
                        value={selectedFilter.type}
                        onChange={(selected) => handleFilterChange("type", selected)}
                      />
                    </Col> */}
                    {/* <Col md="2">
                      <Label>Sub Type</Label>
                      <Select
                        menuPosition="fixed"
                        options={filterSubTypeOption}
                        value={selectedFilter.subType}
                        onChange={(selected) => handleFilterChange("subType", selected)}
                      />
                    </Col> */}
                    {/* <Col md="2">
                      <Label>Department</Label>
                      <Select
                        menuPosition="fixed"
                        options={filterDepartmentOption}
                        value={selectedFilter.department}
                        onChange={(selected) => handleFilterChange("department", selected)}
                      />
                    </Col> */}
                    <Col md="2" className="d-flex align-items-center">
                      <Button color="danger" onClick={resetFilters}>Reset</Button>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <MDBDataTable
                  bordered
                  responsive
                  searching
                  entries={10}
                  data={data}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ResponseSourceReport;
