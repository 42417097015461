import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { get } from "../../../../helpers/api_helper.js";
import Breadcrumbs from "../../../../components/Common/Breadcrumb.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ResponseTypeGraph = () => {
  const API_URL = process.env.REACT_APP_APIURL + 'response/v2';
  const [responseData, setResponseData] = useState({
    labels: [],
    counts: []
  });
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: ''
  });

  const fetchResponseTypeData = async () => {
    if (loading) return;

    setLoading(true);
    try {
      const queryParams = new URLSearchParams();
      if (dateRange.startDate) queryParams.append('startDate', dateRange.startDate);
      if (dateRange.endDate) queryParams.append('endDate', dateRange.endDate);
      
      const response = await get(`${API_URL}/graph/product?${queryParams}`);
      
      if (response.success && response.data) {
        const labels = Object.keys(response.data).map(key => 
          key.split(' ').map(word => 
            word.charAt(0).toUpperCase() + word.slice(1)
          ).join(' ')
        );
        const counts = Object.values(response.data).map(item => item.count);

        setResponseData({
          labels,
          counts
        });
      }
    } catch (error) {
      console.error("Error fetching response type data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchResponseTypeData();
  }, [dateRange]); //eslint-disable-line

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDateRange(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleClearDate = (fieldName) => {
    setDateRange(prev => ({
      ...prev,
      [fieldName]: ''
    }));
  };

  const handleClearAllDates = () => {
    setDateRange({
      startDate: '',
      endDate: ''
    });
  };

  // Validate if dates are selected to show/hide clear all button
  const hasDateSelection = dateRange.startDate || dateRange.endDate;

  const chartData = {
    labels: responseData.labels,
    datasets: [
      {
        label: 'Response Count',
        data: responseData.counts,
        backgroundColor: [
          '#FF5252', '#32CD32', '#FFD700', '#8A2BE2',
          '#00CED1', '#FF8C00', '#FF1493', '#20B2AA',
          '#DC143C', '#4169E1', '#FFA07A', '#008080',
          '#9400D3', '#FF4500', '#7CFC00', '#FFDAB9',
          '#4CAF50', '#FFA726', '#42A5F5', '#EC407A',
          '#AB47BC', '#AD93E6', '#26A69A','#5BB974',
          '#9C27B0', '#B39DDB', '#00BFA5', '#FFB300',
          '#FF7043', '#78909C', '#7CB342', '#FF4081',
          '#673AB7', '#29B6F6', '#FF5722', '#607D8B',
          '#CDDC39','#FF9F40', '#4285F4', '#F06292',
        ],
        borderColor: 'rgba(255, 255, 255, 0.5)',
        borderWidth: 1,
        barThickness: 40,
        borderRadius: 4
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: 'Product Wise Response Analysis',
        font: {
          size: 18,
          weight: 'bold'
        },
        padding: {
          bottom: 30
        }
      },
      tooltip: {
        callbacks: {
          label: (context) => `Count: ${context.parsed.y}`
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Number of Responses',
          font: {
            size: 14,
            weight: 'bold'
          }
        },
        ticks: {
          stepSize: 1,
          maxTicksLimit: 8,
          callback: value => Math.round(value)
        }
      },
      x: {
        title: {
          display: true,
          text: 'Product Types',
          font: {
            size: 14,
            weight: 'bold'
          }
        }
      }
    }
  };

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Breadcrumbs title='Home' breadcrumbItem='Product Graph' />
        <div>
          <Row className="mb-4">
              <Col md={8}>
                <div className="d-flex gap-3 align-items-end">
                  <div className="form-group position-relative">
                    <label htmlFor="startDate" className="form-label">Start Date</label>
                    <div className="d-flex align-items-center">
                      <input
                        type="date"
                        id="startDate"
                        name="startDate"
                        className="form-control"
                        value={dateRange.startDate}
                        onChange={handleDateChange}
                      />
                      {dateRange.startDate && (
                        <Button
                          color="link"
                          className="p-0 ms-2"
                          onClick={() => handleClearDate('startDate')}
                        >
                          ✕
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="form-group position-relative">
                    <label htmlFor="endDate" className="form-label">End Date</label>
                    <div className="d-flex align-items-center">
                      <input
                        type="date"
                        id="endDate"
                        name="endDate"
                        className="form-control"
                        value={dateRange.endDate}
                        onChange={handleDateChange}
                      />
                      {dateRange.endDate && (
                        <Button
                          color="link"
                          className="p-0 ms-2"
                          onClick={() => handleClearDate('endDate')}
                        >
                          ✕
                        </Button>
                      )}
                    </div>
                  </div>
                  {hasDateSelection && (
                    <Button
                      color="secondary"
                      outline
                      size="sm"
                      onClick={handleClearAllDates}
                      className="mb-2"
                    >
                      Clear All
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
        </div>
        <Card>
          <CardBody>
            <div style={{ height: "500px" }}>
              {loading ? (
                <div className="d-flex justify-content-center align-items-center h-100">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <Bar data={chartData} options={options} />
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default ResponseTypeGraph;