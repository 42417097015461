import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Modal, Spinner } from "reactstrap"
import { post, del, get, put } from "../../../helpers/api_helper.js"
import toastr from "toastr"
import moment from "moment"
import Swal from "sweetalert2"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Divider } from "@mui/material"
import "./style.scss"
import { clearCookiesExcept, getCookieName } from "../../../helpers/functions.js"
const GmbLogin = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  let gmb = searchParams.get("gmb")

  const clearQueryParams = () => {
    navigate("/social-config")
  }

  const [loggedIn, setLoggedIn] = useState(false)
  const [connected, setConnected] = useState(false)
  const [accounts, setAccounts] = useState([])
  const [loginInfo, setLoginInfo] = useState({})
  const [open, setOpen] = useState(false)
  const [openLog, setOpenLog] = useState(false)
  const [sync, setSync] = useState([])
  const [syncLog, setSyncLog] = useState([])
  const [latestSync, setLatestSync] = useState({})
  useEffect(() => {
    getConnectedStatus()
    getLoginStatus()
    //eslint-disable-next-line
  }, [])

  const getConnectedStatus = () => {
    get(`google/tokeninfo`)
      .then(res => {
        setConnected(true)
        fetchAccounts()
        getSyncLog()
      })
      .catch(err => {
        console.error(err)
        setConnected(false)
      })
  }

  const getSyncLog=()=>{
    get(`google/sync-log`).then(res=>{
      const data = res.data[0]
      setSyncLog(res.data)
      setLatestSync({
        ...data,
        syncDate: moment(data.syncDate).format("DD-MM-YYYY"),
        syncTime: moment(data.syncTime, 'HH:mm:ss').format("hh:mm A"),
      })
    }).catch(err=>{console.error(err.response.data.message)})
  }

  const getLoginStatus = () => {
    get(`google/login-check`)
      .then(res => {
        setLoginInfo(res.data)
        setLoggedIn(true)
      })
      .catch(err => {
        setLoggedIn(false)
        console.log(err)
      })
  }

  const accountTypeMap = {
    PERSONAL: "Personal",
    LOCATION_GROUP: "Location Group",
  }
  const fetchAccounts = () => {
    get(`google/accounts`)
      .then(res => {
        // setAccounts(res.data)
        const accountArr = res.data.map(item => ({
          _id: item._id,
          status: item.status,
          accountName: item.accountName,
          accountNumber: item.accountNumber,
          type: accountTypeMap[item.type],
        }))
        setAccounts(accountArr)
        if (gmb) {
          setOpen(true)
        }
      })
      .catch(err => console.log(err))
  }

  const generateLoginUrl = () => {
    const accessToken = getCookieName()
    clearCookiesExcept([accessToken, `_${accessToken}`]) // clear other cookies from the server if it exists
    get(`google/authurl`)
      .then(res => {
        window.location.href = res.data
      })
      .catch(err => console.log(err))
  }

  const handleLogOut = () => {
    return Swal.fire({
      title: "Are you sure you want to log out?",
      // text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Logout",
    }).then(result => {
      if (result.isConfirmed) {
        post(`google/log-out`)
          .then(res => {
            setLoggedIn(false)
            setAccounts([])
            setConnected(false)
            setLoginInfo({})
            setSyncLog([])
            toastr.success("Google user logged out")
          })
          .catch(err => {
            toastr.error(err?.response?.data?.message)
          })
      }
    })
  }

  const saveAccounts = () => {
    put(`google/accounts`, { data: accounts })
      .then(res => {
        handleModalClose()
      })
      .catch(err => console.log(err))
  }

  const handleModalClose = async () => {
    setOpen(false)
    if (gmb) {
      clearQueryParams()
      gmb = null
    }
    fetchAccounts()
  }

  const handleLogModalClose = async () => {
    setOpenLog(false)

  }

  const handleCheckBoxChange = index => {
    const updateData = [...accounts]
    updateData[index].status = updateData[index].status === 1 ? 0 : 1
    setAccounts(updateData)
  }
  const handleSync = type => {
    const syncTypeMap = {
      'account'  : "fetch-accounts",
      'location'  : "fetchLocations",
      'metric'  : "fetchMetrics", 
      'review'  : "fetchReviews", 
    }
    console.log('syncTypeMap', syncTypeMap[type])
    post(`google/${syncTypeMap[type]}`).then(res=> {
      toastr.success(res.message)
    }).catch(err=> {
      toastr.error(err.response.data.message)
    })
    const newArr = [...sync]
    if (!sync.includes(type)) {
      newArr.push(type)
    }
    setSync(newArr)
    if (syncTimeouts[type]) {
      clearTimeout(syncTimeouts[type])
    }
    const timeoutId = setTimeout(() => {
      setSync(prev => prev.filter(item => item !== type))
      delete syncTimeouts[type]
      getSyncLog()
    }, 5000)
    syncTimeouts[type] = timeoutId
  }

  const syncTimeouts = {}

  const statusMap = {
    0: <span className="badge bg-soft-warning font-size-14">Processing</span>,
    1: <span className="badge bg-soft-success font-size-14">Synced</span>,
    2: <span className="badge bg-soft-danger font-size-14">Failed</span>,
  }

  const logTypeMap = {
    0 : 'Auto-Sync',
    1 : 'Manual-Sync',
    2 : 'Initial Sync',
  }

  const logDataTypeMap = {
    'account' : 'GMB Accounts',
    'location' : 'GMB Locations',
    'review' : 'Google Reviews',
    'metric' : 'GMB Metrics',

  }

  return (
    <>
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="d-flex align-items-center gap-3 m-2">
              <img src="/sm-google.svg" width="40" alt="logo" />
              <h6 className="m-0">Google My Business</h6>
            </div>
            <div>
              <div className="d-flex gap-3 align-items-center">
                {connected ? (
                  <span className="badge bg-soft-success font-size-14" style={{ padding: "11px" }}>
                    Connected
                  </span>
                ) : (
                  <span className="badge bg-soft-danger font-size-14" style={{ padding: "11px" }}>
                    Disconnected
                  </span>
                )}
                {loggedIn ? (
                  <>
                  {loginInfo.confirmation == 1 && loginInfo.initialSync == 1 &&<>
                    <Button
                      disabled={sync.includes('account')}
                      className="bg-soft-info"
                      color="info"
                      onClick={() => handleSync('account')}
                    >
                      <span className="d-flex align-items-center">
                        {sync.includes('account') ? (
                          <Spinner type="grow" style={{ height: "15px", width: "15px" }} />
                        ) : (
                          <i className="uil-refresh"></i>
                        )}
                        <span className="ms-1">Sync Accounts</span>
                      </span>
                    </Button>
                    <Button
                      disabled={sync.includes('location')}
                      className="bg-soft-info"
                      color="info"
                      onClick={() => handleSync('location')}
                    >
                      <span className="d-flex align-items-center">
                        {sync.includes('location') ? (
                          <Spinner type="grow" style={{ height: "15px", width: "15px" }} />
                        ) : (
                          <i className="uil-refresh"></i>
                        )}
                        <span className="ms-1">Sync Locations</span>
                      </span>
                    </Button>
                    <Button
                      disabled={sync.includes('review')}
                      className="bg-soft-info"
                      color="info"
                      onClick={() => handleSync('review')}
                    >
                      <span className="d-flex align-items-center">
                        {sync.includes('review') ? (
                          <Spinner type="grow" style={{ height: "15px", width: "15px" }} />
                        ) : (
                          <i className="uil-refresh"></i>
                        )}
                        <span className="ms-1">Sync Reviews</span>
                      </span>
                    </Button>
                    <Button
                      disabled={sync.includes('metric')}
                      className="bg-soft-info"
                      color="info"
                      onClick={() => handleSync('metric')}
                    >
                      <span className="d-flex align-items-center">
                        {sync.includes('metric') ? (
                          <Spinner type="grow" style={{ height: "15px", width: "15px" }} />
                        ) : (
                          <i className="uil-refresh"></i>
                        )}
                        <span className="ms-1">Sync GMB Metrics</span>
                      </span>
                    </Button></>}
                    <Button className="bg-soft-danger" color="danger" onClick={handleLogOut}>
                      Logout
                    </Button>
                  </>
                ) : (
                  <Button className="bg-soft-primary" color="primary" onClick={generateLoginUrl}>
                    Login
                  </Button>
                )}
              </div>
            </div>
          </div>
          {loggedIn && <Divider sx={{ opacity: "1" }} />}
          {loggedIn && (
            <div className="m-2">
              <div className="d-flex justify-content-between align-items-end">
                <div className="d-flex gap-3 align-items-end">
                  <div className="d-flex gap-1 font-size-14 align-items-end">
                    <img src={loginInfo.picture} alt="profile" width={20} />
                    <span className="fw-bold font-size-14">{loginInfo?.name}</span>
                  </div>
                  <div>
                    <span className="d-flex gap-1 font-size-14 align-items-end">
                      <img width={20} src="/gmail.png" alt="icon" />
                      <span className="font-size-14">{loginInfo?.email}</span>
                    </span>
                  </div>
                  <div className="d-flex gap-1 align-items-center">
                    {sync.length > 0 ? (
                      <Spinner style={{ height: "20px", width: "20px" }} />
                    ) : (
                      <i
                        className="uil-refresh text-primary"
                        style={{
                          width: "20px",
                          height: "20px",
                          fontSize: "20px",
                          display: "inline-block",
                          lineHeight: "20px",
                          textAlign: "center",
                        }}
                      />
                    )}
                    <span className="fw-bold font-size-14">
                      {sync.length > 0
                        ? "Syncing..."
                        : `Last Synced, ${
                            moment().format("DD-MM-YYYY") !==latestSync?.syncDate ? loginInfo?.syncDate : ''
                          }
                      ${latestSync?.syncTime}`}
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-end gap-3">
                  <span onClick={() => setOpen(true)} className="settings-btn">
                    <i className="dripicons-gear font-size-16 mt-1"></i>
                    <span>Account Settings</span>
                  </span>
                  <span onClick={() => setOpenLog(true)} className="settings-btn">
                    <i className="fa fa-list font-size-16"></i>
                    <span>Sync Log</span>
                  </span>
                </div>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
      <Modal
        show={open}
        isOpen={open}
        toggle={() => !gmb && handleModalClose()}
        size="lg"
        centered={true}
        className="lead-modal"
      >
        <div className="modal-header">
          <div className="modal-title">
            <div className="d-flex gap-3 align-items-center">
              <img src="/sm-google.svg" width="40" alt="logo" />
              <h5 className="m-0">Google My Business - Linked Accounts</h5>
            </div>
          </div>
          {!gmb && (
            <button
              style={{ marginBottom: "2px" }}
              type="button"
              onClick={() => handleModalClose()}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          )}
        </div>
        {loggedIn && (
          <div className="modal-body">
            <div className="d-flex justify-content-between gap-3 mb-4 align-items-center">
              <div className="d-flex gap-2 align-items-end">
                <img src={loginInfo.picture} alt="profile" width={20} />
                <span className="fw-bold font-size-16 me-3">{loginInfo?.name}</span>
                <span className="d-flex gap-1 font-size-16">
                  <img width={20} src="/gmail.png" alt="icon" /> <span>{loginInfo?.email}</span>
                </span>
              </div>
              <div className="font-size-14">
                {" "}
                Last Synced :{" "}
                {moment().format("DD-MM-YYYY") !== loginInfo?.upDate && loginInfo?.upDate}{" "}
                <span className="fw-bold">{loginInfo?.upTime}</span>
              </div>
            </div>
            <p className="font-size-14">Select accounts to connect.</p>
            <ul>
              {accounts.map((item, index) => (
                <>
                  <li
                    style={{
                      listStyle: "none",
                      display: "flex",
                      alignItems: "start",
                      gap: "10px",
                      paddingBottom: "10px",
                      paddingTop: "17px",
                      fontSize: "15px",
                      // borderTop: index !== 0 && ".5px solid black",
                    }}
                  >
                    <input
                      checked={item.status === 0}
                      id={`item${index}`}
                      style={{
                        width: "20px", // Increased width
                        height: "20px", // Increased height
                        cursor: "pointer",
                      }}
                      type="checkbox"
                      onClick={() => handleCheckBoxChange(index)}
                    />
                    <label style={{ cursor: "pointer" }} htmlFor={`item${index}`}>
                      {item.accountName}
                    </label>
                    <span>{item.type ? `( ${item.type} )` : ""}</span>
                    <span>{item?.accountNumber ? ` -  AC/No. ${item?.accountNumber}` : ""}</span>
                  </li>
                  <Divider />
                </>
              ))}
            </ul>
            <div className="d-flex justify-content-center gap-3">
              <Button color="primary" className="bg-soft-primary mb-3" onClick={saveAccounts}>
                Save {gmb ? "and Continue" : "Changes"}
              </Button>
              {!gmb && (
                <Button color="danger" className="bg-soft-danger mb-3" onClick={handleModalClose}>
                  Discard
                </Button>
              )}
            </div>
          </div>
        )}
      </Modal>
      <Modal
        show={openLog}
        isOpen={openLog}
        toggle={() =>handleLogModalClose()}
        size="xl"
        centered={true}
        className="lead-modal"
      >
        <div className="modal-header">
          <div className="modal-title">
            <div className="d-flex gap-3 align-items-center">
              <img src="/sm-google.svg" width="40" alt="logo" />
              <h5 className="m-0">Google My Business - Sync Log</h5>
            </div>
          </div>
          {!gmb && (
            <button
              style={{ marginBottom: "2px" }}
              type="button"
              onClick={() => handleLogModalClose()}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          )}
        </div>
        {loggedIn && (
          <div className="modal-body">
            <div>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th style={{width:'120px'}}>Sync Type</th>
                    <th>Data</th>
                    <th>Sync Date</th>
                    <th>Sync Time</th>
                    <th>Status</th>
                    <th>User</th>
                  </tr>
                </thead>
                <tbody>
                  {syncLog.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td >{logTypeMap[item.type]}</td>
                      <td style={{textAlign:'center'}}>{logDataTypeMap[item?.dataType]}</td>
                      <td style={{textAlign:'center'}}>{moment(item.syncDate).format('DD MMM YYYY')}</td>
                      <td style={{textAlign:'center'}}>{moment(item.syncTime, 'HH:mm:ss').format('hh:mm A')}</td>
                      <td style={{textAlign:'center'}}>{statusMap[item.status]}</td>
                      <td style={{textAlign:'center'}}>{item?.addedBy?.firstName || ''} {item?.addedBy?.lastName || ''}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>)}</Modal>
    </>
  )
}

export default GmbLogin
