import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap';
import toastr from 'toastr';
import classnames from 'classnames';
import { put } from "../../helpers/api_helper"
import { get } from "lodash"

const ClientCredentials = () => {
  const [activeTab, setActiveTab] = useState('facebook');
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [credentials, setCredentials] = useState({
    facebook: { appId: '', appSecret: '', accessToken: '', pageId: '', webhookUrl: '', verifyToken: '' },
    instagram: { accessToken: '', userId: '', webhookUrl: '', verifyToken: '' },
    twitter: { apiKey: '', apiKeySecret: '', accessToken: '', accessTokenSecret: '', webhookUrl: '', verifyToken: '' }
  });

  useEffect(() => {
    fetchCredentials();
  }, []);

  const fetchCredentials = async () => {
    try {
      const response = await get(`/client/credentials`);
      setCredentials(response.data.socialCredentials);
    } catch (error) {
      toastr.error('Failed to fetch credentials');
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (platform, field, value) => {
    setCredentials(prev => ({
      ...prev,
      [platform]: {
        ...prev[platform],
        [field]: value
      }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    try {
      await put(
        `/client/credentials`,
        { socialCredentials: credentials },
    );
      toastr.success('Credentials updated successfully');
    } catch (error) {
      toastr.error(error.response?.data?.message || 'Failed to update credentials');
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return <div className="text-center p-5"><Spinner /></div>;
  }

  return (
    <Card>
      <CardHeader>
        <h4 className="mb-0">Social Media Credentials</h4>
      </CardHeader>
      <CardBody>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'facebook' })}
              onClick={() => setActiveTab('facebook')}
            >
              Facebook
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'instagram' })}
              onClick={() => setActiveTab('instagram')}
            >
              Instagram
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'twitter' })}
              onClick={() => setActiveTab('twitter')}
            >
              Twitter
            </NavLink>
          </NavItem>
        </Nav>

        <Form onSubmit={handleSubmit} className="mt-4">
          <TabContent activeTab={activeTab}>
            <TabPane tabId="facebook">
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label>App ID</Label>
                    <Input
                      type="text"
                      value={credentials.facebook.appId}
                      onChange={(e) => handleInputChange('facebook', 'appId', e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>App Secret</Label>
                    <Input
                      type="password"
                      value={credentials.facebook.appSecret}
                      onChange={(e) => handleInputChange('facebook', 'appSecret', e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Access Token</Label>
                    <Input
                      type="password"
                      value={credentials.facebook.accessToken}
                      onChange={(e) => handleInputChange('facebook', 'accessToken', e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Page ID</Label>
                    <Input
                      type="text"
                      value={credentials.facebook.pageId}
                      onChange={(e) => handleInputChange('facebook', 'pageId', e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Webhook URL</Label>
                    <Input
                      type="text"
                      value={credentials.facebook.webhookUrl}
                      onChange={(e) => handleInputChange('facebook', 'webhookUrl', e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Verify Token</Label>
                    <Input
                      type="text"
                      value={credentials.facebook.verifyToken}
                      onChange={(e) => handleInputChange('facebook', 'verifyToken', e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
            </TabPane>

            <TabPane tabId="instagram">
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label>Access Token</Label>
                    <Input
                      type="password"
                      value={credentials.instagram.accessToken}
                      onChange={(e) => handleInputChange('instagram', 'accessToken', e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>User ID</Label>
                    <Input
                      type="text"
                      value={credentials.instagram.userId}
                      onChange={(e) => handleInputChange('instagram', 'userId', e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Webhook URL</Label>
                    <Input
                      type="text"
                      value={credentials.instagram.webhookUrl}
                      onChange={(e) => handleInputChange('instagram', 'webhookUrl', e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Verify Token</Label>
                    <Input
                      type="text"
                      value={credentials.instagram.verifyToken}
                      onChange={(e) => handleInputChange('instagram', 'verifyToken', e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
            </TabPane>

            <TabPane tabId="twitter">
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label>API Key</Label>
                    <Input
                      type="text"
                      value={credentials.twitter.apiKey}
                      onChange={(e) => handleInputChange('twitter', 'apiKey', e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>API Key Secret</Label>
                    <Input
                      type="password"
                      value={credentials.twitter.apiKeySecret}
                      onChange={(e) => handleInputChange('twitter', 'apiKeySecret', e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Access Token</Label>
                    <Input
                      type="password"
                      value={credentials.twitter.accessToken}
                      onChange={(e) => handleInputChange('twitter', 'accessToken', e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Access Token Secret</Label>
                    <Input
                      type="password"
                      value={credentials.twitter.accessTokenSecret}
                      onChange={(e) => handleInputChange('twitter', 'accessTokenSecret', e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Webhook URL</Label>
                    <Input
                      type="text"
                      value={credentials.twitter.webhookUrl}
                      onChange={(e) => handleInputChange('twitter', 'webhookUrl', e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label>Verify Token</Label>
                    <Input
                      type="text"
                      value={credentials.twitter.verifyToken}
                      onChange={(e) => handleInputChange('twitter', 'verifyToken', e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
            </TabPane>
          </TabContent>

          <div className="text-end mt-4">
            <Button color="primary" type="submit" disabled={isSaving}>
              {isSaving ? <Spinner size="sm" /> : 'Save Credentials'}
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default ClientCredentials;