import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Card, CardBody, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, CardHeader } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import moment from "moment";
import toastr from "toastr";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { get, post, put, del } from "../../../helpers/api_helper.js";
import Swal from "sweetalert2";

const Product = () => {
  const API_URL_USER = process.env.REACT_APP_APIURL + 'product'
  const API_URL = process.env.REACT_APP_APIURL

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [categories, setCategories] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    code: "",
    shortCode: "",
    category: null
  });
  const [isEditing, setIsEditing] = useState(false);
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const columns = [
    {
      label: "#",
      field: "index",
      sort: "asc",
      width: 50,
    },
    {
      label: "Date",
      field: "date",
      sort: "asc",
      width: 100,
    },
    {
      label: "Name",
      field: "name",
      sort: "asc",
      width: 150,
    },
    {
      label: "Short Code",
      field: "shortCode",
      sort: "asc",
      width: 100,
    },
    {
      label: "Code",
      field: "code",
      sort: "asc",
      width: 100,
    },
    {
      label: "Category",
      field: "category",
      sort: "asc",
      width: 150,
    },
    {
      label: "Actions",
      field: "actions",
      sort: false,
      width: 100,
    },
  ];

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await get(`${API_URL_USER}/products`);
      setProducts(response.products || []);
    } catch (error) {
      console.error('Error fetching products:', error);
      toastr.error(error.message || "Error fetching products");
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await get(`${API_URL}options/categories/list`);
      setCategories(response.options || []);
    } catch (error) {
      console.error('Error fetching categories:', error);
      toastr.error("Error loading categories");
    }
  };

  useEffect(() => {
    fetchProducts();
    fetchCategories();
  }, []); //eslint-disable-line

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    
    if (touched[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: validateField(name, value)
      }));
    }
  };

  const handleSelectChange = (selectedOption) => {
    setFormData({
      ...formData,
      category: selectedOption
    });

    if (touched.category) {
      setErrors(prev => ({
        ...prev,
        category: validateField('category', selectedOption)
      }));
    }
  };

  const validateField = (name, value) => {
    switch (name) {
      case 'name':
      if (!value) {
        return 'Name is required';
      } else if (value.length < 2) {
        return 'Name must be at least 2 characters long';
      } else if (value.length > 50) {
        return 'Name cannot exceed 50 characters';
      } else if (/^\d+$/.test(value)) {
        return 'Name cannot contain only numbers';
      } else if (!/^[a-zA-Z0-9\s-]*$/.test(value)) {
        return 'Name can only contain letters, numbers, spaces, and hyphens';
      }
      return '';

      case 'code':
      if (!value) {
        return 'Code is required';
      } else if (value.length < 2) {
        return 'Code must be at least 2 characters long';
      } else if (value.length > 20) {
        return 'Code cannot exceed 20 characters';
      }
      return '';

      case 'shortCode':
      if (!value) {
        return 'Short code is required';
      } else if (value.length < 2) {
        return 'Short code must be at least 2 characters long';
      } else if (value.length > 10) {
        return 'Short code cannot exceed 10 characters';
      }
      return '';

      case 'category':
        return !value ? 'Category is required' : '';
        
      default:
        return '';
    }
  };

  const handleBlur = (field = null) => {
    if (field === 'category') {
      setTouched(prev => ({
        ...prev,
        category: true
      }));
      setErrors(prev => ({
        ...prev,
        category: validateField('category', formData.category)
      }));
    } else if (field?.target) {
      const { name, value } = field.target;
      setTouched(prev => ({
        ...prev,
        [name]: true
      }));
      setErrors(prev => ({
        ...prev,
        [name]: validateField(name, value)
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const newErrors = {};
    Object.keys(formData).forEach(key => {
      const error = validateField(key, formData[key]);
      if (error) {
        newErrors[key] = error;
      }
    });

    const newTouched = {};
    Object.keys(formData).forEach(key => {
      newTouched[key] = true;
    });

    setTouched(newTouched);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        // Set submitting to true to prevent multiple submissions
        setSubmitting(true);
        
        const payload = {
          ...formData,
          category: formData.category?.value
        };

        if (isEditing) {
          await put(`${API_URL_USER}/products/${selectedProduct._id}`, payload);
          toastr.success("Product updated successfully");
        } else {
          await post(`${API_URL_USER}/products`, payload);
          toastr.success("Product created successfully");
        }
        resetForm();
        fetchProducts();
      } catch (error) {
        toastr.error(error.message || "Product already exists");
      } finally {
        // Set submitting back to false when operation completes
        setSubmitting(false);
      }
    }
  };

  const handleView = (product) => {
    setSelectedProduct(product);
    setModalVisible(true);
  };

  const handleEdit = (product) => {
    setFormData({
      name: product.name,
      code: product.code,
      shortCode: product.shortCode,
      category: categories.find(option => option.value === product.category._id)
    });
    setSelectedProduct(product);
    setIsEditing(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure you want to delete this product?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`${API_URL_USER}/products/${id}`)
          .then(res => {
            fetchProducts()
            toastr.success(res.message || "Product deleted successfully");
          })
          .catch(err => {
            toastr.error(err.response?.data?.message || "Error deleting product");
          })
      }
    })
  }

  const resetForm = () => {
    setFormData({
      name: "",
      code: "",
      shortCode: "",
      category: null
    });
    setIsEditing(false);
    setSelectedProduct(null);
    setTouched({});
    setErrors({});
    setSubmitting(false);
  };

  const tableData = useMemo(() => {
    const rows = products.map((product, index) => ({
      index: index + 1,
      date: moment(product.createdAt).format("DD-MM-YYYY"),
      name: product.name,
      shortCode: product.shortCode,
      code: product.code,
      category: product.category?.category || 'N/A',
      actions: (
        <div className="d-flex justify-content-center align-items-center gap-3">
          <Button
            color="link"
            className="p-0 me-2"
            onClick={() => handleView(product)}
          >
            <FontAwesomeIcon icon={faEye} color='#3D3D3D' />
          </Button>
          <Button
            color="link"
            className="p-0 me-2"
            onClick={() => handleEdit(product)}
          >
            <FontAwesomeIcon icon={faPenToSquare} color='#3D3D3D' />
          </Button>
          <Button
            color="link"
            className="p-0"
            onClick={() => handleDelete(product._id)}
          >
            <FontAwesomeIcon icon={faTrash} color='#3D3D3D' />
          </Button>
        </div>
      ),
    }));

    return {
      columns,
      rows
    };
  }, [products]); //eslint-disable-line

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Home" breadcrumbItem="Product" />
        
        {/* Add/Edit Form */}
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h4 className="card-title mb-3 fw-bold">
                  {isEditing ? "Edit Product" : "Add Product"}
                </h4>
              </CardHeader>
              <CardBody>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>
                          Product Name<span className="text-danger"> *</span>
                        </Label>
                        <input
                          name="name"
                          className={`form-control ${errors.name && touched.name ? 'is-invalid' : ''}`}
                          type="text"
                          value={formData.name}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          placeholder="Enter Product Name"
                        />
                        {errors.name && touched.name && (
                          <div className="invalid-feedback">
                            {errors.name}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>
                          Code<span className="text-danger"> *</span>
                        </Label>
                        <input
                          name="code"
                          className={`form-control ${errors.code && touched.code ? 'is-invalid' : ''}`}
                          type="text"
                          value={formData.code}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          placeholder="Enter Code"
                        />
                        {errors.code && touched.code && (
                          <div className="invalid-feedback">
                            {errors.code}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>
                          Short Code<span className="text-danger"> *</span>
                        </Label>
                        <input
                          name="shortCode"
                          className={`form-control ${errors.shortCode && touched.shortCode ? 'is-invalid' : ''}`}
                          type="text"
                          value={formData.shortCode}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          placeholder="Enter Short Code"
                        />
                        {errors.shortCode && touched.shortCode && (
                          <div className="invalid-feedback">
                            {errors.shortCode}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>
                          Category<span className="text-danger"> *</span>
                        </Label>
                        <Select
                          value={formData.category}
                          onChange={handleSelectChange}
                          onBlur={() => handleBlur('category')}
                          options={categories}
                          className={`basic-single ${errors.category && touched.category ? 'is-invalid' : ''}`}
                          classNamePrefix="select"
                          placeholder="Select Category"
                          isClearable
                        />
                        {errors.category && touched.category && (
                          <div className="invalid-feedback" style={{ display: 'block' }}>
                            {errors.category}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3" style={{ paddingTop: "28px" }}>
                        <Button color="primary" type="submit" className="me-2" disabled={submitting}>
                          {submitting ? "Processing..." : (isEditing ? "Update" : "Submit")}
                        </Button>
                        <Button color="danger" onClick={resetForm}>
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Product List */}
        <Row>
          <Col className="col-12">
            <Card>
              <CardHeader>
                <h4 className="card-title mb-3 fw-bold">Product List</h4>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div className="text-center">Loading...</div>
                ) : (
                  <MDBDataTable
                    responsive
                    bordered
                    data={tableData}
                    searching={true}
                    paging={true}
                    info={true}
                    noRecordsFoundLabel="No products found"
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* View Modal */}
        <Modal
          isOpen={modalVisible}
          toggle={() => setModalVisible(!modalVisible)}
          className="modal-dialog-centered"
          size="lg"
        >
          <ModalHeader toggle={() => setModalVisible(false)}>
            <h5 className="mb-0">Product Details</h5>
          </ModalHeader>
          <ModalBody>
            {selectedProduct && (
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="fw-bold">Product Name:</Label>
                    <p>{selectedProduct.name}</p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="fw-bold">Code:</Label>
                    <p>{selectedProduct.code}</p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="fw-bold">Short Code:</Label>
                    <p>{selectedProduct.shortCode}</p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="fw-bold">Category:</Label>
                    <p>{selectedProduct.category?.category || 'N/A'}</p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="fw-bold">Created At:</Label>
                    <p>{moment(selectedProduct.createdAt).format("DD-MM-YYYY")}</p>
                  </div>
                </Col>
              </Row>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModalVisible(false)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default Product;