/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useEffect, useRef, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Card, CardBody, CardHeader, Row, Col, Button, Label } from "reactstrap"
import { Tooltip } from "@mui/material"
import TablePagination from "../../../components/Common/TablePagination"
import _, { debounce, isArray } from "lodash"
import "../style.scss"
import { jsonToExcel, toTop } from "../../../helpers/functions"
import moment from "moment"
import Select from "react-select"
import { AvField, AvForm } from "availity-reactstrap-validation"
import CreatableSelect from "react-select/creatable"
import makeAnimated from "react-select/animated"
import { API_URL } from "../../../config"
import toastr from "toastr"
import { BulkUploadModal } from "../../../components/Whatsapp"
import * as mainConfig from "../config"
import { del, get, post, put } from "../../../helpers/api_helper"

const animatedComponents = makeAnimated()
const index = () => {
  const [masterObj, setMasterObj] = useState([{ objId: Date.now(), name: "", mobile: "" }])
  const [selected, setSelected] = useState([{ category: "", label: [] }])
  const [filterQuery, setFilterQuery] = useState("")
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState({})
  const [exportData, setExportData] = useState([])

  const [options, setOptions] = useState({})

  useEffect(() => {
    optionsFetch(mainConfig.OPTIONS_ROUTE.contactCategory(1), "category")
    optionsFetch(mainConfig.OPTIONS_ROUTE.contactLabel, "label")
  }, [])

  const optionsFetch = async (OPTION_PATH, NAME) => {
    try {
      let res = await get(`${API_URL}${OPTION_PATH}`)
      if (res.data) setOptions(prev => ({ ...prev, [NAME]: res.data }))
    } catch (error) {
      console.log(`${NAME} options fetch failed`)
    }
  }

  const formRef = useRef()
  const [file, setFile] = useState(null)
  const [show, setShow] = useState(false)

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const handleChange = (e, idx) => {
    const { name, value } = e.target

    let trimmedName = name.split("-")[0]

    setMasterObj(prev => {
      const updatedObj = [...prev]
      let specificObj = updatedObj[idx]
      specificObj = { ...specificObj, [trimmedName]: value }
      updatedObj[idx] = specificObj
      return updatedObj
    })
  }

  const handleSelectChange = (e, name, idx) => {
    let value = isArray(e) ? e.map(item => item?.value) : e?.value

    setSelected(prev => {
      const updatedObj = [...prev]
      let specificObj = updatedObj[idx]
      specificObj = { ...specificObj, [name]: e }
      updatedObj[idx] = specificObj
      return updatedObj
    })

    setMasterObj(prev => {
      const updatedObj = [...prev]
      let specificObj = updatedObj[idx]
      specificObj = { ...specificObj, [name]: value ?? "" }
      updatedObj[idx] = specificObj
      return updatedObj
    })
  }

  const handlePageChange = value => {
    setPage(value)
    fetchContactData(value)
  }

  const reset = () => {
    toTop()
    setMasterObj([{ name: "", mobile: "" }])
    setSelected([{ category: "", label: [] }])
    formRef?.current?.reset()
  }

  const toggle = () => {
    setShow(!show)
  }

  const handleSubmit = async () => {
    toTop()
    try {
      if (isArray(masterObj) && masterObj[0]?._id) {
        let res = await put(
          mainConfig.ROUTE.contact({ put: true }, masterObj[0]?._id, {}),
          masterObj[0]
        )

        if (res.message) toastr.success(res.message)
      } else {
        let res = await post(mainConfig.ROUTE.contact({ post: true }, _, {}), masterObj)

        if (res.message) toastr.success(res.message)
      }

      fetchContactData()
      reset()
    } catch (error) {
      toastr.error(error?.message ?? "Form submission failed")
    }
  }

  const handleDelete = async id => {
    try {
      let res = await del(mainConfig.ROUTE.contact({ del: true }, id, {}))
      toastr.success(res.message ?? "Successfully deleted")
      fetchContactData()
    } catch (error) {
      toastr.error(error?.message ?? "Contact delete failed")
    }
  }

  const handleUpdate = doc => {
    let obj = [
      {
        _id: doc._id,
        name: doc?.name ?? "",
        mobile: doc?.mobile ?? "",
        category: doc?.category?._id ?? null,
        labels: isArray(doc.labels) ? doc.labels.map(item => item?.value) : [],
      },
    ]

    setSelected([
      {
        category: doc?.category ?? "",
        label: doc?.labels ?? [],
      },
    ])
    setMasterObj(obj)
  }

  useEffect(() => {
    fetchContactData()
  }, [])

  const fetchContactData = async (currentPage = page, currentFilterQuery = filterQuery) => {
    const query = new URLSearchParams()
    query.append("search", currentFilterQuery)
    const queryString = query.toString()

    let exportDataCreate = []
    try {
      let res = await get(
        `${mainConfig.ROUTE.contact({}, _, { currentPage, limit, query: queryString })}`
      )

      if (res) {
        const { data, count } = res

        data.map((doc, idx) => {
          doc.id = (currentPage - 1) * limit + idx + 1
          doc.date1 = moment(doc?.date).format("DD-MM-YYYY")

          let categoryName = doc.category?.name ?? "---"

          doc.categoryDisplay = (
            <span class="badge bg-soft-primary font-size-12">{categoryName}</span>
          )

          // data.addedBy = data?.addedBy?.firstName
          //   ? `${data?.addedBy?.firstName} ${data?.addedBy?.lastName}`
          //   : data?.addedBy
          //     ? data?.addedBy
          //     : ""

          doc.label = (
            <Select
              className="label-select-disabled"
              value={doc.labels}
              placeholder={"Select labels..."}
              isMulti={true}
              isDisabled={true}
            />
          )

          let tempExportData = {}
          tempExportData.Id = doc.id ?? "---"
          tempExportData.Date = doc.date1 ?? "---"
          tempExportData.Name = doc.name ?? "---"
          tempExportData.Mobile = doc.mobile ?? "---"
          tempExportData.Category = categoryName
          tempExportData.Labels = doc.labels?.map(item => item?.label)?.join(", ") ?? "---"
          exportDataCreate.push(tempExportData)

          doc.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop()
                  handleUpdate(doc)
                }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  handleDelete(doc?._id)
                }}
              ></i>
            </div>
          )

          return doc
        })

        const totalPage = Math.ceil(Number(count) / limit)
        setTotalPage(totalPage)
        setTableData(data)
        setExportData(exportDataCreate)
      }
    } catch (err) {
      console.error(err?.message)
    }
  }

  const handleExport = () => {
    if (exportData?.length > 0) {
      jsonToExcel(exportData, `contacts-${moment().format("DD-MM-YY")}`)
    } else {
      toastr.info("There is not data to export!")
    }
  }

  const debouncedFetchData = useCallback(debounce(fetchContactData, 300), [])

  useEffect(() => {
    debouncedFetchData(page, filterQuery)
    return debouncedFetchData.cancel
  }, [filterQuery])

  const handleFilterReset = () => setFilterQuery("")

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date",
        field: "date1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 150,
      },
      {
        label: "Category",
        field: "categoryDisplay",
        sort: "asc",
        width: 150,
      },
      {
        label: "Label",
        field: "label",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "action",
        sort: false,
        width: 100,
      },
    ],
    rows: tableData,
  }

  const handleCreateOption = (e, name) => {
    if (name === "label" || name === "category") {
      setIsLoading({ [name]: true })

      post(`${mainConfig.ROUTE.manage({ [name]: true })}`, { name: e })
        .then(res => {
          setIsLoading({ [name]: false })
          setOptions(prev => ({ ...prev, [name]: res.data }))

          if (name === "label") optionsFetch(mainConfig.OPTIONS_ROUTE.contactLabel, "label")
          if (name === "category")
            optionsFetch(mainConfig.OPTIONS_ROUTE.contactCategory(1), "category")

          toastr.success(`${name} create successfully`)
        })
        .catch(err => {
          setIsLoading({ [name]: false })
          toastr.info(`${name} create failed`)
        })
    }
  }

  const addMoreInput = () => {
    if (masterObj?.length < 3) {
      toTop()
    }
    if (masterObj?.length < 5) {
      setMasterObj(prev => [...prev, { objId: Date.now() }])
    } else {
      toastr.info("Limit Reached. You can only add a maximum of 5 contacts.")
    }
  }

  const removeSpecificInput = idx => {
    setMasterObj(masterObj.filter(doc => doc.objId !== masterObj[idx]?.objId))
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid whatsapp-container">
          <Breadcrumbs title="Home" breadcrumbItem="Contacts" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleSubmit()
                    }}
                  >
                    {masterObj.map((_, idx) => (
                      <Row
                        key={idx}
                        className={`${isArray(masterObj) && idx !== 0 && "mt-4"} position-relative`}
                      >
                        <Col md={3}>
                          <div className="mb-3">
                            <Label>
                              Name<span className="text-danger"> *</span>
                            </Label>
                            <AvField
                              name={`name-${idx}`}
                              type="text"
                              placeholder="Name"
                              value={isArray(masterObj) ? masterObj[idx]?.name : ""}
                              onChange={e => handleChange(e, idx)}
                              errorMessage="Enter a Name"
                              validate={{
                                required: { value: true },
                              }}
                            />
                          </div>
                        </Col>

                        <Col md={3}>
                          <div className="mb-3">
                            <Label>
                              Mobile<span className="text-danger"> *</span>
                            </Label>
                            <AvField
                              name={`mobile-${idx}`}
                              type="number"
                              placeholder="Mobile"
                              value={isArray(masterObj) ? masterObj[idx]?.mobile : ""}
                              onChange={e => handleChange(e, idx)}
                              errorMessage="Enter valid mobile no"
                              validate={{
                                required: { value: true },
                                pattern: {
                                  value: "(0|91)?[6-9][0-9]{9}",
                                },
                              }}
                            />
                          </div>
                        </Col>

                        <Col md={3}>
                          <div className="mb-3">
                            <Label>Category</Label>
                            <CreatableSelect
                              name="category"
                              placeholder="Select a Category"
                              options={options.category}
                              value={isArray(selected) ? selected[idx]?.category : ""}
                              onChange={e => handleSelectChange(e, "category", idx)}
                              isOptionDisabled={e =>
                                isArray(selected) && selected[idx]?.category?.length >= 5
                                  ? true
                                  : false
                              }
                              onCreateOption={e => handleCreateOption(e, "category")}
                              isClearable
                              isDisabled={isLoading?.category}
                              isLoading={isLoading?.category}
                            />
                          </div>
                        </Col>

                        <Col md={3}>
                          <div className="mb-3">
                            <Label>Label</Label>
                            <CreatableSelect
                              name="label"
                              placeholder="Select a label"
                              options={options.label}
                              isMulti={true}
                              closeMenuOnSelect={false}
                              value={isArray(selected) ? selected[idx]?.label : ""}
                              onChange={e => handleSelectChange(e, "label", idx)}
                              isOptionDisabled={e =>
                                isArray(selected) && selected[idx]?.label?.length >= 5
                                  ? true
                                  : false
                              }
                              onCreateOption={e => handleCreateOption(e, "label")}
                              isClearable
                              components={animatedComponents}
                              isDisabled={isLoading?.label}
                              isLoading={isLoading?.label}
                            />
                          </div>
                        </Col>

                        {isArray(masterObj) && idx !== 0 && (
                          <div
                            style={{
                              right: "13px",
                              top: "-7px",
                              width: "30px",
                            }}
                            className="position-absolute"
                          >
                            <i
                              className="uil-minus-square"
                              style={{
                                fontSize: "1.2em",
                                cursor: "pointer",
                                marginLeft: "0.5rem",
                                marginRight: "0.5rem",
                              }}
                              onClick={() => removeSpecificInput(idx)}
                            ></i>
                          </div>
                        )}
                      </Row>
                    ))}

                    <Row>
                      <Col md="12">
                        <div
                          className="mb-3"
                          style={{
                            paddingTop: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <Button
                              color={
                                isArray(masterObj) && masterObj[0]?._id ? "warning" : "success"
                              }
                              style={{ marginRight: "10px" }}
                              type="submit"
                            >
                              {isArray(masterObj) && masterObj[0]?._id ? "Update" : "Add"}
                            </Button>

                            <Button onClick={reset} color="danger" type="button">
                              Reset
                            </Button>
                          </div>

                          {isArray(masterObj) && !masterObj[0]?._id && masterObj?.length < 5 && (
                            <div>
                              <i
                                className="uil-plus-square"
                                style={{
                                  fontSize: "1.2em",
                                  cursor: "pointer",
                                  marginLeft: "0.5rem",
                                  marginRight: "0.5rem",
                                }}
                                onClick={addMoreInput}
                              ></i>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card>
            <CardHeader>
              <h6 className="bold" style={{ textWrap: "nowrap" }}>
                Contact List
              </h6>

              <div className="extra-options">
                <div className="input-output">
                  <Tooltip title="Import (.xls, .xlsx)" arrow placement="bottom">
                    <Button onClick={toggle}>
                      <i className="uil-import" />
                      <span>{file ? "Imported" : "Import"}</span>
                    </Button>
                  </Tooltip>
                  <div className="divider"></div>
                  <Tooltip title="Export xlsx file" arrow placement="bottom">
                    <Button onClick={handleExport}>
                      <i className="uil-export" />
                      <span>Export</span>
                    </Button>
                  </Tooltip>
                </div>

                <Tooltip title="Download sample file">
                  <a
                    href={`./sample/bulk_upload_contacts.xlsx`}
                    target="_blank"
                    rel="noreferrer"
                    download
                    style={{ fontSize: "1rem", width: "fit-content" }}
                    className="d-flex align-items-center btn btn-success download-btn"
                  >
                    <i style={{ fontSize: "13px" }} className="fa fa-solid fa-download" />
                    <span style={{ marginLeft: "5px", fontSize: "13px" }}>
                      Download Sample Sheet
                    </span>
                  </a>
                </Tooltip>
              </div>
            </CardHeader>
            <CardBody>
              <AvForm>
                <Row>
                  <Col md={3}>
                    <AvField
                      name="search"
                      type="text"
                      placeholder="Search name or mobile"
                      value={filterQuery}
                      onChange={e => setFilterQuery(e.target.value)}
                    />
                  </Col>
                  <Col md={3}>
                    <Button color="danger" type="button" onClick={handleFilterReset}>
                      Reset
                    </Button>
                  </Col>
                </Row>
              </AvForm>

              <>
                <TablePagination
                  className="whatsapp-tableModule"
                  data={data}
                  page={page}
                  onChange={handlePageChange}
                  count={totalPage}
                />
              </>
            </CardBody>
          </Card>
        </div>
      </div>

      <BulkUploadModal
        show={show}
        toggle={toggle}
        setFile={setFile}
        urlPath={"whatsapp/contact/bulk-upload/"}
        selectOptions={"options/contactCategory"}
      />
    </>
  )
}

export default index
