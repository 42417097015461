import React, { useEffect, useRef, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label, Modal, FormFeedback } from "reactstrap"
import Select from "react-select"
import toastr from "toastr"
import { Tooltip } from "@mui/material"
import { MDBDataTable } from "mdbreact"
import ReactSelect from "react-select"
import { get, post, put } from "../../../helpers/api_helper"
import queryString from "query-string"
import moment from "moment"
import { getDate } from "../../../helpers/functions"
const NewResponse = () => {
  const formRef = useRef()
  const filterRef = useRef()
  const fileInputRef = useRef(null);
  const [popupView, setPopupView] = useState(false)
  const [modalDetails, setModalDetails] = useState(null)
  const [loading, setLoading] = useState(false)
  const [masterObject, setMasterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})
  const limit = 100
  const [page, setPage] = useState(1)
  const [totalCount, setTotalCount] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [image, setImage] = useState("")
  const [tableData, setTableData] = useState([])
  const [tableModalData, setTableModalData] = useState([])
  const [filterObject, setFilterObject] = useState({})
  const [selectFilter, setSelectFilter] = useState({})
  const [sourceOptions, setSourceOptions] = useState([])
  const [sourceSubTypeOptions, setSourceSubTypeOptions] = useState([])
  const [departmentOption, setDepartmentOption] = useState([])
  const [productOption, setProductOption] = useState([])
  const [typeOption, setTypeOption] = useState([])
  const [subTypeOption, setSubTypeOption] = useState([])
  const [formData, setFormData] = useState({})
  const [errors, setErrors] = useState({});

  const [fileInputKey, setFileInputKey] = useState(Date.now());


  const todayDate = getDate()
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Source",
        field: "source",
        width: 150,
      },
      {
        label: "Unique Id",
        field: "uniqueId",
        width: 150,
      },
      // {
      //   label: "Reference",
      //   field: "Reference",
      //   width: 150,
      // },
      {
        label: "Name",
        field: "Name",
        width: 150,
      },
      {
        label: "Priority",
        field: "Priority",
        width: 150,
      },
      {
        label: "Department",
        field: "Department",
        width: 150,
      },
      {
        label: "Type",
        field: "Type",
        width: 150,
      },
      {
        label: "User",
        field: "User",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        width: 150,
      },

      {
        label: "Actions",
        field: "options",
        sort: "desc",
        width: 400,
      },
    ],
    rows: tableData,
  }
  const viewdetail_data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Source",
        field: "source",
        width: 150,
      },

      {
        label: "Comments",
        field: "comments",
        width: 150,
      },
      {
        label: "User",
        field: "User",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        width: 150,
      },
    ],
    rows: tableModalData,
  }

  useEffect(() => {
    handleSourceOptions()
    handleSourceTypeOptions()
    handledepartmentOptions()
    handleTypeOptions()
    handleSubTypeOptions()
    handleproductOptions()
    handleTableData()
  }, []) // eslint-disable-line
  const handleValueChange = async ({ value, name }) => {
    setMasterObject(prev => ({ ...prev, [name]: value }));

    // Remove error for the field when user types
    setErrors(prev => {
      if (prev[name]) {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      }
      return prev;
    });
  };

  const handleFilterSelectChange = async ({ selected, name }) => {
    const obj = { ...selectFilter }
    obj[name] = selected
    setSelectFilter(obj)
    handleFilterValueChange({ value: selected.value, name })
  }

  const handleFilterValueChange = async ({ value, name }) => {
    const obj = { ...filterObject }
    obj[name] = value
    setFilterObject(obj)
  }

  const handleSelectValueChange = async (selected, name) => {
    console.log("selected", selected, "name", name)
    console.log("type of name is", typeof (name))
    const updatedFields = { ...selectedFields, [name]: selected };
    setSelectedFields(updatedFields);
    if (errors[name]) {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    }
    if (name === "source") {
      updatedFields.source = { label: selected.displayName, value: selected.value };
      handleSourceTypeOptions(selected.value);
    }
    if (name === "type") {
      updatedFields.responseType = { label: selected.displayName, value: selected.value };
      handleSubTypeOptions(selected.value);
    }
    handleValueChange({ value: selected.value, name })
  }
  const toTop = () => window.scroll({ top: 0, left: 0, behavior: "smooth" })


  const handleSubmit = async () => {
    if (loading) return;
    try {
      setLoading(true);
      console.log("sumbit button is clicked.....!")
      let newErrors = {};
      if (!selectedFields.source) {
        console.log("Source Field is not Selected............!")
        newErrors.source = "Source is required";
        console.log("New Reuqied Field Error1813", newErrors)
      }

      if (!masterObject.name) newErrors.name = "Name is required";
      if (!masterObject.mobile) newErrors.mobile = "Mobile Number is required";
      if (!masterObject.email) newErrors.email = "Email is required";
      if (!masterObject.comments) newErrors.comments = "Comments is required";
      else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(masterObject.email)) {
        newErrors.email = "Enter a valid email address";
      }
      if (!masterObject.response_date) newErrors.response_date = "Response Date is required";

      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        setLoading(false);
        return;
      }
      console.log("New Reuqied Field Error", newErrors)
      let response
      if (masterObject.id) response = await put(`response/v2/review/newresponse/add`, masterObject)
      else response = await post(`response/v2/review/newresponse/add`, masterObject)

      setMasterObject({})
      setSelectedFields({})
      setErrors({})

      // reset()
      toastr.success(response.message)
      handleTableData()
    } catch (error) {
      // toastr.error(error?.response?.data.message)
    } finally {
      setLoading(false); // Re-enable button after submission
    }
  }
  const handleSourceOptions = async () => {
    try {
      const response = await get(`options/source`)

      setSourceOptions(response.data)
    } catch (error) { }
  }
  const handleSourceTypeOptions = async (sourceId) => {
    if (!sourceId) return;
    try {
      const response = await get(`options/source/subType?source=${sourceId}`);
      const formattedOptions = response.data.map(option => ({
        label: option.displayName,
        value: option.value
      }));

      setSourceSubTypeOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching source sub-type options", error);
    }
  };
  const handledepartmentOptions = async () => {
    try {
      const response = await get(`options/department/list`)
      setDepartmentOption(response.data)
    } catch (error) { }
  }
  const handleproductOptions = async () => {
    try {
      const response = await get(`options/product/list`)
      console.log("Response", response)
      setProductOption(response.options)
    } catch (error) { }
  }
  const handleTypeOptions = async () => {
    try {
      const response = await get(`options/responseType/list`)
      setTypeOption(response.data)
    } catch (error) { }
  }
  const handleSubTypeOptions = async (typeId) => {
    if (!typeId) return;
    try {
      const response = await get(`options/responseSubType/list?responseType=${typeId}`);
      console.log("response is", response)
      const formattedOptions = response.data.map(option => ({
        label: option.name,
        value: option.value
      }));

      setSubTypeOption(formattedOptions);
    } catch (error) { }
  }
  const handleTableData = async (currentPage = page) => {
    const query = `?page=${currentPage}&limit=${limit}&${queryString.stringify(filterObject)}`
    const response = await get(`response/v2/review/newresponse${query}`)

    const { count, data } = response

    data.map((item, index) => {
      item.id = (currentPage - 1) * limit + index + 1
      item.date = item.date ? moment(item.date).format("DD-MM-YYYY") : "----"
      item.uniqueId = item.uniqueId ? item.uniqueId : "-----"
      item.source = item.sourceName ? item.sourceName : "----"
      item.Name = item.firstName ? item.firstName : "----"
      item.status =
      item.follow_up_status === 0
      ? "New"
      : item.follow_up_status === 1
        ? "Pending"
        : item.follow_up_status === 2
          ? "Closed"
          : item.follow_up_status === 3
            ? "Responded"
            : item.follow_up_status === 4
              ? "Deept Change"
              : item.follow_up_status === 5
                ? "Reopen"
                : "-----"
      item.Department = item.department ? item.department : "----"
      item.Type = item.typeData[0] ? item.typeData[0]?.name : "----"
      item.Priority =
        item.priority === 1
          ? "Low"
          : item.priority === 2
            ? "Medium"
            : item.priority === 3
              ? "High"
              : ""
      item.User = item.user
      item.options = (
        <div>
          <Tooltip title="View" arrow placement="top">
            <i
              style={{ color: "rgb(52, 55, 71)" }}
              className="fas fa-eye eye-icon"
              onClick={() => handleOpenModal(item)}
            ></i>
          </Tooltip>
        </div>
      )
      return item
    })

    const totalPage = Math.ceil(Number(count) / limit)
    setTotalPage(totalPage)
    setTableData(data)
    setTotalCount(count)
  }

  const handleOpenModal = async item => {
    setLoading(true)
    try {
      const response = await get(`response/v2/review/newResponse/details?id=${item._id}`)
      const { data } = response
      const modalData = data[0]

      setModalDetails(modalData)

      // console.log("modal Data ", modalData)
      const followUpData = modalData.follow_up || []
      // console.log("followUp Data", followUpData)
      const rows = followUpData.map((followUp, index) => ({
        id: index + 1,
        date: moment(followUp.date).format("DD-MM-YYYY") || "----",
        source: followUp.sourceDetails?.source_name || "----",
        comments: followUp.comments || "----",
        User: followUp.addedByDetails?.firstName || "----",
        status:
        followUp.status === 0
        ? "New"
        : followUp.status === 1
          ? "Pending"
          : followUp.status === 2
            ? "Closed"
            : followUp.status === 3
              ? "Responded"
              : followUp.status === 4
                ? "Dept Change"
                : followUp.status === 5
                  ? "Reopen"
                  : "-----",
      }))
     
      setTableModalData(rows)
      //     viewdetail_data.rows = rows
      setPopupView(true)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleCloseModal = () => {
    setPopupView(false)
    setModalDetails(null)
  }

  const reset = () => {
    formRef.current.reset()
    // filterRef.current.reset()
    toTop()
    setMasterObject({})
    setSelectedFields({})

    setFilterObject({})
    setSelectFilter({})

    setTotalCount(0)
  }

  const resetForm = () => {
    formRef.current.reset()
    setFileInputKey(Date.now());
    // filterRef.current.reset()
    toTop()
    setFormData({});
    setImage("");
  }

  const handleStatusChange = async () => {
    try {
      // if (!formData.followUpStatus) {
      //   toastr.warning("Please select a status!")

      //   return;
      // }
      if (formData.followUpStatus === null || formData.followUpStatus === undefined) {
        console.log("followup status not selected");
        toastr.warning("Please select a status!");
        return;
      }

      // if (formData.communication_type === null || formData.communication_type === undefined) {
      //   toastr.warning("Please select a communication type!");
      //   return;
      // }

      if (!formData.comments?.trim()) {
        toastr.warning("Please enter comments!");
        return;
      }
      const updatedFormData = {
        ...formData,
        responseId: modalDetails._id,
        screenshot: image,
      }
      await put("response/v2/review/status/update", updatedFormData)
      setFormData({})
      setImage("")
      handleOpenModal()
      resetForm()
      // if (fileInputRef.current) {
      //   fileInputRef.current.value = "";
      // }
      toastr.success("Status updated successfully!")
      handleCloseModal();
    } catch (error) { }
  }

  const uploadImage = async e => {
    const selectedFile = e.target.files[0]
    if (selectedFile) {
      try {
        const fd = new FormData()
        fd.append("screenshot", selectedFile)
        const response = await post(`response/v2/upload`, fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        if (response.status === 200) {
          toastr.success("File uploaded successfully")
          setImage(response.data.new_filename)
        } else {
          toastr.error(response.data.message || "Failed to upload image")
        }
      } catch (error) {
        toastr.error("An error occurred while uploading the file.")
        // e.target.value = ""
      }
    }
  }

  return (
    <>
      <Modal show={popupView} isOpen={popupView} toggle={handleCloseModal} size="xl" centered>
        <div className="modal-header">
          <div className="modal-title">
            <h5 style={{ marginBottom: "0px" }}>Details</h5>
          </div>
          <button
            style={{ marginBottom: "2px" }}
            type="button"
            onClick={handleCloseModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body pt-0">
          {loading ? (
            <div></div>
          ) : modalDetails ? (
            <>
              <Row>
                <Col md={12}>
                  <Row>
                    <Col md="12">
                      <table className="table table-borderd lead-info table-striped">
                        <tbody>
                          <tr>
                            <td>Name</td>
                            <th style={{ textAlign: "left" }}>
                              : {modalDetails.firstName || "----"}
                            </th>
                            <td>Mobile</td>
                            <th style={{ textAlign: "left" }}>: {modalDetails.mobile || "----"}</th>
                            <td>Email</td>
                            <th style={{ textAlign: "left" }}>: {modalDetails.email || "----"}</th>
                            {/* <td>District</td>
                            <th style={{ textAlign: "left" }}>: {modalDetails.district || '----'}</th> */}
                          </tr>
                          <tr>
                            <td>Send To</td>
                            <th style={{ textAlign: "left" }}>
                              : {modalDetails.department || "----"}
                            </th>
                            <td>Source</td>
                            <th style={{ textAlign: "left" }}>
                              : {modalDetails.sourceName || "----"}
                            </th>
                            {/* <td>Campaign</td>
                            <th style={{ textAlign: "left" }}>: {modalDetails.campaign || '----'}</th> */}
                            <td>Date</td>
                            <th style={{ textAlign: "left" }}>: {moment(modalDetails.date).format("DD-MM-YYYY") || "----"}</th>
                          </tr>
                          <tr>
                            <td>Priority</td>
                            <th style={{ textAlign: "left" }}>
                              : {modalDetails?.priority === 1
                                ? "Low"
                                : modalDetails?.priority === 2
                                  ? "Medium"
                                  : modalDetails?.priority === 3
                                    ? "High"
                                    : "----"}
                            </th>
                            <td>Type</td>
                            <th style={{ textAlign: "left" }}>: {modalDetails.type || "----"}</th>
                            <td>Sub Type</td>
                            <th style={{ textAlign: "left" }}>
                              : {modalDetails.subType || "----"}
                            </th>
                          </tr>
                          <tr>
                            <td>Staff</td>
                            <th style={{ textAlign: "left" }}>: {modalDetails.user || "----"}</th>
                            <td>Comments</td>
                            <th style={{ textAlign: "left" }}>
                              : {modalDetails.comments || "----"}
                            </th>
                            <td>Location</td>
                            <th style={{ textAlign: "left" }}>
                              : {modalDetails.location || "----"}
                            </th>
                          </tr>
                          <tr>
                            <td>Unique Id</td>
                            <th style={{ textAlign: "left" }}>: {modalDetails.uniqueId || "----"}</th>


                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col md="12" lg="12">
                  <Card>
                    <CardBody>
                      <AvForm
                        onValidSubmit={(e, values) => {
                          handleStatusChange(values)
                        }}
                      >
                        <Row>
                          <Col md="2">
                            <div className="mb-3">
                              <Label>Status</Label>
                              <ReactSelect
                                name="followUpStatus"
                                options={[
                                  { label: "New", value: 0 },
                                  { label: "Pending", value: 1 },
                                  { label: "Closed", value: 2 },
                                  { label: "Responded", value: 3 },
                                  { label: "Dept.change", value: 4 },
                                  { label: "Reopen", value: 5 },
                                ]}
                                value={{
                                  label: formData.followUpStatusLabel || "Select Status",
                                  value: formData.followUpStatus || "",
                                }}
                                onChange={selectedOption =>
                                  setFormData(prev => ({
                                    ...prev,
                                    followUpStatus: selectedOption.value,
                                    followUpStatusLabel: selectedOption.label,
                                  }))
                                }
                              />
                            </div>
                          </Col>
                          {formData.followUpStatus === 4 && (
                            <Col md="2">
                              <div className="mb-3">
                                <Label>Send To</Label>
                                <ReactSelect
                                  name="send_to"
                                  options={departmentOption}
                                  value={
                                    formData.sendTo
                                      ? departmentOption.find(option => option.value === formData.sendTo)
                                      : ""
                                  }
                                  onChange={selectedOption =>
                                    setFormData(prev => ({
                                      ...prev,
                                      sendTo: selectedOption ? selectedOption.value : "",
                                    }))
                                  }
                                />
                              </div>
                            </Col>
                          )}

                          {/* <Col md="3">
                            <div className="mb-3">
                              <Label>Upload</Label>
                              <AvField
                                name="upload"
                                type="file"
                                accept="image/*"
                                onChange={e =>
                                  setFormData(prev => ({
                                    ...prev,
                                    file: e.target.files[0],
                                  }))
                                }
                              />
                            </div>
                          </Col> */}
                          <Col md="2">
                            <div className="mb-3">
                              <Label>Upload</Label>
                              <AvField
                                key={fileInputKey}
                                name="screenshot"
                                type="file"
                                accept="image/*"
                                onChange={uploadImage}

                              // onChange={e =>
                              //   setFormData(prev => ({
                              //     ...prev,
                              //     file: e.target.files[0],
                              //   }))
                              // }
                              />
                            </div>
                          </Col>

                          <Col md="2">
                            <div className="mb-3">
                              <Label>Comments</Label>
                              <AvField
                                name="comments"
                                rows={1}
                                type="textarea"
                                placeholder="Comments"
                                value={formData.comments || ""}
                                onChange={e =>
                                  setFormData(prev => ({
                                    ...prev,
                                    comments: e.target.value,
                                  }))
                                }
                              />
                            </div>
                          </Col>
                          <Col md="2">
                            <div className="mb-3">
                              <Label>Communication Type</Label>
                              <ReactSelect
                                name="communication_type"
                                options={[
                                  { label: "In", value: 0 },
                                  { label: "Out", value: 1 },
                                  { label: "Internal", value: 2 },
                                ]}
                                value={{
                                  label:
                                    formData.communicationTypeLabel || "Select Communication Type",
                                  value: formData.communication_type || "",
                                }}
                                onChange={selectedOption =>
                                  setFormData(prev => ({
                                    ...prev,
                                    communicationType: selectedOption.value,
                                    communicationTypeLabel: selectedOption.label,
                                  }))
                                }
                              />
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3" style={{ paddingTop: "25px" }}>
                              <Button style={{ marginRight: "3%" }} color="primary" type="submit">
                                Submit
                              </Button>
                              <Button style={{ marginRight: "3%" }} color="danger" type="reset" onClick={resetForm}>
                                Reset
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <div>No data found</div>
          )}
        </div>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="New Response" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm ref={formRef} onValidSubmit={handleSubmit} id="responseForm">
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Name<span style={{ color: "red" }}>*</span></Label>
                          <AvField
                            name="name"
                            value={masterObject.name || ""}
                            onChange={e => handleValueChange(e.target)}
                            placeholder="Name"
                            type="text"
                            // errorMessage="Name is required"
                            // validate={{ required: { value: true } }}
                            className="form-control"
                            onKeyDown={(e) => {
                              if (!/^[A-Za-z\s]+$/.test(e.key) && e.key !== "Backspace") {
                                e.preventDefault();
                              }
                            }}
                          />
                          {errors.name && <FormFeedback className="d-block text-danger">{errors.name}</FormFeedback>}
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Mobile No.<span style={{ color: "red" }}>*</span></Label>
                          <AvField
                            name="mobile"
                            value={masterObject.mobile || ""}
                            onChange={e => {
                              handleValueChange(e.target)
                            }}
                            className="form-control"
                            type="text"
                            placeholder="Mobile No."
                            maxLength="10"
                            onKeyDown={(e) => {
                              // Allow only numbers and control keys
                              if (!/^[0-9]$/.test(e.key) &&
                                !["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab", "Enter"].includes(e.key)) {
                                e.preventDefault();
                              }
                            }}
                          // validate={{ required: { value: true } }}
                          // errorMessage="Mobile Number is required"
                          />
                          {errors.mobile && <FormFeedback className="d-block text-danger">{errors.mobile}</FormFeedback>}

                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Email<span style={{ color: "red" }}>*</span></Label>
                          <AvField
                            name="email"
                            value={masterObject.email || ""}
                            onChange={e => handleValueChange(e.target)}
                            className="form-control"
                            type="text"
                            placeholder="Email"
                          // errorMessage="Enter a valid email"
                          // validate={{
                          //   required: { value: true, errorMessage: "Email is required" },
                          //   pattern: {
                          //     value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          //     errorMessage: "Enter a valid email address",
                          //   },
                          // }}

                          />
                          {errors.email && <FormFeedback className="d-block text-danger">{errors.email}</FormFeedback>}
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Response Date<span style={{ color: "red" }}>*</span></Label>
                          <AvField
                            name="response_date"
                            className="form-control"
                            type="date"
                            placeholder="Email"
                            max={todayDate}
                            // errorMessage="Enter Category"
                            value={masterObject.response_date || ""}
                            onChange={e => handleValueChange(e.target)}
                          />
                          {errors.response_date && <FormFeedback className="d-block text-danger">{errors.response_date}</FormFeedback>}
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Source<span style={{ color: "red" }}>*</span></Label>
                          <Select
                            options={sourceOptions}
                            value={selectedFields.source || ""}
                            onChange={selected => handleSelectValueChange(selected, "source")}
                            type="text"
                          // className="form-control"
                          // isClearable={true}
                          // isSearchable={true}
                          // validate={{ required: { value: true } }}
                          // errorMessage="Source is required"
                          />
                          {errors.source && <FormFeedback className="d-block text-danger">{errors.source}</FormFeedback>}
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Source Sub Type</Label>
                          <ReactSelect
                            options={sourceSubTypeOptions}
                            value={selectedFields.source_sub_type || ""}
                            onChange={selected =>
                              handleSelectValueChange(selected, "source_sub_type")
                            }
                          // isClearable={true}
                          // isSearchable={true}
                          // errorMessage="Source SubType Required"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Send To</Label>
                          <ReactSelect
                            name="send_to"
                            type="text"
                            options={departmentOption}
                            value={selectedFields.send_to || ""}
                            onChange={selected => handleSelectValueChange(selected, "send_to")}
                          // options={[
                          //   { label: "Customer Service", value: "1" },
                          //   { label: "HR", value: "2" },
                          //   { label: "Operations", value: "3" },
                          //   { label: "Maintanance", value: "4" },
                          // ]}
                          // errorMessage="Enter Category"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Product</Label>
                          <ReactSelect
                            name="product"
                            type="text"
                            options={productOption}
                            value={selectedFields.product || ""}
                            onChange={selected => handleSelectValueChange(selected, "product")}
                          // options={[
                          //   { label: "Customer Service", value: "1" },
                          //   { label: "HR", value: "2" },
                          //   { label: "Operations", value: "3" },
                          //   { label: "Maintanance", value: "4" },
                          // ]}
                          // errorMessage="Enter Product"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Select priority</Label>
                          <ReactSelect
                            name="priority"
                            type="text"
                            value={selectedFields.priority || ""}
                            onChange={selected => handleSelectValueChange(selected, "priority")}
                            options={[
                              { label: "Low", value: "1" },
                              { label: "Medium", value: "2" },
                              { label: "High", value: "3" },
                            ]}
                          // errorMessage="Enter Category"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Select Type</Label>
                          <ReactSelect
                            name="type"
                            type="text"
                            // options={[
                            //   { label: "Appreciation", value: "1" },
                            //   { label: "General Quaries", value: "2" },
                            //   { label: "Informations", value: "3" },
                            // ]}
                            options={typeOption}
                            value={selectedFields.type || ""}
                            onChange={selected => handleSelectValueChange(selected, "type")}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Select Sub Type</Label>
                          <ReactSelect
                            name="sub_type"
                            type="text"
                            // options={[
                            //   { label: "ATM", value: "1" },
                            //   { label: "Branch", value: "2" },
                            //   { label: "Staff", value: "3" },
                            // ]}
                            options={subTypeOption}
                            value={selectedFields.sub_type || ""}
                            onChange={selected => handleSelectValueChange(selected, "sub_type")}
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Location</Label>
                          <AvField
                            name="location"
                            className="form-control"
                            type="text"
                            value={masterObject.location || ""}
                            onChange={e => handleValueChange(e.target)}
                            placeholder="Location"
                          // errorMessage="Enter Category"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Comments<span style={{ color: "red" }}>*</span></Label>
                          <AvField
                            name="comments"
                            className="form-control"
                            type="textarea"
                            value={masterObject.comments || ""}
                            onChange={e => handleValueChange(e.target)}
                            rows={1}
                            placeholder="Comments"
                          // errorMessage="Comments is required"
                          // validate={{ required: { value: true } }}
                          />
                          {errors.comments && <FormFeedback className="d-block text-danger">{errors.comments}</FormFeedback>}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          <Button color="primary" type="submit" disabled={loading}>
                            {loading ? "Submitting..." : "Submit"}
                          </Button>
                          <Button style={{ marginLeft: "2px" }} color="danger" onClick={reset}>
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="Managetableid1"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default NewResponse
