/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import SelectErrorMessage from "../../Common/SelectErrorMessage"
import PropTypes from "prop-types"

const UrlModel = ({ show, toggle, editorHtml, setEditorHtml }) => {
  const initialUrl = { protocol: "https://", domain: "" }
  const [url, setUrl] = useState(initialUrl)

  const [error, setError] = useState({})

  const checkErrorUrlInject = () => {
    let flag = false
    const domainRegex =
      /^(?!-)[A-Za-z0-9-]{1,63}(?<!-)\.(?:[A-Za-z]{2,6}|[A-Za-z0-9-]{2,}\.[A-Za-z]{2,})$/

    if (!url?.domain) {
      setError(prev => ({ ...prev, url: true, urlMsg: "Please enter a domain" }))
    } else if (url?.domain && !domainRegex.test(url?.domain)) {
      setError({
        url: true,
        urlMsg: "Please enter a valid domain",
      })
    } else {
      setError({})
      flag = true
    }

    return flag
  }

  const handleUrlInject = () => {
    let flag = checkErrorUrlInject()

    if (flag) {
      let text = editorHtml
      text = text.replace(/<[^>]+>/g, "")
      let formedUrl = url.protocol + url?.domain
      text += `[[${formedUrl}]]`
      let textHtml = `<p>${text}</p>`
      setEditorHtml(textHtml)
      setUrl(initialUrl)
      toggle("url")
    }
  }

  return (
    <Modal isOpen={show?.url} toggle={() => toggle("url")}>
      <ModalHeader toggle={() => toggle("url")}>Enter URL</ModalHeader>

      <ModalBody>
        <div className="custom-url-enter mb-3">
          <select onChange={e => setUrl(prev => ({ ...prev, protocol: e.target.value }))}>
            <option value="https://" selected>
              https
            </option>
            <option value="http://">http</option>
          </select>
          <input
            name="url"
            placeholder="Enter url eg: - srvsocial.com"
            value={url?.domain ?? ""}
            onChange={e => {
              checkErrorUrlInject()
              setUrl(prev => ({ ...prev, domain: e.target.value }))
            }}
          />
        </div>
        <SelectErrorMessage message={error.urlMsg} show={error?.url} />
      </ModalBody>
      <ModalFooter>
        <Button type="submit" color="primary" onClick={handleUrlInject}>
          Add
        </Button>
        <Button color="secondary" aria-label="url" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

UrlModel.prototype = {
  show: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
  editorHtml: PropTypes.string.isRequired,
  setEditorHtml: PropTypes.func.isRequired,
}

export default UrlModel
