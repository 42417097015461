import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { setTitle } from "../../../helpers/functions"

import toastr from "toastr"
import { post } from "../../../helpers/api_helper"

const WhatsappConfig = () => {
  const [details, setDetails] = useState({})

  useEffect(() => {
    // Load Facebook SDK
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "1115688393259275", // Replace with your App ID
        autoLogAppEvents: true,
        xfbml: true,
        version: "v22.0",
      })
    }

    // Inject SDK script dynamically
    ;(function (d, s, id) {
      let js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.src = "https://connect.facebook.net/en_US/sdk.js"
      fjs.parentNode.insertBefore(js, fjs)
    })(document, "script", "facebook-jssdk")

    // Event listener for messages from Facebook
    window.addEventListener("message", event => {
      if (
        event.origin !== "https://www.facebook.com" &&
        event.origin !== "https://web.facebook.com"
      )
        return
      try {
        const data = JSON.parse(event.data.startsWith("{") ? event.data : "{}")
        if (data.type === "WA_EMBEDDED_SIGNUP") {
          console.log(data)
          setDetails(data.data)
        }
      } catch (error) {
        toastr.error("Something went wrong. Please try again.")
        console.log("Message Event:", event.data)
        console.log("Error:", error)
      }
    })
  }, [])

  // Facebook login function
  const launchWhatsAppSignup = () => {
    window.FB.login(
      response => {
        if (response.authResponse) {
          const code = response.authResponse.code
          console.log("Login Successful:", code)
          console.log("Details:", details)

          post("auth/whatsapp/callback", { code, ...details }).then(res => {
            console.log(res)
          })
          // Handle successful login
        } else {
          toastr.error("Login failed. Please try again.")
        }
      },
      {
        config_id: "1848269229317212",
        response_type: "code",
        override_default_response_type: true,
        extras: {
          setup: {},
          featureType: "",
          sessionInfoVersion: "3",
        },
      }
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem={setTitle("Whatsapp Config")} />

          <Col md="12">
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center gap-3 p-2">
                    <img src="/sm-whatsapp.svg" width="40" alt="logo" />
                    <h6 className="m-0">Whatsapp</h6>
                  </div>
                  <div className="d-flex align-items-center gap-3 p-2">
                    {false > 0 ? (
                      <>
                        <Button className={`bg-soft-success`} color={"success"}>
                          Connected
                        </Button>
                        <Button className="bg-soft-danger" color="danger">
                          Logout
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          className="bg-soft-primary"
                          color="primary"
                          onClick={launchWhatsAppSignup}
                        >
                          Login
                        </Button>
                      </>
                    )}
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <span className="fw-bold font-size-14">Rizin Srv </span>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default WhatsappConfig
