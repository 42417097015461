import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import CountUp from "react-countup";
import { Line } from "react-chartjs-2";
import { get } from "../../../helpers/api_helper.js";
import Breadcrumbs from "../../../components/Common/Breadcrumb.js";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ResponseDashboard = () => {
  const API_URL = process.env.REACT_APP_APIURL + 'response/v2';
  const [dashboardData, setDashboardData] = useState({
    counts: {
      total: 0,
      new: 0,
      pending: 0,
      responded: 0,
      closed: 0,
      deptChanged: 0
    },
    sourceData: []
  });
  const [timeFrame, setTimeFrame] = useState('yearly');
  const [loading, setLoading] = useState(false);

  const fetchDashboardStats = useCallback(async () => {
    if (loading) return;

    setLoading(true);
    try {
      const response = await get(`${API_URL}/stats?timeFrame=${timeFrame}`);
      if (response.success) {
        setDashboardData(prevData => ({
          ...prevData,
          ...response.data
        }));
      }
    } catch (error) {
      console.error("Error fetching dashboard stats:", error);
    } finally {
      setLoading(false);
    }
  }, [timeFrame]);

  useEffect(() => {
    fetchDashboardStats();
  }, [fetchDashboardStats]);

  const getRandomColor = useCallback(() => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }, []);

  const chartData = useMemo(() => {
    if (!dashboardData.sourceData.length) return { labels: [], datasets: [] };

    const labels = dashboardData.sourceData.map(item => item.period);
    const datasets = dashboardData.sourceData.reduce((acc, curr) => {
      curr.sources.forEach(source => {
        const existingDataset = acc.find(ds => ds.label === source.name);
        if (existingDataset) {
          existingDataset.data.push(source.count);
        } else {
          const color = getRandomColor();
          acc.push({
            label: source.name,
            data: [source.count],
            borderColor: color,
            backgroundColor: color,
            pointStyle: 'circle',
            pointRadius: 6,
            pointBackgroundColor: color,
            fill: false,
            tension: 0.4
          });
        }
      });
      return acc;
    }, []);

    return { labels, datasets };
  }, [dashboardData.sourceData, getRandomColor]);

  const chartOptions = useMemo(() => ({
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          maxTicksLimit: 5,
          callback: value => Math.round(value)
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
        align: 'end',
        labels: {
            usePointStyle: true,
            pointStyle: 'circle',
            boxWidth: 9,
            boxHeight: 9,
            font: {
              size:  12
            }
          }
      },
      tooltip: {
        mode: 'index',
        intersect: false
      }
    }
  }), []);

  const StatCard = React.memo(({ title, value, icon }) => (
    <Card>
      <CardBody>
        <Row className="align-items-center">
          <Col xs={8}>
            <h2 className="mt-2 mb-2">
              <CountUp end={value} separator="," duration={2} />
            </h2>
            <p className="mb-0 text-muted">{title}</p>
          </Col>
          <Col xs={4} className="text-end">
            <i className={`${icon} fa-3x`}></i>
          </Col>
        </Row>
      </CardBody>
    </Card>
  ));

  return (
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Home" breadcrumbItem="Response Dashboard" />
            
            <Row>
              <Col xl={3} md={4}>
                <StatCard
                  title="TOTAL"
                  value={dashboardData.counts.total}
                  icon="fas fa-database"
                />
              </Col>
              <Col xl={3} md={4}>
                <StatCard
                  title="NEW"
                  value={dashboardData.counts.new}
                  icon="fas fa-plus-circle"
                />
              </Col>
              <Col xl={3} md={4}>
                <StatCard
                  title="PENDING"
                  value={dashboardData.counts.pending}
                  icon="fas fa-clock"
                />
              </Col>
              <Col xl={3} md={4}>
                <StatCard
                  title="RESPONDED"
                  value={dashboardData.counts.responded}
                  icon="fas fa-reply"
                />
              </Col>
              <Col xl={3} md={4}>
                <StatCard
                  title="CLOSED"
                  value={dashboardData.counts.closed}
                  icon="fas fa-check-circle"
                />
              </Col>
              <Col xl={3} md={4}>
                <StatCard
                  title="DEPT. CHANGED"
                  value={dashboardData.counts.deptChanged}
                  icon="fas fa-exchange-alt"
                />
              </Col>
            </Row>
    
            <Row className="mt-4">
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <h4 className="card-title">Source wise Response Analysis</h4>
                      <div className="d-flex align-items-center gap-2">
                        <label className="mb-0">Sort By</label>
                        <select 
                          value={timeFrame}
                          onChange={(e) => setTimeFrame(e.target.value)}
                          className="form-select"
                          style={{ width: 'auto', minWidth: '120px' }}
                        >
                          <option value="yearly">Yearly</option>
                          <option value="monthly">Monthly</option>
                          <option value="weekly">Weekly</option>
                        </select>
                      </div>
                    </div>
                    <div style={{ height: "400px" }}>
                      <Line data={chartData} options={chartOptions} />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      );
};

export default React.memo(ResponseDashboard);