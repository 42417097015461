import { GET_INTERACTIONS, GET_INTERACTIONS_FAIL, GET_INTERACTIONS_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  interactions: [],
  interaction: {},
  error: {},
}

const interactions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INTERACTIONS:
      return {
        ...state,
        params: action.payload,
      }

    case GET_INTERACTIONS_SUCCESS:
      return {
        ...state,
        interactions: action.payload.data,
        count: action?.payload?.count || 0,
      }

    case GET_INTERACTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default interactions
