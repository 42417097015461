/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import { Tooltip } from "@mui/material"
import { AvField, AvForm } from "availity-reactstrap-validation"

import { SunEditor } from "../"
import Select from "react-select"

import * as config from "./config"
import * as sunEditorConfig from "../SunEditor/config"
import "./style.scss"
import { isArray } from "lodash"
import toastr, { error } from "toastr"
import SelectErrorMessage from "../../Common/SelectErrorMessage"
import { get, post } from "../../../helpers/api_helper"
import { API_URL } from "../../../config"
import PhoneFrame from "../PhoneFrame"
import { generatePlaceholderExample, generatePlaceholderTemplate } from "../../../helpers/functions"
import { useDispatch } from "react-redux"
import { refreshPageAction } from "../../../store/actions"

const index = () => {
  let id = Date.now()
  const [masterObj, setMasterObj] = useState({
    buttons: [],
    language: "en_US",
    headerMediaType: "none",
  })

  const dispatch = useDispatch()

  const [refresh, setRefresh] = useState({ editor: false, options: false })
  const [selected, setSelected] = useState({
    headerMediaType: { label: "None", value: "none" },
    language: { label: "English (US)", value: "en_US" },
    buttons: [],
  })
  const [show, setShow] = useState({ status: false, idx: null })
  const toggle = () => {
    setShow({ status: false, idx: null })
  }
  const [error, setError] = useState({})
  const [options, setOptions] = useState({
    category: config.category,
    language: config.supportLanguage,
    headerMediaType: config.headerMediaType,
    buttonType: config.buttonType,
  })

  let GET_VARIABLES = async () => {
    try {
      let res = await get(`${API_URL}options/templateVariable?type=button`)
      if (res) {
        setOptions(prev => ({ ...prev, buttonAttribute: res.data }))
        if (res.data?.length === 0) {
          toastr.info("There is no button attribute configured")
          setShow({ status: false, idx: null })
        }
      }
    } catch (error) {
      console.log(error.message ?? "Couldn't get variables")
    }
  }

  const formRef = useRef()

  const handleChange = e => {
    let { name, value } = e.target
    setMasterObj(prev => ({ ...prev, [name]: value }))
  }

  const handleSelectChange = (e, name) => {
    let value = e.value ?? null
    if (value) {
      if (name === "headerMediaType") {
        setMasterObj(prev => ({ ...prev, header: "" }))
      }

      setMasterObj(prev => ({ ...prev, [name]: value }))
      setSelected(prev => ({ ...prev, [name]: e }))
    }
  }

  const reset = () => {
    setMasterObj({ buttons: [], language: "en_US", headerMediaType: "none" })
    setSelected({
      headerMediaType: { label: "None", value: "none" },
      language: { label: "English (US)", value: "en_US" },
      buttons: [],
    })
    setRefresh(prev => ({ ...prev, editor: true }))
    setError({})
    setOptions(prev => ({ ...prev, buttonType: config.buttonType }))
    formRef?.current?.reset()
  }

  const checkInputError = () => {
    let flag = true
    if (!masterObj?.language) {
      flag = false
      setError(prev => ({ ...prev, language: true }))
    }

    if (!masterObj?.category) {
      flag = false
      setError(prev => ({ ...prev, category: true }))
    }

    if (!masterObj?.body) {
      flag = false
      setError(prev => ({ ...prev, body: { msg: "body field is required", status: true } }))
    }

    if (!masterObj?.header && masterObj?.headerMediaType === "text") {
      flag = false
      setError(prev => ({ ...prev, header: { msg: "header field is required", status: true } }))
    }

    return flag
  }
  const handleSubmit = () => {
    let flag = checkInputError()
    let formData = new FormData()

    Object.keys(masterObj).map(key => {
      if (key === "body" || key === "header") {
        formData.append(key, masterObj[key])
      } else if (key !== "file") {
        formData.append(key, JSON.stringify(masterObj[key]))
      } else {
        formData.append(key, masterObj[key])
      }
    })

    if (flag) {
      post(`${API_URL}whatsapp/template/create`, formData)
        .then(res => {
          toastr.success(res.message)
          reset()
          dispatch(refreshPageAction(true))
        })
        .catch(err => toastr.error(err.message ?? "Template creation failed"))
    }
  }

  const handleVariableInject = (idx, name) => {
    let url = masterObj.buttons[idx]?.url
    if (!url) {
      toastr.info("Please enter the ur")
    }

    let value = null
    if (url.endsWith("/")) {
      value = `${url}${name}`
    } else {
      value = `${url}/${name}`
    }

    if (value) {
      let exampleButtonText = generatePlaceholderExample(value)

      if (exampleButtonText?.length > 1)
        toastr.info("button URL supports only one dynamic variable")

      if (exampleButtonText.length < 2) {
        setSelected(prev => {
          const updatedObj = { ...prev }
          let specificObj = updatedObj.buttons[idx]
          specificObj = { ...specificObj, mode: { label: "Dynamic", value: "dynamic" } }
          updatedObj.buttons[idx] = specificObj
          return updatedObj
        })

        setMasterObj(prev => {
          const updatedObj = { ...prev }
          let specificObj = updatedObj.buttons[idx]
          if (specificObj?.mode === "static") {
            specificObj = { ...specificObj, mode: "dynamic" }
          }

          specificObj = { ...specificObj, url: value }
          updatedObj.buttons[idx] = specificObj
          return updatedObj
        })
      }
    }
  }

  const getHeaderOptions = type => {
    if (type === "text") {
      return (
        <SunEditor
          variableLimit={1}
          label="Header"
          description=""
          remove={true}
          height="60px"
          maxLength={60}
          refresh={refresh}
          setMasterObj={setMasterObj}
          customPlugins={[sunEditorConfig.customPlugins.bodyAttribute]}
          error={error}
          setError={setError}
        />
      )
    } else if (type === "image" || type === "video" || type === "document") {
      let accept = ".pdf"
      let name = "File"
      if (type === "image") {
        accept = ".jpeg, .jpg, .png"
        name = "Image"
      }

      if (type === "video") {
        accept = ".mp4"
        name = "Video"
      }

      return (
        <>
          <p>Please provide a example file for reference</p>
          {/* <p>Please provide a valid sample URL or upload an example file for reference</p> */}
          <Row>
            {/* <Col md={4}>
              <Label>Url</Label>
              <div>
                <AvField
                  name="exampleContent"
                  type="text"
                  accept={accept}
                  style={{ lineHeight: "1.8" }}
                  value={masterObj?.exampleContent ?? ""}
                  placeholder={`https://example.com/demo${accept?.split(",")[0]}`}
                  validate={{ required: { value: true } }}
                  onChange={handleChange}
                  errorMessage="Please select a image example"
                />
              </div>
            </Col> */}

            <Col md={4}>
              <Label>Upload {name}</Label>
              <Tooltip title={`Upload your ${name}`} arrow placement="bottom">
                <div>
                  <AvField
                    name="file"
                    type="file"
                    accept={accept}
                    style={{ lineHeight: "1.8" }}
                    validate={{ required: { value: true } }}
                    errorMessage="Please select a image file"
                    onChange={e => {
                      let file = e.target.files[0]
                      setMasterObj(prev => ({ ...prev, file: file }))
                    }}
                  />
                </div>
              </Tooltip>
            </Col>
          </Row>
        </>
      )
    }

    return null
  }

  const handleButtonOptionsChange = (e, idx, name = null) => {
    let value = ""
    let set_name = ""

    if (name) {
      set_name = name
      value = isArray(e) ? e.map(item => item?.value) : e?.value
    } else {
      set_name = e.target.name?.split("-")[0]
      value = e.target.value
    }

    if (set_name === "url") {
      let exampleButtonText = generatePlaceholderExample(value)

      if (exampleButtonText.length > 1) {
        toastr.info("button URL supports only one dynamic variable")
      }

      if (exampleButtonText.length < 2) {
        setSelected(prev => {
          const updatedObj = { ...prev }
          let specificObj = updatedObj.buttons[idx]
          if (exampleButtonText.length > 0) {
            specificObj = { ...specificObj, mode: { label: "Dynamic", value: "dynamic" } }
          }
          updatedObj.buttons[idx] = specificObj
          return updatedObj
        })

        setMasterObj(prev => {
          const updatedObj = { ...prev }
          let specificObj = updatedObj.buttons[idx]
          if (exampleButtonText.length > 0) {
            if (specificObj?.mode === "static") {
              specificObj = { ...specificObj, mode: "dynamic" }
            }
          }
          specificObj = { ...specificObj, url: value }
          updatedObj.buttons[idx] = specificObj
          return updatedObj
        })
      }
    } else {
      if (name) {
        setSelected(prev => {
          const updatedObj = { ...prev }
          let specificObj = updatedObj.buttons[idx]
          specificObj = { ...specificObj, [set_name]: e }
          updatedObj.buttons[idx] = specificObj
          return updatedObj
        })
      }

      setMasterObj(prev => {
        const updatedObj = { ...prev }
        let specificObj = updatedObj.buttons[idx]
        specificObj = { ...specificObj, [set_name]: value ?? "" }

        if (name === "mode") {
          specificObj = { ...specificObj, exampleButtonText: "" }
        }
        updatedObj.buttons[idx] = specificObj
        return updatedObj
      })
    }
  }

  const limitOptions = () => {
    let buttonLimit = {
      URL: 2,
      PHONE_NUMBER: 1,
      COPY_CODE: 1,
      QUICK_REPLY: 3,
    }

    let buttonType = config.buttonType.map((type, idx) => {
      const typeCount = masterObj?.buttons?.filter(doc => doc?.type === type.value).length || 0
      if (
        type?.value === "URL" ||
        type?.value === "PHONE_NUMBER" ||
        type?.value === "COPY_CODE" ||
        type?.value === "QUICK_REPLY"
      ) {
        return {
          ...type,
          isDisabled: typeCount === buttonLimit[type.value] ? true : false,
        }
      } else {
        return {
          ...type,
          isDisabled: false,
        }
      }
    })

    setOptions(prev => ({ ...prev, buttonType: buttonType }))
  }

  useEffect(() => {
    limitOptions()
    setRefresh(prev => ({ ...prev, options: false, editor: false }))
  }, [selected.selectedButton, refresh.options])

  const AddButtonOption = () => {
    if (masterObj.buttons?.length > 7) {
      toastr.info("Limit Reached. You can only add a maximum of 7 buttons.")
      return
    }

    limitOptions()

    if (selected?.selectedButton?.value) {
      try {
        let id = Date.now()
        setMasterObj(prev => {
          let obj = { ...prev }
          let value = { id: id, type: selected.selectedButton.value }
          if (selected.selectedButton.value === "URL") {
            value.mode = "static"
          }
          obj.buttons.push(value)
          return obj
        })

        setSelected(prev => {
          let obj = { ...prev }

          let value = { type: selected.selectedButton, id: id }
          if (selected.selectedButton.value === "URL") {
            value.mode = { label: "Static", value: "static" }
          }

          obj.buttons.push(value)
          obj.selectedButton = ""
          return obj
        })
      } catch (error) {
        console.error(error.message)
      }
    }
  }

  const getButtonsComponents = (type, idx) => {
    switch (type) {
      case "URL":
        return (
          <>
            <Col md={2} className="mb-3">
              <Select
                name="button"
                placeholder="Select a mode"
                options={[
                  { label: "Static", value: "static" },
                  { label: "Dynamic", value: "dynamic" },
                ]}
                value={isArray(selected?.buttons) ? selected.buttons[idx]?.mode : ""}
                onChange={e => handleButtonOptionsChange(e, idx, "mode")}
              />
            </Col>
            <Col md={3}>
              <AvField
                name={`text-${idx}`}
                type="text"
                placeholder="Visit Us"
                value={isArray(masterObj?.buttons) ? masterObj.buttons[idx]?.text : ""}
                onChange={e => handleButtonOptionsChange(e, idx)}
                errorMessage="Enter url text label"
                validate={{
                  required: { value: true },
                  maxLength: { value: 25 },
                }}
              />
            </Col>

            <Col md={3}>
              <AvField
                name={`url-${idx}`}
                type="text"
                placeholder={`https://www.srvsocial.com/${
                  isArray(selected?.buttons) && selected.buttons[idx]?.mode?.value === "dynamic"
                    ? "{{id}}"
                    : ""
                }`}
                value={isArray(masterObj?.buttons) ? masterObj.buttons[idx]?.url : ""}
                onChange={e => handleButtonOptionsChange(e, idx)}
                errorMessage="Enter your URL"
                validate={{
                  required: { value: true },
                  maxLength: { value: 2000 },
                }}
              />
            </Col>

            <Col md={2}>
              <i
                class="fa fa-cogs"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShow({ status: true, idx: idx })
                  GET_VARIABLES()
                }}
              ></i>

              <i
                className="uil-minus-square-full"
                style={{ fontSize: "1.2em", cursor: "pointer", marginLeft: "10px" }}
                onClick={() => {
                  removeButtonsComponents(masterObj?.buttons[idx]?.id)
                }}
              ></i>
            </Col>

            {masterObj.buttons[idx]?.mode === "dynamic" ? (
              <Col md={12}>
                <AvField
                  name={`exampleButtonText-${idx}`}
                  type="text"
                  placeholder={"Enter the example content for dynamic variable"}
                  value={
                    isArray(masterObj?.buttons) ? masterObj.buttons[idx]?.exampleButtonText : ""
                  }
                  onChange={e => handleButtonOptionsChange(e, idx)}
                  errorMessage="Enter the example of content"
                  validate={{
                    required: { value: true },
                    maxLength: { value: 200 },
                  }}
                />
                <p>
                  Make sure not to include any actual user or customer information, and provide only
                  sample content in your example.{" "}
                  <a
                    href="https://developers.facebook.com/docs/whatsapp/message-templates/guidelines"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more
                  </a>
                </p>
              </Col>
            ) : null}
          </>
        )

      case "PHONE_NUMBER":
        return (
          <>
            <Col md={4}>
              <AvField
                name={`text-${idx}`}
                type="text"
                placeholder="Call Us"
                value={isArray(masterObj?.buttons) ? masterObj.buttons[idx]?.text : ""}
                onChange={e => handleButtonOptionsChange(e, idx)}
                errorMessage="Enter call text label"
                validate={{
                  required: { value: true },
                  maxLength: { value: 25 },
                }}
              />
            </Col>

            <Col md={4}>
              <AvField
                name={`phone_number-${idx}`}
                type="text"
                placeholder="9876543210"
                value={isArray(masterObj?.buttons) ? masterObj.buttons[idx]?.phone_number : ""}
                onChange={e => handleButtonOptionsChange(e, idx)}
                errorMessage="Enter your phone number with country code"
                validate={{
                  required: { value: true },
                  maxLength: { value: 25 },
                }}
              />
            </Col>

            <Col md={2}>
              <i
                className="uil-minus-square-full"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  removeButtonsComponents(masterObj?.buttons[idx]?.id)
                }}
              ></i>
            </Col>
          </>
        )
      case "COPY_CODE":
        return (
          <>
            <Col md={4} className="mb-3">
              <input
                name={`text-${idx}`}
                type="text"
                className="form-control"
                defaultValue={"Copy offer code"}
                onChange={e => handleButtonOptionsChange(e, idx)}
                disabled={true}
              />
            </Col>

            <Col md={4}>
              <AvField
                name={`offer_code-${idx}`}
                type="text"
                placeholder="Enter a Offer code"
                value={isArray(masterObj?.buttons) ? masterObj.buttons[idx]?.offer_code : ""}
                onChange={e => handleButtonOptionsChange(e, idx)}
                errorMessage="Enter your offer code"
                validate={{
                  required: { value: true },
                  maxLength: { value: 15 },
                  minLength: { value: 3 },
                }}
              />
            </Col>
            <Col md={2}>
              <i
                className="uil-minus-square-full"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  removeButtonsComponents(masterObj?.buttons[idx]?.id)
                }}
              ></i>
            </Col>
          </>
        )
      case "QUICK_REPLY":
        return (
          <>
            <Col md={4}>
              <AvField
                name={`text-${idx}`}
                type="text"
                placeholder="Unsubscribe from All"
                value={isArray(masterObj?.buttons) ? masterObj.buttons[idx]?.text : ""}
                onChange={e => handleButtonOptionsChange(e, idx)}
                errorMessage="Enter quick replay text label"
                validate={{
                  required: { value: true },
                  maxLength: { value: 25 },
                }}
              />
            </Col>
            <Col md={2}>
              <i
                className="uil-minus-square-full"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  removeButtonsComponents(masterObj?.buttons[idx]?.id)
                }}
              ></i>
            </Col>
          </>
        )
      default:
        return null
    }
  }

  const removeButtonsComponents = id => {
    setMasterObj(prev => {
      let obj = { ...prev }
      obj.buttons = obj.buttons.filter(btn => btn.id !== id)
      return obj
    })

    setSelected(prev => {
      let obj = { ...prev }
      obj.buttons = obj.buttons.filter(btn => btn.id !== id)
      return obj
    })

    setRefresh(prev => ({ ...prev, options: true }))
  }

  const handleExampleContent = (name, e, idx) => {
    let value = e?.target?.value

    setMasterObj(prev => {
      const updatedObj = { ...prev }

      if (updatedObj[name]) {
        updatedObj[name][idx] = value
      } else {
        updatedObj[name] = [value]
      }
      return updatedObj
    })
  }

  return (
    <div className="mb-4" id="new-template">
      <div className="template-creation-model">
        <Card>
          <CardBody>
            <AvForm
              ref={formRef}
              className="needs-validation"
              onValidSubmit={(e, v) => {
                handleSubmit()
              }}
              onInvalidSubmit={() => {
                checkInputError()
              }}
            >
              <Row>
                <Col md={6} lg={4} className="mt-3">
                  <div className="mb-3">
                    <Label className="bold">
                      Template Name<span className="text-danger"> *</span>
                    </Label>
                    <AvField
                      name="templateName"
                      type="text"
                      placeholder="Template name"
                      value={masterObj?.templateName ?? ""}
                      onChange={handleChange}
                      errorMessage="Enter a template name"
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                </Col>

                <Col md={6} lg={4} className="mt-3">
                  <div className="mb-3">
                    <Label className="bold">
                      Category <span className="text-danger"> *</span>
                    </Label>
                    <Select
                      name="Category"
                      placeholder="Select a Category"
                      options={options.category}
                      value={selected?.category ?? ""}
                      onChange={e => handleSelectChange(e, "category")}
                    />
                  </div>
                  <SelectErrorMessage
                    message="Please select the category"
                    show={!masterObj?.category && error.category}
                  />
                </Col>

                <Col md={6} className="mt-3">
                  <div className="mb-3">
                    <Label className="bold">
                      Language <span className="text-danger"> *</span>
                    </Label>
                    <Select
                      name="Language"
                      placeholder="Select a language"
                      options={options.language}
                      value={selected?.language ?? ""}
                      onChange={e => handleSelectChange(e, "language")}
                    />
                  </div>
                  <SelectErrorMessage
                    message="Please select the language"
                    show={!masterObj?.language && error.language}
                  />
                </Col>

                <Col md={12} className="mt-3">
                  <div className="mb-3">
                    <Label className="bold">Header (Optional)</Label>
                    <p>Highlight your brand here, use image or video, to stand out</p>
                    <Col md={6} lg={4}>
                      <Select
                        name="header_type"
                        placeholder="Select a media type"
                        options={options.headerMediaType}
                        value={selected?.headerMediaType ?? ""}
                        onChange={e => handleSelectChange(e, "headerMediaType")}
                      />
                    </Col>
                  </div>
                  {getHeaderOptions(selected?.headerMediaType?.value)}
                </Col>

                <SunEditor
                  label="Body"
                  description="Make your messages personal using a variable like {{name}} and get more replies"
                  setMasterObj={setMasterObj}
                  refresh={refresh}
                  maxLength={1024}
                  customPlugins={[
                    sunEditorConfig.customPlugins.customLink,
                    sunEditorConfig.customPlugins.bodyAttribute,
                  ]}
                  error={error}
                  setError={setError}
                />

                <Col md={12} className="mt-3">
                  <div className="mb-3">
                    <Label className="bold">Footer (Optional)</Label>
                    <p>Footer are greater to add any disclaimer or to add a thoughtful PS</p>
                    <AvField
                      name="footer"
                      type="text"
                      placeholder="Powered by srvsocial.com"
                      value={masterObj?.footer ?? ""}
                      onChange={handleChange}
                      validate={{
                        maxLength: { value: 60 },
                      }}
                    />
                  </div>
                </Col>

                <Col md={12} className="mt-3">
                  <div className="mb-3">
                    <Label className="bold">Buttons (Optional) </Label>

                    <p>
                      Insert buttons so your customers can take action and engage with your message!
                    </p>

                    <div className="button-choice">
                      <Col md={6} lg={4}>
                        <Select
                          name="button"
                          placeholder="Select a button type"
                          options={options.buttonType}
                          value={selected?.selectedButton ?? ""}
                          onChange={e => handleSelectChange(e, "selectedButton")}
                        />
                      </Col>

                      <button className="action-btn" type="button" onClick={AddButtonOption}>
                        Add Buttons
                      </button>
                    </div>
                    {masterObj.buttons?.map((dataField, idx) => (
                      <Row key={idx} className={idx > 0 ? "mt-3" : "mt-2"}>
                        <Col md={2} className="mb-3">
                          <Select
                            name="button"
                            placeholder="Select a button type"
                            value={isArray(selected?.buttons) ? selected.buttons[idx]?.type : ""}
                            isDisabled={true}
                          />
                        </Col>

                        {getButtonsComponents(dataField?.type, idx)}
                      </Row>
                    ))}
                  </div>
                </Col>

                {masterObj?.exampleBodyOptions?.length > 0 ||
                masterObj?.exampleHeaderOptions?.length > 0 ? (
                  <Col md={12} className="mt-3">
                    <div className="mb-3">
                      <Label className="bold">Sample content</Label>
                      <p>Just enter sample content here (it doesn't need to be exact).</p>

                      {masterObj?.exampleHeaderOptions?.length > 0 ? (
                        <Col md={12}>
                          <Label className="bold">Sample content of Header</Label>
                          {masterObj?.exampleHeaderOptions?.map((dataField, key) => (
                            <Col md={12} className={"mb-4"} key={key}>
                              <AvField
                                name={`headerContent-${key}`}
                                type="text"
                                placeholder={dataField ?? "Enter sample content here"}
                                value={masterObj?.exampleHeaderText[key] ?? ""}
                                onChange={e => handleExampleContent("exampleHeaderText", e, key)}
                                errorMessage="Enter sample content"
                                validate={{
                                  required: { value: true },
                                  maxLength: { value: 200 },
                                }}
                              />
                              <p>
                                Make sure not to include any actual user or customer information,
                                and provide only sample content in your example.{" "}
                                <a
                                  href="https://developers.facebook.com/docs/whatsapp/message-templates/guidelines"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Learn more
                                </a>
                              </p>
                            </Col>
                          ))}
                        </Col>
                      ) : null}

                      {masterObj?.exampleBodyOptions?.length > 0 ? (
                        <Col md={12}>
                          <Label className="bold">Sample content of Body</Label>
                          {masterObj?.exampleBodyOptions?.map((dataField, key) => (
                            <Col md={12} className={"mb-4"} key={key}>
                              <AvField
                                name={`bodyContent-${key}`}
                                type="text"
                                placeholder={dataField ?? "Enter sample content here"}
                                value={masterObj?.exampleBodyText[key] ?? ""}
                                onChange={e => handleExampleContent("exampleBodyText", e, key)}
                                errorMessage="Enter sample content"
                                validate={{
                                  required: { value: true },
                                  maxLength: { value: 200 },
                                }}
                              />
                              <p>
                                Make sure not to include any actual user or customer information,
                                and provide only sample content in your example.{" "}
                                <a
                                  href="https://developers.facebook.com/docs/whatsapp/message-templates/guidelines"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Learn more
                                </a>
                              </p>
                            </Col>
                          ))}
                        </Col>
                      ) : null}
                    </div>
                  </Col>
                ) : null}
              </Row>

              <Row>
                <Col md="12">
                  <div
                    className="mb-3"
                    style={{
                      paddingTop: "20px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <Button color="primary" style={{ marginRight: "10px" }} type="submit">
                        Submit
                      </Button>

                      <Button onClick={reset} color="danger" type="button">
                        Reset
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>

        <PhoneFrame
          masterObj={masterObj}
          header={masterObj?.previewHeader}
          body={masterObj?.previewBody}
          footer={masterObj?.footer}
          buttons={masterObj?.buttons}
        />
      </div>

      <Modal isOpen={show.status} toggle={() => toggle()}>
        <ModalHeader toggle={() => toggle()}>Select Attributes</ModalHeader>

        <ModalBody>
          <div className="attribute-container">
            {options.buttonAttribute?.map((dataField, key) => (
              <button onClick={() => handleVariableInject(show?.idx, dataField?.name)} key={key}>
                {dataField?.name}
              </button>
            ))}
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default index
