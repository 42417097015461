/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useEffect, useState } from "react"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Card, CardBody, CardHeader, Row, Col, Button, Nav, NavItem, NavLink } from "reactstrap"
import TablePagination from "../../../../components/Common/TablePagination"
import _, { debounce } from "lodash"
import "../../style.scss"
import moment from "moment"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { CreateBroadcast } from "../../../../components/Whatsapp"
import classNames from "classnames"
import * as config from "./config"
import * as mainConfig from "../../config"
import { del, get, post } from "../../../../helpers/api_helper"
import { useNavigate, useSearchParams } from "react-router-dom"
import toastr from "toastr"

const index = () => {
  const [filterQuery, setFilterQuery] = useState({ status: 1 })
  const [data, setData] = useState(config.sent)
  const [switchTab, setSwitchTab] = useState(0)
  const [refresh, setRefresh] = useState(false)
  let navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const tabParams = searchParams.get("tab")

  const switchTabFunc = tab => {
    if (tab === 1) {
      setData(config.sent)
      navigate("?tab=sent")
    }
    if (tab === 2) {
      setData(config.schedule)
      navigate("?tab=schedule")
    }
    setSwitchTab(tab)
    setFilterQuery({ status: tab })
  }

  useEffect(() => {
    if (tabParams === "sent") switchTabFunc(1)
    if (tabParams === "schedule") switchTabFunc(2)
  }, [tabParams])
  useEffect(() => {
    console.log("refresh...")
    fetchTemplateData()
    setRefresh(false)
  }, [tabParams, refresh])

  const limit = 10
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const handlePageChange = value => {
    setPage(value)
    fetchTemplateData(value)
  }

  const getStatus = status => {
    switch (status) {
      case 0:
        return "Success"
      case 1:
        return "Failed"
      default:
        return "Pending"
    }
  }

  const forceSent = async id => {
    try {
      let res = await post(mainConfig.ROUTE.broadcast({ sent: true }, id, {}))
      toastr.success(res?.message ?? "broadcast sent successfully")
      setRefresh(true)
    } catch (err) {
      toastr.info(err?.message)
    }
  }

  const handleDelete = async id => {
    try {
      let res = await del(mainConfig.ROUTE.broadcast({ del: true }, id, {}))
      toastr.success(res?.message ?? "broadcast deleted successfully")
      setRefresh(true)
    } catch (err) {
      toastr.info(err?.message)
    }
  }

  const fetchTemplateData = async (currentPage = page, currentFilterQuery = filterQuery) => {
    const query = new URLSearchParams()
    query.append("status", currentFilterQuery.status)

    if (currentFilterQuery?.search) {
      query.append("search", currentFilterQuery.search)
    }
    const queryString = query.toString()

    try {
      let res = await get(
        mainConfig.ROUTE.broadcast({}, _, { currentPage, limit, query: queryString })
      )

      if (res) {
        let { data, count } = res

        data?.map((doc, idx) => {
          doc.id = (currentPage - 1) * limit + idx + 1
          doc.date1 = moment(doc.data).format("DD-MM-YYYY")

          doc.broadcastName = doc?.name ?? "---"
          doc.templateName = doc?.template?.name ?? "---"

          doc.templateCategory = (
            <span class="badge bg-soft-primary font-size-12">
              {doc?.template?.category ?? "---"}
            </span>
          )

          let scheduleStatus = doc?.schedule?.status
          let scheduleDateAndTime = moment(doc?.schedule?.dateAndTime, "YYYY-MM-DD HH:mm").format(
            "DD-MM-YYYY hh:mm A"
          )
          doc.scheduleDisplay = scheduleStatus == 2 ? scheduleDateAndTime : "---"

          doc.contactCategory = (
            <span class="badge bg-soft-primary font-size-12">{doc?.category?.name ?? "---"}</span>
          )

          doc.action =
            doc?.schedule?.status === 1 ? (
              <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
                <i
                  className="uil-trash-alt"
                  style={{ fontSize: "1.2em", cursor: "pointer" }}
                  onClick={() => handleDelete(doc?._id)}
                ></i>
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
                <button className="action-btn" onClick={() => forceSent(doc?._id)}>
                  Send Now
                </button>
                <i
                  className="uil-trash-alt"
                  style={{ fontSize: "1.2em", cursor: "pointer" }}
                  onClick={() => handleDelete(doc?._id)}
                ></i>
              </div>
            )

          return doc
        })

        const totalPage = Math.ceil(Number(count) / limit)
        setTotalPage(totalPage)
        setData(prev => ({ ...prev, rows: data }))
      }
    } catch (error) {
      console.error(error.message ?? "fetch broadcast failed")
    }
  }

  const debouncedFetchData = useCallback(debounce(fetchTemplateData, 300), [])

  useEffect(() => {
    debouncedFetchData(page, filterQuery)
    return debouncedFetchData.cancel
  }, [filterQuery])

  const handleFilterReset = () => {
    setFilterQuery({ status: switchTab, search: "" })
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid whatsapp-container">
          <Breadcrumbs title="Home" breadcrumbItem="Broadcast" />

          <CreateBroadcast setRefresh={setRefresh} />

          <Card>
            <CardHeader>
              <h6 className="bold" style={{ textWrap: "nowrap" }}>
                Broadcast List
              </h6>
            </CardHeader>

            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classNames({ active: switchTab === 1 })}
                  disabled={switchTab === 1 ? true : false}
                  onClick={() => switchTabFunc(1)}
                >
                  Sent
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  disabled={switchTab === 2 ? true : false}
                  className={classNames({ active: switchTab === 2 })}
                  onClick={() => switchTabFunc(2)}
                >
                  Schedule
                </NavLink>
              </NavItem>
            </Nav>
            <CardBody>
              <AvForm>
                <Row>
                  <Col sm={6} md={3}>
                    <AvField
                      name="search"
                      type="text"
                      placeholder="Search"
                      value={filterQuery?.search ?? ""}
                      onChange={e => setFilterQuery(prev => ({ ...prev, search: e.target.value }))}
                    />
                  </Col>
                  <Col sm={6} md={3}>
                    <Button color="danger" type="button" onClick={handleFilterReset}>
                      Reset
                    </Button>
                  </Col>
                </Row>
              </AvForm>

              <>
                <TablePagination
                  className="whatsapp-tableModule"
                  data={data}
                  page={page}
                  onChange={handlePageChange}
                  count={totalPage}
                />
              </>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  )
}

export default index
