import React, { Suspense, useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import Cookies from "js-cookie"
import { post } from "../../helpers/api_helper"
import { startTransition } from "react"
import { getCookieName } from "../../helpers/functions"
import { getToken } from "../../store/auth/login/saga"
// import { useDispatch } from "react-redux"
// import { accessToken } from "../../store/actions"
// import { accessToken, logoutUser } from "../../store/actions"

const Authmiddleware = props => {
  const [allowed, setAllowed] = useState(null)
  const [options, setOptions] = useState({})
  const [indexDbExists, setIndexDbExists] = useState(true)

  // const dispatch = useDispatch()

  useEffect(() => {
    // setAllowed(null)
    // setOptions({})
    // if (!Cookies.get("token")) {
    //   console.log("not exi")

    //   if (Cookies.get(`_${getCookieName()}`)) {
    //     dispatch(accessToken())
    //   }
    // }

    const checkPrivilege = async () => {
      startTransition(async () => {
        try {
          // const accessToken = await getToken("tkn")
          const refreshToken = await getToken()

          if (refreshToken) {
            setIndexDbExists(true)
          } else {
            setIndexDbExists(false)
          }

          // if (Cookies.get(`_${getCookieName()}`)) {
          if (Cookies.get(`_${getCookieName()}`) || refreshToken) {
            const response = await post("auth/allowed", {
              module_url: props.path,
            })

            if (response && response.allowed) {
              setAllowed(true)
              setOptions(response.options)
            } else {
              setAllowed(false)
            }
          }
        } catch (error) {
          console.error("Error checking privileges:", error)
          setAllowed(false)
        }
      })
    }

    // if (Cookies.get(getCookieName())) {
    checkPrivilege()
    // }

    if (!Cookies.get(getCookieName()) && Cookies.get(`_${getCookieName()}`)) {
      setTimeout(() => {
        checkPrivilege()
      }, 500)
    }
  }, [props.path])

  if (!Cookies.get(`_${getCookieName()}`) && !indexDbExists) {
    return <Navigate to="/login" />
  }

  if (allowed === false) {
    return <Navigate to={JSON.parse(localStorage.getItem("authUser")).modules.redirect_url} />
  }
  if (allowed === null) {
    return <></>
  }

  return (
    <React.Fragment>
      {/* <Suspense>{props.children}</Suspense> */}
      <Suspense>{React.cloneElement(props.children, { options })}</Suspense>
    </React.Fragment>
  )
}

export default Authmiddleware
