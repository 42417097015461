/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import PropTypes from "prop-types"
import { get } from "../../../helpers/api_helper"
import { API_URL } from "../../../config"

const UrlModel = ({ label, show, toggle, editorHtml, setEditorHtml }) => {
  let [options, setOptions] = useState([])

  useEffect(() => {
    if (show.bodyAttribute) {
      if (label === "body") GET_VARIABLES("body")
      if (label === "header") GET_VARIABLES("header")
    }
  }, [show])

  let GET_VARIABLES = async OPTIONS_TYPE => {
    try {
      let res = await get(`${API_URL}options/templateVariable?type=${OPTIONS_TYPE}`)
      if (res) setOptions(res.data)
    } catch (error) {
      console.log(error.message ?? "Couldn't get variables")
    }
  }

  const handleVariableInject = e => {
    let attribute = e?.currentTarget?.getAttribute("aria-label")
    let text = editorHtml
    text = text.replace(/<[^>]+>/g, "")
    text += `${attribute}`
    let textHtml = `<p>${text}</p>`
    setEditorHtml(textHtml)
  }

  return (
    <Modal isOpen={show?.bodyAttribute} toggle={() => toggle("bodyAttribute")}>
      <ModalHeader toggle={() => toggle("bodyAttribute")}>Select Attributes</ModalHeader>

      <ModalBody>
        <div className="attribute-container">
          {options?.map((dataField, key) => (
            <button aria-label={dataField?.name} onClick={handleVariableInject} key={key}>
              {dataField?.name}
            </button>
          ))}
        </div>
      </ModalBody>
    </Modal>
  )
}

UrlModel.prototype = {
  show: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
  editorHtml: PropTypes.string.isRequired,
  setEditorHtml: PropTypes.func.isRequired,
}

export default UrlModel
