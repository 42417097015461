export const dashboardItems = [
  { key: "sendCount", icon: "./icons/send.svg", label: "Send" },
  { key: "deliveredCount", icon: "./icons/delivered.svg", label: "Delivered" },
  { key: "readCount", icon: "./icons/read.svg", label: "Read" },
  { key: "repliedCount", icon: "./icons/replied.svg", label: "Replied" },
  { key: "sendingCount", icon: "./icons/sending.svg", label: "Sending" },
  { key: "failedCount", icon: "./icons/failed.svg", label: "Failed" },
  { key: "processing", icon: "./icons/processing.svg", label: "Processing" },
  { key: "queuedCount", icon: "./icons/queued.svg", label: "Queued" },
]

export const INITIAL_DASHBOARD_COUNT = {
  sendCount: 0,
  deliveredCount: 0,
  readCount: 0,
  repliedCount: 0,
  sendingCount: 0,
  failedCount: 0,
  processing: 0,
  queuedCount: 0,
}

export const INITIAL_OPTIONS = {
  period: [
    {
      label: "1 Days",
      value: 1,
    },
    {
      label: "7 Days",
      value: 2,
    },
    {
      label: "30 Days",
      value: 3,
    },
  ],
}

export const series = [50]
export const options1 = {
  fill: {
    colors: ["#34c38f"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
}
