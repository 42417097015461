import moment from "moment"
import { APP_NAME, API_URL } from "../config"
import * as XLSX from "xlsx"

export const getClientConfig = () => {
  const clientData = localStorage.getItem("clientConfig")
  return clientData ? JSON.parse(clientData) : null
}

export const setBrowserToken = token => {
  localStorage.setItem("browserToken", token)
}

export function getTimeAgoOrDate(timestamp) {
  const now = moment()
  const timeAgo = moment(timestamp, "YYYY-MM-DD HH:mm:ss").fromNow()

  const timeFormatted = moment(timestamp, "YYYY-MM-DD HH:mm:ss").format("hh:mm A DD MMM YYYY")
  return now.diff(moment(timestamp, "YYYY-MM-DD HH:mm:ss"), "hours") > 8 ? timeFormatted : timeAgo
}

export const whatsappTextToHtml = (paragraph = null) => {
  if (!paragraph || typeof paragraph !== "string") {
    return paragraph
  }

  paragraph = paragraph?.replace(/\b(\d{10,15})\b/g, "<a href='tel:$1'>$1</a>")
  paragraph = paragraph?.replace(/\b(http|https|ftp):\/\/[^\s]+/g, match => {
    return `<a href="${match}">${match}</a>`
  })
  paragraph = paragraph?.replace(/\*(.*?)\*/g, "<strong>$1</strong>")
  paragraph = paragraph?.replace(/_(.*?)_/g, "<i>$1</i>")
  paragraph = paragraph?.replace(/~(.*?)~/g, "<del>$1</del>")
  paragraph = JSON.stringify(paragraph?.replace(/\\n/g, "<br />"))
  paragraph = JSON.parse(paragraph)
  paragraph = paragraph?.replace(/\s+/g, " ").trim()

  try {
    return JSON.parse(paragraph)
  } catch (error) {
    return paragraph
  }
}

export const generatePlaceholderExample = (paragraph = null) => {
  try {
    if (!paragraph || typeof paragraph !== "string") {
      throw new Error("Invalid input: A valid paragraph string is required.")
    }

    const placeholderRegex = /\{\{(.*?)\}\}/g
    const placeholders = paragraph.match(placeholderRegex)

    const replacementDetails = []

    if (!placeholders) {
      return replacementDetails
    }

    let counter = 1
    placeholders.forEach(placeholder => {
      replacementDetails.push(`Enter the content for ${placeholder}`)
      counter++
    })

    return replacementDetails
  } catch (error) {
    return { error: error.message }
  }
}

export const generatePlaceholderTemplate = (paragraph = null) => {
  try {
    if (!paragraph || typeof paragraph !== "string") {
      throw new Error("Invalid input: A valid paragraph string is required.")
    }

    const placeholderRegex = /\{\{(.*?)\}\}/g
    const placeholders = [...paragraph.matchAll(placeholderRegex)].map(match => match[1])

    const replacementDetails = []

    for (let i = 0; i < placeholders.length; i++) {
      const placeholder = `{{${placeholders[i]}}}`
      const replacement = `{{${i + 1}}}`

      replacementDetails.push({
        placeholder,
        replacement,
      })

      paragraph = paragraph.replace(placeholder, replacement)
    }

    return { paragraph, replacementDetails }
  } catch (error) {
    return { error: error.message }
  }
}
export const createSampleExcelSheet = (header = [], name_of_sample_sheet) => {
  const ws = XLSX.utils.aoa_to_sheet([header])
  const range = XLSX.utils.decode_range(ws["!ref"])
  for (let col = range.s.c; col <= range.e.c; col++) {
    const cell = ws[XLSX.utils.encode_cell({ r: 0, c: col })]
    if (cell) {
      cell.s = {
        font: { bold: true },
        alignment: { horizontal: "center", vertical: "center" },
      }
    }
  }

  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, name_of_sample_sheet)

  XLSX.writeFile(wb, `${name_of_sample_sheet}.xlsx`)
}

export const jsonToExcel = (jsonArray, sheetName = moment().format("DDMMYYhhmmss")) => {
  // Create a worksheet
  const ws = XLSX.utils.json_to_sheet(jsonArray)

  // Create a workbook
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, sheetName)

  // Save to a file
  XLSX.writeFile(wb, `${sheetName}.xlsx`)
}

export const excelToJson = (excelFile = null, callback) => {
  if (!excelFile) {
    const err = new Error("Please provide an excel file")
    return callback(err)
  }

  try {
    let reader = new FileReader()

    reader.onload = function (event) {
      try {
        const data = event.target.result

        const wb = XLSX.read(data, { type: "array" })

        const sheet = wb.Sheets[wb.SheetNames[0]]

        const jsonData = XLSX.utils.sheet_to_json(sheet, { raw: true })

        callback(null, jsonData)
      } catch (err) {
        callback(err)
      }
    }

    reader.readAsArrayBuffer(excelFile)
  } catch (err) {
    callback(err)
  }
}

export const getCookieName = () => {
  const origin = window.location.protocol + "//" + window.location.host || "default_origin"
  const cleanedOrigin = origin.replace(/[^a-zA-Z0-9_-]/g, "_")
  const cookieName = `${cleanedOrigin}_session`
  return cookieName
}

export function clearCookiesExcept(cookiesToKeep) {
  const cookies = document.cookie.split(";")
  cookies.forEach(cookie => {
    const cookieName = cookie.split("=")[0].trim()
    if (!cookiesToKeep.includes(cookieName)) {
      document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/"
    }
  })
}

export const dateConverter = date => {
  const formats = [moment.ISO_8601, "YYYY-MM-DD HH:mm:ss"]
  const isValidDate = moment(date, formats, true).isValid()
  return isValidDate ? moment(date).format("DD-MM-YYYY") : ""
}

export const timeConverter = time => {
  const formats = ["HH:mm:ss", "HH:mm", "YYYY-MM-DD HH:mm:ss"]
  const isValidTime = moment(time, formats, true).isValid()
  return isValidTime ? moment(time, formats).format("hh:mm a") : ""
}

export const numberToString = (number = 0) => {
  return (
    number.toLocaleString("en-IN", {
      maximumFractionDigits: 2,
    }) || 0
  )
}

export const renderTableValue = value => value || <div className="text-center">-</div>

export const setTitle = (title = "") => {
  document.title = `${title} | ${APP_NAME}`
  return title
}

export const toTop = () => window.scroll({ top: 0, left: 0, behavior: "smooth" })

export const getFirstday = date => moment().startOf("month").format("YYYY-MM-DD")

export const getDate = () => moment().format("YYYY-MM-DD")
export const timeSince = (date, now_date) => {
  var seconds = Math.floor((now_date - date) / 1000)

  var interval = seconds / 31536000

  if (interval > 1) {
    return Math.floor(interval) + " yrs"
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return Math.floor(interval) + " months"
  }
  interval = seconds / 86400
  if (interval > 1) {
    return Math.floor(interval) + " days"
  }
  interval = seconds / 3600
  if (interval > 1) {
    return Math.floor(interval) + " hrs"
  }
  interval = seconds / 60
  if (interval > 1) {
    return Math.floor(interval) + " min"
  }
  return Math.floor(seconds) + " sec"
}

export const handleDownload = async (path, filename) => {
  try {
    const response = await fetch(`${API_URL}${path}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })

    if (!response.ok) {
      throw new Error(`Failed to fetch the file: ${response.statusText}`)
    }

    // Create a blob from the response
    const blob = await response.blob()

    // Create a blob URL for the downloaded file
    const blobUrl = URL.createObjectURL(blob)

    // Trigger the download
    const downloadLink = document.createElement("a")
    downloadLink.href = blobUrl
    downloadLink.download = filename || "downloaded-file" // Set the default filename
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)

    // Revoke the blob URL to free up memory
    URL.revokeObjectURL(blobUrl)
  } catch (error) {
    console.error("Error downloading the file:", error)
    alert("Failed to download the file. Please try again later.")
  }
}

export const reactSelectCustomStyle = {
  control: (provided, state) => ({
    ...provided,
    height: "30px",
    minHeight: "30px",
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
  }),
  valueContainer: provided => ({
    ...provided,
    height: "30px",
    padding: "0 8px",
  }),
  input: provided => ({
    ...provided,
    margin: "0",
    padding: "0",
  }),

  indicatorsContainer: provided => ({
    ...provided,
    height: "30px",
    display: "flex",
    alignItems: "center",
  }),
}
