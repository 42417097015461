/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useEffect, useState } from "react"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Card, CardBody, CardHeader, Row, Col, Button } from "reactstrap"
import { Drawer, Tooltip } from "@mui/material"
import TablePagination from "../../../../components/Common/TablePagination"
import _, { debounce } from "lodash"
import "../../style.scss"
import { jsonToExcel, toTop } from "../../../../helpers/functions"
import moment from "moment"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { API_URL } from "../../../../config"
import toastr from "toastr"
import { BulkUploadModal, NewTemplateModel, PhoneFrame } from "../../../../components/Whatsapp"
import { get } from "../../../../helpers/api_helper"
import * as mainConfig from "../../config"
import { useDispatch, useSelector } from "react-redux"
import { refreshPageAction } from "../../../../store/actions"

const index = () => {
  const [filterQuery, setFilterQuery] = useState("")
  const [tableData, setTableData] = useState([])
  const [exportData, setExportData] = useState([])
  const [drawer, setDrawer] = useState(false)
  const [previewData, setPreviewData] = useState({})

  const toggleDrawer = value => setDrawer(value)

  const refresh = useSelector(state => state.refreshReducer.refresh)
  const dispatch = useDispatch()

  const [file, setFile] = useState(null)
  const [show, setShow] = useState(false)
  const toggle = () => {
    setShow(!show)
  }

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const handlePageChange = value => {
    setPage(value)
    fetchTemplateData(value)
  }

  const handlePreview = doc => {
    toggleDrawer(true)
    let whitelistedMediaType = ["image", "video", "document"]

    setPreviewData({
      type: doc?.headerMediaType ?? "",
      headerUrl: `${API_URL}${doc?.uploaded}`,
      file: whitelistedMediaType.includes(doc.headerMediaType) ? true : false,
      header: doc?.header,
      body: doc?.body,
      footer: doc?.footer,
      buttons: doc?.buttons,
    })
  }

  const fetchTemplateData = async (currentPage = page, currentFilterQuery = filterQuery) => {
    const query = new URLSearchParams()
    query.append("search", currentFilterQuery)
    const queryString = query.toString()
    try {
      let exportDataCreate = []
      let res = await get(
        mainConfig.ROUTE.template({}, _, { page: currentPage, limit, query: queryString })
      )
      if (res) {
        let { data, count } = res

        data.map((doc, idx) => {
          doc.id = (currentPage - 1) * limit + idx + 1
          doc.date1 = moment(doc?.date).format("DD-MM-YYYY")

          doc.status =
            doc?.templateStatus === "APPROVED" ? (
              <span className="badge bg-soft-success font-size-12">Approved</span>
            ) : doc?.templateStatus === "REJECTED" ? (
              <span class="badge bg-soft-danger font-size-12 cursor-pointer">
                <Tooltip arrow={true} title={doc?.rejectionReason ?? "Rejected"} placement="top">
                  Rejected
                </Tooltip>
              </span>
            ) : (
              <span class="badge bg-warning-subtle font-size-12 text-warning">Pending</span>
            )

          // data.addedBy = data?.addedBy?.firstName
          //   ? `${data?.addedBy?.firstName} ${data?.addedBy?.lastName}`
          //   : data?.addedBy
          //     ? data?.addedBy
          //     : ""
          doc.categoryDisplay = doc?.category?.name ?? "---"

          let tempExportData = {}
          tempExportData.Id = doc.id ?? "---"
          tempExportData["Date"] = doc.date1 ?? "---"
          tempExportData["Template Name"] = doc.template_name ?? "---"
          tempExportData.Category = doc.categoryDisplay ?? "---"
          tempExportData.Status = doc?.status ?? "---"
          tempExportData.Language = doc.language ?? "---"
          tempExportData["Last Updated"] = doc.date2 ?? "---"
          exportDataCreate.push(tempExportData)

          doc.action = (
            <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
              <i
                className="uil-eye"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  handlePreview(doc)
                }}
              ></i>
            </div>
          )

          return doc
        })

        const totalPage = Math.ceil(Number(count) / limit)
        setTotalPage(totalPage)
        setTableData(data)
        setExportData(exportDataCreate)
      }
    } catch (error) {
      console.error(error.message)
    }
  }

  const handleExport = () => {
    if (exportData?.length > 0) {
      jsonToExcel(exportData, `broadcast-template-${moment().format("DD-MM-YY")}`)
    } else {
      toastr.info("There is not data to export!")
    }
  }

  const debouncedFetchData = useCallback(debounce(fetchTemplateData, 300), [])

  useEffect(() => {
    dispatch(refreshPageAction(false))

    debouncedFetchData(page, filterQuery)
    return debouncedFetchData.cancel
  }, [refresh, filterQuery])

  const handleFilterReset = () => setFilterQuery("")

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date",
        field: "date1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Template Name",
        field: "name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Category",
        field: "category",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Language",
        field: "language",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "action",
        sort: false,
        width: 100,
      },
    ],
    rows: tableData,
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid whatsapp-container">
          <Breadcrumbs title="Home" breadcrumbItem="Broadcast Templates" />
          <NewTemplateModel />

          <Card>
            <CardHeader>
              <h6 className="bold" style={{ textWrap: "nowrap" }}>
                Broadcast Templates List
              </h6>

              <div className="extra-options d-none">
                <div className="input-output">
                  <Tooltip title="Import (.xls, .xlsx)" arrow placement="bottom">
                    <Button onClick={toggle}>
                      <i className="uil-import" />
                      <span>{file ? "Imported" : "Import"}</span>
                    </Button>
                  </Tooltip>
                  <div className="divider"></div>
                  <Tooltip title="Export xlsx file" arrow placement="bottom">
                    <Button onClick={handleExport}>
                      <i className="uil-export" />
                      <span>Export</span>
                    </Button>
                  </Tooltip>
                </div>

                <Tooltip title="Download sample file">
                  <a
                    href={`${API_URL}sample/bulk_upload_broadcast_templates.xlsx`}
                    target="_blank"
                    rel="noreferrer"
                    download
                    style={{ fontSize: "1rem", width: "fit-content" }}
                    className="d-flex align-items-center btn btn-success download-btn"
                  >
                    <i style={{ fontSize: "13px" }} className="fa fa-solid fa-download" />
                    <span style={{ marginLeft: "5px", fontSize: "13px" }}>
                      Download Sample Sheet
                    </span>
                  </a>
                </Tooltip>
              </div>
            </CardHeader>
            <CardBody>
              <AvForm>
                <Row>
                  <Col md={3}>
                    <AvField
                      name="search"
                      type="text"
                      placeholder="Search"
                      value={filterQuery}
                      onChange={e => setFilterQuery(e.target.value)}
                    />
                  </Col>
                  <Col md={3}>
                    <Button color="danger" type="button" onClick={handleFilterReset}>
                      Reset
                    </Button>
                  </Col>
                </Row>
              </AvForm>

              <>
                <TablePagination
                  className="whatsapp-tableModule"
                  data={data}
                  page={page}
                  onChange={handlePageChange}
                  count={totalPage}
                />
              </>
            </CardBody>
          </Card>
        </div>
      </div>

      <Drawer anchor={"right"} open={drawer} onClose={() => toggleDrawer(false)}>
        <div className="p-2">
          <PhoneFrame
            masterObj={{ headerUrl: previewData?.headerUrl, headerMediaType: previewData?.type }}
            previewData={previewData}
            file={previewData?.file}
            header={previewData?.header}
            body={previewData?.body}
            footer={previewData?.footer}
            buttons={previewData?.buttons}
          />
        </div>
      </Drawer>

      <BulkUploadModal
        show={show}
        toggle={toggle}
        setFile={setFile}
        urlPath={"/"}
        selectOptions={[
          {
            label: "Marketing",
            value: "MARKETING",
          },
          {
            label: "Utility",
            value: "UTILITY",
          },
        ]}
      />
    </>
  )
}

export default index
