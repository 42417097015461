  import React, { useEffect, useRef, useState  } from "react";
  import { AvField, AvForm } from "availity-reactstrap-validation";
  import Breadcrumbs from "../../../components/Common/Breadcrumb.js";
  import Select from "react-select";
  import { Row, Col, Card, CardBody, Button, Label, Modal, Spinner } from "reactstrap";
  import { MDBDataTable } from "mdbreact";
  import OverlayTrigger from "react-bootstrap/OverlayTrigger";
  import Tooltip from "react-bootstrap/Tooltip";
  import "./AssignFeedback.scss";
  import {post, del, get} from '../../../helpers/api_helper.js';
  import toastr from 'toastr';
  import Swal from "sweetalert2";
  import Tab from "@mui/material/Tab";
  import TabContext from "@mui/lab/TabContext";
  import TabList from "@mui/lab/TabList";
  import TabPanel from "@mui/lab/TabPanel";
  import { Box } from "@mui/material";
  import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
  import QrCode2Icon from '@mui/icons-material/QrCode2';
  import PrintIcon from '@mui/icons-material/Print';
  import moment from "moment";
  import { getClientConfig } from "../../../helpers/functions.js"
  import { API_URL } from "../../../config.js";
  import queryString from "query-string";
  import QRCodeTemplate from "./QrTemplate.jsx";


  function AssignFeedback() {

    const toTop = () => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    }

    const toggle = (start = true) => {
      var body = document.body

      if (start) {
        body.classList.remove("vertical-collpsed")
        body.classList.remove("sidebar-enable")
        body.setAttribute("data-sidebar-size", "sm")
      } else {
        body.classList.add("vertical-collpsed")
        body.classList.add("sidebar-enable")
        body.setAttribute("data-sidebar-size", "lg")
      }
    }

    useEffect(() => {
      toggle()

      return () => {
        toggle(false)
      }
      // eslint-disable-next-line
    }, [])

    const initialMasterObject = {
      branch:'',
      category:'',
      subCategory:'', 
      form: '',
      qrCodes:[],
      assignType:1,
      printType:1
    }

    const formRef = useRef()
    const [popupView, setPopupView] = useState(false)
    const [popupView2, setPopupView2] = useState(false)


    const [branchOptions, setBranchOptions] = useState([])
    const [departmentOptions, setDepartmentOptions] = useState([])
    const [categoryOptions, setCategoryOptions] = useState([])
    const [subCategoryOptions, setSubCategoryOptions] = useState([])
    const [tab, setTab] = useState(1)
    const [feedbackFormOption, setFeedbackFormOptions] = useState([])
    const [domainFb, setDomainFb] = useState('')
    const [qrOptions, setQrOptions] = useState([])
    const [printableQr, setPrintableQr] = useState([])
    const [disableButton, setDisableButton]= useState(false)
    const [templateId, setTemplateId] = useState(null)
    const [templateOptions, setTemplateOptions] = useState([])
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(1)
    const [refresh2, setRefresh2] = useState(1)

    const [generateCount, setGenerateCount] = useState(0)
    const [tableData, setTableData] = useState([])

    const [selectedFields, setSelectedFields] = useState({branch: '', category:'', subCategory:'', form: '', qrCodes:[]})
    const [masterObject, setMasterObject] = useState(initialMasterObject)

    const handleCloseModal = () => setPopupView(false)
    const handleCloseModal2 = () => {
      setPopupView2(!popupView2)
      setTemplateId(null)
    }

    const replaceTableData = (value)=> {
      if(value !== '' && value !== undefined && value !== null){
        return value
      }else{
        return '-----'
      }
    }

    const fetchBranchOptions = ()=> {
      get(`/options/branches`).then(res=>{
        setBranchOptions(res.data)
      }).catch(err=>{console.log(err)})
    }

    const fetchDepartmentOptions = ()=> {
      get(`/options/lead-departments`).then(res=>{
        setDepartmentOptions(res.data)
      }).catch(err=>{console.log(err)})
    }


    const fetchTemplateOptions = ()=> {
      get(`feedback/qr/template/options`).then(res=>{
        setTemplateOptions(res.data)
      }).catch(err=>{console.log(err)})
    }

      const fetchQrOptions = ()=> {
      get(`feedback/qr/options`).then(res=>{
        setQrOptions(res.data)
      }).catch(err=>{console.log(err)})
    }

    const fetchCategoryOptions = (obj)=> {
    get(`/options/fb-category?branch=${obj?.branch}`).then(res=>{
      setCategoryOptions(res.data)
    }).catch(err=>{console.log(err)})
    }

    const fetchSubCategoryOptions = (obj)=> {
    get(`/options/fb-sub-category?branch=${obj?.branch}&category=${obj?.category}`).then(res=>{
      setSubCategoryOptions(res.data)
    }).catch(err=>{console.log(err)})
    }

    const fetchFeedbackForms = ()=> {
      get(`/options/fb-form`).then(res=>{
        setFeedbackFormOptions(res.data)
      }).catch(err=>{console.log(err)})
    }

    // useEffect(() => {
    //   let timeoutId;
    //   const fetchPrintableQr = () => {
    //     setLoading(true);
    //     get(`feedback/qr/download`)
    //       .then(res => {
    //         if(res?.data[0]?.status === 0){
    //           toastr.success(res.data[0]?.message);
    //         }else{
    //           toastr.success(res.message);
    //         }
    //         setPrintableQr(res.data);
    //         timeoutId = setTimeout(() => setLoading(false), 2000);
    //       })
    //       .catch(err => {
    //         console.log(err);
    //         setLoading(false);
    //       });
    //   };
    //   if (tab === 3) fetchPrintableQr();
    //   return () => {
    //     if (timeoutId) clearTimeout(timeoutId);
    //   };
    // }, [tab, refresh]); 

    const fetchTableData = ()=> {
      setLoading(true)
      get(`feedback/qr`).then(res=> {
        const data = res.data
        setGenerateCount(res.generateCount)
        setTableData(data.map((item,index)=> ({
          ...item,
          branch: replaceTableData(item.branch),
          category: replaceTableData(item.category),
          form: replaceTableData(item.form),
          subCategory: replaceTableData(item.subCategory),
          name:`QR${item?.count?.toString().padStart(4, '0')}`,
          index : index + 1,
          date: moment(item.date).format('DD-MM-YYYY'),
          visitors:item?.visitors || "---",
          status: item?.status === 0 ? <span className="badge bg-warning font-size-12">Unassgined</span> : 
          item?.status === 2 ? <span className="badge bg-success font-size-12">Assigned</span> : 
          item?.status === 3 ? <span className="badge bg-danger font-size-12">Failed</span> :'',
          link: (
            <>
              <a
                style={{
                  marginRight: "5px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                href={`${domainFb}/fbs/${item.uniqueId}`}
                target="_blank"
                rel="noreferrer"
              >
                {domainFb || ''}/fbs/{item.uniqueId}
              </a>
              <OverlayTrigger placement="top" overlay={<Tooltip>{"Copy URL"}</Tooltip>}>
                <i className="far fa-copy" style={{ cursor: "pointer" }}
                  onClick={() => navigator.clipboard.writeText(`${domainFb}/fbs/${item.uniqueId}`)}
                ></i>
              </OverlayTrigger>
            </>
          ),
          actions: (
            <div style={{ display: "flex", justifyContent: "center" }}>
            {item.status !== 3 && <span
                onClick={()=>{
                  handleDownload(`${API_URL}feedback/qr/view/${`QR${item?.count?.toString().padStart(4, '0')}`}`)
                }}
                target="_blank"
                rel="noreferrer"
            > <i
                className="fas fa-qrcode"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
              >
                </i></span>}
                {item.status !== 3 && <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop()
                  fetchUpdateData(item._id)
                }}
              ></i>}
              {item.status === 2 && <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  handleDelete(item._id)
                }}
              ></i>}
            </div>
          )
        })))
        setTimeout(() => setLoading(false), 2000);
      }).catch(err=> console.error(err));
    }


    useEffect(()=>{
      if(domainFb){
        fetchTableData()
      }else{
        setDomainFb(getClientConfig()?.domainFb)
      }
      //eslint-disable-next-line
    },[domainFb, refresh2])
    
    useEffect(()=>{
      fetchBranchOptions()
      fetchDepartmentOptions()
      if(tab === 2 || tab === 3)fetchTemplateOptions()
      // fetchCategoryOptions()
      // fetchSubCategoryOptions()
      fetchFeedbackForms()
      fetchQrOptions()
      //eslint-disable-next-line
    },[tab])

    const handleSelectChange = (e, name)=> {
      let newObj = {...selectedFields}
      if(name === 'branch'){
        if(e !== null && e !== ''){
          newObj.category =null
          newObj.subCategory =null
        }
        newObj.branch = e
      }else if(name === 'category'){
        if(e !== null && e !== ''){
          newObj.subCategory = null
        }
        newObj.category = e
      }else if(name ==='template'){
        if(e.value === 1){
          setPopupView2(true)
          newObj.template = null
        }else{
          newObj.template = e
        }
      }else if(name ==='qrCodes'){
        if(newObj._id){
          newObj = { qrCodes: e}
        }else{
          newObj.qrCodes = e
        }
      }else{
        newObj[name] = e
      }
      setSelectedFields(newObj)
      if(name==='qrCodes'){
        e.value = e?.map(item=>parseInt(item.value,10)) || []
      }
      handleValueChange(e?.value || '' , name)
    }

    const handleValueChange = (value, name)=> {
      let newObj = {...masterObject}
      if(name === 'branch'){
        if(value !== null && value !== ''){
          delete newObj.category
          delete newObj.subCategory
        }
        newObj.branch = value
        // fetchCategoryOptions(newObj)
        // fetchSubCategoryOptions(newObj)
      }else if(name === 'category'){
        if(value !== null && value !== ''){
          delete newObj.subCategory
        }
        newObj.category = value
        // fetchSubCategoryOptions(newObj)
      }else if(name === 'qrFrom' || name === 'qrTo'){
        newObj[name] = parseInt(value, 10)
      }else if(name ==='template'){
        if(value === 1){
          delete newObj.template
        }else{
          newObj.template = value
        }
      }else if(name ==='qrCodes'){
        if(newObj._id){
          newObj = {...initialMasterObject, qrCodes: value}
          formRef.current.reset()
        }else{
          newObj.qrCodes = value
        }
      }else{
        newObj[name] = value
      }
      setMasterObject(newObj)
    }

    const handleSetNewTemplate=(newTemplateObj)=>{
      fetchTemplateOptions()
      setSelectedFields({...selectedFields, template : {_id:newTemplateObj._id, label: newTemplateObj.name, value: newTemplateObj._id}})
      setMasterObject({...masterObject, template: newTemplateObj._id}) 
    }

    const reset = ()=> {
      setMasterObject(initialMasterObject)
      setSelectedFields({})
      formRef.current.reset()
    }

    const handleSubmit1 = ()=> {
      if(masterObject?.assignType === 1){
        if(masterObject?.qrCodes?.length===0)return toastr.error('Please select QR Codes')
        const isValid = masterObject.qrCodes.every(item=>!isNaN(item))
        if(!isValid)return  toastr.error('Please provide valid QR Codes')
      }else if(masterObject?.assignType === 2){
        if(isNaN(masterObject.qrFrom))return toastr.error('Invalid QR from range.')
        if(isNaN(masterObject.qrTo))return toastr.error('Invalid QR from range.')
        if(masterObject.qrFrom > masterObject?.qrTo)return toastr.error('Invalid QR range')
      }
      setDisableButton(true)
        post(`feedback/qr/assign`, {...masterObject}).then(res=>{
          reset()
          fetchTableData()
          toastr.success(res.message)
        }).catch(err=>{
          setDisableButton(false)
          toastr.error(err?.response?.data?.message || err?.message || "Something wentwrong")
        })
      setDisableButton(false)
    }

    const handleSubmit2 = ()=> {
      if(!masterObject.template)return toastr.error('Please select a template')
      setDisableButton(true)
      const params = queryString.stringify(masterObject);
      post(`feedback/qr/generate?${params}`, ).then(res=>{
          reset()
          toastr.success(res.message)
        }).catch(err=>{
          setDisableButton(false)
          toastr.error(err.response.data.message)})
      setDisableButton(false)
    }

    const handleSubmit3 =async ()=> {
      if(masterObject?.printType === 1){
        if(masterObject?.qrCodes?.length===0)return toastr.error('Please select QR Codes')
        const isValid = masterObject.qrCodes.every(item=>!isNaN(item))
        if(!isValid)return  toastr.error('Please provide valid QR Codes')
      }else if(masterObject?.printType === 2){
        if(isNaN(masterObject.qrFrom))return toastr.error('Invalid QR from range.')
        if(isNaN(masterObject.qrTo))return toastr.error('Invalid QR to range.')
        if(masterObject.qrFrom > masterObject?.qrTo)return toastr.error('Invalid QR range')
      }
      setDisableButton(true)
      const response = await fetch(`${API_URL}feedback/qr/print`, {
        method: "POST",
        credentials: "include",
        headers:{
          "Content-Type": "application/json"
        },
        body:JSON.stringify(masterObject)});

      if (!response.ok) {
        const data = await response.json()
        toastr.error(data.message || "Failed to download the file");
        setDisableButton(false)
        return
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link to download the file
      const link = document.createElement("a");
      link.href = url;
      link.download = "qrcode.pdf"; // Default filename
      document.body.appendChild(link);
      link.click();
      
      // Clean up
      link.remove();
      window.URL.revokeObjectURL(url);
      
      reset()
      setDisableButton(false)
    }

    const updateDownloadStatus = (id)=>{
      post(`feedback/qr/download/update-status/${id}`).then(res=>{
        console.log(res.message)
      }).catch(err=>console.error(err.response.data.message))
    }

    const fetchUpdateData =(id)=> {
      get(`feedback/qr/${id}`).then(res=> {
        setTab(1)
        const data = res.data
        setMasterObject({
          ...data,
          assignType: 1,
          qrCodes: [data?.count] || '',
          branch: data?.branch?._id || '',
          department: data?.department?._id || '',
          category: data?.category?._id || '',
          subCategory: data?.subCategory?._id || '',
          form: data?.form._id || ''
        })
        setSelectedFields({
          _id: data?._id,
          branch: data?.branch || '', 
          department: data?.department || '', 
          category: data?.category || '', 
          subCategory: data?.subCategory || '', 
          form: data?.form || '', 
          qrCodes:[{label:`QR${data?.count?.toString()?.padStart(4, '0')}`, value: data?.count }]
        })
      })
    }

    function handleDelete(id) {
      return Swal.fire({
        title: "Are you sure you want to unassign this QR?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Unassign",
      }).then(result => {
        if (result.isConfirmed) {
          del(`feedback/qr-unassign/${id}`)
            .then(res => {
              fetchTableData()
              reset()
              toastr.success(res.message)
            })
            .catch(err => {
              toastr.error(err.response.data.message)
            })
        }
      })
    }
    
    const data = {
      columns: [
        {
          label: "#",
          field: "index",
          width: 150,
        },
        {
          label: "QR Code",
          field: "name",
          width: 150,
        }, 
        {
          label: "Link",
          field: "link",
          width: 150,
        },
        {
          label: "Status",
          field: "status",
          width: 150,
        },
        {
          label: "Form",
          field: "form",
          width: 150,
        },
        {
          label: "Branch",
          field: "branch",
          width: 150,
        },
        {
          label: "Department",
          field: "department",
          width: 150,
        },
        // {
        //   label: "Category",
        //   field: "category",
        //   width: 150,
        // },
        // {
        //   label: "Sub Category",
        //   field: "subCategory",
        //   width: 150,
        // },
        {
          label: "Responses",
          field: "responses",
          sort: "desc",
          width: 100,
        },
        {
          label: "Visitors",
          field: "visitors",
          sort: "desc",
          width: 100,
        },
        {
          label: "Actions",
          field: "actions",
          sort: "desc",
          width: 400,
        },
      ],
      rows: tableData
    }

    const handleDownload = async (fullUrl)=>{

      const response = await fetch(fullUrl, {
        method: "GET",
        credentials: "include",
      })
  
  
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
  
      // Create a temporary link to download the file
      const link = document.createElement("a");
      link.href = url;
      link.download = "qrcode.pdf"; // Default filename
      document.body.appendChild(link);
      link.click();
      
      // Clean up
      link.remove();
      window.URL.revokeObjectURL(url);

    }

    return (
      <>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Assign Feedback" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                  <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        onChange={(e, value)=>{setTab(value);reset()}}
                        aria-label="lab API tabs example"
                      >
                        <Tab
                          icon={<QrCode2Icon style={{ fontSize: "30px" }} />}
                          label="Assign QR Code"
                          value={1}
                          size="small"
                          style={{
                            textTransform: "capitalize",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        />
                        <Tab
                        icon={<QrCodeScannerIcon style={{ fontSize: "30px" }} />}
                          label="Generate QR Code"
                          value={2}
                          size="small"
                          style={{
                            textTransform: "capitalize",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        />
                        <Tab
                        icon={<PrintIcon style={{ fontSize: "30px" }} />}
                          label="Print QR Code"
                          value={3}
                          size="small"
                          style={{
                            textTransform: "capitalize",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        />
                      </TabList>
                    </Box>
                    {/*//? Assign QR Code */}
                    <TabPanel value={1}>
                      <AvForm onValidSubmit={handleSubmit1} ref={formRef}>
                      <Row className="mb-3">
                        <Col md={3} style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:"24px"}}>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio1"
                            autoComplete="off"
                            checked={masterObject.assignType === 1}
                            onClick={()=>setMasterObject({...masterObject, assignType:1})}
                          />
                          <label className="btn btn-outline-dark" htmlFor="btnradio1">
                            Individual Assign
                          </label>

                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio2"
                            autoComplete="off"
                            checked={masterObject.assignType === 2}
                            onClick={()=>{
                              if(masterObject._id){
                                return
                              }else{
                                setMasterObject({...masterObject, assignType:2})
                              }
                              }}
                          />
                          <label className="btn btn-outline-dark" htmlFor="btnradio2">
                            Range Assign
                          </label>
                          </div>
                          </Col>
                      {masterObject.assignType === 1 ? <Col md='3'>
                          <Label>QR Codes</Label>
                          <Select
                            options={qrOptions}
                            isMulti
                            name='qrCodes'
                            placeholder='Select QR Codes'
                            onChange={e=>handleSelectChange(e, 'qrCodes')}
                            value={selectedFields.qrCodes || ''}
                          />
                        </Col> : <>
                        <Col md='2'>
                          <Label>QR Codes From</Label>
                          <AvField
                            type='number'
                            name='qrFrom'
                            placeholder='From'
                            validate={{
                              pattern: {
                                value: '^[1-9]\\d*$',
                                errorMessage: 'Invalid QR range',
                              },
                              maxLength:{
                                value:4,
                                errorMessage: 'Invalid QR range',
                              },
                              number:true
                            }}
                            onChange={e=>handleValueChange(e.target.value, 'qrFrom')}
                            value={masterObject.qrFrom || ''}
                          />
                        </Col>
                        <Col md='2'>
                          <Label>QR Codes To</Label>
                          <AvField
                            type='number'
                            name='qrTo'
                            placeholder='To'
                            maxLength={4}
                            validate={{
                              pattern: {
                                value: '^[1-9]\\d*$',
                                errorMessage: 'Invalid QR range',
                              },
                              maxLength:{
                                value:4,
                                errorMessage: 'Invalid QR range',
                              },
                              number:true
                            }}
                            onChange={e=>handleValueChange(e.target.value, 'qrTo')}
                            value={masterObject.qrTo || ''}
                          />
                        </Col></>}
                        <Col className="d-flex justify-content-end align-items-center">
                            <span className="text-primary fw-bold font-size-14 me-5">QR Generated <span className="badge bg-primary font-size-14 text-white">{generateCount}</span></span>
                        </Col>
                      </Row>
                      <Row>
                        <Col md='3'>
                          <Label>Branch</Label>
                          <Select
                            options={branchOptions}
                            name='branch'
                            placeholder='Branch'
                            onChange={e=>handleSelectChange(e, 'branch')}
                            value={selectedFields.branch || ''}
                            isClearable
                          />
                        </Col>
                        <Col md='3'>
                        <Label>Department</Label>
                          <Select
                            options={departmentOptions}
                            name='department'
                            placeholder='Department'
                            onChange={e=>handleSelectChange(e, 'department')}
                            value={selectedFields.department || ''}
                            isClearable
                          />
                        </Col>
                        {/* <Col md='3'>
                        <Label>Category</Label>
                          <Select
                            options={categoryOptions}
                            name='category'
                            placeholder='Category'
                            onChange={e=>handleSelectChange(e, 'category')}
                            value={selectedFields.category || ''}
                            isClearable
                          />
                        </Col>
                        <Col md='3'>
                        <Label>Sub-category</Label>
                          <Select
                            options={subCategoryOptions}
                            name='subCategory'
                            placeholder='Sub-category'
                            onChange={e=>handleSelectChange(e, 'subCategory')}
                            value={selectedFields?.subCategory || ''}
                            isClearable
                          />
                        </Col> */}
                        <Col md="3" >
                        <Label>Feedback Form</Label>
                        <Select
                          name="form"
                          options={feedbackFormOption}
                          type="text"
                          placeholder='Feedback Form'
                          onChange={(e)=>handleSelectChange(e, 'form')}
                          value={selectedFields.form || ''}
                          isClearable
                        />
                        </Col> </Row>
                        <Row className="mb-3 mt-3 text-align-center">
                        <Col md='12' style={{textAlign:'center'}}>
                          <div className="mb-3" style={{ paddingTop: "20px" }}>
                            <Button className={masterObject._id ? "" : "bg-soft-primary"} color={masterObject._id ? "info" : "primary"} style={{ marginRight: "10px" }} type="submit" disabled={disableButton || loading}>
                              {masterObject._id ? 'Update' : 'Assign' }
                            </Button>

                            <Button color="danger" onClick={reset} disabled={disableButton || loading}>
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      </AvForm>
                    </TabPanel>
                    {/*//? Generate QR Code */}

                    <TabPanel value={2}>
                      <AvForm onValidSubmit={handleSubmit2} ref={formRef}>
                        <Row>
                          <Col md='2'>
                          <Label>Generate Count</Label>
                          <AvField
                            type='number'
                            name='count'
                            placeholder='Count'
                            validate={{
                              max:{
                                value: 100,
                                errorMessage:'Only 100 allowed at a time'
                              },
                              required:{
                                value: true,
                                errorMessage:'Count is required'
                              },
                              pattern: {
                                value: '^[1-9]\\d*$',
                                errorMessage: 'Invalid Count',
                              }
                            }}
                            onChange={e=>handleValueChange( e.target.value,'count')}
                            value={masterObject.count || ''}
                          />
                        </Col>
                        <Col md='3'>
                        <Label>Choose Template</Label>
                            <Select
                              options={[{label:'Create New...', value: 1},...templateOptions]}
                              name='template'
                              placeholder='Template'
                              onChange={e=>handleSelectChange(e, 'template')}
                              value={selectedFields.template || ''}
                            />
                        </Col>
                        {masterObject.template && <Col md='2' className="d-flex align-items-center mt-3">
                          <Button 
                            onClick={()=>{
                              setTemplateId(masterObject.template); 
                              setPopupView2(true)}
                            } 
                            color='info'>
                            View Template
                          </Button>
                        </Col>}
                        <Col className="d-flex justify-content-end align-items-center">
                            <span className="text-primary fw-bold font-size-14 me-5">QR Generated <span className="badge bg-primary font-size-14 text-white">{generateCount}</span></span>
                        </Col>
                      </Row>
                      <Row>
                        <Col md='3'>
                          <Label>Branch</Label>
                          <Select
                            options={branchOptions}
                            name='branch'
                            placeholder='Branch'
                            onChange={e=>handleSelectChange(e, 'branch')}
                            value={selectedFields.branch || ''}
                            isClearable
                          />
                        </Col>
                        <Col md='3'>
                        <Label>Department</Label>
                          <Select
                            options={departmentOptions}
                            name='department'
                            placeholder='Department'
                            onChange={e=>handleSelectChange(e, 'department')}
                            value={selectedFields.department || ''}
                            isClearable
                          />
                        </Col>
                        {/* <Col md='3'>
                        <Label>Category</Label>
                          <Select
                            options={categoryOptions}
                            name='category'
                            placeholder='Category'
                            onChange={e=>handleSelectChange(e, 'category')}
                            value={selectedFields.category || ''}
                            isClearable
                          />
                        </Col>
                        <Col md='3'>
                        <Label>Sub-category</Label>
                          <Select
                            options={subCategoryOptions}
                            name='subCategory'
                            placeholder='Sub-category'
                            onChange={e=>handleSelectChange(e, 'subCategory')}
                            value={selectedFields?.subCategory || ''}
                            isClearable
                          />
                        </Col> */}
                        <Col md="3">
                        <Label>Feedback Form</Label>
                        <Select
                          name="form"
                          options={feedbackFormOption}
                          type="text"
                          placeholder='Feedback Form'
                          onChange={(e)=>handleSelectChange(e, 'form')}
                          value={selectedFields.form || ''}
                          isClearable
                        />
                        </Col> </Row>
                        <Row className="mb-3 mt-3 text-align-center">
                        <Col md='12' style={{textAlign:'center'}}>
                          <div className="mb-3" style={{ paddingTop: "20px" }}>
                            <Button className="bg-soft-success" 
                              color={masterObject._id ? "warning" : "success"} 
                              style={{ marginRight: "10px" }} type="submit" 
                              disabled={disableButton || loading}
                            >
                              {masterObject._id ? 'Update' : 'Generate' }
                            </Button>

                            <Button color="danger" onClick={reset} disabled={disableButton || loading}>
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      </AvForm>
                    </TabPanel>
                    {/*//? Print QR Code */}
                    <TabPanel value={3}>
                    <AvForm onValidSubmit={handleSubmit3} ref={formRef}>
                      <Row>
                      <Col className="d-flex justify-content-end align-items-center">
                            <span className="text-primary fw-bold font-size-14 me-5">QR Generated <span className="badge bg-primary font-size-14 text-white">{generateCount}</span></span>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={3} style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:"24px"}}>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio1"
                            autoComplete="off"
                            checked={masterObject.printType === 1}
                            onClick={()=>setMasterObject({...masterObject, printType:1})}
                          />
                          <label className="btn btn-outline-dark" htmlFor="btnradio1">
                            Individual Print
                          </label>

                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio2"
                            autoComplete="off"
                            checked={masterObject.printType === 2}
                            onClick={()=>setMasterObject({...masterObject, printType:2})}
                          />
                          <label className="btn btn-outline-dark" htmlFor="btnradio2">
                            Range Print
                          </label>
                          </div>
                          </Col>
                      {masterObject.printType === 1 ? <Col md='3'>
                          <Label>QR Codes</Label>
                          <Select
                            options={qrOptions}
                            isMulti
                            name='qrCodes'
                            placeholder='Select QR Codes'
                            onChange={e=>handleSelectChange(e, 'qrCodes')}
                            value={selectedFields.qrCodes || ''}
                          />
                        </Col> : <>
                        <Col md='2'>
                          <Label>QR Codes From</Label>
                          <AvField
                            type='number'
                            name='qrFrom'
                            placeholder='From'
                            validate={{
                              pattern: {
                                value: '^[1-9]\\d*$',
                                errorMessage: 'Invalid QR Range',
                              },
                              maxLength: {
                                value: 4,
                                errorMessage: 'Invalid QR Range',
                              },
                            }}
                            onChange={e=>handleValueChange(e.target.value, 'qrFrom')}
                            value={masterObject.qrFrom || ''}
                          />
                        </Col>
                        <Col md='2'>
                          <Label>QR Codes To</Label>
                          <AvField
                            type='number'
                            name='qrTo'
                            placeholder='To'
                            validate={{
                              pattern: {
                                value: '^[1-9]\\d*$',
                                errorMessage: 'Invalid QR Range',
                              },
                              maxLength: {
                                value: 4,
                                errorMessage: 'Invalid QR Range',
                              },
                            }}
                            onChange={e=>handleValueChange(e.target.value, 'qrTo')}
                            value={masterObject.qrTo || ''}
                          />
                        </Col></>}
                        <Col md={2}>
                        <Label>Choose Template</Label>
                            <Select
                              options={templateOptions}
                              name='template'
                              placeholder='Template'
                              onChange={e=>handleSelectChange(e, 'template')}
                              value={selectedFields.template || ''}
                            />
                        </Col>
                        <Col md='3'>
                          <div className="" style={{ paddingTop: "27px" }}>
                            <Button className="bg-soft-warning" color="warning" style={{ marginRight: "10px" }} type="submit" disabled={disableButton || loading}>

                            {(disableButton || loading) ? (
                            <Spinner style={{ height: "15px", width: "15px" }} />
                          ) : "Print"}
                    
                            </Button>
                            <Button color="danger" onClick={reset} disabled={disableButton || loading}>
                              Reset
                            </Button>
                            {/* <OverlayTrigger placement="top" overlay={<Tooltip>{"Copy URL"}</Tooltip>}>
                        <Button
                          color="info"
                          onClick={()=>setRefresh(prev=>prev+1)}
                          style={{
                            
                            width: "60px",
                            marginLeft: "5px",
                          }}
                          disabled={loading}
                          outline
                        >
                          {loading ? (
                            <Spinner style={{ height: "15px", width: "15px" }} />
                          ) : (
                            <i class="fas fa-redo"></i>
                          )}
                        </Button>
                    </OverlayTrigger> */}
                          </div>
                        
                        </Col>
                        {printableQr[0]?.downloadLink[0] && 
                        <Col md='2'>
                          <div style={{ paddingTop: "35px " }}>
                          <a 
                            href={`${API_URL}${printableQr[0].downloadLink[0]}`} 
                            download 
                            target="_blank" 
                            rel="noopener noreferrer"
                            onClick={()=>updateDownloadStatus(printableQr[0]._id)}
                          >
                            <i style={{fontSize:'25px', marginRight:"10px"}} className="fas fa-file-pdf mr-2"></i>
                            {printableQr[0].name}.{printableQr[0].fileExtension}
                          </a></div>
                        </Col>}
                      
                      </Row>
                      </AvForm>
                    </TabPanel>
                  </TabContext>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    
                    <Row>
                      <Col md='12' className="d-flex justify-content-end">
                      <OverlayTrigger placement="top" overlay={<Tooltip>{"Copy URL"}</Tooltip>}>
                        <Button
                          color="success"
                          onClick={()=>setRefresh2(prev=>prev+1)}
                          style={{
                            
                            width: "140px",
                            marginLeft: "5px",
                          }}
                          disabled={loading}
                          outline
                        > {loading ? "Refreshing..." : "Refresh"}
                          {loading ? (
                            <Spinner style={{ height: "15px", width: "15px", marginLeft:'15px' }} />
                          ) : (
                            <i class="fas fa-redo" style={{marginLeft:'5px'}}></i>
                          )}
                        </Button>
                    </OverlayTrigger>
                      </Col>
                    </Row>
                    <MDBDataTable
                      id="AssignFeedbackId"
                      bordered
                      responsive
                      searching={true}
                      entries={20}
                      sortable={false}
                      disableRetreatAfterSorting={true}
                      data={data}
                    ></MDBDataTable>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <Modal
          show={popupView2}
          isOpen={popupView2}
          toggle={() => handleCloseModal2()}
          size="xl"
          centered={true}
          className="lead-modal"
        >
          <div className="modal-header">
            <div className="modal-title">
              <span style={{ marginBottom: "0px" }} className="modal-title ">
                <h4>Create Feedback Template</h4>
              </span>
            </div>
            <button
              style={{ marginBottom: "2px" }}
              type="button"
              onClick={()=>handleCloseModal2()}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
              <QRCodeTemplate handleModalClose={handleCloseModal2} setTemplate={handleSetNewTemplate} templateId={templateId}/>
            </div>
        </Modal>
        <Modal
          show={popupView}
          isOpen={popupView}
          toggle={() => setPopupView(!popupView)}
          size="lg"
          centered={true}
          className="lead-modal"
        >
          <div className="modal-header">
            <div className="modal-title">
              <h6 style={{ marginBottom: "0px" }} className="modal-title ">
                <h6>Feedback Details</h6>
              </h6>
            </div>
            <button
              style={{ marginBottom: "2px" }}
              type="button"
              onClick={handleCloseModal}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <div>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th style={{ width: "120px", textAlign: "left" }}>Type</th>
                    <th style={{ width: "320px", textAlign: "left" }}>Question</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ textAlign: "left" }}>Rating</td>
                    <td style={{ textAlign: "left" }}>How do you rate the overall experience?</td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: "left" }}>Emoji Rating</td>
                    <td style={{ textAlign: "left" }}>How likely do you rate our staff behaviour?</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>Emoji Rating</td>
                    <td style={{ textAlign: "left" }}>
                      How do you feel about the cleanliness of the branch/ATM?
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>Yes/No</td>
                    <td style={{ textAlign: "left" }}>
                      Would you recommend Bank of Maharashtra to your friends or relatives?
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal>
      </>
    )
  }

  export default AssignFeedback