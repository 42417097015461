import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import ReactApexChart from "react-apexcharts";
import MiniWidget from "./mini-widget";
import moment from "moment";
import { get } from "../../../helpers/api_helper";
import Breadcrumb from "../../../components/Common/Breadcrumb";

const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

function MediaPlatformInstagram() {
  // Initialize dates - default to last 30 days
  const [startDate, setStartDate] = useState(new Date(moment().subtract(30, 'days')));
  const [endDate, setEndDate] = useState(new Date());
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [allMetrics, setAllMetrics] = useState({});
  const [allSentimentData, setAllSentimentData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if(name === 'since') {
      setStartDate(new Date(value));
    } else if (name === 'until') {
      setEndDate(new Date(value));
    }
  }

  // Reports structure for MiniWidget
  const getReports = (metricsData) => {
  
    if (!metricsData) {
      return [];
    }
    
    return [
      {
        title: "Impressions",
        value: Number(metricsData?.views?.value || 0),
        prefix: "",
        suffix: "",
        badgeValue: `${Number(metricsData?.views?.weekChange || 0).toFixed(1)}%`,
        color: Number(metricsData?.views?.weekChange || 0) >= 0 ? "success" : "danger",
        desc: "since last week",
        icon: Number(metricsData?.views?.weekChange || 0) >= 0 ? "mdi mdi-arrow-up-bold" : "mdi mdi-arrow-down-bold",
        icon1: "mdi mdi-eye",
        decimal: 0
      },
      {
        title: "Profile Views",
        value: Number(metricsData?.profileViews?.value || 0),
        prefix: "",
        suffix: "",
        badgeValue: `${Number(metricsData?.profileViews?.weekChange || 0).toFixed(1)}%`,
        color: Number(metricsData?.profileViews?.weekChange || 0) >= 0 ? "success" : "danger",
        desc: "since last week",
        icon: Number(metricsData?.profileViews?.weekChange || 0) >= 0 ? "mdi mdi-arrow-up-bold" : "mdi mdi-arrow-down-bold",
        icon1: "mdi mdi-account-circle",
        decimal: 0
      },
      {
        title: "Reach",
        value: Number(metricsData?.reach?.value || 0),
        prefix: "",
        suffix: "",
        badgeValue: `${Number(metricsData?.reach?.weekChange || 0).toFixed(1)}%`,
        color: Number(metricsData?.reach?.weekChange || 0) >= 0 ? "success" : "danger",
        desc: "since last week",
        icon: Number(metricsData?.reach?.weekChange || 0) >= 0 ? "mdi mdi-arrow-up-bold" : "mdi mdi-arrow-down-bold",
        icon1: "mdi mdi-chart-line",
        decimal: 0
      },
      {
        title: "New Followers",
        value: Number(metricsData?.followers?.newFollowers || 0),
        prefix: "+",
        suffix: "",
        badgeValue: `${Number(metricsData?.followers?.weekChange || 0).toFixed(1)}%`,
        color: Number(metricsData?.followers?.weekChange || 0) >= 0 ? "success" : "danger",
        desc: "since last week",
        icon: Number(metricsData?.followers?.weekChange || 0) >= 0 ? "mdi mdi-arrow-up-bold" : "mdi mdi-arrow-down-bold",
        icon1: "mdi mdi-account-plus",
        decimal: 0
      },
      {
        title: "Unfollowers",
        value: Number(metricsData?.unfollowers?.unfollowers || 0),
        prefix: "-",
        suffix: "",
        badgeValue: `${Number(metricsData?.unfollowers?.weekChange || 0).toFixed(1)}%`,
        color: Number(metricsData?.unfollowers?.weekChange || 0) >= 0 ? "success" : "danger",
        desc: "since last week",
        icon: Number(metricsData?.unfollowers?.weekChange || 0) >= 0 ? "mdi mdi-arrow-up-bold" : "mdi mdi-arrow-down-bold",
        icon1: "mdi mdi-account-minus",
        decimal: 0
      },
      {
        title: "Engagements",
        value: Number(metricsData?.engagements?.total || 0),
        prefix: "",
        suffix: "",
        badgeValue: `${Number(metricsData?.engagements?.weekChange || 0).toFixed(1)}%`,
        color: Number(metricsData?.engagements?.weekChange || 0) >= 0 ? "success" : "danger",
        desc: "since last week",
        icon: Number(metricsData?.engagements?.weekChange || 0) >= 0 ? "mdi mdi-arrow-up-bold" : "mdi mdi-arrow-down-bold",
        icon1: "mdi mdi-heart",
        decimal: 0
      },
      {
        title: "Engaged Users",
        value: Number(metricsData?.engagedUsers?.value || 0),
        prefix: "",
        suffix: "",
        badgeValue: `${Number(metricsData?.engagedUsers?.weekChange || 0).toFixed(1)}%`,
        color: Number(metricsData?.engagedUsers?.weekChange || 0) >= 0 ? "success" : "danger",
        desc: "since last week",
        icon: Number(metricsData?.engagedUsers?.weekChange || 0) >= 0 ? "mdi mdi-arrow-up-bold" : "mdi mdi-arrow-down-bold",
        icon1: "mdi mdi-account-group",
        decimal: 0
      }
    ];
  };

  // Sentiment chart options
  const getSentimentChartOptions = () => ({
    chart: {
      type: "bar",
      height: 600,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "right",
        },
        distributed: true,
        barHeight: '40%',
        columnWidth: '90%',
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(0);
      },
      style: {
        fontSize: "12px",
        colors: ["#fff"]
      }
    },
    colors: ["#34c38f", "#f46a6a", "#FFCD69"],
    xaxis: {
      categories: ["Positive", "Negative", "Neutral"],
      labels: {
        formatter: function (val) {
          return Math.abs(Math.round(val));
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '13px',
          fontWeight: 500
        }
      }
    },
    grid: {
      padding: {
        top: -10,
        right: 0,
        bottom: -10,
        left: 0
      }
    },
    tooltip: {
      enabled: true,
      shared: false,
      y: {
        formatter: function (val) {
          return Math.abs(Math.round(val));
        }
      }
    }
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);
      const fromDate = moment(startDate).format("YYYY-MM-DD");
      const toDate = moment(endDate).format("YYYY-MM-DD");
            
      // Fetch both metrics and sentiment data in parallel
      const [metricsResponse, sentimentResponse] = await Promise.all([
        get(`${API_URL}report/v2/instagram/insights?fromDate=${fromDate}&toDate=${toDate}`),
        get(`${API_URL}report/v2/sentiment/instagram?fromDate=${fromDate}&toDate=${toDate}`)
      ]);
      
      if (metricsResponse?.status === "Success" && Array.isArray(metricsResponse.data)) {
        setAccounts(metricsResponse.data);
        
        // Store metrics for all accounts
        const metricsMap = {};
        metricsResponse.data.forEach(account => {
          metricsMap[account.pageId] = account.metrics;
        });
        setAllMetrics(metricsMap);

        // Set initial selected account if none is selected
        if (!selectedAccount && metricsResponse.data.length > 0) {
          setSelectedAccount(metricsResponse.data[0].pageId);
        }
      }

      if (sentimentResponse?.success && sentimentResponse?.data?.counts) {
        setAllSentimentData(sentimentResponse.data.counts);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError('Failed to load data');
    } finally {
      setLoading(false);
    }
  };

  // Only fetch data when dates change
  useEffect(() => {
    if (startDate && endDate) {
      fetchData();
    }
  }, [startDate, endDate]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title='Home' breadcrumbItem='Instagram Report' />
          <div className="d-flex justify-content-between align-items-center mb-4">
            {/* Left side - Date filters */}
            <div className="d-flex align-items-center gap-4">
              <FormGroup className="mb-0">
                <Label for="sinceDate">Since</Label>
                <Input
                  type="date"
                  name="since"
                  id="sinceDate"
                  style={{ width: '150px' }}
                  onChange={handleDateChange}
                  value={moment(startDate).format("YYYY-MM-DD")}
                />
              </FormGroup>
              
              <FormGroup className="mb-0">
                <Label for="untilDate">Until</Label>
                <Input
                  type="date"
                  name="until"
                  id="untilDate"
                  style={{ width: '150px' }}
                  onChange={handleDateChange}
                  value={moment(endDate).format("YYYY-MM-DD")}
                />
              </FormGroup>
            </div>
            
            {/* Right side - Account selector */}
            <div className="d-flex align-items-center">
              <span className="me-2">Select Account:</span>
              <UncontrolledDropdown>
                <DropdownToggle tag="button" className="btn btn-primary">
                  {accounts.find(acc => acc.pageId === selectedAccount)?.pageName || 'Select Account'}
                  <i className="mdi mdi-chevron-down ms-1"></i>
                </DropdownToggle>
                <DropdownMenu>
                  {accounts.map((account) => (
                    <DropdownItem 
                      key={account.pageId}
                      onClick={() => setSelectedAccount(account.pageId)}
                      active={selectedAccount === account.pageId}
                    >
                      {account.pageName}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>

          <Row>
            {loading ? (
              <Col>
                <div className="text-center p-4">Loading metrics...</div>
              </Col>
            ) : error ? (
              <Col>
                <div className="alert alert-danger">{error}</div>
              </Col>
            ) : allMetrics[selectedAccount] ? (
              <MiniWidget reports={getReports(allMetrics[selectedAccount])} />
            ) : (
              <Col>
                <div className="alert alert-info">No data available</div>
              </Col>
            )}
          </Row>

          <Row className="mt-4">
          <Col md="8">
            <Card>
              <CardBody>
                <h5 className="mb-4">Sentiment Breakup</h5>
                {allSentimentData ? (
                  <ReactApexChart
                    options={getSentimentChartOptions()}
                    series={[{
                      name: 'Sentiment Counts',
                      data: [
                        Number(allSentimentData.positive || 0),
                        Number(allSentimentData.negative || 0),
                        Number(allSentimentData.neutral || 0)
                      ]
                    }]}
                    type="bar"
                    height={350}
                  />
                ) : (
                  <div className="text-center">Loading sentiment data....</div>
                )}
              </CardBody>
            </Card>
          </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default MediaPlatformInstagram;