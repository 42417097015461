import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, CardHeader } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import campaignService from "../../services/campaignService";
import moment from "moment";
import toastr from "toastr";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";

const Campaign = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    shortCode: "",
    budget: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});

  const fetchCampaigns = async () => {
    try {
      setLoading(true);
      const response = await campaignService.getCampaigns();
      setCampaigns(response.campaigns || []);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      toastr.error(error.response?.data?.message || "Error fetching campaigns");
      setCampaigns([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    
    // Validate on change if field was touched
    if (touched[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: validateField(name, value)
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate all fields
    const newErrors = {};
    Object.keys(formData).forEach(key => {
      const error = validateField(key, formData[key]);
      if (error) {
        newErrors[key] = error;
      }
    });
  
    // Mark all fields as touched
    const newTouched = {};
    Object.keys(formData).forEach(key => {
      newTouched[key] = true;
    });
  
    setTouched(newTouched);
    setErrors(newErrors);
  
    // Only proceed if no errors
    if (Object.keys(newErrors).length === 0) {
      try {
        setLoading(true);

        if (isEditing) {
          await campaignService.updateCampaign(selectedCampaign._id, formData);
          toastr.success("Campaign updated successfully");
        } else {
          await campaignService.createCampaign(formData);
          toastr.success("Campaign created successfully");
        }
        resetForm();
        fetchCampaigns();
      } catch (error) {
        toastr.error(error.response?.data?.message || "Operation failed");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleView = (campaign) => {
    setSelectedCampaign(campaign);
    setModalVisible(true);
  };

  const handleEdit = (campaign) => {
    setFormData({
      name: campaign.name,
      shortCode: campaign.shortCode,
      budget: campaign.budget,
    });
    setSelectedCampaign(campaign);
    setIsEditing(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure you want to delete this campaign?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        campaignService.deleteCampaign(id)
          .then(res => {
            fetchCampaigns()
            toastr.success(res.message || "Campaign deleted successfully");
          })
          .catch(err => {
            toastr.error(err.response?.data?.message || "Error deleting campaign");
          })
      }
    })
  }

  const validateField = (name, value) => {
    switch (name) {
      case 'name':
        return !value ? 'Campaign name is required' : '';
      case 'shortCode':
        return !value ? 'Short code is required' : '';
      case 'budget':
        return !value ? 'Budget is required' : 
               isNaN(value) ? 'Budget must be a number' : '';
      default:
        return '';
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setTouched(prev => ({
      ...prev,
      [name]: true
    }));
    setErrors(prev => ({
      ...prev,
      [name]: validateField(name, value)
    }));
  };

  const resetForm = () => {
    setFormData({
      name: "",
      shortCode: "",
      budget: "",
    });
    setIsEditing(false);
    setSelectedCampaign(null);
    setTouched({});
    setErrors({});
    setLoading(false);
  };

  const columns = [
    {
      label: "#",
      field: "index",
      sort: "asc",
      width: 50,
    },
    {
      label: "Date",
      field: "date",
      sort: "asc",
      width: 100,
    },
    {
      label: "Campaign",
      field: "name",
      sort: "asc",
      width: 150,
    },
    {
      label: "Short Code",
      field: "shortCode",
      sort: "asc",
      width: 100,
    },
    {
      label: "Clicks",
      field: "clicks",
      sort: "asc",
      width: 70,
    },
    {
      label: "Leads",
      field: "leads",
      sort: "asc",
      width: 70,
    },
    {
      label: "Budget",
      field: "budget",
      sort: "asc",
      width: 100,
    },
    {
      label: "Added by",
      field: "addedBy",
      sort: "asc",
      width: 100,
    },
    {
      label: "Actions",
      field: "actions",
      sort: false,
      width: 100,
    },
  ];

  const tableData = React.useMemo(() => {
    const rows = campaigns.map((campaign, index) => ({
      index: index + 1,
      date: campaign?.createdAt ? moment(campaign.createdAt).format("DD-MM-YYYY") : '',
      name: campaign?.name || '',
      shortCode: campaign?.shortCode || '',
      clicks: campaign?.clicks || 0,
      leads: campaign?.leads || 0,
      budget: campaign?.budget || 0,
      addedBy: campaign?.addedBy ? `${campaign.addedBy.firstName || ''} ${campaign.addedBy.lastName || ''}` : '',
      actions: (
        <div className="d-flex justify-content-around">
          <Button
            color="link"
            className="p-0 me-2"
            onClick={() => handleView(campaign)}
          >
            <FontAwesomeIcon icon={faEye} color='#3D3D3D' />
          </Button>
          <Button
            color="link"
            className="p-0 me-2"
            onClick={() => handleEdit(campaign)}
          >
            <FontAwesomeIcon icon={faPenToSquare} color='#3D3D3D' />
          </Button>
          <Button
            color="link"
            className="p-0"
            onClick={() => handleDelete(campaign._id)}
          >
            <FontAwesomeIcon icon={faTrash} color='#3D3D3D' />
          </Button>
        </div>
      ),
    }));

    return {
      columns,
      rows: rows || []
    };
  }, [campaigns]); // eslint-disable-line

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Home" breadcrumbItem="Manage Campaign" />
        
        {/* Add/Edit Form */}
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h4 className="card-title mb-3 fw-bold">
                  {isEditing ? "Edit Campaign" : "Add Campaign"}
                </h4>
              </CardHeader>
              <CardBody>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Campaign Name</Label><span className="text-danger"> *</span>
                        <input
                          name="name"
                          className={`form-control ${errors.name && touched.name ? 'is-invalid' : ''}`}
                          type="text"
                          value={formData.name}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          placeholder="Enter Campaign Name"
                          // required
                        />
                        {errors.name && touched.name && (
                          <div className="invalid-feedback">
                            {errors.name}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Short Code</Label><span className="text-danger"> *</span>
                        <input
                          name="shortCode"
                          className={`form-control ${errors.shortCode && touched.shortCode ? 'is-invalid' : ''}`}
                          type="text"
                          value={formData.shortCode}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          placeholder="Enter Short Code"
                          // required
                        />
                        {errors.shortCode && touched.shortCode && (
                          <div className="invalid-feedback">
                            {errors.shortCode}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Budget</Label><span className="text-danger"> *</span>
                        <input
                          name="budget"
                          className={`form-control ${errors.budget && touched.budget ? 'is-invalid' : ''}`}
                          type="number"
                          value={formData.budget}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          placeholder="Enter Budget"
                          // required
                        />
                        {errors.budget && touched.budget && (
                          <div className="invalid-feedback">
                            {errors.budget}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3" style={{ paddingTop: "28px" }}>
                        <Button color="primary" type="submit" className="me-2" disabled={loading}>
                          {loading ? "Processing..." : (isEditing ? "Update" : "Submit")}
                        </Button>
                        <Button color="danger" onClick={resetForm}>
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Campaign List */}
        <Row>
          <Col className="col-12">
            <Card>
              <CardHeader>
                <h4 className="card-title mb-3 fw-bold">Campaign List</h4>       
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div className="text-center">Loading...</div>
                ) : (
                  <MDBDataTable
                    responsive
                    bordered
                    data={tableData}
                    searching={true}
                    paging={true}
                    info={true}
                    noRecordsFoundLabel="No campaigns found"
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* View Modal */}
        <Modal
          isOpen={modalVisible}
          toggle={() => setModalVisible(!modalVisible)}
          className="modal-dialog-centered"
          size="lg"
        >
          <ModalHeader toggle={() => setModalVisible(false)} className="bg-light text-white">
            <h5 className="mb-0">Campaign Details - {selectedCampaign?.name}</h5>
          </ModalHeader>
          <ModalBody>
            {selectedCampaign && (
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="fw-bold text-primary">Campaign Name :</Label>
                    <p>{selectedCampaign.name}</p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="fw-bold text-primary">Short Code :</Label>
                    <p>{selectedCampaign.shortCode}</p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="fw-bold text-primary">Budget :</Label>
                    <p>{selectedCampaign.budget || 0}</p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="fw-bold text-primary">Clicks :</Label>
                    <p>{selectedCampaign.clicks || 0}</p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="fw-bold text-primary">Leads :</Label>
                    <p>{selectedCampaign.leads || 0}</p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="fw-bold text-primary">Added By :</Label>
                    <p>{`${selectedCampaign.addedBy?.firstName || ''} ${selectedCampaign.addedBy?.lastName || ''}`}</p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="fw-bold text-primary">Created At :</Label>
                    <p>{moment(selectedCampaign.createdAt).format("DD-MM-YYYY")}</p>
                  </div>
                </Col>
              </Row>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModalVisible(false)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default Campaign;