import PropTypes from "prop-types"
import React from "react"
import { Col, Card, CardBody } from "reactstrap"
import CountUp from "react-countup"

const MiniWidget = props => {
  // Add check for empty reports
  if (!props.reports || props.reports.length === 0) {
    return <div>No data available</div>;
  }

  return (
    <React.Fragment>
      {props.reports.map((report, key) => {
        return (
          <Col md={6} xl={3} key={key}>
            <Card>
              <CardBody>
                <div className="float-end mt-2">
                  <i
                    style={{ marginTop: "2px", fontSize: "30px" }}
                    className={report.icon1}
                  ></i>
                </div>
                <div>
                  <h4 className="mb-1 mt-1">
                    <span>
                      <CountUp
                        start={0}
                        end={parseFloat(report.value) || 0}
                        duration={2}
                        separator=","
                        prefix={report.prefix || ''}
                        suffix={report.suffix || ''}
                        decimals={report.decimal || 0}
                        decimal="."
                        useEasing={true}
                      />
                    </span>
                  </h4>
                  <p className="text-muted mb-0">{report.title}</p>
                </div>
                <p className="text-muted mt-3 mb-0">
                  <span className={`text-${report.color} me-1`}>
                    <i className={`${report.icon} me-1`}></i>
                    {report.badgeValue}
                  </span>{" "}
                  {report.desc}
                </p>
              </CardBody>
            </Card>
          </Col>
        );
      })}
    </React.Fragment>
  )
}

MiniWidget.propTypes = {
  reports: PropTypes.array,
}

export default MiniWidget;