import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import leadService from '../../../services/leadService';
import { MDBDataTable } from 'mdbreact';
import moment from 'moment';
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
} from 'reactstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toastr from "toastr"
import { faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import Breadcrumbs from "../../../components/Common/Breadcrumb.js"
import Pagination from '../../Leads/Pagination.jsx'
import Swal from 'sweetalert2';
import './TrashedLeads.scss';



const TrashedLeads = () => {
  const API_URL = process.env.REACT_APP_APIURL;

  const { id } = useParams();
  const [leads, setLeads] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalLeads: 0
  });

  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [leadTypes, setLeadTypes] = useState([]);
  const [sources, setSources] = useState([]);
  const [assignedTo, setAssignedTo] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  const [tableLoading, setTableLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Constants and Options
  const levelOptions = [
    { value: 'P', label: 'P' },
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' }
  ];

  const assignStatusOptions = [
    { value: 'assigned', label: 'Assigned'},
    { value: 'not_assigned', label: 'Not Assigned'}
  ];

  const [filters, setFilters] = useState({
      from: '',
      to: '',
      level: null,
      department: null,
      source: null,
      staff: null,
      campaign: null,
      assignStatus: null,
      page: 1,
      limit: 10
    });

  const initialFormState = {
    mobileNo: '',
    name: '',
    email: '',
    leadSource: '',
    department: '',
    designation: '',
    leadType: '',
    level: '',
    priority: '',
    address: '',
    followUpDate: '',
    followUpTime: '',
    comments: '',
    campaign: '',
    assignTo: ''
  };
  const [formData, setFormData] = useState(initialFormState);
  const [tableData, setTableData] = useState({
    columns: [
      {
        label: '#',
        field: 'index',
        sort: 'asc',
        width: 50
      },
      {
        label: 'Date',
        field: 'date',
        sort: 'asc',
        width: 150
      },
      {
        label: 'ID',
        field: 'uniqueId',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Mobile',
        field: 'mobile',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Department',
        field: 'department',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Level',
        field: 'level',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Source',
        field: 'source',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Assign To',
        field: 'assignTo',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Campaign',
        field: 'campaign',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Actions',
        field: 'actions',
        sort: false,
        width: 100
      }
    ],
    rows: []
  });

  useEffect(() => {
      const fetchDropdownData = async () => {
        setIsLoading(true);
        setFetchError(null);
        try {
          const [deptResponse, desigResponse, typesResponse, assignResponse, sourceResponse] = await Promise.all([
            leadService.getDepartments(),
            leadService.getDesignations(),
            leadService.getLeadTypes(),
            leadService.getAssignedTo(),
            leadService.getSource()
          ]);
  
         // Transform responses to react-select format
         const formatOptions = (data, labelKey = 'name') => {
          if (!Array.isArray(data)) return [];
          return data.map(item => ({
            value: item._id,
            label: item[labelKey],
            ...item
          }));
        };
  
        setDepartments(formatOptions(deptResponse.data || deptResponse));
        setDesignations(formatOptions(desigResponse.data || desigResponse));
        setLeadTypes(formatOptions(typesResponse.data || typesResponse));
        setAssignedTo(formatOptions(assignResponse.data || assignResponse, 'label'));
        setSources(formatOptions(sourceResponse.data || sourceResponse))
  
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
        setFetchError('Failed to load form data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchDropdownData();
  }, []);

  function handleRestore(leadId) {
    return Swal.fire({
      title: "Are you sure you want to restore this lead?",
      // text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, restore it!",
    }).then(result => {
      if (result.isConfirmed) {
        leadService.restoreLead(leadId)
          .then(res => {
            fetchLeads(filters)
            toastr.success(res.message || "Lead restored successfully");
          })
          .catch(err => {
            toastr.error(err.response?.data?.message || "Failed to restore lead");
          })
      }
    })
  }

  const fetchLead = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_URL}leads/${id}`);
      const data = await response.json();
      setFormData(data);
    } catch (error) {
      console.error('Error fetching lead:', error);
    } finally {
      setLoading(false);
    }
  };


  const fetchLeads = useCallback(async (filterParams = {}) => {
    try {
      setTableLoading(true);
      setError(null);
  
      const apiFilters = {
        ...filters,
        ...filterParams,
        level: filterParams.level?.value || filters.level?.value || '',
        department: filterParams.department?.value || filters.department?.value || '',
        source: filterParams.source?.value || filters.source?.value || '',
        staff: filterParams.staff?.value || filters.staff?.value || '',
        campaign: filterParams.campaign?.value || filters.campaign?.value || '',
        assignStatus: filterParams.assignStatus?.value || filters.assignStatus?.value || '',
      };
  
      const response = await leadService.getTrashLeads(apiFilters);
      
      // Defensive check for response structure
      if (!response) {
        throw new Error('No response received');
      }
  
      const leads = response.leads || [];
      const currentPage = response.currentPage || 1;
      const totalPages = response.totalPages || 1;
      const totalLeads = response.totalLeads || 0;
      const limit = apiFilters.limit || 10;
  
      // Calculate starting index for current page
      const startIndex = (currentPage - 1) * limit;
  
      const formattedRows = leads.map((lead, index) => ({
        index: startIndex + index + 1,
        date: moment(lead.createdAt).format('DD/MM/YYYY'),
        uniqueId: lead.uniqueId,
        name: lead.name,
        mobile: lead.mobileNo || 'N/A',
        department: lead.department?.name || 'N/A',
        level: lead.level || 'N/A',
        source: lead.leadSource?.displayName || 'N/A',
        assignTo: lead.assignTo 
          ? `${lead.assignTo.firstName || ''} ${lead.assignTo.lastName || ''}`.trim() 
          : 'Not Assigned',
        campaign: lead.campaign?.name || 'N/A',
        actions: (
          <div className="d-flex justify-content-center align-items-center">
            <button
              onClick={() => handleRestore(lead._id)}
              className="btn btn-link text-success p-1"
              title="Restore Lead"
            >
              <FontAwesomeIcon icon={faRotateLeft} color='#3D3D3D' />
            </button>
          </div>
        )
      }));
  
      setTableData(prev => ({
        ...prev,
        rows: formattedRows
      }));
  
      setPagination({
        currentPage: currentPage,
        totalPages: totalPages,
        totalLeads: totalLeads
      });
  
      setFilters(prev => ({
        ...prev,
        page: currentPage,
        limit: limit
      }));
  
      setLeads(leads);
  
    } catch (error) {
      console.error('Error fetching leads:', error);
      setError('Failed to fetch leads data');
      toastr.error('Error loading leads');
    } finally {
      setTableLoading(false);
    }
  }, []);

  useEffect(() => {
    if (assignedTo.length > 0 && !id) {
      fetchLeads(filters);
    }
  }, [assignedTo, id, fetchLeads]); // eslint-disable-line

  useEffect(() => {
      if(id) {
        fetchLead();
      }
    }, [id]);

  const handleFilterChange = async (e) => {
    const { name, value } = e.target;
    const updatedFilters = {
      ...filters,
      [name]: value,
      page: 1
    };
    
    setFilters(updatedFilters);
    await fetchLeads(updatedFilters);
  };

  // Add this useEffect to fetch initial data
    // useEffect(() => {
    //   if (assignedTo.length > 0) {
    //     fetchLeads();
    //   }
    // }, [assignedTo, fetchLeads]);

    const handleFilterSelectChange = async (name, option) => {
      const updatedFilters = {
        ...filters,
        [name]: option,
        page: 1
      };
      
      setFilters(updatedFilters);
      await fetchLeads(updatedFilters);
    };

    const handleExport = async (e) => {
      e.preventDefault();
      try {
        // Show loading indicator
        setIsLoading(true);
        setError(null);

        // Format filters to extract just the values from select options
        const formattedFilters = {
          ...filters,
          department: filters.department?.value || '',
          designation: filters.designation?.value || '',
          leadType: filters.leadType?.value || '',
          assignTo: filters.assignTo?.value || '',
          source: filters.source?.value || '',
          staff: filters.staff?.value || '',
          level: filters.level?.value || '',
          campaign: filters.campaign?.value || '',
          assignStatus: filters.assignStatus?.value || '',
        };
    
        // Implement chunk-based export for large datasets
        const exportLeadsInChunks = async () => {
          const chunkSize = 1000; // Number of records to fetch per chunk
          let currentPage = 1;
          let allExportData = [];
    
          while (true) {
            // Fetch leads in chunks
            const chunkData = await leadService.getTrashLeads({ 
              ...formattedFilters, 
              page: currentPage,
              limit: chunkSize,
              populate: 'true'
            });
    
            // Transform the chunk of leads
            const chunkExportData = chunkData.leads.map(lead => ({
              Date: moment(lead.createdAt).format('DD/MM/YYYY'),
              ID: lead.uniqueId,
              Name: lead.name,
              Mobile: lead.mobileNo || 'N/A',
              Email: lead.email || 'N/A',
              Department: lead.department?.name || 'N/A',
              // Designation: lead.designation?.name || 'N/A',
              Level: lead.level || 'N/A',
              Priority: lead.priority || 'N/A',
              Source: lead.leadSource?.displayName || 'N/A',
              Campaign: lead.campaign?.name || 'N/A',
              'Lead Type': lead.leadType?.name || 'N/A',
              'Assigned To': lead.assignTo ? 
              `${lead.assignTo.firstName || ''} ${lead.assignTo.lastName || ''}`.trim() : 
              'Not Assigned',
              'Follow Up Date': lead.followUpDate || 'N/A',
              'Follow Up Time': lead.followUpTime || 'N/A',
              Address: lead.address || 'N/A',
              Comments: lead.comments || 'N/A',
            }));
    
            // Append chunk to total export data
            allExportData = [...allExportData, ...chunkExportData];
    
            // Break if no more leads or less than chunk size
            if (chunkData.leads.length < chunkSize) {
              break;
            }
    
            // Increment page for next chunk
            currentPage++;
          }
    
          // Create worksheet
          const worksheet = XLSX.utils.json_to_sheet(allExportData);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Leads");
          
          // Export the file
          XLSX.writeFile(workbook, `trashed_leads_export_${new Date().toISOString().split('T')[0]}.xlsx`);
        };
    
        // Execute chunk-based export
        await exportLeadsInChunks();
    
      } catch (error) {
        console.error('Error exporting leads:', error);
        setError('Failed to export leads');
      } finally {
        // Hide loading indicator
        setIsLoading(false);
      }
    };

    const handleFilterReset = async () => {
      const initialFilters = {
        from: '',
        to: '',
        level: null,
        department: null,
        source: null,
        staff: null,
        campaign: null,
        assignStatus: null,
        page: 1,
        limit: 10
      };
      
      setFilters(initialFilters);
      await fetchLeads(initialFilters);
    };

    const renderFilters = () => (
      <Form className="mb-4">
        <Row className="g-3">
          <Col md={12} className="mb-3">
            <Row className="g-3">
              <Col md={2}>
                <FormGroup>
                  <Label className="form-label">From</Label>
                  <Input
                    type="date"
                    name="from"
                    value={filters.from}
                    onChange={handleFilterChange}
                    className="select-sm"
                  />
                </FormGroup>
              </Col>
    
              <Col md={2}>
                <FormGroup>
                  <Label className="form-label">To</Label>
                  <Input
                    type="date"
                    name="to"
                    value={filters.to}
                    onChange={handleFilterChange}
                    className="select-sm"
                  />
                </FormGroup>
              </Col>
    
              <Col md={2}>
                <FormGroup>
                  <Label className="form-label">Level</Label>
                  <Select
                    name="level"
                    value={filters.level}
                    options={levelOptions}
                    onChange={(option) => handleFilterSelectChange('level', option)}
                    isClearable
                    placeholder="Select..."
                    className="select-sm"
                  />
                </FormGroup>
              </Col>
    
              <Col md={2}>
                <FormGroup>
                  <Label className="form-label">Department</Label>
                  <Select
                    name="department"
                    value={filters.department}
                    options={departments}
                    onChange={(option) => handleFilterSelectChange('department', option)}
                    isClearable
                    isLoading={isLoading}
                    placeholder="Select..."
                    className="select-sm"
                  />
                </FormGroup>
              </Col>
    
              <Col md={2}>
                <FormGroup>
                  <Label className="form-label">Source</Label>
                  <Select
                    name="source"
                    value={filters.source}
                    options={sources}
                    onChange={(option) => handleFilterSelectChange('source', option)}
                    isClearable
                    placeholder="Select..."
                    className="select-sm"
                  />
                </FormGroup>
              </Col>
    
              <Col md={2}>
                <FormGroup>
                  <Label className="form-label">Staff</Label>
                  <Select
                    name="staff"
                    value={filters.staff}
                    options={assignedTo}
                    onChange={(option) => handleFilterSelectChange('staff', option)}
                    isClearable
                    isLoading={isLoading}
                    placeholder="Select..."
                    className="select-sm"
                  />
                </FormGroup>
              </Col>
              
              <Col md={2}>
                <FormGroup>
                  <Label className="form-label">Assign Status</Label>
                  <Select
                    name="assignStatus"
                    value={filters.assignStatus}
                    options={assignStatusOptions}
                    onChange={(option) => handleFilterSelectChange('assignStatus', option)}
                    isClearable
                    placeholder="Select..."
                    className="select-sm"
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md={12}>
            <div className="d-flex gap-2 mb-3">
              <Button
                color="danger"
                size="btn-sm"
                onClick={handleFilterReset}
              >
                Reset
              </Button>
              <Button
                color="warning"
                size="btn-sm"
                onClick={handleExport}
                disabled={isLoading}
              >
                {isLoading ? "Exporting..." : "Export"}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    );

    return (
    <div className="page-content">
      <Container fluid className="py-2">
        <Breadcrumbs title="Home" breadcrumbItem="Trashed Leads" />
        {/* Leads List */}
        <Card>
          <CardBody>
          {renderFilters()}
          {tableLoading ? (
            <div className="text-center py-3">Loading...</div>
          ) : (
            <>
              <MDBDataTable
                data={tableData}
                paging={false}
                striped
                bordered
                hover
                responsive
              />
              
              <Pagination
                currentPage={pagination.currentPage}
                totalPages={pagination.totalPages}
                totalLeads={pagination.totalLeads}
                onPageChange={(page) => {
                  const updatedFilters = {
                    ...filters,
                    page: page
                  };
                  setFilters(updatedFilters);
                  fetchLeads(updatedFilters);
                }}
                onEntriesChange={(newLimit) => {
                  const updatedFilters = {
                    ...filters,
                    limit: newLimit,
                    page: 1 // Reset to first page when changing entries per page
                  };
                  setFilters(updatedFilters);
                  fetchLeads(updatedFilters);
                }}
                entriesPerPage={filters.limit}
              />
            </>
          )}
        </CardBody>
        </Card>
      </Container>
    </div>
    );
};

export default TrashedLeads;