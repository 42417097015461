const API_URL = process.env.REACT_APP_APIURL + 'followups'
const API_URL_USER = process.env.REACT_APP_APIURL

export const FollowupService = {
    getDepartments: async () => {
        try {
          const response = await fetch(`${API_URL_USER}options/lead-departments`);
          if (!response.ok) {
            console.error(`HTTP error! status: ${response.status}`);
          }
          return await response.json();
        } catch (error) {
          console.error('Error fetching departments:', error);
          throw error;
        }
      },
    
      getDesignations: async () => {
        try {
          const response = await fetch(`${API_URL_USER}options/lead-designations`);
          if (!response.ok) {
            console.error(`HTTP error! status: ${response.status}`);
          }
          return await response.json();
        } catch (error) {
          console.error('Error fetching designations:', error);
          throw error;
        }
      },
    
      // getLeadTypes: async () => {
      //   try {
      //     const response = await fetch(`${API_URL_USER}options/lead-types`);
      //     if (!response.ok) {
      //       console.error(`HTTP error! status: ${response.status}`);
      //     }
      //     return await response.json();
      //   } catch (error) {
      //     console.error('Error fetching lead types:', error);
      //     throw error;
      //   }
      // },
    
      getAssignedTo: async () => {
        try {
          const response = await fetch(`${API_URL_USER}options/assign-to`);
          if (!response.ok) {
            console.error(`HTTP error! status: ${response.status}`);
          }
          return await response.json();
        } catch (error) {
          console.error('Error fetching Staff:', error);
          throw error;
        }
      },

      getSource: async () => {
        try {
          const response = await fetch(`${API_URL_USER}options/source`);
          if (!response.ok) {
            console.error(`HTTP error! status: ${response.status}`);
          }
          return await response.json();
        } catch (error) {
          console.error('Error fetching Source:', error);
          throw error;
        }
      },

      getFollowUps: async (filters = {}) => {
        try {
          const queryParams = new URLSearchParams();
          
          // Add all filters to query params
          Object.entries(filters).forEach(([key, value]) => {
            if (value) queryParams.append(key, value);
          });
          
          const response = await fetch(`${API_URL}?${queryParams}`);
          
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          
          const data = await response.json();
          
          if (!data.success) {
            throw new Error(data.message || 'Failed to fetch followups');
          }
          
          return data;
        } catch (error) {
          console.error('Error fetching Followups:', error);
          throw error;
        }
      },

      updateAssignment: async (leadId, staffMemberId) => {
        try {
          const response = await fetch(`${API_URL}/assign/${leadId}`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ assignedTo: staffMemberId })
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to update assignment');
          }
    
          const data = await response.json();
          return data;
        } catch (error) {
          console.error('Error in updateAssignment:', error);
          throw error;
        }
      },
    
      addFollowupHistory: async (leadId, historyData) => {
        try {
          const response = await fetch(`${API_URL}/history/${leadId}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(historyData)
          });
    
          if (!response.ok) {
            throw new Error('Failed to add followup history');
          }
    
          return await response.json();
        } catch (error) {
          throw error;
        }
      },

      getFollowupHistory: async (leadId) => {
        try {
          const response = await fetch(`${API_URL}/history/${leadId}`);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return await response.json();
        } catch (error) {
          console.error('Error fetching followup details:', error);
          throw error;
        }
      },
    
      deleteFollowup: async (id) => {
        return fetch(`${API_URL}/${id}`, {
          method: 'DELETE'
        });
      }
}

export default FollowupService;