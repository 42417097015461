/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from "react"
import { Tooltip } from "@mui/material"
import { get, post } from "../../../helpers/api_helper"
import Select from "react-select"
import * as mainConfig from "../../../pages/Whatsapp/config"
import toastr from "toastr"
import { useDispatch } from "react-redux"
import { Col, Label, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { refreshPageAction } from "../../../store/actions"
import { API_URL } from "../../../config"
const Footer = ({ selectedUser, setScrollBottom }) => {
  const [sendMessage, setSendMessage] = useState(false)
  const [show, setShow] = useState({})
  const [inputValue, setInputValue] = useState("")
  const [selected, setSelected] = useState({})
  const [masterObj, setMasterObj] = useState()
  const formRef = useRef()
  const whatsappDropInputRef = useRef()
  const [whatsappDropInput, setWhatsappDropInput] = useState(false)
  const fileUploadRef = useRef()
  const [file, setFile] = useState(null)
  const [filePreview, setFilePreview] = useState(null)
  const [options, setOptions] = useState({})

  const dispatch = useDispatch()

  const fetchOptions = async (OPTIONS_PATH, NAME) => {
    try {
      const res = await get(`${OPTIONS_PATH}`)
      if (res) {
        setOptions(prev => ({ ...prev, [NAME]: res.data }))
      }
    } catch (error) {
      console.error(`Failed to fetch options: ${NAME}`)
    }
  }

  useEffect(() => {
    if (selectedUser?._id) {
      setMasterObj({ numberId: selectedUser?._id })
      isTemplateActive()

      reset()
    }
  }, [selectedUser?._id])

  const isTemplateActive = async () => {
    try {
      let res = await get(mainConfig?.ROUTE?.isTemplateActive(selectedUser?._id))
      if (res) {
        setSendMessage(true)
      } else {
        setSendMessage(false)
      }
    } catch (error) {
      setSendMessage(false)
    }
  }

  useEffect(() => {
    fetchOptions(mainConfig?.OPTIONS_ROUTE.template, "templates")
  }, [])

  const handleSendClick = async () => {
    try {
      let status = false
      const formData = new FormData()

      if (file) {
        formData.append("file", file)
        status = true
      }

      if (inputValue.trim() !== "") {
        setInputValue("")
        formData.append("text", inputValue?.trim())
        status = true
      }

      if (status) {
        await post(mainConfig?.ROUTE.chat({ post: true }, selectedUser?._id), formData)
        setScrollBottom(true)
        dispatch(refreshPageAction(true))
        reset()
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleKeyPress = event => {
    if (event.key === "Enter") {
      handleSendClick()
    }
  }

  const handleSelectChange = (e, name) => {
    let value = e?.value ?? ""

    setMasterObj(prev => {
      let obj = { ...prev }
      obj[name] = value

      if (name === "template") {
        obj.headerMediaType = e?.headerMediaType ?? "none"
        let whitelistedMediaType = ["image", "video", "document"]

        if (whitelistedMediaType.includes(obj.headerMediaType)) {
          if (e?.uploaded) {
            obj.headerUrl = `${API_URL}${e?.uploaded}`
          }
        }

        obj.variableBodyText =
          e?.body?.replacementDetails?.filter(doc => doc?.custom)?.length > 0
            ? e?.body?.replacementDetails?.filter(doc => doc?.custom)
            : []
        obj.variableHeaderText =
          e?.header?.replacementDetails?.filter(doc => doc?.custom)?.length > 0
            ? e?.header?.replacementDetails?.filter(doc => doc?.custom)
            : []

        obj.variableButtonText = e?.buttons?.filter(
          btn => btn.mode === "dynamic" && btn.replacementDetails.custom
        )

        formRef?.current?.reset()
      }

      return obj
    })
    setSelected(prev => ({ ...prev, [name]: e }))
  }

  const handleExampleContent = (name, e, idx) => {
    let value = e?.target.value
    setMasterObj(prev => {
      let obj = { ...prev }
      obj[name][idx].value = value
      return obj
    })
  }

  const reset = () => {
    formRef?.current?.reset()
    setSelected({})
    setFile(null)
    setFilePreview(null)

    setShow({})

    if (whatsappDropInputRef.current) {
      whatsappDropInputRef.current.checked = false
      setWhatsappDropInput(false)
    }

    if (fileUploadRef.current) {
      fileUploadRef.current.value = ""
    }
    setMasterObj({ numberId: selectedUser?._id })
  }

  const handleTemplateUse = () => {
    if (!masterObj?.template) toastr.info("Please select a template!")

    post(mainConfig.ROUTE.singleTemplateSend(masterObj?.template), masterObj)
      .then(() => {
        reset()
        // isTemplateActive()
      })
      .catch(err => {
        toastr.error(err?.message)
      })
  }

  const handleWhatsappMore = e => {
    let label = e.currentTarget.getAttribute("aria-label")

    if (fileUploadRef.current) {
      fileUploadRef.current.value = ""
    }

    setFilePreview(null)
    setFile(null)
    let triggerDropDown = () => {
      setTimeout(() => {
        if (whatsappDropInputRef.current) {
          whatsappDropInputRef.current.checked = true
          setWhatsappDropInput(true)
        }
      }, 300)
    }

    switch (label) {
      case "image":
        setShow({ image: true })
        triggerDropDown()
        break
      case "video":
        setShow({ video: true })
        triggerDropDown()
        break
      case "document":
        setShow({ document: true })
        triggerDropDown()
        break
      case "template":
        setTimeout(() => {
          setShow({ template: true })
        }, 300)
        break
      case "close":
        reset()
        setShow({})
        triggerDropDown()
        break
      case "dropdown":
        if (show?.file || show?.image || show?.video || show?.document) {
          setShow({})
          if (whatsappDropInputRef.current) {
            whatsappDropInputRef.current.checked = true
          }
        } else if (whatsappDropInput) {
          setWhatsappDropInput(false)
          setShow({})
          if (whatsappDropInputRef.current) {
            whatsappDropInputRef.current.checked = false
          }
        }
        break
      default:
        break
    }
  }

  const fileUploaded = e => {
    if (fileUploadRef.current?.files[0]) {
      const fileReaderUrl = URL?.createObjectURL(fileUploadRef.current?.files[0])
      setFilePreview(fileReaderUrl)
      setFile(fileUploadRef.current?.files[0])
    }
  }

  const getPreview = (preview, show) => {
    if (show?.image) return <img src={filePreview} alt="uploaded-image" />
    return <object data={preview}></object>
  }

  const handleTemplatePreview = () => {}

  return (
    <div className="chat-content-footer template-selection">
      {!sendMessage || show?.template ? (
        <AvForm
          ref={formRef}
          className="needs-validation"
          onValidSubmit={(e, v) => {
            handleTemplateUse()
          }}
        >
          {masterObj?.variableHeaderText?.length > 0 ||
          masterObj?.variableBodyText?.length > 0 ||
          masterObj?.variableButtonText?.length > 0 ||
          ["image", "video", "document"].includes(masterObj?.headerMediaType) ? (
            <Row className="variable-container">
              <Col md={12} className="mt-3">
                <div className="mb-3">
                  <Label className="bold">Variable content</Label>
                  <p>Just enter variable value here (it does need to be exact).</p>

                  {["image", "video", "document"].includes(masterObj?.headerMediaType) ? (
                    <Col md={12}>
                      <Label className="bold">Provide the dynamic media</Label>

                      <AvField
                        name="headerUrl"
                        type="url"
                        placeholder={`Enter url of ${masterObj?.headerMediaType} here`}
                        value={masterObj?.headerUrl ?? ""}
                        onChange={e =>
                          setMasterObj(prev => ({ ...prev, headerUrl: e.target.value }))
                        }
                        errorMessage="Enter url here"
                        validate={{
                          required: { value: true },
                          maxLength: { value: 2000 },
                        }}
                      />
                    </Col>
                  ) : null}

                  {masterObj?.variableHeaderText?.length > 0 ? (
                    <Col md={12}>
                      <Label className="bold">Variable content of Header</Label>
                      {masterObj?.variableHeaderText?.map((dataField, key) => (
                        <Col md={12} className={"mb-4"} key={key}>
                          <AvField
                            name={`variableHeader-${key}`}
                            type="text"
                            placeholder={`Enter ${masterObj?.variableHeaderText[key]?.placeholder} value here`}
                            value={masterObj?.variableHeaderText[key]?.value ?? ""}
                            onChange={e => handleExampleContent("variableHeaderText", e, key)}
                            errorMessage="Enter variable value here"
                            validate={{
                              required: { value: true },
                              maxLength: { value: 200 },
                            }}
                          />
                        </Col>
                      ))}
                    </Col>
                  ) : null}

                  {masterObj?.variableBodyText?.length > 0 ? (
                    <Col md={12}>
                      <Label className="bold">Variable content of body</Label>
                      {masterObj?.variableBodyText?.map((dataField, key) => (
                        <Col md={12} className={"mb-4"} key={key}>
                          <AvField
                            name={`variableBody-${key}`}
                            type="text"
                            placeholder={`Enter ${masterObj?.variableBodyText[key]?.placeholder} value here`}
                            value={masterObj?.variableBodyText[key]?.value ?? ""}
                            onChange={e => handleExampleContent("variableBodyText", e, key)}
                            errorMessage="Enter variable value here"
                            validate={{
                              required: { value: true },
                              maxLength: { value: 200 },
                            }}
                          />
                        </Col>
                      ))}
                    </Col>
                  ) : null}

                  {masterObj?.variableButtonText?.length > 0 ? (
                    <Col md={12}>
                      <Label className="bold">Variable content of button</Label>
                      {masterObj?.variableButtonText?.map((dataField, key) => (
                        <Col md={12} className={"mb-4"} key={key}>
                          <AvField
                            name={`variableButton-${key}`}
                            type="text"
                            placeholder={`Enter url path here`}
                            value={masterObj?.variableButtonText[key]?.value ?? ""}
                            onChange={e => handleExampleContent("variableButtonText", e, key)}
                            errorMessage="Enter variable value here"
                            validate={{
                              required: { value: true },
                              maxLength: { value: 200 },
                            }}
                          />
                        </Col>
                      ))}
                    </Col>
                  ) : null}
                </div>
              </Col>
            </Row>
          ) : null}

          <div className="send-box">
            {show?.template && (
              <button
                className="addFilees"
                type="button"
                aria-label="close"
                onClick={handleWhatsappMore}
              >
                <i className="fas fa-paperclip" style={{ rotate: "160deg" }}></i>
              </button>
            )}

            {/* <button className="addFilees" type="button" onClick={handleTemplatePreview}>
              <i class="fa fa-eye"></i>
            </button> */}

            <Select
              className="template-select"
              menuPlacement="top"
              isClearable
              value={selected?.template ?? ""}
              onChange={e => handleSelectChange(e, "template")}
              options={options?.templates ?? ""}
            />

            <button type="submit" className="btnSendMsg">
              <i className="fa fa-paper-plane"></i>
            </button>
          </div>
        </AvForm>
      ) : (
        <div className="send-box">
          {/* <button className="addFilees">
                <i className="fa fa-plus"></i>
              </button> */}

          <div className="whatsapp-dropdown">
            <input
              type="checkbox"
              id="dropdown"
              ref={whatsappDropInputRef}
              aria-label="dropdown"
              onChange={e => {
                setWhatsappDropInput(e.target?.checked)
                handleWhatsappMore(e)
              }}
            />
            <label className="dropdown__face" htmlFor="dropdown">
              <div className="dropdown__text">
                {whatsappDropInput || whatsappDropInputRef?.current?.checked ? (
                  <i className="fas fa-paperclip" style={{ rotate: "160deg" }}></i>
                ) : (
                  <i className="fas fa-paperclip" style={{ rotate: "137deg" }}></i>
                )}
              </div>
            </label>

            <div
              className={`dropdown-file-upload ${
                show?.file || show?.image || show?.video || show?.document ? "active" : ""
              }`}
            >
              {!filePreview ? (
                <>
                  <label htmlFor="fileUpload">
                    {show?.image ? (
                      <i class="bx bx-image" aria-label="image"></i>
                    ) : show?.video ? (
                      <i class="bx bxs-videos"></i>
                    ) : (
                      <i class="bx bx-file"></i>
                    )}
                    <h1>
                      Select the {show?.image ? "Image" : show?.video ? "Video" : "Document"} form
                      your computer
                    </h1>
                  </label>
                  <input
                    type="file"
                    accept={
                      show?.image
                        ? "image/png, image/jpeg, image/gif"
                        : show?.video
                        ? "video/mp4"
                        : "application/pdf"
                    }
                    id="fileUpload"
                    onChange={fileUploaded}
                    ref={fileUploadRef}
                  />
                </>
              ) : (
                getPreview(filePreview, show)
              )}
            </div>

            <ul
              className={`dropdown__items ${
                show?.file || show?.image || show?.video || show?.document ? "" : "active"
              }`}
            >
              <li>
                <Tooltip title="Image" arrow placement="top">
                  <button className="li-btn">
                    <i class="bx bx-image" aria-label="image" onClick={handleWhatsappMore}></i>
                  </button>
                </Tooltip>
              </li>
              <li>
                <Tooltip title="Video" arrow placement="top">
                  <button className="li-btn" aria-label="video" onClick={handleWhatsappMore}>
                    <i class="bx bxs-videos"></i>
                  </button>
                </Tooltip>
              </li>
              <li>
                <Tooltip title="Document" arrow placement="top">
                  <button className="li-btn" aria-label="document" onClick={handleWhatsappMore}>
                    <i class="bx bx-file"></i>
                  </button>
                </Tooltip>
              </li>
              <li>
                <Tooltip title="Template" arrow placement="top">
                  <button className="li-btn" aria-label="template" onClick={handleWhatsappMore}>
                    <i class="bx bx-receipt"></i>
                  </button>
                </Tooltip>
              </li>
            </ul>

            <svg>
              <filter id="goo">
                <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                <feColorMatrix
                  in="blur"
                  type="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                  result="goo"
                />
                <feBlend in="SourceGraphic" in2="goo" />
              </filter>
            </svg>
          </div>
          <input
            className="footer-chat-input"
            type="text"
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Type a message here"
          />
          <button onClick={handleSendClick} className="btnSendMsg" id="sendMsgBtn">
            <i className="fa fa-paper-plane"></i>
          </button>
        </div>
      )}
    </div>
  )
}

export default Footer
