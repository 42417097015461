import React, { useEffect, useState,useRef} from "react";
import { AvField, AvForm } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import axios from "axios";
import toastr from "toastr";
import { getDate, toTop } from "../../../helpers/functions"
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

const ResponseReport = () => {
    const todayDate = getDate()
    const filterRef = useRef(null)
  const [data, setData] = useState({
    columns: [
      { label: "#", field: "id", sort: "asc" },
      { label: "Department", field: "departmentName", sort: "asc" },
      { label: "Total Responses", field: "totalResponses", sort: "asc" },
      { label: "Closed Responses", field: "closedResponses", sort: "asc" },
      { label: "Pending Closure", field: "pendingClosure", sort: "asc" },
      { label: "Pending > 1 Month", field: "pendingMoreThanMonth", sort: "asc" },
      { label: "Pending > 15 Days", field: "pendingFifteenDays", sort: "asc" },
      { label: "Initial Responses", field: "initialResponseGiven", sort: "asc" },
      { label: "Info Received", field: "infoFromCustomerReceived", sort: "asc" },
      { label: "Avg Resolution Time (Days)", field: "avgResolutionTime", sort: "asc" },
    ],
    rows: [],
  });

 const [filterObject, setFilterObject] = useState({})
   const [selectFilter, setSelectFilter] = useState({})
   const [filterTypeOption, setFilterTypeOption] = useState([])
   const [selectedFilterType, setSelectedFilterType] = useState([])
   const [filterSubTypeOption, setFilterSubTypeOption] = useState([])
   const [selectedFilterSubType, setSelectedFilterSubType] = useState([])
   const [filterDepartmentOption, setFilterDepartmentOption] = useState([])
   const [selectedFilterDepartment, setSelectedFilterDepartment] = useState([])

  const fetchResponseReport = async () => {
    try {
      const response = await axios.get(`${API_URL}response/v2/review/responseReport`, {
        params: filterObject,
      });
      const formattedRows = response.data.data.map((item, index) => ({
        id: index + 1,
        departmentName: item.departmentName,
        totalResponses: item.totalResponses,
        closedResponses: item.closedResponses,
        pendingClosure: item.pendingClosure,
        pendingMoreThanMonth: item.pendingMoreThanMonth,
        pendingFifteenDays: item.pendingFifteenDays,
        initialResponseGiven: item.initialResponseGiven,
        infoFromCustomerReceived: item.infoFromCustomerReceived,
        avgResolutionTime: item.avgResolutionTime?.toFixed(2) || "",
      }));
      setData((prevData) => ({ ...prevData, rows: formattedRows }));
    } catch (error) {
      toastr.error("Failed to fetch response report");
    }
  };

 

  const handleSubmit = (event, values) => {
    event.preventDefault();
    fetchResponseReport();
  };



  useEffect(() => {
    fetchResponseReport();
  }, [filterObject]);

   const handleFilterValueChange = e => {
      const { name, value } = e.target || e
      console.log("Updated value for", name, value)
      setFilterObject(prev => ({
        ...prev,
        [name]: value,
      }))
    }
  
    const handleFilterSelectChange = async ({ selected, name }) => {
      console.log(selected, "===========", name)
      const obj = { ...selectFilter }
      obj[name] = selected
      setSelectFilter(obj)
      if (name === "type") {
        setSelectedFilterType(selected)
      }
      if (name === "subType") {
        setSelectedFilterSubType(selected)
      }
      if (name === "department") {
        setSelectedFilterDepartment(selected)
      }
  
      handleFilterValueChange({ value: selected ? selected.value : "", name })
    }
  
    useEffect(() => {
      handlefilterTypeOptions()
      handlefilterSubTypeOptions()
      handlefilterDepartmentOptions()
    }, []) // eslint-disable-line
  
    const handlefilterTypeOptions = async (state = "") => {
      try {
        const response = await axios.get(`${API_URL}options/responseType/list`)
        setFilterTypeOption(response.data.data)
      } catch (error) {}
    }
    const handlefilterSubTypeOptions = async (state = "") => {
      try {
        const response = await axios.get(`${API_URL}options/responseSubType/list`)
        setFilterSubTypeOption(response.data.data)
      } catch (error) {}
    }
    const handlefilterDepartmentOptions = async (state = "") => {
      try {
        const response = await axios.get(`${API_URL}options/department/list`)
      
        setFilterDepartmentOption(response.data.data)
      } catch (error) {}
    }
    const reset = () => {
      // formRef.current.reset()
      filterRef.current.reset()
      toTop()
      setFilterObject({})
      setSelectFilter({
        type: null,
        subType: null,
        department: null,
        
      })
      setSelectedFilterType([])
      setSelectedFilterSubType([])
      setSelectedFilterDepartment([])
    }
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Home" breadcrumbItem="Response Report" />
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <AvForm onSubmit={handleSubmit} ref={filterRef}>
                <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>From date</Label>
                          <AvField
                            type="date"
                            name="fromDate"
                            value={filterObject.fromDate || ""}
                            onChange={handleFilterValueChange}
                            max={todayDate}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>To date</Label>
                          <AvField
                            type="date"
                            name="toDate"
                            value={filterObject.toDate || ""}
                            onChange={handleFilterValueChange}
                            min={filterObject.fromDate || ""}
                            max={todayDate}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Type</Label>
                          <Select
                            menuPosition="fixed"
                            options={filterTypeOption}
                            value={selectedFilterType}
                            onChange={selected =>
                              handleFilterSelectChange({
                                selected,
                                name: "type",
                              })
                            }
                          />
                        </div>
                      </Col>
                      {/* <Col md="2">
                        <div className="mb-3">
                          <Label>Sub Type</Label>
                          <Select
                            menuPosition="fixed"
                            // value={filterSubTypeOption.find(
                            //   option => option.value === filterObject.type
                            // )}
                            value={selectedFilterSubType}
                            options={filterSubTypeOption}
                            onChange={selected =>
                              handleFilterSelectChange({ selected, name: "subType" })
                            }
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col> */}
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Department</Label>
                          <Select
                            menuPosition="fixed"
                            // value={filterDepartmentOption.find(
                            //   option => option.value === filterObject.type
                            // )}
                            value={selectedFilterDepartment}
                            options={filterDepartmentOption}
                            onChange={selected =>
                              handleFilterSelectChange({ selected, name: "department" })
                            }
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          <Button color="danger" type="submit" onClick={reset}>
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <MDBDataTable
                  id="responseTable"
                  bordered
                  responsive
                  searching={true}
                  entries={20}
                  disableRetreatAfterSorting={true}
                  data={data}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ResponseReport;
