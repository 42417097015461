import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap"
import ConversationReport from "./ConversationReport"

const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/"
function WhatsappReport() {
  // date
  const formatDate = (date, fullMonth = false) => {
    const options = fullMonth
      ? {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }
      : {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }

    return new Date(date).toLocaleString("en-US", options)
  }

  const [startDate, setStartDate] = useState(() =>
    new Date().setDate(new Date().getDate() - 30)
  )
  const [endDate, setEndDate] = useState(new Date())
  const [showFullMonth, setShowFullMonth] = useState(false)

  const toggleShowFullMonth = () => {
    setShowFullMonth(!showFullMonth)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div>
            <p className="titletit">Report</p>
            {/* <h6 className="dateText" onClick={toggleShowFullMonth}>
              {formatDate(startDate, showFullMonth)} -{" "}
              {formatDate(endDate, showFullMonth)}
            </h6> */}
          </div>

          <Row>

            <Row>
              <Col xl={12}>
                <Card>
                  <CardBody>
                    <div className="float-end">
                    </div>
                    <ConversationReport />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default WhatsappReport
