import React from "react"
import { Navigate } from "react-router-dom"

// Dashboard
import MarketingDashboard from "../pages/Dashboard/index"

//Pages
import PagesStarter from "../pages/Utility/pages-starter"
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"

// Users
import User from "../pages/User"
import UserDashboard from "../pages/User/UserDashboard"

// Manage
import Designation from "../pages/Manage/Designation/ManageDesignation"
import Department from "../pages/Manage/Department/Department"
import Template from "../pages/Manage/Template/Template"
import Leadcategory from "../pages/Manage/Category/LeadType"

import WhatsappConfig from "../pages/Manage/WhatsappConfig"

// Master Settings
import Privilage from "../pages/MasterSettings/Privilage"
import Modules from "../pages/MasterSettings/Modules"
import Rules from "../pages/MasterSettings/Rules"
import Company from "../pages/MasterSettings/Company"
import Branch from "../pages/MasterSettings/Branch"

// Security
import BlockedIp from "../pages/Security/BlockedIp"
import UserActivity from "../pages/Security/UserActivity"

// Social Listening
import SocialListening from "../pages/Social-Listening/index.js"
import ClientCredentials from "../pages/Social-Listening/credentialForm.jsx"
import Dashboard from "../pages/Social-Listening/dashboard.jsx"

// GMB
import GMB from "../pages/GMB/Googlemybussiness"

// Reviews
import Reviews from "../pages/Social/Reviews/Reviews"
import Reviewreply from "../pages/Social/Reviews/Reviewreply"

// // Response
// import FollowUp from "../pages/Response/FollowUp/followUp"
// import NewResponse from "../pages/Response/NewResponse/NewResponse"
// import AllResponse from "../pages/Response/AllResponse/allResponse"

// Reports
import DashboardSummary from "../pages/Reports/dashboardSummary"
import FollowersSummary from "../pages/Reports/followersSummary"
import SentimentSummary from "../pages/Reports/sentimentSummary"
import UniqueUsersReport from "../pages/Reports/uniqueUsersReport"
import VolumeSummary from "../pages/Reports/volumeSummary"
import MediaPlatformFacebook from "../pages/Reports/MediaPlatformFacebook"
import MediaPlatformInstagram from "../pages/Reports/MediaPlatformInstagram"
import MediaPlatformTwitter from "../pages/Reports/MediaPlatformTwitter"

// Notification
import Notification from "../pages/Notifications"
import SocialConfig from "../pages/MasterSettings/Social-Config"

// Manage
import Branchmanage from "../pages/Manage/Branch/Branch"
import Zonemanage from "../pages/Manage/Zone/Zone"
import ProductCategory from "../pages/Manage/ProductCategory"
import Product from "../pages/Manage/Product"
import SourceMain from "../pages/Manage/Source/SourceMain"
import SourceSub from "../pages/Manage/Source/SourceSub"
import ResponseMain from "../pages/Manage/Response/Response Type"
import ResponseSub from "../pages/Manage/Response/Response Sub Type"

// Responses
import AllResponse from "../pages/NewResponse/AllResponse"
import NewResponse from "../pages/NewResponse/NewResponse"
import PendingResponse from "../pages/NewResponse/PendingResponse"
import ClosedResponse from "../pages/NewResponse/ClosedResponse"
import ResponseTypeGraph from "../pages/Response/Graphs/ResponseType-Graph/index.jsx"
import ProductGraph from "../pages/Response/Graphs/Product-Graph/index.jsx"
import ResponseGraph from "../pages/Response/Graphs/ResponseGraph/index.jsx"

// Feedback from and reports
import Latestfeedback from "../pages/FeedBack/feedbacksLog"
import Feedbackform from "../pages/FeedBack/FeedBackQuestions"
import WeeklyReport from "../pages/FeedBack/FeedBack-Reports/WeeklyReport.jsx"
import QuestionWiseReport from "../pages/FeedBack/FeedBack-Reports/QuestionWiseReport.jsx"
import MonthlyReport from "../pages/FeedBack/FeedBack-Reports/MonthlyReport.jsx"

// Content
import Messages from "../pages/Content/Messages/Messages"
import Newpost from "../pages/Content/Posts/Newpost"
import Allpost from "../pages/Content/Posts/Allpost"
import Socialmonitor from "../pages/Content/Socialmonitor/Socialmonitor"
// Campiagn
import Campaign from "../pages/Campaign/Campaign"
import CampaignDashboard from "../pages/Campaign/CampaignDashboard/CampaignDashboard"
import TrackClicks from "../pages/Campaign/TrackClicks"
// Leads
import Newlead from "../pages/Leads/NewLead/Newlead"
import TrashedLeads from "../pages/Leads/TrashedLeads/TrashedLeads"
import Followup from "../pages/Leads/Followup/Followup"
import BulkUpload from "../pages/Leads/Bulk Upload/BulkUpload.jsx"
import Survey from "../pages/Survey/Survey"
import AddSurvey from "../pages/Survey/Add-Survey"
import Surveyquestions from "../pages/Survey/Survey-questions"
import SurveyReports from "../pages/Survey/Survey-reports"
import Calender from "../pages/Calender"
import FeedbackCategory from "../pages/FeedBack/Feedback-Category"
import FeedbackSubCategory from "../pages/FeedBack/Feedback-Sub-Category"
import AssignFeedback from "../pages/FeedBack/Assign Feedback"
import LeadManagement from "../pages/Leads/Leads/LeadManagement"
import LeadDashboard from "../pages/Leads/Dashboard/Dashboard.jsx"
import ResponseDashboard from "../pages/Response/Dashboard/Dashboard.jsx"
import ResponseReport from "../pages/Reports/ResponseReport/index.js"
import ResponseSourceReport from "../pages/Reports/ResponseSourceReport/index.js"

// Whatsapp
import Inbox from "../pages/Whatsapp/Inbox"
import Contact from "../pages/Whatsapp/Contact"
import ContactCategory from "../pages/Whatsapp/Manage/ContactCategory"
import ContactLabel from "../pages/Whatsapp/Manage/ContactLabel"
import WhatsappBulkList from "../pages/Whatsapp/Manage/BuilUploadList"
import WhatsappReport from "../pages/Whatsapp/Report"
// - Broadcast
import BroadcastTemplate from "../pages/Whatsapp/Broadcast/BroadcastTemplate"
import BroadcastAnalytics from "../pages/Whatsapp/Broadcast/BroadcastAnalytics"
import BroadcastSchedule from "../pages/Whatsapp/Broadcast/BroadcastSchedule"

import WhatsappInvoice from "../pages/Invoices/index.jsx"

import TvScreen from "../pages/TvScreen"

// Alert System
import AlertKeyWords from "../pages/AlertSystem/KeyWords/"

//Own Pages
const userRoutes = [
  //dahshboard
  // { path: "/feedback-dashboard", component: <MarketingDashboard /> },
  {
    path: "/social-dashboard",
    component: <MarketingDashboard name="Leads" />,
  },
  { path: "/hrms-dashboard", component: <MarketingDashboard name="HRMS" /> },
  {
    path: "/accounts-dashboard",
    component: <MarketingDashboard name="Accounts" />,
  },
  {
    path: "/feedback-dashboard",
    component: <MarketingDashboard name="Feedback" />,
  },
  //Utility
  { path: "/pages", component: <PagesStarter /> },

  // Whatsapp
  { path: "/Inbox", title: "Contact", component: <Inbox /> },
  { path: "/contacts", title: "Contact", component: <Contact /> },
  { path: "/contact-group", title: "Contact Category", component: <ContactCategory /> },
  { path: "/contact-tags", title: "Contact Category", component: <ContactLabel /> },
  {
    path: "/whatsapp-bulkupload-log",
    title: "Whatsapp Bulk List",
    component: <WhatsappBulkList />,
  },
  { path: "/conversation-graph", title: "Whatsapp Report", component: <WhatsappReport /> },
  // - Broadcast
  { path: "/templates", title: "Broadcast Templates", component: <BroadcastTemplate /> },
  { path: "/analytics", title: "Broadcast Analytics", component: <BroadcastAnalytics /> },
  { path: "/broadcast", title: "Broadcast Schedule", component: <BroadcastSchedule /> },

  // Users
  { path: "/user", component: <User /> },
  { path: "/user/:user_id", component: <UserDashboard /> },

  // Manage
  { path: "/manage_designation", component: <Designation /> },
  { path: "/manage_department", component: <Department /> },
  { path: "/lead-type", component: <Leadcategory /> },
  { path: "/wa-config", component: <WhatsappConfig /> },

  { path: "/manage_template", component: <Template /> },
  { path: "/manage_branch", component: <Branchmanage /> },
  { path: "/manage_zone", component: <Zonemanage /> },
  { path: "/manage_product_category", component: <ProductCategory /> },
  { path: "/manage_product", component: <Product /> },
  { path: "/source-main", component: <SourceMain /> },
  { path: "/source-sub", component: <SourceSub /> },
  { path: "/response-main", component: <ResponseMain /> },
  { path: "/response-sub", component: <ResponseSub /> },

  // notifications
  { path: "/notifications", component: <Notification /> },

  // Master Settings
  { path: "/privilage", component: <Privilage /> },
  { path: "/rules", component: <Rules /> },
  { path: "/modules", component: <Modules /> },
  { path: "/company", component: <Company /> },
  { path: "/branch", component: <Branch /> },
  { path: "/social-config", component: <SocialConfig /> },

  // Security
  { path: "/user-activity", component: <UserActivity /> },
  { path: "/blocked-ip", component: <BlockedIp /> },

  // Social Listening
  { path: "/social_listening", component: <SocialListening /> },
  { path: "/credentials", component: <ClientCredentials /> },
  { path: "/listening-dashboard", component: <Dashboard /> },

  // GMB
  { path: "/google_my_business", component: <GMB /> },

  // Feedbacks
  { path: "/latest_feedback", component: <Latestfeedback /> },
  { path: "/feedback_questions", component: <Feedbackform /> },
  { path: "/assign-feedback", component: <AssignFeedback /> },
  { path: "/feedback-category", component: <FeedbackCategory /> },
  { path: "/feedback-sub-category", component: <FeedbackSubCategory /> },
  { path: "/question-wise-report", component: <QuestionWiseReport /> },
  { path: "/weekly-report", component: <WeeklyReport /> },
  { path: "/monthly-report", component: <MonthlyReport /> },

  // Content
  { path: "/message", component: <Messages /> },
  { path: "/newpost", component: <Newpost /> },
  { path: "/allpost", component: <Allpost /> },
  { path: "/monitor", component: <Socialmonitor /> },

  // Campaign
  { path: "/campaign", component: <Campaign /> },
  { path: "/campaign-dashboard", component: <CampaignDashboard /> },
  { path: "/track-clicks", component: <TrackClicks /> },

  // Leads
  { path: "/lead-dashboard", component: <LeadDashboard /> },
  { path: "/add-lead", component: <LeadManagement /> },
  { path: "/leads", component: <Newlead /> },
  { path: "/follow-up", component: <Followup /> },
  { path: "/trashed-leads", component: <TrashedLeads /> },
  { path: "/bulk-upload", component: <BulkUpload /> },

  //Survey
  { path: "/survey", component: <Survey /> },
  { path: "/add-survey", component: <AddSurvey /> },
  { path: "/survey-questions", component: <Surveyquestions /> },
  { path: "/survey-reports", component: <SurveyReports /> },

  // Reviews
  { path: "/reviews", component: <Reviews /> },
  { path: "/reviews/replyreview", component: <Reviewreply /> },

  // // Response
  // { path: "/response_followup", component: <FollowUp /> },
  // { path: "/new_response", component: <NewResponse /> },
  // { path: "/all_response", component: <AllResponse /> },

  // Reports
  { path: "/reports-summary", component: <DashboardSummary /> },
  { path: "/reports-followers", component: <FollowersSummary /> },
  { path: "/reports-sentiment", component: <SentimentSummary /> },
  { path: "/reports-unique-users", component: <UniqueUsersReport /> },
  { path: "/reports-volume", component: <VolumeSummary /> },
  { path: "/reports-response", component: <ResponseReport /> },
  { path: "/reports-response-source", component: <ResponseSourceReport /> },

  { path: "/calender", component: <Calender /> },

  { path: "response-dashboard", component: <ResponseDashboard /> },
  { path: "/all_response", component: <AllResponse /> },
  { path: "/new_response", component: <NewResponse /> },
  { path: "/pending_response", component: <PendingResponse /> },
  { path: "/closed_response", component: <ClosedResponse /> },
  { path: "/response-type-graph", component: <ResponseTypeGraph /> },
  { path: "/product-graph", component: <ProductGraph /> },
  { path: "/response_graphs", component: <ResponseGraph /> },

  { path: "/invoices", component: <WhatsappInvoice /> },

  {
    path: "/reports-media-platform-facebook",
    component: <MediaPlatformFacebook />,
  },
  {
    path: "/reports-media-platform-instagram",
    component: <MediaPlatformInstagram />,
  },
  {
    path: "/reports-media-platform-twitter",
    component: <MediaPlatformTwitter />,
  },

  // Alert System
  { path: "/alerts", component: <Reviews /> },
  { path: "/alert-keyword", component: <AlertKeyWords /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to={sessionStorage.getItem("module_url")} />,
  },
  {
    path: "*",
    component: <Navigate to={sessionStorage.getItem("module_url")} />,
  },
]

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-register", component: <Register1 /> },
  { path: "/page-recoverpw", component: <Recoverpw /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },

  // Tv screens
  { path: "/tv-screen", component: <TvScreen /> },
]

export { userRoutes, authRoutes }
