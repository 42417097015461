import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState, useRef, useEffect } from "react"
import { Button } from "reactstrap"
import {post, put, get} from '../../../helpers/api_helper.js'
import toastr from "toastr"
import axios from "axios"
import { API_URL } from "../../../config.js"

const QRCodeTemplate = ({ handleModalClose, setTemplate, templateId = null }) => {
  const formRef= useRef()

  const [timeoutId, setTimeoutId] = useState(null);
  const [name, setName] = useState('')
  const [id, setId] = useState(null)
  const [backgroundImage, setBackgroundImage] = useState("")
  const [x, setX] = useState(40) // Position in percentage
  const [y, setY] = useState(45) // Position in percentage
  const [size, setSize] = useState(20) // Size as percentage of A4 width 
  const a4Ref = useRef(null)
  const qrCodeRef = useRef(null);
  const qrCodeData = "/dummy_qr_code.png"

  const [isDragging, setIsDragging] = useState(false);
  const [startDragPosition, setStartDragPosition] = useState({ x: 0, y: 0 });
  const [isResizing, setIsResizing] = useState(false);

  //<------------------------- ARROW KEYS FUNCTIONALITY START---------------------------->
  const roundToTwoDecimals = (value) => {
    value = value <= 0 ? 0 : value >= 100 ? 100 : parseFloat(value.toFixed(2));
    return value
  };

  const startChanging = (key) => {
    const incrementDecrement = () => {
      if (key === 'ArrowRight') {
        setX((prevX) => roundToTwoDecimals(prevX + .3));
      } else if (key === 'ArrowLeft') {
        setX((prevX) => roundToTwoDecimals(prevX - .3)); 
      } else if (key === 'ArrowUp') {
        setY((prevY) => roundToTwoDecimals(prevY + .3)); 
      } else if (key === 'ArrowDown') {
        setY((prevY) => roundToTwoDecimals(prevY - .3));
      } else if (key === '+') {
        setSize((prevSize) => roundToTwoDecimals(prevSize + .3)); 
      } else if (key === '-') {
        setSize((prevSize) => roundToTwoDecimals(prevSize - .3)); 
      }

      const id = setTimeout(incrementDecrement, 100);
      setTimeoutId(id);
    };

    incrementDecrement();
  };

  const stopChanging = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === '+' ||
        event.key === '-') {
        event.preventDefault();
        startChanging(event.key);
      }
    };

    const handleKeyUp = () => {
      stopChanging();
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      if (timeoutId) clearTimeout(timeoutId);
    };
    //eslint-disable-next-line
  }, [timeoutId]);

  //<------------------------- ARROW KEYS FUNCTIONALITY END---------------------------->

  //<------------------------- DRAG FUNCTIONALITY START ------------------------->
  const handleMouseDown = (e) => {
    e.preventDefault();
    if (e.target.dataset.resize) {
      setIsResizing(true);
    } else {
      setIsDragging(true);
    }
    const qrCodeRect = qrCodeRef.current.getBoundingClientRect();
    setStartDragPosition({
      x: e.clientX - qrCodeRect.left,
      y: e.clientY - qrCodeRect.top,
    });
  };

  const handleMouseMove = (e) => {
    const a4Rect = a4Ref.current.getBoundingClientRect();

    if (isDragging) {
      e.preventDefault();

      const newX = ((e.clientX - a4Rect.left - startDragPosition.x) / a4Rect.width) * 100;
      const newY = ((a4Rect.bottom - e.clientY - startDragPosition.y) / a4Rect.height) * 100;

      setX(roundToTwoDecimals(newX));
      setY(roundToTwoDecimals(newY));
    } else if (isResizing) {
      e.preventDefault();

      const qrCodeRect = qrCodeRef.current.getBoundingClientRect();
      const dx = e.clientX - qrCodeRect.right;
      const newSize = ((qrCodeRect.width + dx) / a4Rect.width) * 100;

      setSize(roundToTwoDecimals(newSize));
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setIsResizing(false);
  };

  useEffect(() => {
    if (isDragging || isResizing) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
    //eslint-disable-next-line
  }, [isDragging, isResizing]);
  //<------------------------- DRAG FUNCTIONALITY END --------------------------->

  const getTemplate = ()=> {
    get(`feedback/qr/template/${templateId}`).then(res=>{
      const data = res.data
      setBackgroundImage(data.backgroundImage)
      setName(data?.name)
      setX(data?.left)
      setY(data?.bottom)
      setSize(data?.size)
      setId(data?._id)
    }).catch(err=>console.error(err))
  }

  useEffect(()=>{
    if(templateId)getTemplate()
      //eslint-disable-next-line
  },[templateId])

  const axiosApi = axios.create({
    baseURL: API_URL,
  })

  const uploadImage = async(e) => {
    console.log(e.target.files[0])
    const fd = new FormData()
    const selectedFile = e.target.files[0]
    fd.append("image", selectedFile)
    const response = await axiosApi.post("feedback/qr/template/bg-image", fd)
    if (response?.status === 200) {
      setBackgroundImage(response?.data?.data?.new_filename)
    } else {
      toastr.error(response.data.message)
    }
  }

  const handleSubmit = ()=>{
    const requestBody = {
      name,
      left: x,
      bottom: y,
      size,
      backgroundImage
    }
    if(id){
      requestBody.id = id
      put(`feedback/qr/template`, requestBody).then(res=>{
        setTemplate(res?.data)
        reset()
      }).catch(err=>{toastr.error(err?.response?.data?.message || err?.message)})
    }else{
      post(`feedback/qr/template`, requestBody).then(res=>{
        setTemplate(res?.data)
        reset()
      }).catch(err=>{
        console.error('errr',err)
        toastr.error(err?.response?.data?.message || err?.message)})
    }
  }

  const reset = ()=> {
    setName('')
    setId(null)
    setBackgroundImage("")
    setX(40)
    setY(45)
    setSize(20)
    formRef.current.reset()
    handleModalClose()
  }

  const handleSizeChange = e => {
    if(e.target.value <= 0)e.target.value = 0
    if(e.target.value > 100)e.target.value = 100
    setSize(Number(e.target.value))
  }

  const handlePositionChange = (e, setter) => {
    if(e.target.value <= 0)e.target.value = 0
    if(e.target.value > 100)e.target.value = 100
    setter(Number(e.target.value))
  }

  const resizeCircleStyle = {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: '10px',
    height: '10px',
    border: '.5px solid black',
    borderRadius:'50%',
    backgroundColor: 'white',
    cursor: 'nwse-resize',
    transform: 'translate(50%, 50%)',
  };

  const qrCodeStyle = {
    position: "absolute",
    left: `${x}%`,
    bottom: `${y}%`,
    width: `${size}%`,
    height: "auto",
    cursor: isResizing ? 'nwse-resize' : 'grab',
  };
  const wrapperStyle = {
    maxHeight: "90vh",
    maxWidth: "calc(90vh * 0.707)", 
    margin: "0 auto",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }

  const a4Style = {
    width: "210mm", // A4 width in mm
    height: "90vh",
    maxHeight: "297mm",
    position: "relative",
    backgroundImage: `url(${API_URL}${backgroundImage})`,
    backgroundSize: "contain",
    // backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    border: "1px solid #ddd",
    overflow: "hidden",
  }
  const qrContainerStyle = {
    position: 'relative', 
    width: '100%',
    height: 'auto',
    border:'1px solid black'
  };

  return (
    <div className="d-flex align-items-center justify-content-evenly gap-3">
      <div className="outer">
        <div className="wrapper" style={wrapperStyle}>
          <div ref={a4Ref} style={a4Style}>
            {/* <img src={qrCodeData} alt="QR Code" className="qrCode" style={qrCodeStyle} ref={qrCodeRef}
              onMouseDown={handleMouseDown}/>
              <div
              style={resizeHandleStyle}
              data-resize
              onMouseDown={handleMouseDown}
            /> */}
            <div style={qrCodeStyle} ref={qrCodeRef} onMouseDown={handleMouseDown}>
              <div style={qrContainerStyle}>
                <img src={qrCodeData} alt="QR Code" style={{ width: "100%", height: "auto" }} />
                <div style={resizeCircleStyle} data-resize onMouseDown={handleMouseDown} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div  style={{ padding: "0", width:"100%" }}>
       <span>* TIP : Use Arrows to adjust position and '+' and '-' to adjust size.</span>
        <AvForm className='mt-2' onValidSubmit={handleSubmit} ref={formRef}>
        
          <div className="form-control">
            <label>
              Upload Background Image (<span className="text-danger">jpg, jpeg, png, webp</span>)
              (Recommended size A4){" "}
            </label>
            <AvField
              name="backgroundImage"
              type="file"
              accept=".jpg,.jpeg,.png,.webp"
              onChange={uploadImage}
              style={{ maxWidth: "400px" }}
            />
          </div>
          <div className="form-control">
            <div className="d-flex justify-content-between align-items-baseline">
              <label>QR Code Horizontal Position</label>
              <div className="d-flex align-items-baseline gap-2">
                <AvField
                  style={{ width: "60px" }}
                  name="xNum"
                  value={x}
                  onChange={e => handlePositionChange(e, setX)}
                  type="number"
                />
                %
              </div>
            </div>
            <AvField
              type="range"
              value={x}
              name="x"
              onChange={e => handlePositionChange(e, setX)}
              min="0"
              max="100"
              step="0.01"
            />
          </div>

          <div className="form-control">
            <div className="d-flex justify-content-between align-items-baseline">
              <label>QR Code Vertical Position</label>
              <div className="d-flex align-items-baseline gap-2">
                <AvField
                  style={{ width: "60px" }}
                  name="yNum"
                  value={y}
                  onChange={e => handlePositionChange(e, setY)}
                  type="number"
                />
                %
              </div>
            </div>
            <AvField
              type="range"
              value={y}
              name="y"
              onChange={e => handlePositionChange(e, setY)}
              min="0"
              max="100"
              step="0.01"
            />
          </div>

          <div className="form-control">
            <div className="d-flex justify-content-between align-items-baseline">
              <label>QR Code Size</label>{" "}
              <div className="d-flex align-items-baseline gap-2">
                <AvField
                  style={{ width: "60px" }}
                  name="sizeNum"
                  value={size}
                  onChange={handleSizeChange}
                  type="number"
                />
                %
              </div>
            </div>
            <AvField
              type="range"
              value={size}
              name="size"
              onChange={handleSizeChange}
              min="1"
              max="100"
              step="0.01"
            />
          </div>
          <div className="mt-3 d-flex gap-3 justify-content-center align-items-start">
            <AvField
              placeholder="Name"
              type="text"
              value={name}
              name="name"
              onChange={(e)=>setName(e.target.value)}
              validate={{
                required:{
                  value:true,
                  errorMessage:'Provide a name for the template'
                }
              }}
            />
            <Button className="mb-3" type='submit' color={id ? 'warning' : 'success'}>
                {id ? 'Save Changes' :  'Create Template'}
            </Button>
            <Button className="mb-3" color='danger' onClick={reset}>
                Discard
            </Button>
          </div>
         
        </AvForm>
      </div>
    </div>
  )
}

export default QRCodeTemplate
