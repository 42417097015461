import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
} from "reactstrap";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { get } from "../../../helpers/api_helper"

import axios from "axios";
import toastr from "toastr";
import moment from "moment";

import Breadcrumbs from "../../../components/Common/Breadcrumb";

import accessToken from "../../../helpers/jwt-token-access/accessToken";
import "./Department.scss";

const Department = () => {
  const [departmentObject, setDepartmentObject] = useState({});
  // console.log("staate",departmentObject);
  
  const [departmentIdTobeUpdated, setDepartmentIdToBeUpdated] = useState(null);
  const [departmentIdToBeDeleted, setDepartmentIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [departmentForTable, setDepartmentForTable] = useState([]);
  const setDepartmentValue = useState("")[1];
  const [desiType, setDesiType] = useState([]);
  const [selectedDesi, setSelectedDesi] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();

  useEffect(() => {
    handleTableData();
    fetch_all_DesiType();
  }, []); //eslint-disable-line

  function handleTableData() {
    var url = `${API_URL}department/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data?.department;
        let departmentData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  preUpdateDepartment(item._id)
                  toTop()
                }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setDepartmentIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;

          if (item?.itemsup_date)
            item.date = moment(item.itemsup_date).format("DD-MM-YYYY");
          else item.date = moment(item.items_date).format("DD-MM-YYYY");

          if (item?.up_time) {
            item.time = moment(item.up_time, "HHmmss").format("hh:mm a");
          } else {
            item.time = moment(item.time, "HHmmss").format("hh:mm a");
          }

          item.head = item?.designation?.name || "";

          if (item?.updated_by) {
            let values = item?.updated_by || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.added_by || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          }

          departmentData.push(item);
          return item;
        });
        setDepartmentForTable(departmentData);
      });
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  
  function fetch_all_DesiType() {
    axios
      .get(`${API_URL}options/designation`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var DesigType_data =
          res.data.data &&
          res.data.data?.map((item) => {
            return {
              label: item.name,
              value: item._id,
            };
          });
        setDesiType(DesigType_data); 
      })
      .catch((err) => {
        console.error("Error fetching designation data", err);
      });
  }
  
  

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "designation":
        setSelectedDesi(selected); 
        setDepartmentObject({
          ...departmentObject,
          designation: selected.value, 
        });
        break;

      default:
        break;
    }
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Department",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Code",
        field: "shortCode",
        sort: "asc",
        width: 150,
      },
      {
        label: "Head",
        field: "head",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: departmentForTable,
  };

  function preUpdateDepartment(id) {
    setDepartmentIdToBeUpdated(id);
    get(`department/single/${id}`)
      .then(res => {
        const data = res.department;
        
        setDepartmentObject({
          ...data,
          _id: id
        });
        
        if (data?.designation) {
          setSelectedDesi({
            label: data.designation.name,
            value: data.designation._id,
          });
        }
      })
      .catch(err => {
        console.error("Error fetching department:", err);
      });
  }
  
  const clearForm = () => {
    formRef.current.reset();
    setDepartmentObject({});
    setDepartmentIdToBeUpdated(null);
    setDepartmentValue("");
    setSelectedDesi(null); 
  };
  


  const handleChangeDepartment = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setDepartmentValue(value);
    setDepartmentObject({ ...departmentObject, [name]: value });
  };

  // const handleValidSubmit = () => {
  //   if (departmentIdTobeUpdated) {
  //     const updateData = {
  //       ...departmentObject,
  //       _id: departmentIdTobeUpdated
  //     };
  
  //     axios
  //       .put(`${API_URL}department/${departmentIdTobeUpdated}`, updateData, {
  //         headers: {
  //           "x-access-token": accessToken,
  //         },
  //       })
  //       .then((res) => {
  //         if (res.data.code === 200) {
  //           toastr.success("Department updated successfully");
  //           setDepartmentObject({});
  //           setDepartmentIdToBeUpdated(null); // Reset the update ID
  //           clearForm();
  //           handleTableData();
  //         } else {
  //           toastr.error("Failed to update Department");
  //         }
  //       })
  //       .catch((err) => {
  //         toastr.error(err.response?.data?.message || "Error updating department");
  //       });
  //   } else {
  //     axios
  //       .post(`${API_URL}department`, departmentObject, {
  //         headers: {
  //           "x-access-token": accessToken,
  //         },
  //       })
  //       .then((res) => {
  //         if (res.data.code === 200) {
  //           toastr.success("Department added Successfully");
  //           clearForm();
  //           handleTableData();
  //         } else {
  //           toastr.error("Failed to add Department");
  //           return;
  //         }
  //       })
  //       .catch((err) => {
  //         toastr.error(err.response.data.message);
  //         return;
  //       });
  //   }
  // };

  const handleValidSubmit = async () => {
    // If already loading, prevent multiple submissions
    if (isLoading) return;
  
    try {
      setIsLoading(true);
  
      if (departmentIdTobeUpdated) {
        const updateData = {
          ...departmentObject,
          _id: departmentIdTobeUpdated
        };
    
        const res = await axios.put(
          `${API_URL}department/${departmentIdTobeUpdated}`, 
          updateData, 
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        );
        
        if (res.data.code === 200) {
          toastr.success("Department updated successfully");
          setDepartmentObject({});
          setDepartmentIdToBeUpdated(null);
          clearForm();
          handleTableData();
        } else {
          toastr.error("Failed to update Department");
        }
      } else {
        const res = await axios.post(
          `${API_URL}department`, 
          departmentObject, 
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        );
        
        if (res.data.code === 200) {
          toastr.success("Department added Successfully");
          clearForm();
          handleTableData();
        } else {
          toastr.error("Failed to add Department");
        }
      }
    } catch (err) {
      toastr.error(err.response?.data?.message || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  const customStyles = {
    lineHeight: "1.8",
  };

  const reset = () => {
    formRef.current.reset();
    clearForm();
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
            .delete(`${API_URL}department/${departmentIdToBeDeleted}`, {
              headers: {
                "x-access-token": accessToken,
              },
            })
              .then((res) => {
                if (res.data.code === 200) {
                  toastr.success("Department deleted successfully");
                  if (
                    departmentObject &&
                    departmentObject._id === departmentIdToBeDeleted
                  ) {
                    clearForm();
                  }
                  setDepartmentIdToBeDeleted(null);
                  handleTableData();
                } else {
                  toastr.error(res.data.message, "Failed to delete Department");
                  return;
                }
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Department" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Department Name<span className="text-danger"> *</span>
                          </Label>
                          <AvField
                            style={customStyles}
                            name="name"
                            placeholder="Department Name"
                            type="text"
                            errorMessage="Enter Department Name"
                            className="form-control"
                            validate={{ 
                              required: { value: true },
                              pattern: { 
                                value: '^(?!^[0-9]+$)[a-zA-Z0-9 ]+$',
                                errorMessage: "Only letters or a combination of letters and numbers are allowed" 
                              }  
                            }}
                            id="validationCustom02"
                            value={departmentObject?.name || ""} 
                            onChange={handleChangeDepartment}
                          />
                        </div>
                      </Col>

                       <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Designation Head
                          </Label>
                          <Select
                            name="designation"
                            errorMessage="Select Designation Head"
                              validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={selectedDesi} 
                            onChange={selected => {
                              handleSelectChange(selected, "designation")
                              setSelectedDesi(selected)
                            }}  options={desiType}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Short Code<span className="text-danger"> *</span>
                          </Label>
                          <AvField
                        style={customStyles}
                       name="shortCode"
                       placeholder="Short Code"
                       className="form-control"
                      id="validationCustom02"
                      value={departmentObject?.shortCode || ""} 
                      onChange={handleChangeDepartment}
                      errorMessage="Enter Short Code (only letters and numbers allowed)"
                      validate={{
                        required: { value: true, errorMessage: "Short Code is required" },
                        pattern: { value: '^[a-zA-Z0-9]+$', errorMessage: "Only letters and numbers are allowed" },
                      }}
                           />

                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-2" style={{ paddingTop: "25px" }}>
                          {departmentIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              style={{ marginRight: "5%" }}
                              disabled={isLoading}
                            >
                              {isLoading ? "Updating..." : "Update"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              style={{ marginRight: "5%" }}
                              disabled={isLoading}
                            >
                              {isLoading ? "Submitting..." : "Submit"}
                            </Button>
                          )}
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            {" "}
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="departmentTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Department;
