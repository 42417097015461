const columns = [
  {
    label: "#",
    field: "id",
    sort: "asc",
    width: 50,
  },
  {
    label: "Date",
    field: "date1",
    sort: "asc",
    width: 50,
  },
  {
    label: "Broadcast Name",
    field: "broadcastName",
    sort: "asc",
    width: 200,
  },
  {
    label: "Template Name",
    field: "templateName",
    sort: "asc",
    width: 200,
  },
  {
    label: "Template Category",
    field: "templateCategory",
    sort: "asc",
    width: 200,
  },
  {
    label: "Contact Category",
    field: "contactCategory",
    sort: "asc",
    width: 200,
  },
  {
    label: "Send",
    field: "processing",
    sort: "asc",
    width: 50,
  },
  {
    label: "Failed",
    field: "failed",
    sort: "asc",
    width: 50,
  },
]

export const sent = {
  columns: [
    ...columns,
    {
      label: "Actions",
      field: "action",
      sort: false,
      width: 100,
    },
  ],
  rows: [],
}

export const schedule = {
  columns: [
    ...columns,
    {
      label: "Schedule",
      field: "scheduleDisplay",
      sort: "asc",
      width: 200,
    },
    {
      label: "Actions",
      field: "action",
      sort: false,
      width: 100,
    },
  ],
  rows: [],
}
