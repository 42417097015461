import React, { useState, useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import { Bar } from 'react-chartjs-2';
import { get } from '../../../../helpers/api_helper';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import Select from 'react-select';
const ResponseGraphReport = () => {
  const API_URL = process.env.REACT_APP_APIURL + 'response/v2/graph/response';
  const [filter, setFilter] = useState('week');
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [loading, setLoading] = useState(false);
  const options = [
    { value: 'week', label: 'Weekly' },
    { value: 'month', label: 'Monthly' }
  ];

  const handleChange = (selectedOption) => {
    setFilter(selectedOption.value);
  };
  const fetchGraphData = async () => {
    setLoading(true);
    try {
      const response = await get(`${API_URL}?filter=${filter}`);
      if (response.success && response.data) {
        const labels = filter === 'week' 
          ? response.data.weekWise.map(item => `Week ${item._id}`)
          : response.data.monthWise.map(item => `${item._id.month}/${item._id.year}`);
        
        const counts = filter === 'week' 
          ? response.data.weekWise.map(item => item.count)
          : response.data.monthWise.map(item => item.count);
        
        setChartData({
          labels,
          datasets: [{
            label: 'Response Count',
            data: counts,
            backgroundColor: '#42A5F5',
            borderColor: 'rgba(255, 255, 255, 0.5)',
            borderWidth: 1,
            barThickness: 40,
            borderRadius: 4
          }]
        });
      }
    } catch (error) {
      console.error('Error fetching graph data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGraphData();
  }, [filter]);

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Breadcrumbs title='Home' breadcrumbItem='Response Graph Report' />
        <Card>
          <CardBody>
            <div className='d-flex justify-content-between mb-4' style={{ justifyContent: 'flex-end' }}>

            <Select
                    value={options.find(option => option.value === filter)}
                    onChange={handleChange}
                    options={options}
                    isClearable={false} 
                    // placeholder="Select Period"
 />
              
              {/* <select className='form-select' value={filter} onChange={(e) => setFilter(e.target.value)}>
                <option value='week'>Weekly</option>
                <option value='month'>Monthly</option>
              </select> */}
            </div>
            <div style={{ height: '400px' }}>
              {loading ? (
                <div className='d-flex justify-content-center align-items-center h-100'>
                  <div className='spinner-border text-primary' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                </div>
              ) : (
                <Bar data={chartData} options={{ responsive: true, maintainAspectRatio: false }} />
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default ResponseGraphReport;
