import React, { lazy, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import "./styles.scss"
import axios from "axios"
import { API_URL } from "../../config"
import { Spinner } from "reactstrap"

const LeadDashboard = lazy(() => import("./LeadDashboard"))
const ResponseDashboard = lazy(() => import("./ResponseDashboard"))
const FeedBackDashboard = lazy(()=> import("./FeedBackDashboard"))
const GMBDashboardTotal = lazy(()=> import("./GMBDashboardTotal"))
const GMBDashboardMonthly = lazy(()=> import("./GMBDashboardMonthly"))
const ListeningDashboard = lazy(() => import("./ListeningDashboard"))

const pageCount = 6

const TvScreen = () => {
  const [searchParams] = useSearchParams()
  const session = searchParams.get("session")

  const [screens, setScreens] = useState(1) // 1 = leads, 2 = response
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    if (!session) return

    axios
      .get(`${API_URL}tv`, {
        headers: {
          "x-access-token": session,
        },
      })
      .then(() => setIsValid(true))
      .catch(err => {
        console.error(err?.response?.data || err?.message)
        setIsValid(false)
      })
  }, [session])

  useEffect(() => {
    const interval = setInterval(() => {
      setScreens(prevScreen => (prevScreen === pageCount ? 1 : prevScreen + 1))
    }, 15000)

    return () => clearInterval(interval)
  }, [])

  const errorStyle = {
    display: "flex",
    justifyContent: "center",
    height: "100vh",
    alignItems: "center",
  }

  if (!session) return <div style={errorStyle}>Session Invalid</div>

  if (!isValid)
    return (
      <div style={errorStyle}>
        <Spinner />
      </div>
    )

  const renderScreen = () => {
      switch (screens) {
        case 1:
          return <LeadDashboard token={session} />
        case 2:
          return <FeedBackDashboard token={session} />
        case 3:
          return <ResponseDashboard token={session} />
        case 4:
          return <GMBDashboardTotal token={session} />
        case 5:
          return <GMBDashboardMonthly token={session} />
        case 6:
          return <ListeningDashboard token={session} />
        default:
          return null
      }
    // return <FeedBackDashboard token={session} />
  }

  return <>{renderScreen()}</>
}

export default TvScreen
