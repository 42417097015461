import React, { createContext, useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, Col } from "reactstrap";
import playstore from "../../../assets/fotos/Group.svg"
import google from "../../../assets/fotos/Group 8.svg"
import apple from "../../../assets/fotos/_Group_3.svg"
import business from "../../../assets/fotos/Group 31.svg"
import bing from "../../../assets/fotos/Group 35.svg"
import comment from "../../../assets/fotos/Group 36.svg"
import star from "../../../assets/fotos/Group 37.svg"
import toastr from "toastr"
import LeadModal from "./LeadModal";
import stars from "../../../assets/fotos/Star 1.svg"
import calender from "../../../assets/fotos/Group 44.svg"
// import srv1 from "../../../assets/fotos/Rectangle 7.svg"
// import srv2 from "../../../assets/fotos/Rectangle 8.svg"
// import srv3 from "../../../assets/fotos/Rectangle 9.svg"
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown"
import _ from "lodash"

// import Reviewbar from "./Reviewbar"

import "./Reviews.scss"
import { Link, useNavigate } from "react-router-dom"
// import axios from "axios"
// import accessToken from "../../../helpers/jwt-token-access/accessToken"
import moment from "moment"
import { get, put } from "../../../helpers/api_helper"
import { Pagination } from "@mui/material"
import qs from "query-string"
import Select from "react-select"
// const ReviewsContext = createContext(null);

const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/"

function Reviews() {
  // const [main, setMain] = useState(1)
  const [reviews, setReviews] = useState([])
  const [sortBy, setSortBy] = useState(1)
  const [viewModal, setViewModal] = useState({ show: false, id: null })
  const [locationOptions, setLocationOptions] = useState([])

  const navigate = useNavigate()

  const [pagination, setPagination] = useState({ page: 1, limit: 40 })
  const [count, setCount] = useState(0)

  // source = ""
  const [master, setMaster] = useState({ source: "all" })

  const [formData, setFormData] = useState({
    reviewerName: "",
    comments: "",
    source: "",
    sub_category: "",
    department:""
  });
 const [sourceOptions, setSourceOptions] = useState([])
  const [selectedSourceOption, setSelectedSourceOption] = useState(null)

  const [typeOptions, setTypeOptions] = useState([])
  const [departmentOptions,setDepartmentOptions] = useState([])
  const [selectedTypeOption, setSelectedTypeOption] = useState(null)
  const [leadModal, setLeadModal] = useState({
    isOpen: false,
    reviewData: null
  });

  useEffect(() => {
    handleTableData()
  }, [sortBy, pagination, master])

  useEffect(() => {
    getLocations()
  }, [])

  function handleSourceClick(source) {
    setMaster(pre => ({ ...pre, source }))
  }

  function handleTableData() {
    var url = `${API_URL}google/reviews`
    get(
      `google/reviews?sortBy=${sortBy}&page=${pagination.page}&limit=${
        pagination.limit
      }&${qs.stringify(master)}`
    ).then(res => {
      let result = res?.data?.reviews
      setCount(res?.data?.count || 0)
      setReviews(result)
    })
  }

  const handleReply = item => {
    navigate("/reviews/replyreview", { state: item ?? null })
  }

  const handleSearch = e => {
    e.preventDefault()
    setMaster(pre => ({ ...pre, key: e.currentTarget.elements.search.value }))
  }

  const handleSelectSentiment = (eventKey, id) => {
    put(`google/reviews/sentiment`, {
      id,
      sentiment: eventKey,
    })
      .then(res => {
        handleTableData()
        toastr.success(res.message)
      })
      .catch(err => {
        console.log(err?.response?.data)
        toastr.error(err?.response?.data?.message || "Something went wrong")
      })
  }

  const handleLeadCreation = (review) => {
    // Get the actual source from the review object
    let actualSource = 'GMB'; // Default to GMB if no source found
    
    if (review.platform) {
      // If review has platform property
      switch(review.platform.toLowerCase()) {
        case 'google':
          actualSource = 'GMB';
          break;
        case 'play store':
        case 'playstore':
          actualSource = 'Play Store';
          break;
        case 'app store':
        case 'appstore':
          actualSource = 'App Store';
          break;
        case 'bing':
          actualSource = 'Bing';
          break;
        default:
          actualSource = review.platform;
      }
    } else if (review.source && review.source !== 'all') {
      // If review has source property and it's not 'all'
      actualSource = review.source;
    }
  
    setLeadModal({
      isOpen: true,
      reviewData: {
        reviewerName: review.reviewerName,
        content: review.content,
        source: actualSource,
        _id: review._id
      }
    });
  };

  const getLocations = () => {
    get("google/active-locations").then(res => {
      setLocationOptions(res.data)
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post("/response/v2/review/replay/response", formData);

  //     console.log("The review reply response",response)
  //     alert(response.data.message || "Response created successfully!");
  //     toggle(); 
  //   } catch (error) {
  //     console.error(error);
  //     alert(error.response?.data?.message || "Failed to create response.");
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const responseData = {
      reviewId: formData.reviewId,
      comments: formData.comments,
      source: formData.source, 
      sub_category: formData.sub_category,
      department:formData.department
    };
    try {
      axios.post(`${API_URL}response/v2/review/replay/response`,responseData).then(res => {
        // alert(res.data.message || "Response created successfully!");
            toggle();
      })
    } catch (error) {
    }
  };

  const toggle = () => {
    setViewModal((prev) => ({
      ...prev,
      show: !prev.show,
    }));
  };
  const getTypeOptions = (sourceId) => {
    console.log("snsr1813sry.......",sourceId)
    console.log("")
    if (!sourceId) return;
    axios.get(`${API_URL}listening/option/type?source=${sourceId}`).then(res => {
      setTypeOptions(res?.data?.data || [])
    })
  }
  const handleViewModal = (reviewId, reviewerName, comments, source, sub_category, show = false) => {
    setViewModal({ show });
    console.log("view modal data",source, sub_category)
    const selectedSource = sourceOptions.find(option => option.value === source) || null;
    setFormData({
      reviewId: reviewId || "",  
      reviewerName: reviewerName || "",
      comments: comments || "",
      source: selectedSource || "",  
      sub_category: typeOptions.find(option => option.value === sub_category) || null || ""
    });
  };

  const getSourceOptions = () => {
    axios.get(`${API_URL}listening/option/source`).then(res => {
      setSourceOptions(res?.data?.data || [])
    })
  }


  


  const getDepartmentOptions = () => {  
    axios.get(`${API_URL}options/department/list`).then(res => {
      setDepartmentOptions(res?.data?.data || [])
    })
  }

  useEffect(() => {
    getSourceOptions(); 
    getDepartmentOptions()  
  }, []);
  useEffect(() => {
    console.log("formData are",formData)
    if (formData.source) {
     
      getTypeOptions(formData.source);
    }
  }, [formData.source]);

  // Transform source and sub-category data into Select options
  // const sourceOptions = sources.map((source) => ({
  //   value: source.value,
  //   label: source.label,
  // }));

  // const subCategoryOptions = subCategories.map((subCategory) => ({
  //   value: subCategory.value,
  //   label: subCategory.label,
  // }));


  return (
    <>
      <div>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Reviews" />
            <div className="reviews">
              <ul className="platforms">
                <li
                  onClick={() => handleSourceClick("all")}
                  className={`${master.source === "all" ? "active" : ""}`}
                >
                  <div>
                    <span className="fa fa-globe"></span>
                    <span className="mainfont">All</span>

                    <ul className="ikon">
                      <li>
                        <img className="subikon" src={comment} alt="" />{" "}
                        <span>{reviews?.totalReviewCount}</span>
                      </li>
                      <li>
                        <img className="subikon" src={star} alt="" />{" "}
                        <span> {reviews?.averageRating}</span>
                      </li>
                    </ul>
                  </div>
                </li>

                <li
                  onClick={() => handleSourceClick("google")}
                  className={`${master.source === "google" ? "active" : ""}`}
                >
                  <div>
                    <span className="fa">
                      <img style={{ height: "20px" }} src={business} alt="" />
                    </span>
                    <span className="mainfont">Google Business</span>

                    <ul className="ikon">
                      <li>
                        <img className="subikon" src={comment} alt="" />
                      </li>
                      <li>
                        <img className="subikon" src={star} alt="" />
                      </li>
                    </ul>
                  </div>
                </li>

                <li
                  onClick={() => handleSourceClick("playstore")}
                  className={`${master.source === "playstore" ? "active" : ""}`}
                >
                  <div>
                    <span className="fa">
                      <img style={{ height: "20px" }} src={playstore} alt="" />
                    </span>
                    <span className="mainfont">Play Store</span>

                    <ul className="ikon">
                      <li>
                        <img className="subikon" src={comment} alt="" />
                        {/* <img className="subikon" src={comment} alt="" /> 371 */}
                      </li>
                      <li>
                        <img className="subikon" src={star} alt="" />
                        {/* <img className="subikon" src={star} alt="" /> 4.9 */}
                      </li>
                    </ul>
                  </div>
                </li>

                <li
                  onClick={() => handleSourceClick("appstore")}
                  className={`${master.source === "appstore" ? "active" : ""}`}
                >
                  <div>
                    <span className="fa">
                      <img style={{ height: "20px" }} src={apple} alt="" />
                    </span>
                    <span className="mainfont">App Store</span>

                    <ul className="ikon">
                      <li>
                        <img className="subikon" src={comment} alt="" />
                      </li>
                      <li>
                        <img className="subikon" src={star} alt="" />
                      </li>
                    </ul>
                  </div>
                </li>

                <li
                  onClick={() => handleSourceClick("bing")}
                  className={`${master.source === "bing" ? "active" : ""}`}
                >
                  <div>
                    <span className="fa">
                      <img style={{ height: "20px" }} src={bing} alt="" />
                    </span>
                    <span className="mainfont">Bing</span>

                    <ul className="ikon">
                      <li>
                        <img className="subikon" src={comment} alt="" />
                      </li>
                      <li>
                        <img className="subikon" src={star} alt="" />
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>

              <div className="reviewbar ">
                <Dropdown className="dropdown0">
                  <Dropdown.Toggle
                    className="Dropdown1"
                    style={{ backgroundColor: "white", color: "#1a71b5" }}
                    variant="info"
                    id="dropdown-basic"
                  >
                    <span class="fa fa-angle-down me-2"></span>
                    {sortBy === 1
                      ? "Newest"
                      : sortBy === 2
                      ? "Highest"
                      : sortBy === 3
                      ? "Lowest"
                      : ""}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setSortBy(1)}>Newest</Dropdown.Item>
                    <Dropdown.Item onClick={() => setSortBy(2)}>Highest</Dropdown.Item>
                    <Dropdown.Item onClick={() => setSortBy(3)}>Lowest</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <div class="input-group">
                  <form className="form-inline" onSubmit={handleSearch} method="post">
                    <div style={{ width: "auto", display: "flex" }}>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search For Reviews."
                        name="search"
                      />
                      <Button
                        color="info"
                        type="submit"
                        style={{
                          marginLeft: "-5px",
                          backgroundColor: "#1a71b5",
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        }}
                      >
                        Search
                      </Button>
                    </div>

                    <div style={{ width: "250px" }} className="ms-3 d-flex flex-column">
                      <Select
                        options={locationOptions}
                        name="location"
                        placeholder="Locations"
                        onChange={e => {
                          setMaster(pre => ({ ...pre, location: e?.value ?? null }))
                        }}
                        isClearable
                      />
                    </div>
                  </form>
                </div>

                {/* <div className='revnextpage' >

                                    <div class="pagination">
                                        <Link to="">&laquo;</Link>
                                        <Link to="" href="#">1</Link>
                                        <Link to="" class="nextpage2" href="#">2</Link>
                                        <Link to="" href="#" className='nextcontinue'>....</Link>
                                        <Link to="" href="#">20</Link>
                                        <Link to="" href="#">&raquo;</Link>
                                    </div>


                                </div>



                                <div className='revpagecontent'>
                                    page <div>0</div> of  20
                                </div> */}

                {/* <div className="revnextpage">
                  <div className="pagination">
                    <li to="#" onClick={() => handlePaginationChange(Math.max(currentPage - 1, 1))}>
                      &laquo;
                    </li>
                    {getPageNumbers().map(pageNumber => (
                      <li
                        to="#"
                        key={pageNumber}
                        onClick={() => handlePaginationChange(pageNumber)}
                        className={`pagination-link ${currentPage === pageNumber ? "active" : ""}`}
                      >
                        {pageNumber}
                      </li>
                    ))}
                    <li
                      to="#"
                      onClick={() => handlePaginationChange(Math.min(currentPage + 1, totalPages))}
                    >
                      &raquo;
                    </li>
                  </div>
                </div>

                <div className="revpagecontent">
                  Page <div>{currentPage}</div> of {totalPages}
                </div>

                <div className="revpagelist">
                  <div>Show items</div>

                  <Dropdown className="">
                    <Dropdown.Toggle
                      className="Dropdown1"
                      style={{ backgroundColor: "white", color: "#1a71b5" }}
                      variant="info"
                      id="dropdown-basic"
                    >
                      <span class="fa fa-angle-down"></span> 0
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div> */}
              </div>
            </div>

            <>
              <div className="reviewbox">
                {reviews.length > 0 ? (
                  reviews?.map(el => {
                    const starsArray = Array.from(
                      { length: el.rating },

                      (_, index) => (
                        <img
                          key={index}
                          style={{ height: "18px", paddingBottom: "4px" }}
                          src={stars}
                          alt=""
                        />
                      )
                    )
                    return (
                      <div className="revlist ">
                        <div className="revhead">
                          <div className="captions">
                            <div className="profile">
                              <span class="fab ">{el.reviewerName[0].toUpperCase()}</span>
                              <i>
                                <img src={google} alt="" />
                              </i>
                            </div>

                            <div className="revname">
                              <div className="d-flex">
                                <div className="revtitle">{el.reviewerName}</div>
                                {/* <span>20 reviews 3 photos</span> */}
                                <div style={{ marginLeft: "5px" }}>{starsArray}</div>
                              </div>

                              <div className="revcontent">
                                <div>{el.content}</div>
                              </div>
                            </div>
                          </div>

                          <div style={{ display: "flex", gap: "5px" }} className="date">
                            <span class="fa ">
                              {" "}
                              <img src={calender} alt="" />{" "}
                            </span>
                            <span style={{ whiteSpace: "nowrap" }}>
                              {moment(el.createTime).format("hh:mm A, DD MMM YYYY")}
                            </span>
                          </div>

                          <div className="sentiment">
                            <Dropdown
                              onSelect={eventKey => handleSelectSentiment(eventKey, el?._id)}
                            >
                              <Dropdown.Toggle
                                className="butn1 dropdown1"
                                style={{ backgroundColor: "white", color: "#1a71b5" }}
                                variant="info"
                                id="dropdown-basic"
                              >
                                {el?.sentiment && _.capitalize(el?.sentiment)}{" "}
                                <span className="fa fa-angle-down"></span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item eventKey="neutral">Neutral</Dropdown.Item>
                                <Dropdown.Item eventKey="positive">Positive</Dropdown.Item>
                                <Dropdown.Item eventKey="negative">Negative</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>

                        {el?.reviewReply?.comment && (
                          <div className="own-reply">
                            <div className="inner-reply">
                              <div className="reply-title">
                                <b> Response from the owner</b>
                                <span className="date">
                                  &nbsp;
                                  {moment(el?.reviewReply?.updateTime).endOf("day").fromNow()}
                                </span>
                              </div>
                              <span>{el?.reviewReply?.comment || ""}</span>
                            </div>
                          </div>
                        )}

                        <div className="revfooter">
                          <div className="revtool">
                            {/* <div>
                   
                              <span className="far fa-thumbs-up"></span> Like
                            </div> */}
                            <span onClick={() => handleReply(el)} className="cursor-pointer p-1">
                              <span className="fas fa-reply"></span> Reply
                            </span>
                            {/* <div>
                              <span className="far fa-edit"></span> Edit
                            </div> */}
                          </div>

                          <div className="revtool">
                            <div onClick={() => handleLeadCreation(el)} className="cursor-pointer">
                              <span className="fas fa-plus-circle"></span> Lead
                            </div>
                            <div onClick={() =>
                               handleViewModal(el._id, el.reviewerName, el.content, el.source, el.sub_category, true)}>
                              <span className="fas fa-plus-circle"></span> Response
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div className="d-flex justify-content-center align-items-center py-4">
                    No reviews found.
                  </div>
                )}
              </div>

              <div className="d-flex justify-content-center py-2 pb-4">
                <Pagination
                  page={pagination.page}
                  count={Math.ceil(count / pagination.limit)}
                  shape="rounded"
                  size="small"
                  onChange={(e, value) => setPagination(pre => ({ ...pre, page: value }))}
                />
              </div>
            </>
          </div>
        </div>
      </div>
      <Modal isOpen={viewModal.show} toggle={toggle}>
      <ModalHeader toggle={toggle}>Response</ModalHeader>
      <Form onSubmit={handleSubmit}>
        <ModalBody>
          <FormGroup>
            <Label for="reviewerName">Name</Label>
            <Input
              type="text"
              id="reviewerName"
              name="reviewerName"
              value={formData.reviewerName}
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <Label for="comments">Comments</Label>
            <Input
              type="textarea"
              id="comments"
              name="comments"
              value={formData.comments}
              onChange={handleChange}
              placeholder="Enter your comments"
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="source">Source</Label>
            <Select
              id="source"
              name="source"
              value={sourceOptions.find(option => option.value === formData.source)|| formData.source} 
              onChange={(selectedOption) => handleChange({ target: { name: 'source', value: selectedOption.value } })}
              options={sourceOptions}
              getOptionLabel={(e) => e.label} 
              getOptionValue={(e) => e.value} 
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="sub_category">Sub Category</Label>
            <Select
              id="sub_category"
              name="sub_category"
              value={typeOptions.find(option => option.value === formData.sub_category) ||formData.sub_category} 
              onChange={(selectedOption) => handleChange({ target: { name: 'sub_category', value: selectedOption.value } })}
              options={typeOptions}
              getOptionLabel={(e) => e.label} 
              getOptionValue={(e) => e.value} 
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="department">Department</Label>
            <Select
              id="department"
              name="department"
              value={departmentOptions.find(option => option.value === formData.department)} 
              onChange={(selectedOption) => handleChange({ target: { name: 'department', value: selectedOption.value } })}
              options={departmentOptions}
              getOptionLabel={(e) => e.label} 
              getOptionValue={(e) => e.value} 
              required
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary">
            Submit
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
    <LeadModal 
      isOpen={leadModal.isOpen}
      toggle={() => setLeadModal({ isOpen: false, reviewData: null })}
      reviewData={leadModal.reviewData}
    />

    </>
  )
}
// export { ReviewsContext }
export default Reviews
