import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Card, CardBody, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, CardHeader } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import productCategoryService from "../../../services/productCategoryService";
import moment from "moment";
import toastr from "toastr";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";

const ProductCategory = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [formData, setFormData] = useState({
    category: "",
    code: "",
    parentCategory: null
  });
  const [isEditing, setIsEditing] = useState(false);
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});
  const [parentCategories, setParentCategories] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const columns = [
    {
      label: "#",
      field: "index",
      sort: "asc",
      width: 50,
    },
    {
      label: "Date",
      field: "date",
      sort: "asc",
      width: 100,
    },
    {
      label: "Category",
      field: "category",
      sort: "asc",
      width: 150,
    },
    {
      label: "Code",
      field: "code",
      sort: "asc",
      width: 100,
    },
    {
      label: "Parent Category",
      field: "parentCategory",
      sort: "asc",
      width: 150,
    },
    {
      label: "Actions",
      field: "actions",
      sort: false,
      width: 100,
    },
  ];

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const response = await productCategoryService.getCategories();
      setCategories(response.categories || []);

      const options = response.categories.map(cat => ({
        value: cat.category,
        label: cat.category
      }));
      setParentCategories(options);

    } catch (error) {
      console.error('Error fetching categories:', error);
      toastr.error(error.message || "Error fetching categories");
      setCategories([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    
    if (touched[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: validateField(name, value)
      }));
    }
  };

  const handleSelectChange = (selectedOption) => {
    setFormData({
      ...formData,
      parentCategory: selectedOption
    });

    if (touched.parentCategory) {
      setErrors(prev => ({
        ...prev,
        parentCategory: validateField('parentCategory', selectedOption)
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const newErrors = {};
    Object.keys(formData).forEach(key => {
      const error = validateField(key, formData[key]);
      if (error) {
        newErrors[key] = error;
      }
    });
  
    const newTouched = {};
    Object.keys(formData).forEach(key => {
      newTouched[key] = true;
    });
  
    setTouched(newTouched);
    setErrors(newErrors);
  
    if (Object.keys(newErrors).length === 0) {
      try {
        setSubmitting(true);

        const payload = {
          ...formData,
          parentCategory: formData.parentCategory?.value || null
        };

        if (isEditing) {
          await productCategoryService.updateCategory(selectedCategory._id, payload);
          toastr.success("Category updated successfully");
        } else {
          await productCategoryService.createCategory(payload);
          toastr.success("Category created successfully");
        }
        resetForm();
        fetchCategories();
      } catch (error) {
        toastr.error(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  };

  const validateField = (name, value) => {
    switch (name) {
      case 'category':
        if (!value) {
          return 'Name is required';
        } else if (value.length < 2) {
          return 'Name must be at least 2 characters long';
        } else if (value.length > 50) {
          return 'Name cannot exceed 50 characters';
        } else if (/^\d+$/.test(value)) {
          return 'Name cannot contain only numbers';
        } else if (!/^[a-zA-Z0-9\s-]*$/.test(value)) {
          return 'Name can only contain letters, numbers, spaces, and hyphens';
        }
        return '';

      case 'code':
        if (!value) {
        return 'Code is required';
      } else if (value.length < 2) {
        return 'Code must be at least 2 characters long';
      } else if (value.length > 20) {
        return 'Code cannot exceed 20 characters';
      }
      return '';

      case 'parentCategory':
        return;
        
      default:
        return '';
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setTouched(prev => ({
      ...prev,
      [name]: true
    }));
    setErrors(prev => ({
      ...prev,
      [name]: validateField(name, value)
    }));
  };

  const handleView = (category) => {
    setSelectedCategory(category);
    setModalVisible(true);
  };

  const handleEdit = (category) => {
    setFormData({
      category: category.category,
      code: category.code,
      parentCategory: parentCategories.find(option => option.value === category.parentCategory)
    });
    setSelectedCategory(category);
    setIsEditing(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure you want to delete this category?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        productCategoryService.deleteCategory(id)
          .then(res => {
            fetchCategories()
            toastr.success(res.message || "Category deleted successfully");
          })
          .catch(err => {
            toastr.error(err.response?.data?.message || "Error deleting category");
          })
      }
    })
  }

  const resetForm = () => {
    setFormData({
      category: "",
      code: "",
      parentCategory: null
    });
    setIsEditing(false);
    setSelectedCategory(null);
    setTouched({});
    setErrors({});
    setSubmitting(false);
  };

  const tableData = useMemo(() => {
    const rows = categories.map((category, index) => ({
      index: index + 1,
      date: category?.createdAt ? moment(category.createdAt).format("DD-MM-YYYY") : '',
      category: category?.category || '',
      code: category?.code || '',
      parentCategory: parentCategories.find(option => option.value === category?.parentCategory)?.label || 'N/A',
      actions: (
        <div className="d-flex justify-content-center align-items-center gap-3">
          <Button
            color="link"
            className="p-0 me-2"
            onClick={() => handleView(category)}
          >
            <FontAwesomeIcon icon={faEye} color='#3D3D3D' />
          </Button>
          <Button
            color="link"
            className="p-0 me-2"
            onClick={() => handleEdit(category)}
          >
            <FontAwesomeIcon icon={faPenToSquare} color='#3D3D3D' />
          </Button>
          <Button
            color="link"
            className="p-0"
            onClick={() => handleDelete(category._id)}
          >
            <FontAwesomeIcon icon={faTrash} color='#3D3D3D' />
          </Button>
        </div>
      ),
    }));

    return {
      columns,
      rows: rows || []
    };
  }, [categories]);

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Home" breadcrumbItem="Product Category" />
        
        {/* Add/Edit Form */}
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h4 className="card-title mb-3 fw-bold">
                  {isEditing ? "Edit Category" : "Add Category"}
                </h4>
              </CardHeader>
              <CardBody>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>
                          Category<span className="text-danger"> *</span>
                        </Label>
                        <input
                          name="category"
                          className={`form-control ${errors.category && touched.category ? 'is-invalid' : ''}`}
                          type="text"
                          value={formData.category}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          placeholder="Enter Category"
                        />
                        {errors.category && touched.category && (
                          <div className="invalid-feedback">
                            {errors.category}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>
                          Code<span className="text-danger"> *</span>
                        </Label>
                        <input
                          name="code"
                          className={`form-control ${errors.code && touched.code ? 'is-invalid' : ''}`}
                          type="text"
                          value={formData.code}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          placeholder="Enter Code"
                        />
                        {errors.code && touched.code && (
                          <div className="invalid-feedback">
                            {errors.code}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Parent Category</Label>
                        <Select
                          value={formData.parentCategory}
                          onChange={handleSelectChange}
                          options={parentCategories}
                          className="basic-single"
                          classNamePrefix="select"
                          placeholder="Select Parent Category"
                          isClearable
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3" style={{ paddingTop: "28px" }}>
                        <Button color="primary" type="submit" className="me-2" disabled={submitting}>
                          {submitting ? "Processing..." : (isEditing ? "Update" : "Submit")}
                        </Button>
                        <Button color="danger" onClick={resetForm}>
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Category List */}
        <Row>
          <Col className="col-12">
            <Card>
              <CardHeader>
                <h4 className="card-title mb-3 fw-bold">Category List</h4>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div className="text-center">Loading...</div>
                ) : (
                  <MDBDataTable
                    responsive
                    bordered
                    data={tableData}
                    searching={true}
                    paging={true}
                    info={true}
                    noRecordsFoundLabel="No categories found"
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* View Modal */}
        <Modal
          isOpen={modalVisible}
          toggle={() => setModalVisible(!modalVisible)}
          className="modal-dialog-centered"
          size="lg"
        >
          <ModalHeader toggle={() => setModalVisible(false)}>
            <h5 className="mb-0">Category Details</h5>
          </ModalHeader>
          <ModalBody>
            {selectedCategory && (
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="fw-bold">Category:</Label>
                    <p>{selectedCategory.category}</p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="fw-bold">Code:</Label>
                    <p>{selectedCategory.code}</p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="fw-bold">Parent Category:</Label>
                    <p>{parentCategories.find(option => option.value === selectedCategory.parentCategory)?.label || 'N/A'}</p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="fw-bold">Created At:</Label>
                    <p>{moment(selectedCategory.createdAt).format("DD-MM-YYYY")}</p>
                  </div>
                </Col>
              </Row>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModalVisible(false)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default ProductCategory;