import React, { useState, useEffect, useRef } from 'react';
import { get } from '../../../helpers/api_helper';
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import toastr from 'toastr';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment"
import queryString from "query-string"
import { Card, CardHeader, CardTitle, CardBody, Row, Col, Button, ButtonGroup, Label } from 'reactstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const MonthlyFeedbackReport = () => {
  const formRef = useRef()
  const [fetchFeedback, setFetchfeedBack] = useState([])
  const [filterObject, setFilterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})
  const [branchOptions, setBranchOptions] = useState([])
  const [departmentOptions, setDepartmentOptions] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const [subCategoryOptions, setSubCategoryOptions] = useState([])
  const [feedbackFormOption, setFeedbackFormOptions] = useState([])
  const [disableButton, setDisableButton] = useState(false)
  const [disableReset, setDisableReset] = useState(true)
  const [triggerRefresh, setTriggerRefresh] = useState(false)
  const [monthlyData, setMonthlyData] = useState([])
  const [selectedYear, setSelectedYear] = useState(null)
  const [yearOptions, setYearOptions] = useState([])
  const [monthOptions, setMonthOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [exportProgress, setExportProgress] = useState(0)

  const chartContainerRef = useRef(null);

  const generateYearOptions = () => {
    const startYear = 2020;
    const currentYear = new Date().getFullYear();
    const years = [];
    
    for (let year = currentYear; year >= startYear; year--) {
      years.push({
        value: year.toString(),
        label: year.toString()
      });
    }
    setYearOptions(years);
  };

  const handleMonthChange = (selectedOption, action) => {
    const fieldName = action.name;
    const newObj = { ...filterObject };
    
    if (selectedOption) {
      newObj[fieldName] = selectedOption.value;
    } else {
      delete newObj[fieldName];
    }
    
    setFilterObject(newObj);
    
    // Update selected fields state for React Select
    setSelectedFields(prev => ({
      ...prev,
      [fieldName]: selectedOption
    }));
  };

  const handleYearChange = (option) => {
    setSelectedYear(option);
    const newObj = { ...filterObject };
    if (option) {
      newObj.year = option.value;
    } else {
      delete newObj.year;
    }
    setFilterObject(newObj);
  };

  useEffect(() => {
    const options = [];
    const currentDate = moment();
    const startDate = moment().subtract(11, 'months'); // Last 12 months including current month
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
  
    while (startDate.isSameOrBefore(currentDate, 'month')) {
      const year = startDate.year();
      const monthIndex = startDate.month(); // 0-indexed
      const monthName = months[monthIndex];
      options.push({
        value: `${year}-${String(monthIndex + 1).padStart(2, '0')}`,
        label: `${monthName} ${year}`
      });
      startDate.add(1, 'month');
    }
  
    // setMonthOptions(options.reverse()); // Ensure latest month appears first
    setMonthOptions(options);

    // Set default date range
    setFilterObject(prev => ({
      ...prev,
      fromDate: options[0].value,
      toDate: options[options.length - 1].value,
    }));
  
    setSelectedFields(prev => ({
      ...prev,
      fromDate: options[0],
      toDate: options[options.length - 1],
    }));
  }, []);  

  async function fetchTableData() {
    if(filterObject.fromDate || filterObject.toDate){
      if(filterObject.fromDate > filterObject.toDate || filterObject.fromDate === null || filterObject.toDate === null){
        toastr.error('Invalid Date Range');
        return;
      }
    }

    const apiFilterObject = { ...filterObject };
    if (selectedYear) {
      apiFilterObject.year = selectedYear.value;
    }

    const filterString = queryString.stringify(apiFilterObject);

    try {
      const response = await get(`/feedback/month-report?${filterString}`);
      if (response.success) {
        const processedData = processMonthlyData(response.data);
        setMonthlyData(processedData);
        setFetchfeedBack(response.data);
        
        setDisableButton(true);
        setDisableReset(!!filterObject.form || !!filterObject.fromDate || !!filterObject.toDate);
        setTriggerRefresh(!!filterObject.form || !!filterObject.fromDate || !!filterObject.toDate);
      }
    } catch (error) {
      toastr.error('Error fetching data');
      console.error(error);
    }
  }

  const processMonthlyData = (data) => {
    if (!data || !Array.isArray(data)) return [];
    
    // Generate months between fromDate and toDate
    const generateFilteredMonths = () => {
      const months = [];
      const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      
      if (filterObject.fromDate && filterObject.toDate) {
        const startDate = moment(filterObject.fromDate);
        const endDate = moment(filterObject.toDate);
        let currentDate = startDate.clone();

        while (currentDate <= endDate) {
          months.push(`${monthNames[currentDate.month()]} ${currentDate.year()}`);
          currentDate.add(1, 'month');
        }
      } else {
        // If no date range selected, show all months of selected year
        const currentYear = selectedYear ? selectedYear.value : new Date().getFullYear();
        monthNames.forEach(month => {
          months.push(`${month} ${currentYear}`);
        });
      }
      
      return months;
    };

    const filteredMonths = generateFilteredMonths();
    
    return data.map(form => {
      let questionNumber = 0;
      const processedQuestions = form.questions.map(questionData => {
        questionNumber += 1;
        // Create a map of existing data
        const monthDataMap = {};
        questionData.monthWiseData.forEach(monthData => {
          monthDataMap[monthData.monthName] = monthData;
        });
        
        // Generate data only for filtered months
        return filteredMonths.map(monthName => {
          const existingData = monthDataMap[monthName] || { sentiments: {} };
          
          if (questionData.type === 0) {
            return {
              month: monthName,
              question: `Q${questionNumber}. ${questionData.question}`,
              questionType: questionData.type,
              formName: form.formName,
              yes: existingData.sentiments.Yes || 0,
              no: existingData.sentiments.No || 0
            };
          } else {
            return {
              month: monthName,
              question: `Q${questionNumber}. ${questionData.question}`,
              questionType: questionData.type,
              formName: form.formName,
              excellent: existingData.sentiments.Excellent || 0,
              best: existingData.sentiments.Best || 0,
              good: existingData.sentiments.Good || 0,
              average: existingData.sentiments.Average || 0,
              needs_improvement: existingData.sentiments["Needs Improvement"] || 0
            };
          }
        });
      });
      
      return {
        formName: form.formName,
        questions: processedQuestions.flat()
      };
    });
  };
  
  const createLoadingOverlay = () => {
    const loadingElement = document.createElement('div');
    loadingElement.style.position = 'fixed';
    loadingElement.style.top = '50%';
    loadingElement.style.left = '50%';
    loadingElement.style.transform = 'translate(-50%, -50%)';
    loadingElement.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
    loadingElement.style.color = 'white';
    loadingElement.style.padding = '20px 40px';
    loadingElement.style.borderRadius = '8px';
    loadingElement.style.zIndex = '9999';
    loadingElement.style.textAlign = 'center';
    loadingElement.innerHTML = `
      <div style="margin-bottom: 15px;">
        <i class="fa fa-spinner fa-spin" style="font-size: 24px;"></i>
      </div>
      <div style="font-size: 16px; margin-bottom: 10px;">
        Please wait while we prepare your report...
      </div>
      <div style="font-size: 14px; color: #ccc;">
        This may take a few minutes depending on the report size
      </div>
      <div style="font-size: 14px; margin-top: 10px; color: #4CAF50;">
        Progress: <span id="export-progress">0</span>%
      </div>
    `;
    document.body.appendChild(loadingElement);
    
    return {
      updateProgress: (progress) => {
        const progressElement = document.getElementById('export-progress');
        if (progressElement) {
          progressElement.textContent = progress;
        }
      },
      remove: () => {
        document.body.removeChild(loadingElement);
      }
    };
  };

  // Function to export as PDF
  const exportToPDF = async () => {
    setIsLoading(true);
    setExportProgress(0);
    const loadingOverlay = createLoadingOverlay();

    if (!monthlyData.length || !chartContainerRef.current) {
      toastr.error('No data available to export');
      setIsLoading(false);
      return;
    }

    try {
      toastr.info('Preparing PDF export, please wait...');
      
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'a4',
        compress: true
      });

      const pageWidth = pdf.internal.pageSize.width;
      const pageHeight = pdf.internal.pageSize.height;
      const margin = 15;
      let yPos = margin;

      // Initial setup for header
      pdf.setFontSize(18);
      pdf.text('Monthly Feedback Report', pageWidth / 2, yPos, { align: 'center' });
      yPos += 15;

      // Add filters more compactly
      if (Object.keys(filterObject).length > 0) {
        const filters = [
          ['Branch', selectedFields.branch?.label],
          ['Department', selectedFields.department?.label],
          ['Category', selectedFields.category?.label],
          ['Sub-Category', selectedFields.subCategory?.label],
          ['Form', selectedFields.form?.label],
          ['Year', filterObject.year],
          ['From Date', filterObject.fromDate],
          ['To Date', filterObject.toDate]
        ].filter(([_, value]) => value);

        pdf.setFontSize(10);
        let filterText = filters.map(([key, value]) => `${key}: ${value}`).join(' | ');
        
        // Split long filter text into multiple lines if needed
        const maxWidth = pageWidth - (2 * margin);
        const splitText = pdf.splitTextToSize(filterText, maxWidth);
        pdf.text(splitText, margin, yPos);
        yPos += (splitText.length * 5) + 10;
      }

      // Prepare all chart data first
      const allCharts = [];
      let totalCharts = 0;

      for (const formData of monthlyData) {
        const uniqueQuestions = Array.from(new Set(formData.questions.map(q => q.question)))
          .map(question => ({
            formName: formData.formName,
            question,
            questionType: formData.questions.find(q => q.question === question).questionType,
            data: formData.questions.filter(q => q.question === question)
          }));
        
        allCharts.push(...uniqueQuestions);
        totalCharts += uniqueQuestions.length;
      }

      // Process charts in batches
      const BATCH_SIZE = 3; // Process 3 charts at a time
      for (let i = 0; i < allCharts.length; i += BATCH_SIZE) {
        const batch = allCharts.slice(i, Math.min(i + BATCH_SIZE, allCharts.length));
        
        // Process each batch in parallel
        const batchPromises = batch.map(async (chart, batchIndex) => {
          const chartIndex = i + batchIndex;
          const chartElement = chartContainerRef.current.querySelectorAll('.mb-5')[chartIndex];
          
          if (!chartElement) return null;

          const canvas = await html2canvas(chartElement.querySelector('.recharts-wrapper'), {
            scale: 0.8, // Reduced scale for better performance
            logging: false,
            useCORS: true,
            allowTaint: true,
            backgroundColor: '#ffffff',
            imageTimeout: 0
          });

          return {
            canvas,
            formName: chart.formName,
            question: chart.question
          };
        });

        const batchResults = await Promise.all(batchPromises);

        // Add batch results to PDF
        for (const result of batchResults.filter(Boolean)) {
          if (yPos > pageHeight - 50) {
            pdf.addPage();
            yPos = margin;
          }

          // Add form name if it's changed
          if (!pdf.previousFormName || pdf.previousFormName !== result.formName) {
            pdf.setFontSize(14);
            pdf.text(`Form: ${result.formName}`, margin, yPos);
            yPos += 10;
            pdf.previousFormName = result.formName;
          }

          // Add question
          pdf.setFontSize(12);
          const questionText = pdf.splitTextToSize(result.question, pageWidth - (2 * margin));
          pdf.text(questionText, margin, yPos);
          yPos += (questionText.length * 5) + 5;

          // Calculate image dimensions
          const imgWidth = pageWidth - (2 * margin);
          const imgHeight = (result.canvas.height * imgWidth) / result.canvas.width;

          // Check if chart needs a new page
          if (yPos + imgHeight > pageHeight - margin) {
            pdf.addPage();
            yPos = margin;
          }

          // Add chart image
          pdf.addImage(
            result.canvas.toDataURL('image/jpeg', 0.6),
            'JPEG',
            margin,
            yPos,
            imgWidth,
            imgHeight,
            undefined,
            'FAST'
          );

          yPos += imgHeight + 15;

          // Update progress
          const progress = Math.round(((i + batchResults.indexOf(result) + 1) / totalCharts) * 100);
          setExportProgress(progress);
          loadingOverlay.updateProgress(progress);

          // Clean up canvas
          result.canvas.remove();
        }
      }

      pdf.save('Monthly_Feedback_Report.pdf');
      loadingOverlay.remove();
      toastr.success('PDF exported successfully');
    } catch (error) {
      console.error('PDF export error:', error);
      toastr.error('Error exporting PDF');
      loadingOverlay.remove();
    } finally {
      setIsLoading(false);
      setExportProgress(0);
    }
  };

  const fetchBranchOptions = () => {
    get(`/options/branches`).then(res => {
      setBranchOptions(res.data)
    }).catch(err => { console.log(err) })
  }

  const fetchDepartmentOptions = () => {
    get(`/options/lead-departments`).then(res => {
      setDepartmentOptions(res.data)
    }).catch(err => { console.log(err) })
  }

  const fetchCategoryOptions = (obj) => {
    get(`/options/fb-category?branch=${obj?.branch}`).then(res => {
      setCategoryOptions(res.data)
    }).catch(err => { console.log(err) })
  }

  const fetchSubCategoryOptions = (obj) => {
    get(`/options/fb-sub-category?branch=${obj?.branch}&category=${obj?.category}`).then(res => {
      setSubCategoryOptions(res.data)
    }).catch(err => { console.log(err) })
  }

  const fetchFeedbackForms = (obj) => {
    // Build query params based on selected filters
    const params = new URLSearchParams();
    if (obj?.branch) params.append('branch', obj.branch);
    if (obj?.category) params.append('category', obj.category);
    if (obj?.subCategory) params.append('subCategory', obj.subCategory);
    
    // Make API call with filters
    get(`/options/fb-form?${params.toString()}`).then(res => {
      setFeedbackFormOptions(res.data);
    }).catch(err => {
      console.log(err);
      toastr.error('Error fetching feedback forms');
    });
  };

  // const fetchFeedbackForms = (obj) => {
  //   get(`/options/fb-form?branch=${obj?.branch}&category=${obj?.category}&subCategory=${obj?.subCategory}`).then(res => {
  //     setFeedbackFormOptions(res.data)
  //   }).catch(err => { console.log(err) })
  // }

  useEffect(() => {
    generateYearOptions()
    fetchBranchOptions()
    fetchDepartmentOptions()
    fetchCategoryOptions()
    fetchSubCategoryOptions()
    fetchFeedbackForms()
  }, [])

  // useEffect(() => {
  //   fetchTableData()
  // }, [filterObject])

  useEffect(() => {
    if (filterObject.fromDate && filterObject.toDate) {
      fetchTableData();
    }
  }, [filterObject]);
  

  const handleSelectChange = (e, name) => {
    const newObj = {...selectedFields};
    
    if (name === 'form') {
      // Clear any existing form data when changing form selection
      setMonthlyData([]);
    }
    
    setSelectedFields({...newObj, [name]: e});
    handleValueChange(e?.value || '', name);
    
    // Refresh form options when relevant filters change
    if (['branch', 'category', 'subCategory'].includes(name)) {
      fetchFeedbackForms({
        ...filterObject,
        [name]: e?.value
      });
    }
  };

  const handleValueChange = (value, name) => {
    const newObj = { ...filterObject }
    if (name === 'fromDate' || name === 'toDate') {
      // The month input value is already in YYYY-MM format, so we can use it directly
      newObj[name] = value;
    } else {
      // Handle other fields as before
      if (name === 'branch') {
        if (value !== null && value !== '') {
          delete newObj.category
          delete newObj.subCategory
          delete newObj.form
        }
        newObj.branch = value
        fetchCategoryOptions(newObj)
        fetchSubCategoryOptions(newObj)
        // fetchFeedbackForms(newObj)
      } else if (name === 'category') {
        if (value !== null && value !== '') {
          delete newObj.subCategory
          delete newObj.form
        }
        newObj.category = value
        fetchSubCategoryOptions(newObj)
        // fetchFeedbackForms(newObj)
      } else if (name === 'subCategory') {
        if (value !== null && value !== '') {
          delete newObj.form
        }
        newObj.subCategory = value
        // fetchFeedbackForms(newObj)
      } else {
        newObj[name] = value
      }
    }
    setFilterObject(newObj)
  }

  const reset = () => {
    setFilterObject({});
    setSelectedFields({});
    setSelectedYear(null);
    setMonthlyData([]);
    // fetchBranchOptions();
    // fetchDepartmentOptions();
    // fetchCategoryOptions();
    // fetchSubCategoryOptions();
    // fetchFeedbackForms();
    formRef.current.reset();
  };
  
  const renderCharts = () => {
    if (!monthlyData.length) return null;

    return (
      <div ref={chartContainerRef}>
        {monthlyData.map(formData => (
          <Card className="mt-4" key={formData.formName}>
            <CardHeader>
              <CardTitle tag="h4">Feedback Form: {formData.formName}</CardTitle>
            </CardHeader>
            <CardBody>
              {formData.questions.reduce((acc, curr) => {
                const existingQuestion = acc.find(q => q.question === curr.question);
                if (!existingQuestion) {
                  const questionData = formData.questions.filter(q => q.question === curr.question);
                  acc.push({
                    question: curr.question,
                    questionType: curr.questionType,
                    data: questionData
                  });
                }
                return acc;
              }, []).map(questionGroup => (
                <div key={questionGroup.question} className="mb-5">
                  <h5>{questionGroup.question}</h5>
                  <ResponsiveContainer width="100%" height={500}>
                    <BarChart 
                      data={questionGroup.data}
                      barSize={65}
                    >
                      <XAxis dataKey="month" />
                      <YAxis label={{ value: "Feedback Count", angle: -90, position: "insideLeft" }}
                      domain={[0, 'auto']}
                      allowDecimals={false}
                      />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend />
                      {questionGroup.questionType === 0 ? (
                        <>
                          <Bar dataKey="yes" stackId="a" fill="#4CAF50" name="Yes" />
                          <Bar dataKey="no" stackId="a" fill="#F44336" name="No" />
                        </>
                      ) : (
                        <>
                          <Bar dataKey="excellent" stackId="a" fill="#8884d8" name="Excellent" />
                          <Bar dataKey="best" stackId="a" fill="#82ca9d" name="Best" />
                          <Bar dataKey="good" stackId="a" fill="#ffc658" name="Good" />
                          <Bar dataKey="average" stackId="a" fill="#FF6B6B" name="Average" />
                          <Bar dataKey="needs_improvement" stackId="a" fill="#A569BD" name="Needs Improvement" />
                        </>
                      )}
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              ))}
            </CardBody>
          </Card>
        ))}
      </div>
    );
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Home" breadcrumbItem="Monthly Feedback Report" />
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <AvForm ref={formRef}>
                  <Row>
                  <Col md="3">
                      <div className="mb-3">
                        <Label>From Month</Label>
                        <Select
                          name="fromDate"
                          options={monthOptions}
                          value={selectedFields.fromDate || null}
                          onChange={(option, action) => handleMonthChange(option, { ...action, name: 'fromDate' })}
                          isClearable
                          placeholder="Select From Month"
                          isOptionDisabled={(option) => 
                            filterObject.toDate && option.value > filterObject.toDate
                          }
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>To Month</Label>
                        <Select
                          name="toDate"
                          options={monthOptions}
                          value={selectedFields.toDate || null}
                          onChange={(option, action) => handleMonthChange(option, { ...action, name: 'toDate' })}
                          isClearable
                          placeholder="Select To Month"
                          isOptionDisabled={(option) => 
                            filterObject.fromDate && option.value < filterObject.fromDate
                          }
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Branch</Label>
                        <Select
                          placeholder='Branch'
                          name="branch"
                          options={branchOptions}
                          onChange={(e) => handleSelectChange(e, 'branch')}
                          value={selectedFields.branch || ''}
                          isClearable
                        />
                      </div>
                    </Col>
                    <Col md='3'>
                      <Label>Department</Label>
                      <Select
                        options={departmentOptions}
                        name='department'
                        placeholder='Department'
                        onChange={e => handleSelectChange(e, 'department')}
                        value={selectedFields.department || ''}
                        isClearable
                      />
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Category</Label>
                        <Select
                          placeholder='Category'
                          name="category"
                          options={categoryOptions}
                          onChange={(e) => handleSelectChange(e, 'category')}
                          value={selectedFields.category || ''}
                          isClearable
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Sub-category</Label>
                        <Select
                          placeholder='Sub-category'
                          name="subCategory"
                          options={subCategoryOptions}
                          onChange={(e) => handleSelectChange(e, 'subCategory')}
                          value={selectedFields.subCategory || ''}
                          isClearable
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Feedback Form</Label>
                        <Select
                          name="form"
                          options={feedbackFormOption}
                          type="text"
                          placeholder='Feedback Form'
                          onChange={(e) => handleSelectChange(e, 'form')}
                          value={selectedFields.form || ''}
                          isClearable
                        />
                      </div>
                    </Col>
                    <Col sm="3">
                      <Label>Year</Label>
                      <Select
                        options={yearOptions}
                        onChange={handleYearChange}
                        value={selectedYear}
                        placeholder="Select Year"
                        isClearable
                      />
                    </Col>
                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          <Button color="danger" onClick={reset}>
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {monthlyData.length > 0 && (
            <div>
              <Row className="mb-3">
                <Col>
                  <Button color="primary" onClick={exportToPDF} disabled={isLoading}>
                    {isLoading ? (
                      <>
                        <i className="fa fa-spinner fa-spin mr-2"></i> Generating PDF...{exportProgress}%
                      </>
                    ) : (
                      <>
                        <i className="fa fa-file-pdf-o mr-2"></i> Export Report to PDF
                      </>
                    )}
                  </Button>
                </Col>
              </Row>
              
              {renderCharts()}
            </div>
          )}
        </div>
      </div>
    );
  };
  
  export default MonthlyFeedbackReport;