/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react"
import SunEditor from "suneditor-react"
import "suneditor/dist/css/suneditor.min.css"
import { Col, Label } from "reactstrap"
import * as config from "./config"
import SelectErrorMessage from "../../Common/SelectErrorMessage"
import UrlModel from "./UrlModel"
import BodyAttribute from "./BodyAttribute"
import "./style.scss"
import PropTypes from "prop-types"
import { generatePlaceholderTemplate } from "../../../helpers/functions"

const index = ({
  variableLimit = null,
  label,
  height = "150px",
  setMasterObj,
  customPlugins = [],
  required = true,
  description,
  remove,
  maxLength = null,
  error,
  setError,
  refresh,
}) => {
  const [editorHtml, setEditorHtml] = useState("")

  const [show, setShow] = useState({})
  const toggle = e => {
    let name = typeof e === "string" ? e : e?.currentTarget?.getAttribute("aria-label")
    setShow(prevState => ({ ...prevState, [name]: !prevState[name] }))
  }

  useEffect(() => {
    setMasterObj(prev => ({ ...prev, [label?.toLowerCase()]: null }))
    setEditorHtml("")
  }, [refresh?.editor])

  const handleChangeEditor = value => {
    let body = value
    let editorHtml = value

    body = body?.replace(/<strong>(.*?)<\/strong>/g, "*$1*")
    body = body?.replace(/<i>(.*?)<\/i>/g, "_$1_")
    body = body?.replace(/<em>(.*?)<\/em>/g, "_$1_")
    body = body?.replace(/<del>(.*?)<\/del>/g, "~$1~")
    body = body?.replace(/<del>(.*?)<\/del>/g, "~$1~")
    body = body?.replace(/&nbsp;/g, " ")
    body = body?.replace(/<p><br><\/p>/g, "")
    body = JSON.stringify(body?.replace(/<\/p><p>|<br>/g, "\n"))    
    body = body?.replace(/<[^>]+>/g, "")
    body = body?.replace(/\s+/g, " ").trim()
    body = body?.replace(/\[\[(.*?)\]\]/g, "$1")
    editorHtml = editorHtml?.replace(/\[\[(.*?)\]\]/g, "<a href='$1'>$1</a>")

    if (body) {
      let flag = true

      if (maxLength && body?.length > maxLength) {
        flag = false

        setError(prev => ({
          ...prev,
          [label?.toLowerCase()]: { msg: `${maxLength} character maximum`, status: true },
        }))
      } else {
        flag = true
        setError(prev => ({
          ...prev,
          [label?.toLowerCase()]: { msg: ``, status: false },
        }))
      }

      let exampleOptions = config.generatePlaceholderExample(body)

      if (variableLimit && Array.isArray(exampleOptions) && exampleOptions.length > variableLimit) {
        flag = false

        setError(prev => ({
          ...prev,
          [label?.toLowerCase()]: {
            msg: `${label?.toLowerCase()} only support ${variableLimit} ${
              variableLimit > 1 ? "variables" : "variable"
            }`,
            status: true,
          },
        }))
      }

      if (flag) {
        setMasterObj(prev => ({
          ...prev,
          [label?.toLowerCase()]: body,
          [`example${label}Options`]: exampleOptions,
          [`example${label}Text`]: [],
          [`preview${label}`]: generatePlaceholderTemplate(JSON.parse(body)),
        }))
        setEditorHtml(editorHtml)
      } else {
        setMasterObj(prev => ({
          ...prev,
          [label?.toLowerCase()]: null,
          [`example${label}Options`]: null,
        }))
      }
    } else {
      setMasterObj(prev => ({
        ...prev,
        [label?.toLowerCase()]: null,
        [`example${label}Options`]: null,
      }))
    }
  }

  return (
    <>
      <Col className="mt-3 text-editor" md="12">
        {!remove && (
          <Label className="bold">
            {label} {!required ? "(Optional)" : <span className="text-danger"> *</span>}
          </Label>
        )}
        <div className="mb-3">
          {!remove && <p>{description}</p>}
          <SunEditor
            lang="en"
            setDefaultStyle={`font-family: Arial; font-size: 14px; height: ${height}`}
            setOptions={config.sunEditorOptions(toggle, customPlugins, remove)}
            setContents={editorHtml || ""}
            onChange={handleChangeEditor}
          />
        </div>
        <SelectErrorMessage
          message={error[label?.toLowerCase()]?.msg}
          show={error[label?.toLowerCase()]?.status}
        />
      </Col>

      <UrlModel show={show} toggle={toggle} editorHtml={editorHtml} setEditorHtml={setEditorHtml} />
      <BodyAttribute
        label={label?.toLowerCase()}
        show={show}
        toggle={toggle}
        editorHtml={editorHtml}
        setEditorHtml={setEditorHtml}
      />
    </>
  )
}

index.prototype = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  remove: PropTypes.bool.isRequired,
  height: PropTypes.string.isRequired,
  setMasterObj: PropTypes.func.isRequired,
  customPlugins: PropTypes.arrayOf(PropTypes.func),
  maxLength: PropTypes.number,
  error: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
  variableLimit: PropTypes.number,
}
export default index
