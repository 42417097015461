import { API_URL } from "../../config"

export const OPTIONS_ROUTE = {
  campaign: "options/campaign",
  contactCategory: (type = 1) => {
    return `options/contactCategory?type=${type}`
  },
  contactLabel: "options/contactLabel",
  leadType: "options/lead-types",
  leadDepartment: "options/lead-departments",
  template: `${API_URL}options/templates`,
}

export const ROUTE = {
  singleTemplateSend: id => {
    return `${API_URL}whatsapp/inbox/chat/template/${id}`
  },
  isTemplateActive: id => {
    return `${API_URL}whatsapp/inbox/chat/template/${id}/info`
  },
  chat: ({ post = false, put = false, del = false }, id = null) => {
    const base_url = `${API_URL}whatsapp/inbox/chat/${id}`
    if (post) return `${base_url}/create`
  },
  template: (
    { post = false, put = false, del = false },
    id = null,
    { currentPage = 0, limit = 100, query = "" }
  ) => {
    const base_url = `${API_URL}whatsapp/template`
    if (post) return `${base_url}/create`
    if (put) return `${base_url}/update`
    if (del) return `${base_url}/delete`
    return `${base_url}/list?page=${currentPage}&limit=${limit}&${query}`
  },
  broadcast: (
    { post = false, put = false, del = false, sent = false },
    id = null,
    { currentPage = 0, limit = 100, query = "" }
  ) => {
    const base_url = `${API_URL}whatsapp/broadcast`
    if (post) return `${base_url}/create`
    if (put) return `${base_url}/update`
    if (del) return `${base_url}/delete/${id}`
    if (sent) return `${base_url}/${id}`
    return `${base_url}/list?page=${currentPage}&limit=${limit}&${query}`
  },
  contact: (
    { post = false, put = false, del = false },
    id = null,
    { currentPage = 0, limit = 100, query = "" }
  ) => {
    const base_url = `${API_URL}whatsapp/contact`
    if (post) return `${base_url}/create`
    if (put) return `${base_url}/update/${id}`
    if (del) return `${base_url}/delete/${id}`
    return `${base_url}/list?${
      id != null ? "bulkId=" + id + "&" : ""
    }page=${currentPage}&limit=${limit}&${query}`
  },
  manage: ({ category = false, label = false, bulkLog = false }) => {
    if (category) return `${API_URL}whatsapp/manage/category`
    if (label) return `${API_URL}whatsapp/manage/label`
    if (bulkLog) return `${API_URL}whatsapp/contact/bulk-upload`
  },
  inboxUserList: ({ query, page, limit }) => {
    let queries = new URLSearchParams()
    if (query?.unread) {
      queries.append("unread", query.unread)
    }
    if (query?.search) {
      queries.append("search", query.search)
    }
    return `${API_URL}whatsapp/inbox/user-list?page=${page}&limit=${limit}&${queries.toString()}`
  },
}
