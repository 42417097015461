/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from "react"
import srvlogo from "../../../assets/fotos/srv11.png"
import PropTypes from "prop-types"
import { Tooltip } from "@mui/material"
import { get } from "../../../helpers/api_helper"
import WHATSAPP_BACKGROUND from "../../../assets/images/whatsapp_background.jpg"
import { API_URL } from "../../../config"
import { getTimeAgoOrDate, whatsappTextToHtml } from "../../../helpers/functions"

import URL_ICON from "../../../assets/images/whatsapp/url.svg"
import CALL_ICON from "../../../assets/images/whatsapp/call.svg"
import OFFER_CODE_ICON from "../../../assets/images/whatsapp/offercode.svg"
import REPLAY_ICON from "../../../assets/images/whatsapp/replay.svg"
import Footer from "./Footer"
const index = ({ refresh, selectedUser, toggle }) => {
  const [chatItems, setChatItems] = useState([])

  let limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  let [scrollBottom, setScrollBottom] = useState(true)

  useEffect(() => {
    if (selectedUser?._id) {
      setPage(1)
      setTotalPage(1)
      setScrollBottom(prev => {
        let value = true
        fetchMessages(value ? 1 : page)
        return value
      })
    }
  }, [selectedUser?._id, refresh])

  const fetchMessages = async (currentPage = page, scrolled = false) => {
    try {
      const res = await get(
        `${API_URL}whatsapp/inbox/chat/${selectedUser?._id}?page=${currentPage}&limit=${limit}`
      )
      if (res) {
        if (scrolled) {
          setChatItems(prev => [...res?.data, ...prev])
        } else {
          setChatItems(res?.data)
        }

        const totalPage = Math.ceil(Number(res?.count) / limit)
        setTotalPage(totalPage)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const chatContainerRef = useRef(null)

  useEffect(() => {
    if (scrollBottom && chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      })
    }
  }, [chatItems?.length, refresh])

  const getClassName = (type, revChat) => {
    let whiteListedType = ["me", "other"]
    if (!whiteListedType.includes(type)) return ""
    let prevType = revChat?.sender ?? null

    if (prevType === type) return `${type} same-line`

    return type
  }

  const statusIcon = (status, error = null) => {
    switch (status) {
      case "sent":
        return (
          <div className={`read-check`}>
            <i className="uil-check"></i>
          </div>
        )
      case "delivered":
        return (
          <div className={`read-check`}>
            <i className="uil-check"></i>
            <i className="uil-check double-check"></i>
          </div>
        )
      case "read":
        return (
          <div className={`read-check active`}>
            <i className="uil-check"></i>
            <i className="uil-check double-check"></i>
          </div>
        )
      case "failed":
        return (
          <div className={`read-check failed`}>
            <Tooltip title={error?.title ?? "Failed"} placeholder="bottom" arrow={true}>
              <i className="uil-times-circle cursor-pointer"></i>
            </Tooltip>
          </div>
        )
      default:
        return (
          <div className={"read-check"}>
            <i className="uil-clock-seven"></i>
          </div>
        )
    }
  }

  const getButtons = btn => {
    let { type, text, url, phone_number, offer_code } = btn
    if (type === "URL")
      return (
        <a href={url} target="_blank" rel="noreferrer">
          <button>
            <img src={URL_ICON} alt="button icons" /> {text ?? "Visit"}
          </button>
        </a>
      )

    if (type === "PHONE_NUMBER")
      return (
        <a href={`tel:${phone_number}`}>
          <button>
            <img src={CALL_ICON} alt="button icons" /> {text ?? "Call Us"}
          </button>
        </a>
      )

    if (type === "COPY_CODE")
      return (
        <button>
          <Tooltip title={offer_code}>
            <img src={OFFER_CODE_ICON} alt="button icons" /> {text ?? "Replay"}
          </Tooltip>
        </button>
      )
    if (type === "QUICK_REPLY")
      return (
        <button>
          <Tooltip title={text}>
            <img src={REPLAY_ICON} alt="button icons" /> {text ?? "Offer code"}
          </Tooltip>
        </button>
      )
  }

  const focusMessage = message_id => {
    let div = document.getElementById(message_id)
    if (div) {
      div.focus()
      div.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }

  const getFileContent = (url, type = null) => {
    let finalUrl = /https?:\/\/[^\s]+/g.test(url) ? url : `${API_URL}${url}`
    if (type === "image") return <img src={finalUrl} alt="uploaded-content" />
    else if (type === "video") return <video controls src={finalUrl} alt="uploaded-content" />
    else if (type === "audio") return <audio controls src={finalUrl} />
    else return <object data={finalUrl} />
  }

  useEffect(() => {
    const handleScroll = () => {
      if (chatContainerRef.current) {
        if (chatContainerRef.current.scrollTop === 0) {
          setScrollBottom(false)
          setPage(prev => {
            //page down
            if (prev <= totalPage) {
              prev += 1
            }
            fetchMessages(prev, true)
            return prev
          })
        }
      }
    }

    const scrollDiv = chatContainerRef.current
    if (scrollDiv) {
      scrollDiv.addEventListener("scroll", handleScroll)
    }

    return () => {
      if (scrollDiv) {
        scrollDiv.removeEventListener("scroll", handleScroll)
      }
    }
  }, [totalPage])

  return (
    <div className="main_chat_content">
      <div className="content_header">
        <div className="current-chatting-user">
          <button className="mobile-back" aria-label="mobileBack" onClick={toggle}>
            <i className="uil-arrow-left" />
          </button>
          <div className="avatar">
            <div className="avatar_img">
              {selectedUser?.image ? (
                <img src={srvlogo} alt="#" />
              ) : (
                <div className="img-letter">{selectedUser?.name?.charAt(0) ?? "Un"}</div>
              )}
            </div>
            <img className={"platform-icon"} src="/icons/whatsapp.png" alt="platform icon" />
          </div>
          <p className="bold">{selectedUser.name ?? "Unknown"}</p>
        </div>

        <div className="settings" aria-label="lead" onClick={toggle}>
          <Tooltip title="Add Leads">
            <i style={{ fontSize: "20px", cursor: "pointer" }} className="fa fa-user-plus"></i>
          </Tooltip>
        </div>
      </div>

      <div className="chat-content" style={{ backgroundImage: `url(${WHATSAPP_BACKGROUND})` }}>
        <div className="chat-content-body" ref={chatContainerRef}>
          {chatItems.map((item, index) => (
            <div
              className={`chat_item ${getClassName(item.sender, chatItems[index - 1])}`}
              key={index}
              id={item?._id}
            >
              {item?.info ? (
                <div className="info-message" onClick={() => focusMessage(item?.info_message)}>
                  {item?.text}
                </div>
              ) : (
                <div
                  className={`chat_item_content ${
                    item?.emoji ? "reaction-msg" : item?.messageType === "audio" ? "audio" : ""
                  }`}
                >
                  {item?.repliedData?.text || item?.repliedData?.body ? (
                    <div className="replied" onClick={() => focusMessage(item?.repliedData?._id)}>
                      <h2>{item.repliedData?.header}</h2>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: whatsappTextToHtml(
                            item.repliedData?.body ?? item?.repliedData?.text
                          ),
                        }}
                      />
                    </div>
                  ) : null}

                  {item?.mediaUrl && (
                    <div className="file-content">
                      {getFileContent(item?.mediaUrl, item?.messageType)}
                    </div>
                  )}

                  {item?.isTemplateUsed && (
                    <div
                      className="chat_header"
                      dangerouslySetInnerHTML={{
                        __html: whatsappTextToHtml(item.header),
                      }}
                    />
                  )}
                  <div
                    className="chat_msg"
                    dangerouslySetInnerHTML={{
                      __html:
                        item?.isTemplateUsed && item?.body
                          ? whatsappTextToHtml(item.body)
                          : whatsappTextToHtml(item.text),
                    }}
                  />
                  {item?.isTemplateUsed && item?.footer && (
                    <div className="chat_footer">{item?.footer}</div>
                  )}
                  {item?.isTemplateUsed && item?.buttons?.length > 0 && (
                    <div className="chat_buttons">{item?.buttons?.map(btn => getButtons(btn))}</div>
                  )}
                  <div className="chat_meta">
                    <span>{getTimeAgoOrDate(item.timestamp)}</span>

                    {item.sender === "me" && statusIcon(item.status, item?.error)}
                  </div>
                  {item?.emoji && <div className="reaction">{item?.emoji}</div>}
                </div>
              )}
              {/* <div className="avatar">
                <div className="avatar_img">
                  <img src={item.type === "me" ? srvlogo : srvlogo} alt="#" />
                </div>
                <span className="isOnline active"></span>
              </div> */}
            </div>
          ))}
        </div>

        <Footer selectedUser={selectedUser} setScrollBottom={setScrollBottom} />
      </div>
    </div>
  )
}

index.propTypes = {
  selectedUser: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
}

export default index
