/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { Card, CardHeader, CardBody, Button, Row, Col, Label } from "reactstrap"
import Select from "react-select"
import { AvForm, AvField } from "availity-reactstrap-validation"
import srvlogo from "../../../assets/fotos/srv11.png"

import "./style.scss"
import { API_URL } from "../../../config"
import { get } from "../../../helpers/api_helper"
const index = ({ selectedUser, toggle }) => {
  const [masterObj, setMasterObj] = useState({ name: "srv infotech", mobile: "9876543210" })
  const [selected, setSelected] = useState({})
  const [options, setOptions] = useState({})

  const formRef = useRef()

  const handleChange = (e, name = null) => {
    let value = name ? e.value : e?.target?.value
    let set_name = name ? name : e?.target?.name

    if (name) {
      setSelected(prev => ({ ...prev, [set_name]: e }))
    }

    setMasterObj(prev => ({ ...prev, [set_name]: value }))
  }

  const reset = () => {
    setMasterObj({})
    setSelected({})
  }

  const handleSubmit = () => {}
  return (
    <div className="whatsapp-lead-container">
      <Card>
        <CardHeader>
          <div>
            <div className="avatar">
              <div className="avatar_img">
                {selectedUser.image ? (
                  <img src={srvlogo} alt="#" />
                ) : (
                  <div className="img-letter">{selectedUser?.name?.charAt(0) ?? "Un"}</div>
                )}
              </div>
              <img className={"platform-icon"} src="/icons/whatsapp.png" alt="platform icon" />
            </div>

            <div className="meta-data">
              <h6 className="bold" style={{ textWrap: "nowrap" }}>
                {selectedUser.name ?? "Unknown"}
              </h6>
              {selectedUser.mobile ?? ""}
            </div>
          </div>
          <button className="close-btn" aria-label="lead" onClick={toggle}>
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </CardHeader>
        <CardBody>
          <AvForm
            ref={formRef}
            className="needs-validation"
            onValidSubmit={(e, v) => {
              handleSubmit()
            }}
          >
            {[
              { tag: "input", type: "text", name: "Address", isRequired: false },
              { tag: "select", name: "Department" },
              { tag: "select", name: "Lead Type" },
              { tag: "textarea", name: "Note", isRequired: false },
            ].map((field, index) => {
              if (field.tag === "input")
                return (
                  <div className="mb-3" key={index}>
                    <Label>
                      {field.name}{" "}
                      {field?.isRequired && !field?.isDisabled && (
                        <span className="text-danger"> *</span>
                      )}
                    </Label>
                    <AvField
                      name={field?.name?.toLowerCase()}
                      type={field.type}
                      placeholder={field?.name}
                      value={masterObj[field?.name?.toLowerCase()] ?? ""}
                      onChange={handleChange}
                      disabled={field?.isDisabled ? true : false}
                      errorMessage={`Enter a ${field?.name?.toLowerCase()}`}
                      validate={{
                        required: { value: field?.isRequired ? true : false },
                      }}
                    />
                  </div>
                )
              else if (field.tag === "textarea")
                return (
                  <div className="mb-3" key={index}>
                    <Label>
                      {field.name} {field?.isRequired && <span className="text-danger"> *</span>}
                    </Label>
                    <textarea
                      className="form-control"
                      name={field?.name?.toLowerCase()}
                      placeholder={field?.name}
                      value={masterObj[field?.name?.toLowerCase()] ?? ""}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                )
              else
                return (
                  <div className="mb-3">
                    <Label>{field.name}</Label>
                    <Select
                      className="label-select-disabled"
                      name={field?.name?.toLowerCase()}
                      placeholder={`Select a ${field?.name?.toLowerCase()}`}
                      options={options[field?.name?.toLowerCase()] ?? []}
                      value={selected[field?.name?.toLowerCase()] ?? ""}
                      isMulti={true}
                      isDisabled={field?.isDisabled ? true : false}
                      onChange={e => handleChange(e, field?.name?.toLowerCase())}
                    />
                  </div>
                )
            })}

            <div
              className="mb-3"
              style={{
                paddingTop: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <Button color="primary" style={{ marginRight: "10px" }} type="submit">
                  Submit
                </Button>

                <Button onClick={reset} color="danger" type="button">
                  Reset
                </Button>
              </div>
            </div>
          </AvForm>
        </CardBody>
      </Card>
    </div>
  )
}

index.propTypes = {
  chatId: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
}

export default index
