/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useEffect, useState } from "react"
import TablePagination from "../../../components/Common/TablePagination"
import _, { debounce } from "lodash"
import "./style.scss"
import { toTop } from "../../../helpers/functions"
import moment from "moment"
import Select from "react-select"
import { AvField, AvForm } from "availity-reactstrap-validation"
import * as mainConfig from "../../../pages/Whatsapp/config"
import { get } from "../../../helpers/api_helper"
import { Button, Card, CardBody, Col, Row } from "reactstrap"
import PropTypes from "prop-types"

const index = ({ bulkUploadId }) => {
  const [filterQuery, setFilterQuery] = useState("")
  const [tableData, setTableData] = useState([])

  const limit = 100
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const handlePageChange = value => {
    toTop()
    setPage(value)
    fetchContactData(value)
  }

  useEffect(() => {
    fetchContactData()
  }, [])

  const fetchContactData = async (currentPage = page, currentFilterQuery = filterQuery) => {
    const query = new URLSearchParams()
    query.append("search", currentFilterQuery)
    const queryString = query.toString()

    try {
      let res = await get(
        `${mainConfig.ROUTE.contact({}, bulkUploadId, { currentPage, limit, query: queryString })}`
      )

      if (res) {
        const { data, count } = res

        data.map((doc, idx) => {
          doc.id = (currentPage - 1) * limit + idx + 1
          doc.date1 = moment(doc?.date).format("DD-MM-YYYY")

          doc.categoryDisplay = (
            <span className="badge bg-primary font-size-12">{doc?.category.name ?? "---"}</span>
          )
          // data.addedBy = data?.addedBy?.firstName
          //   ? `${data?.addedBy?.firstName} ${data?.addedBy?.lastName}`
          //   : data?.addedBy
          //     ? data?.addedBy
          //     : ""

          doc.label = (
            <Select
              className="label-select-disabled"
              value={doc.labels}
              placeholder={"Select labels..."}
              isMulti={true}
              isDisabled={true}
            />
          )

          return doc
        })

        const totalPage = Math.ceil(Number(count) / limit)
        setTotalPage(totalPage)
        setTableData(data)
      }
    } catch (err) {
      console.error(err?.message)
    }
  }

  const debouncedFetchData = useCallback(debounce(fetchContactData, 300), [])

  useEffect(() => {
    debouncedFetchData(page, filterQuery)
    return debouncedFetchData.cancel
  }, [filterQuery])

  const handleFilterReset = () => setFilterQuery("")

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 150,
      },
      {
        label: "Category",
        field: "categoryDisplay",
        sort: "asc",
        width: 150,
      },
      {
        label: "Label",
        field: "label",
        sort: "asc",
        width: 150,
      },
    ],
    rows: tableData,
  }

  return (
    <Card className="contact-list">
      <CardBody>
        <AvForm>
          <Row>
            <Col md={3}>
              <AvField
                name="search"
                type="text"
                placeholder="Search name or mobile"
                value={filterQuery}
                onChange={e => setFilterQuery(e.target.value)}
              />
            </Col>
            <Col md={3}>
              <Button color="danger" type="button" onClick={handleFilterReset}>
                Reset
              </Button>
            </Col>
          </Row>
        </AvForm>

        <>
          <TablePagination
            className="whatsapp-tableModule"
            data={data}
            page={page}
            onChange={handlePageChange}
            count={totalPage}
          />
        </>
      </CardBody>
    </Card>
  )
}

index.prototype = {
  bulkUploadId: PropTypes.string.isRequired,
}
export default index
