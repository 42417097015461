import React, { useState } from 'react';

const Pagination = ({
    currentPage,
    totalPages,
    totalItems,
    onPageChange,
    onEntriesChange,
    entriesPerPage,
  }) => {
    const [currentEntriesPerPage, setCurrentEntriesPerPage] = useState(entriesPerPage || 20);
  
    const handlePageChange = (page) => {
      if (onPageChange) {
        onPageChange(page);
      }
    };
  
    const handleEntriesChange = (event) => {
      const newLimit = parseInt(event.target.value, 10);
      setCurrentEntriesPerPage(newLimit);
      if (onEntriesChange) {
        onEntriesChange(newLimit);
      }
    };
  
    // Calculate start and end items
    const startItem = totalItems > 0 ? (currentPage - 1) * currentEntriesPerPage + 1 : 0;
    const endItem = Math.min(currentPage * currentEntriesPerPage, totalItems);
  
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px 0',
        }}
      >
        {/* Left: Show Entries */}
        <div
          style={{
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Show{' '}
          <select
            value={currentEntriesPerPage}
            onChange={handleEntriesChange}
            className="form-select form-select-sm"
            style={{
              width: 'auto',
              marginLeft: '5px',
              marginRight: '5px',
              fontSize: '0.9rem',
            }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>{' '}
          entries
        </div>
  
        {/* Center: Pagination */}
        <nav aria-label="Page navigation" style={{ flexGrow: 1, textAlign: 'center' }}>
          <ul
            className="pagination"
            style={{
              fontSize: '0.9rem',
              display: 'inline-flex',
              listStyle: 'none',
              gap: '5px',
              padding: 0,
              margin: 0,
            }}
          >
            {currentPage > 1 && (
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage - 1)}
                  style={{ padding: '8px 13px' }}
                >
                  Previous
                </button>
              </li>
            )}
            {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
              <li
                key={page}
                className={`page-item ${page === currentPage ? 'active' : ''}`}
                style={{ fontSize: '0.85rem' }}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(page)}
                  style={{ padding: '8px 13px' }}
                >
                  {page}
                </button>
              </li>
            ))}
            {currentPage < totalPages && (
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage + 1)}
                  style={{ padding: '8px 13px' }}
                >
                  Next
                </button>
              </li>
            )}
          </ul>
        </nav>
  
        {/* Right: Showing Entries */}
        <div
          style={{
            textAlign: 'right',
            fontSize: '0.85rem',
          }}
        >
          {totalItems > 0 ? (
            <>
              Showing {startItem} to{' '}
              {endItem} of {totalItems} entries
            </>
          ) : (
            'No entries to show'
          )}
        </div>
      </div>
    );
};

export default Pagination;